import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IEnvironment } from '../environment.interface';
import { ENVIRONMENT } from '../constants';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends BaseService {
  promotionApi = `${this.environment.rootApi}/promotion/{id}`;
  firstPromoterLeadApi = `${this.environment.rootApi}/firstpromoter-lead?email={email}&ref_id={ref_id}&order_id={order_id}`;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {
    super();
  }

  applyPromoCodeAsync(orderId: string, promoCode: string): Promise<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(`${this.promotionApi.replace("{id}", orderId)}`, JSON.stringify({ promoCode: promoCode }), { headers })
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  markFirstPromoterLeadAsync(email: string, ref_Id: string, orderId: string) {
    console.log('Generate First Promoter Lead', email, ref_Id);
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(`${this.firstPromoterLeadApi.replace("{email}", email).replace("{ref_id}", ref_Id).replace("{order_id}", orderId)}`, null, { headers })
      .pipe(catchError(this.handleError))
      .toPromise();
  }
}
