import { Component, forwardRef, Input, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl } from '@angular/forms';
import { AudioService } from '../../services/audio.service'
import { AudioTemplateService } from '../../services/audio-template.service';
import * as _ from 'lodash';
import { ControlValueAccessorAndValidator } from '../../generic-utils/control-value-accessor-and-validator.class';
import { v4 as uuid } from 'uuid';
import { IEnvironment } from '../../environment.interface';
import { ENVIRONMENT } from '../../constants';

import { AudioRecording } from '@voiply/shared/model';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Gender } from '@voiply/shared/model'
import { take, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'voiply-voicemail-form-control',
  templateUrl: './voicemail-form-control.component.html',
  styleUrls: ['./voicemail-form-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VoicemailFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => VoicemailFormControlComponent),
      multi: true
    }
  ]
})
export class VoicemailFormControlComponent extends ControlValueAccessorAndValidator implements OnInit {

  @Input() insertTemplateVariableFrom: string | "root" = "root";
  @Input() recordingData: any = {};
  @Input() audioCategory: string;
  @Input() isDisable: boolean;

  audioHtml: any = null;
  audioLoading = false;
  lastUploadedFile: any = null;
  fileName = '';
  audioHtmlUploadedFile = '';
  audioTemplateSelectedValue = '';
  default_file_format = 'wav';
  audioTemplates: { title: string, category: string, text: string }[] = [];
  selectedTemplate: string;
  @ViewChild('popConfirm') popConfirm;


  constructor(@Inject(ENVIRONMENT) private readonly environment: IEnvironment,
    private service: AudioService,
    private audioTemplateService: AudioTemplateService) {

    super();
    this.form = new FormGroup({
      selectedGender: new FormControl(''),
      audioText: new FormControl('', { updateOn: 'blur' }),
      sendVoiceMailTo: new FormControl(''),
      blobFileName: new FormControl(''),
      fileName: new FormControl(''),
      uploadRecording: new FormControl(''),
      showRecording: new FormControl('')
    });

  }

  ngOnInit() {
    this.selectedTemplate = this.recordingData.audioText;
    if (this.recordingData.blobFileName) {
      if (this.recordingData.uploadRecording || this.form.controls['uploadRecording'].value === true) {
        this.audioHtmlUploadedFile = `<audio controls="" preload="auto" class="w-100" src='${this.recordingData.blobFileName}' preload='auto'></audio>`;
      } else {
        this.audioHtml = `<audio controls="controls" preload="auto" class="w-100" src='${this.recordingData.blobFileName}?cachebusting=${Math.round((new Date()).getTime() / 1000)}' preload='auto'></audio>`;
        //this.convertTextToAudio((this.recordingData.selectedGender || this.form.controls['selectedGender'].value), true, (this.recordingData.audioText || this.form.controls['audioText'].value));
      }
    }
    else {
      this.convertTextToAudio((this.recordingData.selectedGender || this.form.controls['selectedGender'].value), true, (this.recordingData.audioText || this.form.controls['audioText'].value));
    }
    this.fileName = this.recordingData.fileName;
    if (this.recordingData.uploadRecording) {
      this.form.controls['uploadRecording'].patchValue(this.recordingData.uploadRecording);
    }
    if (!this.recordingData.selectedGender) this.form.controls['selectedGender'].patchValue(Gender.Male);
    if (!this.recordingData.audioText) this.recordingData.audioText = "";
    if (this.form.controls['selectedGender'].value === "") this.form.controls['selectedGender'].patchValue(this.recordingData.selectedGender);
    if (this.form.controls['audioText'].value === "") this.form.controls['audioText'].patchValue(this.recordingData.audioText);
    this.changeAudioTemplate(this.audioCategory);

    // this.form.valueChanges.pipe(take(1)).subscribe((values) => { this.convertTextToAudio(values.selectedGender, true, values.audioText); });
  }


  insertAudioTemplate() {
    if (this.form.controls['audioText'].value != this.selectedTemplate) {
      this.form.controls['audioText'].patchValue(this.audioTemplateService.replacePlaceholders(this.selectedTemplate, this.insertTemplateVariableFrom));
    }
    this.convertTextToAudio(this.form.controls['selectedGender'].value, true, this.form.controls['audioText'].value);
  }

  audioTemplateSelected(value) {
    this.selectedTemplate = value;
    this.insertAudioTemplate();
  }

  changeUploadFileOption(value) {
    if(this.isDisable)
      return;
    if (this.recordingData.uploadRecording != value) {

      this.form.controls['uploadRecording'].patchValue(value);
      if (!value && this.fileName) {
        //Reset file on switch to ttts recording from uploadrecording
        this.onFileChange({ name: this.fileName, file: null });
      }
      if (!value) {
        this.convertTextToAudio(this.recordingData.selectedGender, true, this.recordingData.audioText);
      } else {
        this.audioHtmlUploadedFile = ''
      }
    }
  }

  convertTextToAudio(gender, showLoading, audioText) {
    //no need to patch values in form here. As this will invoke form_valuechanges() and will get replaced by old values

    if (!this.recordingData.blobFileName) {
      this.recordingData.blobFileName = this.environment.blobStoragePath + uuid() + "." + this.default_file_format
      // this.form.controls['blobFileName'].patchValue(this.recordingData.blobFileName);

    } else if (!this.recordingData.blobFileName.endsWith(this.default_file_format)) {
      // this.form.controls['blobFileName'].patchValue(this.recordingData.blobFileName.split('.').slice(0, -1).join('.') + "." + this.default_file_format);
    }
    if (!this.recordingData.audioText)
      return;

    if (showLoading) {
      this.audioHtml = "";
      this.audioLoading = true;

    }
    const filename = this.recordingData.blobFileName
    this.service.generateAudioFileFromText(audioText, gender, this.recordingData.blobFileName.split('/').pop()).subscribe(
      (data) => {
        this.audioLoading = false;
        if (this.recordingData.blobFileName)
          this.audioHtml = `<audio controls="controls" preload="auto" class="w-100" src='${this.recordingData.blobFileName}?cachebusting=${Math.round((new Date()).getTime() / 1000)}' preload='auto'></audio>`;
        else
          this.audioHtml = `<audio controls="controls" preload="auto" class="w-100" src='${filename}?cachebusting=${Math.round((new Date()).getTime() / 1000)}' preload='auto'></audio>`;

      });
  }



  onFileChange(fileObj: { name: string, file: any }) {
    this.fileName = fileObj.name;
    this.lastUploadedFile = fileObj.file;
    this.audioHtmlUploadedFile = '';

    if (fileObj.file == null) {
      this.service.deleteRecordingFile(this.recordingData.blobFileName.split("/").pop()).subscribe(res => {
        this.audioHtmlUploadedFile = '';
        this.fileName = "";
        this.lastUploadedFile = null;
        this.form.controls['fileName'].patchValue("");
        this.form.controls['blobFileName'].patchValue("");
        // this.emitDataChanged();
      });
    } else {
      if (this.recordingData.blobFileName == '') {
        this.form.controls['blobFileName'].patchValue(this.environment.blobStoragePath + uuid() + "." + fileObj.name.split('.').pop());
      } else {
        this.form.controls['blobFileName'].patchValue(this.recordingData.blobFileName.split('.').slice(0, -1).join('.') + "." + fileObj.name.split('.').pop());
      }
      this.audioLoading = true;

      this.service.uploadRecordingFile(this.recordingData.blobFileName.split('/').pop(), fileObj.file).subscribe((res: any) => {
        this.form.controls['fileName'].patchValue(fileObj.name);
        // this.emitDataChanged();
        this.audioLoading = false;
        this.audioHtmlUploadedFile = `<audio controls="" preload="auto" class="w-100" src='${this.recordingData.blobFileName}?cachebusting=${Math.round((new Date()).getTime() / 1000)}' preload='auto'></audio>`;
      });
    }
  }

  changeAudioTemplate(category) {
    if (!category)
      return;
    this.service.getAudioTemplates().subscribe(value => {
      this.audioTemplates = _.filter(value, (item) => _.includes(item.category.toLowerCase().split(","), category.toLowerCase()));
      if (this.audioTemplates.length > 0)
        this.selectedTemplate = this.audioTemplates[0].text;
    });
  }

  switchtoTextToSpeech() {
    if (this.fileName) {
      this.popConfirm.show();
      return
    } else {
      this.changeUploadFileOption(false);
    }
  }

}
