<!--containing in - Lib: product-configuration -->
<!--used by - Component: home-configuration/extraline-settings-home, business-configuration/extraline-settings-business -->
<!--Added for - App: Extraline-settings -->
<div [formGroup]="form" class="d-flex flex-column">
  <div class="d-flex justify-content-start bg-dark text-white rounded p-2">
    <!-- heading -->
    <span class="pr-2 d-flex align-items-center">
      <img src="assets/images/extraline/icons/fax.svg" class="icon-white">
    </span>
    <span class="text-uppercase font-weight-bold">Online Fax</span>
  </div>
  <div class="input-group rounded overflow-auto mt-3">
    <input type="text" class="form-control border-0 bg-light-grey" style="box-shadow: none !important;"
      placeholder="Select Number" formControlName="mobile" [readOnly]="disableInput" mask='(000) 000-0000'
      [ngClass]="{ 'is-invalid': ((f.mobile.touched || submitted) && f.mobile.errors) }">
    <div *ngIf="((f.mobile.touched || submitted) && f.mobile.errors)">
      <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Phone Number"> </i>
    </div>
    <div class="input-group-append" *ngIf="!disableInput">
      <i class="fas fa-align-center input-group-text clean border-0 bg-light-grey" (click)="toggleNewNumber();"></i>
    </div>
  </div>
  <div class="input-group rounded overflow-auto mt-3">
    <input type="text" class="form-control border-0 bg-light-grey" style="box-shadow: none !important;"
      placeholder="Email" formControlName="email" [readOnly]="disableInput"
      [ngClass]="{ 'is-invalid': ((f.email.touched || submitted) && f.email.errors) }">
    <div *ngIf="((f.email.touched || submitted) && f.email.errors)">
      <span *ngIf="f.email.errors.required">
        <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
      <span *ngIf="f.email.errors.email">
        <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
    </div>
  </div>
  <div class="mt-3 mb-1" style="font-size: small; color: #B5B5B5">
    Incoming faxes going to be sent as PDF attachments to your email account as well.
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <span class="font-weight-bold">Automatic Cover Page</span>
    <!-- * toggle buttons -->
    <div class=" btn-group btn-group-toggle bg-light-grey border rounded" data-toggle="buttons">
      <button type="button" (click)="this.form.get('coverpage').patchValue('No')" class="btn text-muted"
        style="border-radius: 0.25rem 0 0 0.25rem; font-size: 12px;"
        [ngClass]="{'bg-error-gradient text-primary': this.form.get('coverpage').value === 'No'}">
        <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
          <input type="radio" autocomplete="off" value="No" formControlName="coverpage">
          <span>NO</span>
        </div>
      </button>
      <button type="button" (click)="this.form.get('coverpage').patchValue('Yes')" class="btn text-muted"
        style="border-radius: 0 0.25rem 0.25rem 0; font-size: 12px;"
        [ngClass]="{'bg-success-gradient text-primary': this.form.get('coverpage').value === 'Yes'}">
        <div class="d-flex align-items-center justify-content-center font-weight-bold">
          <input type="radio" autocomplete="off" value="Yes" formControlName="coverpage">
          <span>YES</span>
        </div>
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="isHideNewNumber">
  <mat-tab-group animationDuration="0ms" #tabs class="cart-item-background-color mt-3">
    <mat-tab label="LOCAL NUMBERS">
      <div class="">
        <voiply-local-number-search (numberSelected)="selectedNumber = $event"></voiply-local-number-search>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-2" data-dismiss="modal"
          (click)="toggleNewNumber()">close</button>
        <button type="button" class="btn bg-primary text-primary" (click)="toggleNewNumber();onNumberSelected();">
          Select Number
        </button>
      </div>
    </mat-tab>

    <!-- <mat-tab label="TOLL FREE NUMBERS">
      <div class="">
        <voiply-tollfree-number-search (numberSelected)="selectedNumber = $event"></voiply-tollfree-number-search>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-2" data-dismiss="modal"
          (click)="toggleNewNumber()">Close</button>
        <button type="button" class="btn bg-primary text-primary" (click)="toggleNewNumber();onNumberSelected();">
          Select Number
        </button>
      </div>
    </mat-tab> -->
  </mat-tab-group>
</ng-container>