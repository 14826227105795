import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ThemePalette } from '@angular/material/core';
import { ControlValueAccessorAndValidator } from '../../generic-utils';
import { ToastrService } from 'ngx-toastr';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'voiply-voicemail-attachment-form-control-alt',
  templateUrl: './voicemail-attachment-form-control-alt.component.html',
  styleUrls: ['./voicemail-attachment-form-control-alt.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VoicemailAttachmentFormControlAltComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => VoicemailAttachmentFormControlAltComponent),
      multi: true
    }
  ]
})
export class VoicemailAttachmentFormControlAltComponent extends ControlValueAccessorAndValidator implements OnInit {

  constructor(private toastr: ToastrService) {
    super()
  }
  color: ThemePalette = 'primary';
  selectable = true;
  removable = true;
  addOnBlur = true;
  visible = true;
  @Input() submitted = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  ngOnInit() {
    this.form = new FormGroup({
      attachVoicemail: new FormControl(''),
      email: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.email()], updateOn: 'blur' }),
    });
  }




  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


}
