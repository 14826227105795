import { Component, OnInit, Host, ElementRef, ChangeDetectorRef, Inject, Optional, ViewEncapsulation, ChangeDetectionStrategy, InjectionToken, Output, EventEmitter } from '@angular/core';
import { MatExpansionPanel, MatExpansionPanelDefaultOptions, MatExpansionPanelHeader, matExpansionAnimations, MatExpansionPanelState } from '@angular/material/expansion';
import { FocusMonitor } from '@angular/cdk/a11y';

export const MAT_EXPANSION_PANEL_DEFAULT_OPTIONS =
    new InjectionToken<MatExpansionPanelDefaultOptions>('MAT_EXPANSION_PANEL_DEFAULT_OPTIONS');

@Component({
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        matExpansionAnimations.indicatorRotate,
        matExpansionAnimations.bodyExpansion
    ],
    host: {
        'class': 'mat-expansion-panel-header',
        'role': 'button',
        '[attr.id]': 'panel._headerId',
        '[attr.tabindex]': 'disabled ? -1 : 0',
        '[attr.aria-controls]': '_getPanelId()',
        '[attr.aria-expanded]': '_isExpanded()',
        '[attr.aria-disabled]': 'panel.disabled',
        '[class.mat-expanded]': '_isExpanded()',
        '[class.mat-expansion-toggle-indicator-after]': `_getTogglePosition() === 'after'`,
        '[class.mat-expansion-toggle-indicator-before]': `_getTogglePosition() === 'before'`,
        '(keydown)': '_keydown($event)',
        '[@.disabled]': '_animationsDisabled',
        '(@expansionHeight.start)': '_animationStarted()',
        '[@expansionHeight]': `{
        value: _getExpandedState(),
        params: {
          collapsedHeight: '53px',
          expandedHeight: '53px'
        }
    }`,
    },
    selector: 'mat-expansion-panel-header-custom',
    templateUrl: './my-expansion-header.component.html',
    styleUrls: ['./my-expansion-header.component.css']
})
export class MyExpansionHeaderComponent extends MatExpansionPanelHeader {
    onClick$: any;

    constructor(
        @Host() public panel: MatExpansionPanel,
        _element: ElementRef,
        _focusMonitor: FocusMonitor,
        _changeDetectorRef: ChangeDetectorRef
    ) {
        super(panel, _element, _focusMonitor, _changeDetectorRef);
    }

    ngOnInit() {
    }
    @Output()
    public onRemoveCartItem = new EventEmitter<MouseEvent>();

    public handleClick(event: MouseEvent) {
        this.onRemoveCartItem.emit(event);
    }

}
