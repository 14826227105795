<div [formGroup]="form" class="d-flex flex-column flex-grow-1">

  <!-- Recording Selection -->
  <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
    <label
      [ngClass]="{'btn btn-secondary mr-3 rounded': true, 'active': !recordingData.uploadRecording, 'bg-white border-dark text-muted': recordingData.uploadRecording}"
      (click)="switchtoTextToSpeech()" [popover]="popConfirmTemplate" placement="top" triggers=""
      containerClass="popover-css-confirm" [outsideClick]="true" #popConfirm="bs-popover">
      <!-- <i *ngIf="!recordingData.uploadRecording" class="fas fa-check"></i> -->
      <input type="radio" autocomplete="off">
      Text to Speech
    </label>
    <label
      [ngClass]="{'btn btn-secondary ml-3 rounded': true, 'active': recordingData.uploadRecording, 'bg-white border-dark text-muted': !recordingData.uploadRecording}"
      (click)="changeUploadFileOption(true)">
      <!-- <i *ngIf="recordingData.uploadRecording" class="fas fa-check"></i> -->
      <input type="radio" autocomplete="off">
      Upload File
    </label>
  </div>
  <div class="border-bottom my-3"></div>
  <div *ngIf="!recordingData.uploadRecording" class="d-flex flex-column flex-grow-1">
    <div class="d-flex pb-3 flex-row flex-wrap">
      <div class="flex-fill pb-3 w-100">
        <div class="select-input w-100">
          <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
          <select class="select-custom font-weight-bold text-secondary form-control bg-light-grey"
            style="border-radius: 0.25rem !important; border: none !important; box-shadow: none !important;"
            formControlName="selectedGender"
            (change)="convertTextToAudio($event.target.value, true,this.recordingData.audioText)">
            <option value="male">English Male Voice
            </option>
            <option value="female">English Female Voice</option>
            <option value="cnfemale">Chinese Female Voice</option>
            <option value="defemale">German Female Voice</option>
            <option value="itfemale">Italian Female Voice</option>
            <option value="ptfemale">Portugese Female Voice</option>


          </select>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row flex-grow-1">
      <div class=" flex-fill d-flex flex-column">
        <div class="card w-100 border-0 flex-grow-1">
          <div class="card-body p-0 d-flex flex-column">
            <textarea class="form-control rounded-0 flex-grow-1" style="resize: none;"
              placeholder="Text to be played as audio" formControlName="audioText"
              (change)="convertTextToAudio(this.recordingData.selectedGender, true, $event.target.value)"> </textarea>
          </div>
          <!-- Audio Player -->
          <div style="height: 60px" class="mt-3">
            <div class="audio-player">
              <div class="w-100" [innerHtml]="audioHtml | safeHtml"></div>
            </div>
            <div style="position: relative; top: 6px" class="d-flex justify-content-center" *ngIf="audioLoading">
              <mat-spinner diameter="40"></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="recordingData.uploadRecording" class=" d-flex flex-column flex-grow-1">
    <voiply-file-uploader (fileAdded)="onFileChange($event)" [fileName]="fileName"
      class="d-flex flex-grow-1 flex-column">
    </voiply-file-uploader>
    <div style="position: relative; top: 6px" class="d-flex justify-content-center" *ngIf="audioLoading">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <div class="audio-player" [innerHTML]="audioHtmlUploadedFile"></div>
  </div>
</div>


<ng-template #popConfirmTemplate>
  <div class="d-flex flex-row align-items-center">
    <div>You will replace your uploaded audio file with Text. Are you Sure?</div>
    <button type="button" class="btn btn-light p-2 ml-2 d-flex align-items-center" style="height: 38px;"
      (click)="popConfirm.hide();changeUploadFileOption(false)">
      Yes
    </button>
  </div>
</ng-template>