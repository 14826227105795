<div class="d-flex align-items-center">
  <div class="flex-fill">
    <span class="text-secondary font-weight-bold" style="font-size: 12px;">
      TAXES & FEES
    </span>
  </div>
  <div *ngIf="taxDetail">
    <ng-container *ngIf="calculatingTotal">
      <mat-spinner class="float-right" diameter="18" value="accent"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!calculatingTotal">
      <span class="text-secondary">
        <h4 class="m-0 font-weight-bold" style="font-size: 15px;">
          <!-- {{((voipAndServiceTaxTotal || 0)/2) + (hardwareTaxTotal || 0) | formatMoneyValue}} -->
          {{taxDetail.estimatedTotalTax | formatMoneyValue}}
        </h4>

      </span>
    </ng-container>
  </div>

</div>