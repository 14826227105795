import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { CallRecordingFormControlBase } from '../call-recording-form-control.base';

@Component({
  selector: 'voiply-call-recording-form-control-alt',
  templateUrl: './call-recording-form-control-alt.component.html',
  styleUrls: ['./call-recording-form-control-alt.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CallRecordingFormControlAltComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CallRecordingFormControlAltComponent),
      multi: true
    }
  ]
})
export class CallRecordingFormControlAltComponent extends CallRecordingFormControlBase {
  constructor() {
    super();
  }
}
