import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatZip'
})
export class FormatZipPipe implements PipeTransform {
    transform(value, args?: any): any {
        const usZipRegex = new RegExp('\\d{5}(-\\d{4})?')
        const canadianZipRegex = new RegExp('^([A-CEGHJ-NPRSTVXYa-ceghj-nprstvxy]\\d[A-CEGHJ-NPRSTV-Za-ceghj-nprstv-z]( )?\\d[A-CEGHJ-NPRSTV-Za-ceghj-nprstv-z]\\d)$')
        if (usZipRegex.test(value)) {
            return value
        } else if (canadianZipRegex.test(value)) {
            let formattedZip = value.toUpperCase().replace(" ", "")
            formattedZip = formattedZip.substring(0, 3) + ' ' + formattedZip.substring(3, 6)
            return formattedZip;
        } else {
            return value
        }
    }
}