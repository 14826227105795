import { Input, OnInit, Output, EventEmitter, Directive } from '@angular/core';
import { AppStateSelectors, ValidateCartItem, UpdateCartItemAction, CalculatingTotalAction, DEVICES, STATES, OnDestroyCleanup } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import * as Model from '@voiply/shared/model';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { CartItemType } from '@voiply/shared/model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BusinessConfigurationModalComponent } from '../components/business-configuration/business-configuration-modal/business-configuration-modal.component';
import { Select } from '@ngxs/store';
import { HomeConfigurationModalComponent } from '../components/home-configuration/home-configuration-modal/home-configuration-modal.component';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Directive()
export abstract class ProductConfigurationBase extends OnDestroyCleanup implements OnInit {
  @Input()
  productConfigurations$: Observable<any>;
  _productconfigutrations: any;
  isCollapsedPhones = true;
  isCollapsedApps = true;
  @Input()
  userIsVoiplyMember: boolean;
  @Input()
  apps: Model.App[];
  @Input()
  phones: Model.Phone[];
  modalRef: BsModalRef;
  devices = DEVICES

  @Input() extDisabled: boolean;

  @Output()
  removeCartItem = new EventEmitter<string>();
  @Output()
  updateCartItemConfiguration = new EventEmitter<any>();
  _productConfigurationLength: number;
  _phonesconfigurationLength: number;
  _appsconfigurationLength: number;
  phonesconfiguration: any = {};
  appsconfiguration: any = {};
  appPageNumber = 1;
  phonePageNumber = 1;
  public searchTerm = '';
  public searchableList = ['value.configuration.type', 'value.heading', 'value.configuration.firstName', 'value.configuration.email', 'value.configuration.extension'];
  submitted: boolean;
  filterTerm = "extension"
  orderId: string;
  emergencyAddress = new Model.EmergencyAddress();
  showEmergencyAddress = true;
  selectedCountry = 'US';
  @Select(AppStateSelectors.doValidate) performValidation$: Observable<object>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<any>;

  @Dispatch() updateCartItemAction = (key: string, updateFeatureId: number) => new UpdateCartItemAction(key, updateFeatureId)
  @Dispatch() recalculatingTotal = (isCalculating: boolean) => new CalculatingTotalAction(isCalculating);

  constructor(private toastr: ToastrService, public modalService: BsModalService) {
    super();
    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      if (checkoutDetail.shippingAddress.country !== 'US' && checkoutDetail.shippingAddress.country !== 'CA') {
        this.showEmergencyAddress = false;
      }
      this.emergencyAddress = checkoutDetail.emergencyAddress;
      this.selectedCountry = checkoutDetail.shippingAddress.country;
    }));

  }



  ngOnInit(): void {
    this.addAndUpdateProductConfiguration();

    this.performValidation$.subscribe(newvalue => {
      if (newvalue)
        this.submitted = true;
    })
  }

  openBusinessModal(key) {
    _.each(this._productconfigutrations, (values, keys) => {
      if (keys === key) {
        const initialState = {
          key: keys,
          value: values,
          extDisabled: this.extDisabled,
          userIsVoiplyMember: this.userIsVoiplyMember,
          devices: this.devices,
          orderId: this.orderId,
          emergencyAddress: this.emergencyAddress,
          showEmergencyAddress: this.showEmergencyAddress
        };
        this.modalRef = this.modalService.show(BusinessConfigurationModalComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
        status = this.modalRef.content.form.status;
      }
    });
  }

  openHomeModal(key) {
    _.each(this._productconfigutrations, (values, keys) => {
      if (keys === key) {
        const initialState = {
          key: keys,
          value: values,
          extDisabled: this.extDisabled,
          userIsVoiplyMember: this.userIsVoiplyMember,
          devices: this.devices,
          orderId: this.orderId
        };
        this.modalRef = this.modalService.show(HomeConfigurationModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
        status = this.modalRef.content.form.status;
      }
    });

  }

  abstract addAndUpdateProductConfiguration();

  onCartItemdropdownOptionChange(key, updateFeatureId) {
    this.recalculatingTotal(true);
    this.updateCartItemAction(key, updateFeatureId)
  }

  onRemoveCartItem(key, item) {
    if (item.paid) {
      if (this.userIsVoiplyMember) {
        if (!confirm('Customer has already paid for the App. Do you want to remove it?')) {
          return;
        }
      } else {
        this.toastr.error("You have already paid for this item. Please contact support to remove it.");
        return;
      }
    }
    if (item.type === CartItemType.Phone)
      delete this.phonesconfiguration[key];
    else if (item.type === CartItemType.App)
      delete this.appsconfiguration[key];
    this.removeCartItem.emit(key);
  }

  getItemImage(itemId: string, itemType: CartItemType) {
    if (itemType === CartItemType.Phone) {
      if (this.phones.length > 0) return _.filter(this.phones, (phone) => phone.featureId === itemId)[0].image;
    } else if (itemType === CartItemType.App) {
      if (this.apps.length > 0) return _.filter(this.apps, (app) => app.appId === itemId)[0].image;
    }
  }

  appPageChange(number) {
    this.appPageNumber = number;
  }

  phonePageChange(number) {
    this.phonePageNumber = number;
  }

  scrollTo(controlId) {
    const el = document.getElementById(controlId);
    el.scrollIntoView({ behavior: "smooth" });
  }
  validateCartConfiguration(obj: Model.CartItem): boolean {
    if (this.submitted)
      return !(ValidateCartItem(obj));
  }
  phoneAvailableInCountry(phone) {
    if (phone.onlyAvailableInCountry.length) {
      if (_.includes(phone.onlyAvailableInCountry, this.selectedCountry)) {
        return true
      } else return false
    } else return true
  }
}