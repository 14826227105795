import { Component, OnInit } from '@angular/core';
import { CartTotalBase } from '../cart-total-base';

@Component({
  selector: 'checkout-cart-total-after-checkout',
  templateUrl: './cart-total-after-checkout.component.html',
  styleUrls: ['./cart-total-after-checkout.component.scss']
})
export class CartTotalAfterCheckoutComponent extends CartTotalBase {



}
