import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'extraline-settings-view-invoices',
  templateUrl: './view-invoices.component.html',
  styleUrls: ['./view-invoices.component.scss']
})
export class ViewInvoices {
  queryParams;
  constructor(private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
          this.queryParams = params;
      })
  }
}
