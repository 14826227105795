import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Alert911Base } from '../alert911-form-control.base';

@Component({
  selector: 'voiply-alert911-form-control-alt',
  templateUrl: './alert911-form-control-alt.component.html',
  styleUrls: ['./alert911-form-control-alt.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Alert911FormControlAltComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => Alert911FormControlAltComponent),
      multi: true
    }
  ]
})
export class Alert911FormControlAltComponent extends Alert911Base {

  showAddContactForm = false;
  constructor(toastr: ToastrService) {
    super(toastr)
  }


}
