<!--containing in - Lib: product-configuration -->
<!--used by - Component: business-configuration/business-configuration-modal, business-configuration/user-settings-business -->
<!--Added for - App: Business, User-settings-->
<div [formGroup]="form" class="cart-item-background-color p-3">
  <div class="d-flex flex-wrap flex-row">
    <div class="p-1 flex-fill" style="position: relative;">
      <input type="text" class="form-control" placeholder="Email" formControlName="email" [readOnly]="disableInput"
        [ngClass]="{ 'is-invalid': ((f.email.touched || submitted) && f.email.errors) }">
      <div *ngIf="((f.email.touched || submitted) && f.email.errors)">
        <span *ngIf="f.email.errors.required">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
        <span *ngIf="f.email.errors.email">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
      </div>
    </div>
  </div>
  <div class="p-1">
    <a href="https://zoom.us/meetings" _target="blank">Zoom solution</a> includes video conferencing, messaging, and
    screen share across any device. You'll receive a Zoom Pro
    account activation via email upon completed checkout.
  </div>
</div>
