import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select } from "@ngxs/store";
import { AppStateSelectors, OnDestroyCleanup } from "@voiply/shared/common-ui";
import { ExtralineScreen } from "@voiply/shared/model";
import { Observable } from "rxjs";

@Component({
  selector: 'extraline-settings-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.scss']
})
export class ProductConfiguration extends OnDestroyCleanup {
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;
  @Select(AppStateSelectors.metadata) metadata$: Observable<any>;
  queryParams;
  metaData;
  currentScreen: ExtralineScreen;

  constructor(private route: ActivatedRoute) {
    super();
    this.subscriptions$.add(route.queryParams.subscribe(params => this.queryParams = params));
    this.subscriptions$.add(this.metadata$.subscribe(metadata => this.metaData = metadata));
    if (route.snapshot.url[0].path === "voicemail") this.currentScreen = ExtralineScreen.VoiceMail;
    if (route.snapshot.url[0].path === "call-forwarding") this.currentScreen = ExtralineScreen.CallForwarding;
    if (route.snapshot.url[0].path === "spam-call-bot") this.currentScreen = ExtralineScreen.SpamBot;
    if (route.snapshot.url[0].path === "online-fax") this.currentScreen = ExtralineScreen.Fax;
    if (route.snapshot.url[0].path === "call-recording") this.currentScreen = ExtralineScreen.CallRecording;
    if (route.snapshot.url[0].path === "e999") this.currentScreen = ExtralineScreen.Emergency999;
  }
}
