import { Component, OnInit, Output, EventEmitter, Input, ViewChild, Directive } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBase, UpdateCartItemConfigurationAction, OrderService, STATES, UpdatingPhoneSystemAction, COUNTRIES, APPSCONST } from '@voiply/shared/common-ui';
import { CartItemType, Address, EmergencyAddress } from '@voiply/shared/model';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ToastrService } from 'ngx-toastr';
import { pairwise, startWith } from 'rxjs/operators';
import { ValidationService } from 'libs/shared/common-ui/src/lib/services';

@Directive()
export abstract class BusinessConfigurationBase extends FormBase implements OnInit {

    @Input()
    key: string;
    @Input()
    value: any;
    @Input()
    devices: { manufacturer: string, models: [] }[]
    @Input()
    extDisabled = false;
    @Input()
    userIsVoiplyMember = false;
    @Input()
    orderId: string;
    @Input()
    emergencyAddress = new EmergencyAddress();
    @Input()
    showEmergencyAddress = true;
    @Output() action = new EventEmitter();
    isHideConfiguration = true;
    disableInput = false
    @ViewChild('tabs') tabs;
    productConfig: any;
    models = [];
    disableDeviceSettings = new class { disableMac = false; disableSerial = false; disableManufacturer = false; disableModel = false }
    states = STATES;
    countries = COUNTRIES;
    filteredStates: any;
    showDeviceSettingsValidation = false;
    toggle;
    constructor(public toastr: ToastrService, public orderService: OrderService, public validateService: ValidationService) {
        super();

        this.form = new FormGroup({
        });
    }
    @Dispatch() updateCartItemConfigurationAction = (key, cartItemConfiguration) => new UpdateCartItemConfigurationAction(key, cartItemConfiguration);
    @Dispatch() updatingPhoneSystem = (updatingOrder: boolean) => new UpdatingPhoneSystemAction(updatingOrder);

    formStatusChanged() {

    }
    ngOnInit() {
        this.toggle=this.value.configuration.isVoicemailToEmailEnable;
        if(this.toggle !=true && this.toggle !=false){ //Set toggle true if toggle is undefined
            this.toggle=true;
        }
        this.filteredStates = (_.filter(this.countries, { 'countryShortCode': (this.value.configuration.emergencyAddress || {}).country || 'US' })[0] || {}).regions;

        _.each(_.filter(this.devices, (o) => {
            return o.manufacturer === (this.value.configuration.manufacturer || 'grandstream')
        })[0], (values, keys) => {
            if (keys === 'models') {
                this.models = values
            }
        })

        this.productConfig = { ...this.value.configuration };
        if (this.value.type === CartItemType.Phone) {
            this.form.addControl(this.key, new FormGroup({
                phone: new FormControl({
                    firstName: this.value.configuration.firstName,
                    lastName: this.value.configuration.lastName,
                    email: this.value.configuration.email || '',
                    userId: this.value.configuration.userId,
                    extension: this.value.configuration.extension,
                    phoneNumber: this.value.configuration.phoneNumber || '',
                    isNewNumber: this.value.configuration.isNewNumber || false,
                    timezone: this.value.configuration.timezone || 'est'
                }),
                voice: new FormControl({
                    selectedGender: this.value.configuration.selectedGender || 'male',
                    audioText: this.value.configuration.audioText,
                    sendVoiceMailTo: this.value.configuration.sendVoiceMailTo,
                    blobFileName: this.value.configuration.blobFileName,
                    fileName: this.value.configuration.fileName,
                    uploadRecording: this.value.configuration.uploadRecording || false,
                    showRecording: this.value.configuration.showRecording || false
                }),
                callForwarding: new FormControl({
                    callForwarding: this.value.configuration.callForwarding || false,
                    callForwardingNumber: this.value.configuration.callForwardingNumber || '',
                    emergencyCallForwarding: this.value.configuration.emergencyCallForwarding || false,
                    emergencyCallForwardingNumber: this.value.configuration.emergencyCallForwardingNumber || ''
                }),
                deviceSettings: new FormControl({
                    mac: this.value.configuration.mac || '',
                    serial: this.value.configuration.serial || '',
                    manufacturer: this.value.configuration.manufacturer || 'grandstream',
                    model: this.value.configuration.model || ''
                }),
                smsSettings: new FormControl({
                    isSMSArchivingEnable: this.value.configuration.isSMSArchivingEnable || false,
                    archive_sms: this.value.configuration.archive_sms || ''
                }),
                ringCount: new FormControl({
                    voicemailRingTimeoutCount: this.value.configuration.voicemailRingTimeoutCount || 4,
                }),
                voicemailAttachment: new FormControl({
                    attachVoicemail: this.value.configuration.attachVoicemail || false,
                    ccEmail: this.value.configuration.ccEmail || []
                }),
                emergencyAddress: new FormControl({
                    enableEmergencyAddress: (this.value.configuration.emergencyAddress || {}).enableEmergencyAddress || false,
                    name: (this.value.configuration.emergencyAddress || {}).name || '',
                    addressLine1: (this.value.configuration.emergencyAddress || {}).addressLine1 || '',
                    addressLine2: (this.value.configuration.emergencyAddress || {}).addressLine2 || '',
                    city: (this.value.configuration.emergencyAddress || {}).city || '',
                    state: (this.value.configuration.emergencyAddress || {}).state || '',
                    country: (this.value.configuration.emergencyAddress || {}).country || '',
                    zip: (this.value.configuration.emergencyAddress || {}).zip || ''
                }),
                includeinCompanyDirectory: new FormControl({
                    includeinCompanyDirectory: this.value.configuration.includeinCompanyDirectory || false
                }),
                isVoicemailToEmailEnable: new FormControl(this.value.configuration.isVoicemailToEmailEnable || true)
                
            }));
        }
        else if (this.value.heading === APPSCONST.ONLINEFAX) {
            this.form.addControl(this.key, new FormGroup({
                onlineFax: new FormControl({
                    coverpage: this.value.configuration.coverpage || 'Yes',
                    mobile: this.value.configuration.mobile || '',
                    email: this.value.configuration.email || '',
                    isNewNumber: this.value.configuration.isNewNumber || false
                }),

            }));
        } else if (this.value.heading === APPSCONST.CONFERENCING) {
            this.form.addControl(this.key, new FormGroup({
                conferencing: new FormControl({
                    email: this.value.configuration.email || ''
                }),

            }));
        } else if (this.value.heading === APPSCONST.SPAMCALLBOT) {
            this.form.addControl(this.key, new FormGroup({
                spamCallBot: new FormControl({
                    extension: this.value.configuration.extension || 501,
                    autoscreening: this.value.configuration.autoscreening || 'No',
                    smsscreening: this.value.configuration.smsscreening || 'No',
                    callrejection: this.value.configuration.callrejection || 'No',
                    isallowList:this.value.configuration.isallowList || 'No',
                    blockedList: this.value.configuration.blockedList || [],
                    allowedList: this.value.configuration.allowedList || [],
                })
            }))
        } else if (this.value.heading === APPSCONST.CALLRECORDING) {
            this.form.addControl(this.key, new FormGroup({
                callRecording: new FormControl({
                    email: this.value.configuration.email || '',
                    recordIncoming: this.value.configuration.recordIncoming || 'No',
                    recordOutgoing: this.value.configuration.recordOutgoing || 'No',
                }),
                voice: new FormControl({
                    selectedGender: this.value.configuration.selectedGender || 'male',
                    audioText: this.value.configuration.audioText,
                    sendVoiceMailTo: this.value.configuration.sendVoiceMailTo || '',
                    blobFileName: this.value.configuration.blobFileName,
                    fileName: this.value.configuration.fileName,
                    uploadRecording: this.value.configuration.uploadRecording || false,
                    showRecording: this.value.configuration.showRecording || false
                })
            }))
        } else if (this.value.heading === APPSCONST.EMERGENCY) {
            this.form.addControl(this.key, new FormGroup({
                emergency999: new FormControl({
                    emergencyContacts: this.value.configuration.emergencyContacts || []
                })
            }))
        }

        if (this.value.paid && !this.userIsVoiplyMember) {
            this.disableInput = true;
        }

        if (this.value.paid && !this.userIsVoiplyMember && this.value.configuration.mac) {
            this.disableDeviceSettings.disableMac = true;
        }
        if (this.value.paid && !this.userIsVoiplyMember && this.value.configuration.serial) {
            this.disableDeviceSettings.disableSerial = true;
        }
        if (this.value.paid && !this.userIsVoiplyMember && this.value.configuration.manufacturer) {
            this.disableDeviceSettings.disableManufacturer = true;
        }
        if (this.value.paid && !this.userIsVoiplyMember && this.value.configuration.model) {
            this.disableDeviceSettings.disableModel = true;
        }

        if (this.value.heading === 'Bring Your Device') {
            this.showDeviceSettingsValidation = true;
        }
        this.form.controls[this.key].valueChanges.subscribe(
            (changes) => {
                //This is done to update devicemodel array
                if (changes.deviceSettings) {
                    _.each(_.filter(this.devices, (o) => {
                        return o.manufacturer === changes.deviceSettings.manufacturer
                    })[0], (values, keys) => {
                        if (keys === 'models') {
                            this.models = values
                        }
                    })
                }
                if (this.value.type === CartItemType.Phone) {
                    this.productConfig = _.extend(changes.phone, changes.voice, changes.callForwarding, changes.ringCount, changes.voicemailAttachment, changes.includeinCompanyDirectory, changes.deviceSettings,{"isVoicemailToEmailEnable":changes.isVoicemailToEmailEnable},changes.smsSettings);
                    this.productConfig.emergencyAddress = changes.emergencyAddress;
                }
                else if (this.value.heading === APPSCONST.ONLINEFAX) {
                    this.productConfig = changes.onlineFax;
                }
                else if (this.value.heading === APPSCONST.CONFERENCING) {
                    this.productConfig = changes.conferencing;
                }
                else if (this.value.heading === APPSCONST.SPAMCALLBOT) {
                    this.productConfig = changes.spamCallBot;
                }
                else if (this.value.heading === APPSCONST.CALLRECORDING) {
                    this.productConfig = _.extend(changes.callRecording, changes.voice);
                } else if (this.value.heading === APPSCONST.EMERGENCY) {
                    this.productConfig = changes.emergency999;
                }
            });
        if (this.form.controls[this.key]['controls']['emergencyAddress']) {
            this.form.controls[this.key]['controls']['emergencyAddress'].valueChanges.pipe(startWith(this.form.controls[this.key]['controls']['emergencyAddress'].value), pairwise()).subscribe(([prevValue, nextValue]) => {
                if (prevValue.enableEmergencyAddress !== nextValue.enableEmergencyAddress && nextValue.enableEmergencyAddress === true) {
                    if (!nextValue.name)
                        nextValue.name = this.emergencyAddress.name;
                    if (!nextValue.addressLine1)
                        nextValue.addressLine1 = this.emergencyAddress.addressLine1;
                    if (!nextValue.addressLine2)
                        nextValue.addressLine2 = this.emergencyAddress.addressLine2;
                    if (!nextValue.city)
                        nextValue.city = this.emergencyAddress.city;
                    if (!nextValue.state)
                        nextValue.state = this.emergencyAddress.state;
                    if (!nextValue.country)
                        nextValue.country = this.emergencyAddress.country;
                    if (!nextValue.zip)
                        nextValue.zip = this.emergencyAddress.zip;
                    this.form.controls[this.key]['controls']['emergencyAddress'].patchValue(nextValue);
                }
            });
        }
    }

    async onSubmit() {
        // let emergencyAddressValidation = true;
        //Check mac and serial validation for all bring your Device and for all devices if user is voiply member
        if ((this.productConfig.mac || this.productConfig.serial) && (this.value.heading === 'Bring Your Device')) {
            if ((this.productConfig.mac.length || this.productConfig.serial.length) && this.productConfig.manufacturer === 'grandstream') {
                const mac = this.productConfig.mac
                const serial = this.productConfig.serial
                if (mac.length > 5 && serial.length > 5) {
                    const last6Mac = mac.substring(mac.length - 6);
                    const last6Serial = serial.substring(serial.length - 6);
                    if (last6Mac.toUpperCase() !== last6Serial.toUpperCase())
                        this.toastr.error('Last 6 Digits of Mac Address and Serial Number should be same')
                    else {
                        this.updateCartItemConfiguration(this.key, this.productConfig);
                    }
                } else {
                    this.toastr.error('please enter valid Mac Address and Serial Number')
                }
            } else {
                this.updateCartItemConfiguration(this.key, this.productConfig);
            }
        } else if ((this.form.controls[this.key]['controls']["emergencyAddress"] || {}).errors && ((this.form.controls[this.key]['controls']["emergencyAddress"] || {}).value || {}).enableEmergencyAddress) {
            this.toastr.error('Please Enter Valid Emergency Address')
        } else {
            // if (((this.form.controls[this.key]['controls']["emergencyAddress"] || {}).value || {}).enableEmergencyAddress) {
            //     try {
            //         await this.validateService.validateEmergencyAddress(this.form.controls[this.key]['controls']["emergencyAddress"].value)
            //         emergencyAddressValidation = true;
            //     } catch (err) {
            //         emergencyAddressValidation = false
            //         if (err.error.error.length) {
            //             this.toastr.error(err.error.error[0].message)
            //         }
            //     }

            // }
            // if (emergencyAddressValidation)
            this.updateCartItemConfiguration(this.key, this.productConfig);
        }
    }

    abstract updateCartItemConfiguration(key, productConfig);


    onHideConfig(value) {
        this.isHideConfiguration = value;
        if (this.tabs !== undefined)
            this.tabs.realignInkBar();
    }


}
