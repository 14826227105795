import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'extraline-settings-e911',
  templateUrl: './e911.component.html',
  styleUrls: ['./e911.component.scss']
})
export class E911Component {
  queryParams;
  constructor(private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
          this.queryParams = params;
      })
  }
}
