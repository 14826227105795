import { Component, OnInit } from '@angular/core';
import { SubTotalBase } from '../sub-total-base';

@Component({
  selector: 'checkout-sub-total-after-checkout',
  templateUrl: './sub-total-after-checkout.component.html',
  styleUrls: ['./sub-total-after-checkout.component.scss']
})
export class SubTotalAfterCheckoutComponent extends SubTotalBase {


}
