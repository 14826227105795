import { CartItemType } from './enums';
import { PhoneConfiguration } from './phone-configuration.model';

export class CartItem {
    type: CartItemType = CartItemType.Phone;
    heading: string;
    itemId: number;
    price: number;
    discountedPrice: number;
    monthlyCharge: number;
    discountedMonthlyCharge: number;
    configuration: PhoneConfiguration;
    qty: number;
    paid: boolean;
    phoneStatusDetail: any;
    protectionCharge: number;
    protectionPlan: string;
}
