import { OnInit, Directive } from '@angular/core';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { FormGroup, FormControl } from '@angular/forms';

@Directive()
export class MissedCallFormControlBase extends ControlValueAccessorAndValidator implements OnInit {

  constructor() {
    super();
    this.form = new FormGroup({
      voicemailRingTimeoutCount: new FormControl(4),
    });
  }

  ngOnInit() {
  }

}
