import { Directive, OnInit, ViewChild, ElementRef, EventEmitter, ChangeDetectorRef, Input, SimpleChanges, OnChanges, Output } from '@angular/core';
import { Stripe, Card } from '@voiply/shared/model';
import { Select } from '@ngxs/store';
import { AppStateSelectors, StripeDataChangedAction } from '../../+state';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { StripeService } from '../../services';
import { OnDestroyCleanup } from '../../generic-utils';
import { ToastrService } from 'ngx-toastr';

@Directive()
export class ChangeStripeCardBaseComponent extends OnDestroyCleanup implements OnInit, OnChanges {
  @ViewChild('cardElement') cardInfo: ElementRef;
  @Input() orderId: string;
  @Input() unMountCard;
  @Input() checkoutDetail;
  @Output() paymentMethodChange = new EventEmitter<string>();
  stripeDataChanged = new EventEmitter<Stripe>();
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  showCreditCard = false;
  cardChangeRequesting = false;
  cardDetail: Card;
  @Select(AppStateSelectors.cardDetails) cardDeatils$: Observable<Card>;
  @Dispatch() onStripeDataChanged = (stripe) => new StripeDataChangedAction(stripe);
  constructor(private cd: ChangeDetectorRef, public stripeService: StripeService, private tostr: ToastrService) {
    super();
    this.subscriptions$.add(this.cardDeatils$.subscribe((cardDetail) => this.cardDetail = cardDetail));
    this.subscriptions$.add(this.stripeService.emitMountNewCard.subscribe(async (iscalledFromModal) => {
      await this.onCardChangeCancel();
      if (iscalledFromModal)
        this.mountStripeCard();
    }));

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.unMountCard?.currentValue) {
      this.onCardChangeCancel();
    }
  }

  ngOnInit() {
  }
  mountStripeCard() {
    if (this.card)
      return;

    this.card = elements.create('card', {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: '#d3d4d5',
          color: '#212529',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          ':-webkit-autofill': {
            color: '#212529',
          },
          '::placeholder': {
            color: '#2125298a',
          },
        },
        invalid: {
          iconColor: 'red',
          color: 'red',
        },
      },
      hidePostalCode: true
    });

    console.log('Mounting card in order summary');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);

    this.showCreditCard = true;
  }


  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit() {
    this.cardChangeRequesting = true;


    const { token, error } = await stripe.createToken(this.card);
    if (error) {
      this.tostr.error(error.message);
    } else {
      // console.log('Success!', token);
      this.paymentMethodChange.emit(token.id);
      this.stripeService.changeCard(this.orderId, token).then((response) => {
        if (response.result === "success") {
          this.tostr.success('Card updated successfully.');
          this.onStripeDataChanged({
            stripeToken: token.id, card: {
              exp_month: token.card.exp_month, exp_year: token.card.exp_year,
              id: token.card.id, last4: token.card.last4, brand: token.card.brand
            }
          });

          this.cardDetail = {
            exp_month: token.card.exp_month, exp_year: token.card.exp_year,
            id: token.card.id, last4: token.card.last4, brand: token.card.brand
          };
          this.showCreditCard = false;
          this.card.removeEventListener('change', this.cardHandler);
          this.card.destroy();
          this.card = null;
        }
        this.cardChangeRequesting = false;
      })

    }

  }
  onCardChangeCancel() {
    if (this.card) {
      this.showCreditCard = false;
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
      this.card = null;
    }
  }
}
