import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ENVIRONMENT } from '../constants';
import { IEnvironment } from '../environment.interface';

const featuresUrl = `${location.origin}/assets/data.json`;
const featuresUrlBase = `${location.origin}/assets`;

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {
  constructor(private http: HttpClient,@Inject(ENVIRONMENT) private readonly environment: IEnvironment) {
    super();
   }

  getFeaturesAsync(): Promise<any> {
    return this.http.get(featuresUrl).pipe(catchError(this.handleError)).toPromise();
  }

  getHomeFeaturesAsync(): Promise<any> {
    return this.http.get(`${featuresUrlBase}/data-home.json`).pipe(catchError(this.handleError)).toPromise();
  }

  getBusinessFeaturesAsync(): Promise<any> {
    return this.http.get(`${featuresUrlBase}/data-business.json`).pipe(catchError(this.handleError)).toPromise();
  }

  getPhoneStatus(orderId): Promise<any> {
    return this.http.get(`${this.environment.grandstreamAPI}/registrations/${orderId}`).toPromise();
  }
}
