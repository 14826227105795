<div class="d-flex flex-column">
  <div class="d-flex justify-content-start bg-dark text-white rounded p-2">
    <!-- heading -->
    <span class="pr-2 d-flex align-items-center">
      <img src="assets/images/extraline/icons/spam-bot.svg" class="icon-white">
    </span>
    <span class="text-uppercase font-weight-bold">Spam Bot</span>
  </div>
  <div class="d-flex flex-column pt-3">
    <div [formGroup]="form">
      <div class="d-flex flex-column">
        <div class="input-group rounded overflow-auto border-bottom pb-3">
          <div class="input-group-prepend">
            <span class="input-group-text bg-grey spam-bot-prepend border-0">
              Bot Ext
            </span>
          </div>
          <input type="text" class="form-control bg-light-grey text-secondary font-weight-bold spam-bot-input border-0"
            placeholder="Direct Dial" formControlName="extension" disabled
            [ngClass]="{ 'is-invalid': ((f.extension.touched || submitted) && f.extension.errors) }"
            style="box-shadow: none !important;">
          <div class="input-group-append bg-light-grey d-flex align-items-center pr-2"
            *ngIf="!((f.extension.touched || submitted) && f.extension.errors)">
            <i class="fas fa-check text-success"></i>
          </div>
          <div *ngIf="((f.extension.touched || submitted) && f.extension.errors)">
            <span *ngIf="f.extension.errors.required">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter extension"></i></span>
            <span *ngIf="f.extension.errors.extension">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter a 2 to 5 digit extension number.">
              </i>
            </span>
          </div>
        </div>
        <div class="border-bottom pb-3">
          <div class="text-muted pt-3" style="font-size: small;">
            Know telemarketers can be automatically redirected to the bot. Calls can also be transferred to the bot's
            extension.
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold">Screen All Telemarketers</span>
            <!-- * toggle buttons -->
            <div class=" btn-group btn-group-toggle bg-light-grey border rounded" data-toggle="buttons">
              <button type="button" class="btn text-muted" style="border-radius: 0.25rem 0 0 0.25rem; font-size: 12px;"
                (click)="this.form.get('autoscreening').patchValue('No')"
                [ngClass]="{'bg-error-gradient text-primary': this.form.get('autoscreening').value === 'No'}">
                <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
                  <input type="radio" autocomplete="off" value="No" formControlName="autoscreening">
                  <span>NO</span>
                </div>
              </button>
              <button type="button" class="btn text-muted" style="border-radius: 0 0.25rem 0.25rem 0; font-size: 12px;"
                (click)="this.form.get('autoscreening').patchValue('Yes')"
                [ngClass]="{'bg-success-gradient text-primary': this.form.get('autoscreening').value === 'Yes'}">
                <div class="d-flex align-items-center justify-content-center font-weight-bold">
                  <input type="radio" autocomplete="off" value="Yes" formControlName="autoscreening">
                  <span>YES</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="border-bottom pb-3">
          <div class="text-muted pt-3" style="font-size: small;">
            Add here a full/medium description of what SMS Screening is, this way the user can know what it does
            exactly.
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold">Enable SMS Screening?</span>
            <!-- * toggle buttons -->
            <div class=" btn-group btn-group-toggle bg-light-grey border rounded" data-toggle="buttons">
              <button type="button" class="btn text-muted" style="border-radius: 0.25rem 0 0 0.25rem; font-size: 12px;"
                (click)="this.form.get('smsscreening').patchValue('No')"
                [ngClass]="{'bg-error-gradient text-primary': this.form.get('smsscreening').value === 'No'}">
                <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
                  <input type="radio" autocomplete="off" value="No" formControlName="smsscreening">
                  <span>NO</span>
                </div>
              </button>
              <button type="button" class="btn text-muted" style="border-radius: 0 0.25rem 0.25rem 0; font-size: 12px;"
                (click)="this.form.get('smsscreening').patchValue('Yes')"
                [ngClass]="{'bg-success-gradient text-primary': this.form.get('smsscreening').value === 'Yes'}">
                <div class="d-flex align-items-center justify-content-center font-weight-bold">
                  <input type="radio" autocomplete="off" value="Yes" formControlName="smsscreening">
                  <span>YES</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="border-bottom pb-3">
          <div class="text-muted pt-3" style="font-size: small;">
            Anonymous Call Rejection automatically rejects calls from numbers with Caller ID blocked.
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold">Anonymous Call Rejection</span>
            <!-- * toggle buttons -->
            <div class=" btn-group btn-group-toggle bg-light-grey border rounded" data-toggle="buttons">
              <button type="button" class="btn text-muted" style="border-radius: 0.25rem 0 0 0.25rem; font-size: 12px;"
                (click)="this.form.get('callrejection').patchValue('No')"
                [ngClass]="{'bg-error-gradient text-primary': this.form.get('callrejection').value === 'No'}">
                <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
                  <input type="radio" autocomplete="off" value="No" formControlName="callrejection">
                  <span>NO</span>
                </div>
              </button>
              <button type="button" class="btn text-muted" style="border-radius: 0 0.25rem 0.25rem 0; font-size: 12px;"
                (click)="this.form.get('callrejection').patchValue('Yes')"
                [ngClass]="{'bg-success-gradient text-primary': this.form.get('callrejection').value === 'Yes'}">
                <div class="d-flex align-items-center justify-content-center font-weight-bold">
                  <input type="radio" autocomplete="off" value="Yes" formControlName="callrejection">
                  <span>YES</span>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div class="border-bottom pb-3">
          <div class="text-muted pt-3" style="font-size: small;">
            
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold">Allow List Only</span>
            <!-- * toggle buttons -->
            <div class=" btn-group btn-group-toggle bg-light-grey border rounded" data-toggle="buttons">
              <button type="button" class="btn text-muted" style="border-radius: 0.25rem 0 0 0.25rem; font-size: 12px;"
                (click)="this.form.get('isallowList').patchValue('No')"
                [ngClass]="{'bg-error-gradient text-primary': this.form.get('isallowList').value === 'No'}">
                <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
                  <input type="radio" autocomplete="off" value="No" formControlName="isallowList">
                  <span>NO</span>
                </div>
              </button>
              <button type="button" class="btn text-muted" style="border-radius: 0 0.25rem 0.25rem 0; font-size: 12px;"
                (click)="this.form.get('isallowList').patchValue('Yes')"
                [ngClass]="{'bg-success-gradient text-primary': this.form.get('isallowList').value === 'Yes'}">
                <div class="d-flex align-items-center justify-content-center font-weight-bold">
                  <input type="radio" autocomplete="off" value="Yes" formControlName="isallowList">
                  <span>YES</span>
                </div>
              </button>
            </div>
          </div>
        </div>

        <voiply-spam-call-blocked-numbers-alt [blockedNumberList]="this.form.controls['blockedList'].value"
          (addBlockedNumber)="onAddBlockedNumber($event)" (removeBlockedNumber)="onRemoveBlockedNumber($event)">
        </voiply-spam-call-blocked-numbers-alt>

        <voiply-spam-call-allowed-numbers-alt [allowededNumberList]="this.form.controls['allowedList'].value"
          (addAllowedNumber)="onAddAllowedNumber($event)" (removeAllowedNumber)="onRemoveAllowedNumber($event)">
        </voiply-spam-call-allowed-numbers-alt>
      </div>
    </div>
  </div>
</div>