import { Directive, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { ControlValueAccessorAndValidator } from "@voiply/shared/common-ui";

@Directive()
export class OnlineFaxFormControlBase extends ControlValueAccessorAndValidator implements OnInit {
  @Input() submitted = false;
  @Input() disableInput = false;
  selectedNumber = '';
  isHideNewNumber = false;

  constructor() {
    super();
    this.form = new FormGroup({
      coverpage: new FormControl(''),
      mobile: new FormControl('', { validators: [RxwebValidators.required()], updateOn: 'blur' }),
      email: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.email()], updateOn: 'blur' }),
      isNewNumber: new FormControl(''),
    });
  }

  ngOnInit() {
    this.form.controls['mobile'].valueChanges.subscribe(
      (value) => {
        if (value !== this.selectedNumber.toString()) {
          this.form.controls['isNewNumber'].patchValue(false);
        }
      });
  }
  onNumberSelected() {
    const sanitizedPhoneNumber = this.selectedNumber.toString().replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
    this.form.controls['isNewNumber'].patchValue(true);
    this.form.controls['mobile'].patchValue(sanitizedPhoneNumber);
  }

  toggleNewNumber() {
    this.isHideNewNumber = !this.isHideNewNumber;
  }
}
