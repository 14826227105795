import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ShareModule } from '@ngx-share/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Current Module elements
import { COMPONENTS } from './components';
import { CONTAINERS } from './container';
import { MaterialModule } from 'libs/shared/common-ui/src/lib/material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    BsDropdownModule.forRoot(),

    // All material modules
    MaterialModule,
    MatProgressSpinnerModule,
    MatSelectModule,
  ],
  declarations: [
    ...CONTAINERS,
    ...COMPONENTS
  ],
  exports: [
    ...CONTAINERS,

     // All material modules
     MaterialModule,
    MatProgressSpinnerModule,
    MatSelectModule
  ]
})
export class HeaderOrderTrackingModule { }
