import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Shippment } from '@voiply/shared/model';
@Component({
  selector: 'voiply-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent {
  @Input() shipment: Shippment;
  @Input() backButtonUrl;

  constructor(private router: Router) { }

  ngOnInit() {
    this.shipment = this.shipment || {
      delivered: false,
      deliveyDate: null,
      estimatedDeliveryDate: null,
      shipperTrackingLink: '',
      status: 'In Processing',
      status_detail: '',
      trackingRef: ''
    };
  }
  back() {
    this.router.navigateByUrl(this.backButtonUrl);
  }
}
