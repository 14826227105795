import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { AddressBase } from '../address.base';

@Component({
  selector: 'checkout-address-extraline',
  templateUrl: './address-extraline.component.html',
  styleUrls: ['./address-extraline.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressExtralineComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressExtralineComponent),
      multi: true
    }
  ]
})
export class AddressExtralineComponent extends AddressBase {

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

}
