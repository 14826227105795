import { Component, OnInit, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { mac } from '@rxweb/reactive-form-validators';
@Component({
  selector: 'voiply-device-settings-form-control',
  templateUrl: './device-settings-form-control.component.html',
  styleUrls: ['./device-settings-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeviceSettingsFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DeviceSettingsFormControlComponent),
      multi: true
    }
  ]
})
export class DeviceSettingsFormControlComponent extends ControlValueAccessorAndValidator implements OnInit, OnChanges {
  @Input()
  submitted = false;
  @Input()
  models: { model: string, pdfLink: string }[] = [];
  @Input()
  disableDeviceSettings = new class { disableMac = false; disableSerial = false; disableManufacturer = false; disableModel = false }
  @Input()
  showDeviceSettingsValidation = false;
  constructor() {
    super();
    this.form = new FormGroup({
      mac: new FormControl({ value: '' }, { updateOn: 'blur' }),
      serial: new FormControl({ value: '' }, { updateOn: 'blur' }),
      manufacturer: new FormControl({ value: '' }),
      model: new FormControl({ value: '' })
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  ngOnInit() {
    if (this.disableDeviceSettings.disableMac)
      this.form.controls['mac'].disable()
    if (this.disableDeviceSettings.disableSerial)
      this.form.controls['serial'].disable()
    if (this.disableDeviceSettings.disableManufacturer)
      this.form.controls['manufacturer'].disable()
    if (this.disableDeviceSettings.disableModel)
      this.form.controls['model'].disable()

    if (this.showDeviceSettingsValidation)
      this.form.setValidators(this.matchingLast6Digits('mac', 'serial', 'manufacturer'));

  }
  changeModel(model) {
    this.form.controls['model'].setValue(model);
  }
  changeManufacturer(manufacturer) {
    this.form.controls['manufacturer'].setValue(manufacturer);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.models.currentValue.length)
      this.form.controls['model'].setValue(changes.models.currentValue[0].model)
    else {
      this.form.controls['model'].setValue('')

    }
  }
  openPdf() {
    for (let i = 0; i < this.models.length; i++) {
      if (this.models[i].model === this.form.controls['model'].value) {
        return this.models[i].pdfLink;
      }
    }

  }
  matchingLast6Digits(macKey, serialKey, manufacturer) {
    return (group: FormGroup): { [key: string]: any } => {
      const macInput = group.controls[macKey];
      const serialInput = group.controls[serialKey];
      const manufacturerInput = group.controls[manufacturer]
      if ((macInput.value.length || serialInput.value.length) && manufacturerInput.value === 'grandstream') {
        if (macInput.value.length > 5 && serialInput.value.length > 5) {
          const last6Mac = macInput.value.substring(macInput.value.length - 6);
          const last6Serial = serialInput.value.substring(serialInput.value.length - 6);
          if (last6Mac.toUpperCase() !== last6Serial.toUpperCase())
            return { mismatchedLast6Digits: true };
          else
            return { mismatchedLast6Digits: false }
        }
        else
          return { mismatchedLast6Digits: true }
      } else {
        return { mismatchedLast6Digits: false }
      }
    }
  }

  getPlaceHolder() {
    if (this.form.controls['manufacturer'].value === 'yealink')
      return 'Machine ID'
    else
      return 'Serial Number'
  }

}
