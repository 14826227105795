import { Input, Output, EventEmitter, Directive } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { sanitizePhoneNumber } from '@voiply/shared/common-ui';
import { MaskPipe, MaskApplierService } from 'ngx-mask';

@Directive()
export class SpamCallAllowedNumbersBase {
  number: number;
  @Input()
  allowededNumberList = []
  @Output()
  addAllowedNumber = new EventEmitter<any>();
  @Output()
  removeAllowedNumber = new EventEmitter<any>();
  mask = new MaskPipe(this.maskService);
  customPatterns = { '0': { pattern: new RegExp('[xX\\d]') } };
  constructor(protected toastr: ToastrService, protected maskService: MaskApplierService) {

  }

  onAllowNumber(number) {
    if (_.includes(this.addAllowedNumber, number)) {
      this.toastr.error("You have already added " + this.mask.transform(number, ['(000) 000-0000', this.customPatterns]) + " in the Allow list");
      return;
    }
    const sanitizedNumber = sanitizePhoneNumber(number);
    if (sanitizedNumber.length === 10) {
      this.addAllowedNumber.emit(number)
    }
    else {
      this.toastr.error("Please Enter Valid 10 Digit Number");
      return;
    }

  }
  onDeleteNumber(index) {
    this.removeAllowedNumber.emit(index)
  }
}
