import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BillingBase } from '../billing.base';

@Component({
  selector: 'checkout-billing-extraline',
  templateUrl: './billing-extraline.component.html',
  styleUrls: ['./billing-extraline.component.scss']
})
export class BillingExtralineComponent extends BillingBase {

  @Input() showBillingForm=false;
  constructor() {
    super()
  }

  public getForm(): FormGroup {
    return new FormGroup({
      ...this.getCommonFormControls()
    });
  }



}
