<!--Lib: product-configuration -->
<!--Component: home-configuration/home-configuration-model, business-configuration/business-configuration-model-->
<!--Added for Home, Business-->

<div [formGroup]="form" class="cart-item-background-color p-3">
  <div class="d-flex flex-wrap flex-row p-1">
    <div class="flex-fill pr-1" style="position: relative;">
      <input type="text" class="form-control" placeholder="Email" formControlName="email"
        [ngClass]="{ 'is-invalid': ((f.email.touched || submitted) && f.email.errors) }">
      <div *ngIf="((f.email.touched || submitted) && f.email.errors)">
        <span *ngIf="f.email.errors.required">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
        <span *ngIf="f.email.errors.email">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
      </div>
    </div>
    <div class="pl-1 d-none d-md-none d-lg-block">
      <button type="button" class="btn bg-primary font-weight-bold text-primary h-100" style="width: 240px;"
        (click)="toggleConfig()">
        <ng-container *ngIf="!isHideConfiguration">
          Hide Configurations
        </ng-container>
        <ng-container *ngIf="isHideConfiguration">
          Show Configurations
        </ng-container>
      </button>
    </div>
    <div class="pl-1 d-block d-md-block d-lg-none">
      <button type="button" class="btn bg-primary font-weight-bold text-primary h-100" (click)="toggleConfig()">
        <!-- <ng-container *ngIf="!isHideConfiguration"> -->
        <i *ngIf="!isHideConfiguration" class="fa fa-eject" aria-hidden="true"></i>
        <!-- </ng-container>
        <ng-container *ngIf="isHideConfiguration"> -->
        <i *ngIf="isHideConfiguration" class="fa fa-cog" aria-hidden="true"></i>
        <!-- </ng-container> -->
      </button>
    </div>
  </div>
  <div class="d-flex flex-wrap flex-row">
    <div class="p-1">
      <p>Record All Incoming Calls</p>
      <label class="radio-container ml-2 mr-3">
        <input type="radio" value="No" formControlName="recordIncoming">
        <span class="checkmark"></span>
        <span>No</span>
      </label>
      <label class="radio-container">
        <input type="radio" value="Yes" formControlName="recordIncoming">
        <span class="checkmark"></span>
        <span>Yes</span>
      </label>
    </div>
    <!-- Hide record Outgoing call option as it is not supported for now  -->
    <!-- <div class="p-1">
      <p>Record All Outgoing Calls</p>
      <label class="radio-container ml-2 mr-3">
        <input type="radio" value="No" formControlName="recordOutgoing">
        <span class="checkmark"></span>
        <span>No</span>
      </label>
      <label class="radio-container">
        <input type="radio" value="Yes" formControlName="recordOutgoing">
        <span class="checkmark"></span>
        <span>Yes</span>
      </label>
    </div> -->
  </div>
  <div class="p-1">
    Call recordings can be downloaded for up to 30 days.
  </div>

</div>
