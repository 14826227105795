import { Input, Output, EventEmitter, OnChanges, SimpleChanges, Directive } from '@angular/core';
import { Address } from '@voiply/shared/model';
import { FormBase, checkZipCodeIsValid, SetBillingAddressFormStatusAction, CalculatingTotalAction, difference, COUNTRIES } from '@voiply/shared/common-ui';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { FormatZipPipe } from 'libs/shared/common-ui/src/lib/pipes';

@Directive()
export abstract class BillingBase extends FormBase implements OnChanges {

  protected _address: { name: string, phone: string, address: string, address2: string, city: string, state: string, country: string, zip: string };
  countries = COUNTRIES;
  protected _email: string;
  filteredStates: any;
  @Input()
  set billingAddress(value: any) {
    this._email = value.email || '';
    this._address = {
      name: value.name || '',
      phone: value.phone || '',
      address: value.address || '',
      address2: value.address2 || '',
      city: value.city || '',
      state: value.state || '',
      country: value.country || '',
      zip: value.zip || ''
    };

    this.form.patchValue({ address: this._address, email: this._email });
    setTimeout(() => {
      this.form.updateValueAndValidity();      // Temp hack as initial value is not getting validated
    }, 1);
  }


  @Output()
  billingAddressChange = new EventEmitter<Address>();
  formatZipPipe: FormatZipPipe = new FormatZipPipe();
  @Dispatch() formValidChanged = (isValid) => new SetBillingAddressFormStatusAction(isValid);
  @Dispatch() calculatingTotal = (isCalculating: boolean) => new CalculatingTotalAction(isCalculating);

  public abstract getForm(): FormGroup;

  constructor() {
    super();

    this.form = this.getForm();

    // Subscribe to change events and other common initialization
    // this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.billingAddress)
      this.filteredStates = (_.filter(this.countries, { 'countryShortCode': changes.billingAddress.currentValue.country })[0] || {}).regions;
  }

  public formStatusChanged(isValid: boolean) {
    this.formValidChanged(isValid);
  }

  protected getCommonFormControls() {
    const address = new FormControl('');
    const email = new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.email()], updateOn: 'blur' });

    this.subscriptions$.add(address.valueChanges.subscribe((newAddress) => {

      if (this._address !== newAddress) {
        const billingAddress = { ...newAddress, email: this._email };
        billingAddress.zip = this.formatZipPipe.transform(billingAddress.zip)
        //   if (billingAddress.zip !== '' && !checkZipCodeIsValid(billingAddress.zip))
        //     return;
        this.billingAddressChange.emit(billingAddress);
        // const diff = difference(newAddress, this._address);

        // //Re-calculate total only if there is change in city, state or zip fields in billing address
        // if (diff)
        //   if (diff.hasOwnProperty("city") || diff.hasOwnProperty("state") || diff.hasOwnProperty("zip"))
        //     this.calculatingTotal(true);

        this._address = newAddress;
      }
    }));

    this.subscriptions$.add(email.valueChanges.subscribe((newEmail) => {
      if (this._email !== newEmail) {
        const billingAddress = { ...this._address, email: newEmail };

        //   if (billingAddress.zip !== '' && !checkZipCodeIsValid(billingAddress.zip))
        //     return;
        this.billingAddressChange.emit(billingAddress);

        this._email = newEmail;
      }
    }));

    return { address: address, email: email };
  }


}
