import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl } from '@angular/forms';
import { OnlineFaxFormControlBase } from '../online-fax-form-control.base';

@Component({
  selector: 'voiply-online-fax-form-control',
  templateUrl: './online-fax-form-control.component.html',
  styleUrls: ['./online-fax-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlineFaxFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OnlineFaxFormControlComponent),
      multi: true
    }
  ]
})
export class OnlineFaxFormControlComponent extends OnlineFaxFormControlBase {

}
