import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import { PHONES } from '@voiply/shared/common-ui';
import { CartItem, CartItemType, App, Phone, CompanyNumber } from '@voiply/shared/model';
import * as _ from 'lodash';

@Component({
  selector: 'checkout-cart-items-new',
  templateUrl: './cart-items-new.component.html',
  styleUrls: ['./cart-items-new.component.scss']
})
export class CartItemsNewComponent implements OnChanges {

  @Input()
  cartItems: any;
  @Input()
  apps: App[];
  @Input()
  phones: Phone[];
  @Input()
  companyNumbers: CompanyNumber[] = [];
  @Input()
  payAnnually: boolean;
  groupedCartItems: any[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cartItems === undefined)
      return;

    this.groupedCartItems = [];

    const cartItemArray: CartItem[] = [];
    // tslint:disable-next-line: forin
    for (const key in this.cartItems) {
      cartItemArray.push({ key: key, ...this.cartItems[key] });
    }

    //Process phones
    const phoneItems = _.chain(cartItemArray).filter((item) => item.type === CartItemType.Phone).groupBy(item => item.itemId).value();
    _.each(phoneItems, (items) => {
      const monthlyChargeText = this.convertToMoneyFormat(items[0].monthlyCharge * items.length);
      const annualChargeText = this.convertToMoneyFormat(items[0].monthlyCharge * items.length*0.8);
      if (items[0].itemId === 4) {
        const totalPhones = _.reduce(cartItemArray, (result, cartItem) => result + (cartItem.type === CartItemType.Phone && cartItem.itemId !== 4 ? 1 : 0), 0);

        this.groupedCartItems.push({
          ...Object.assign({}, items[0]), qty: items.length + totalPhones, totalCost: '', totalDiscountedCost: 0,
          monthlyTotalCostText: totalPhones>0 ? `Monthly Charges: ${items.length}x ${monthlyChargeText} & ${totalPhones}x INCLUDED` : `Monthly Charges: ${items.length}x ${monthlyChargeText}`,
          annualTotalCostText: totalPhones>0 ? `Monthly Charges: ${items.length}x ${annualChargeText} & ${totalPhones}x INCLUDED` : `Monthly Charges: ${items.length}x ${annualChargeText}`,
        });
      } else {
        this.groupedCartItems.push({
          ...Object.assign({}, items[0]), qty: items.length,
          totalCost: items[0].price > 0 ? items[0].price * items.length : 'FREE',
          totalDiscountedCost: items[0].discountedPrice > 0 ? items[0].discountedPrice * items.length : (items[0].heading==PHONES.BYOD || items[0].heading==PHONES.APPONLY) ? '' : 'FREE', // Do not display "free" for byod device 
          monthlyTotalCostText: "Monthly Charges: " + monthlyChargeText,
          annualTotalCostText: "Monthly Charges: " + annualChargeText,
          
        });
      }
    });

    // Process Apps
    const appItems = _.chain(cartItemArray).filter((item) => item.type === CartItemType.App).groupBy(item => item.itemId).value();
    _.each(appItems, (items) => {
      const monthlyChargeText = this.convertToMoneyFormat(items[0].monthlyCharge * items.length);
      const annualChargeText = this.convertToMoneyFormat(items[0].monthlyCharge * items.length*0.8);
      
      this.groupedCartItems.push({
        ...Object.assign({}, items[0]), qty: items.length,
        totalCost: items[0].price > 0 ? items[0].price * items.length : '',
        totalDiscountedCost: items[0].discountedPrice > 0 ? items[0].discountedPrice * items.length : '',
        monthlyTotalCostText: "Monthly Charges: " + monthlyChargeText,
        annualTotalCostText: "Monthly Charges: " + annualChargeText,
      });
    });

    // Process Company Numbers
    let localNumber = [];
    let internationalNumber = [];

    _.each(this.companyNumbers, (value) => {
      if (value.isInternational) {
        internationalNumber.push(value);
      } else {
        localNumber.push(value)
      }
    });
    if (localNumber.length > 1) {
      const monthlyChargeText = this.convertToMoneyFormat(1.25 * (localNumber.length - 1));
      this.groupedCartItems.push({
        qty: (localNumber.length) - 1,
        totalCost: 1.25 * (localNumber.length - 1),
        totalDiscountedCost: 1.25 * (localNumber.length - 1),
        type: 'CompanyNumber',
        heading: 'Additional Numbers',
        monthlyCharge: 1.25,
        monthlyTotalCostText: "Monthly Charges: " + monthlyChargeText,
      });
    }
    _.each(internationalNumber, (value) => {
      const monthlyChargeText = this.convertToMoneyFormat(value.package);
      this.groupedCartItems.push({
        qty: 1,
        totalCost: value.package,
        totalDiscountedCost: value.package,
        type: 'CompanyNumber',
        heading: 'International Number',
        monthlyCharge: value.package,
        monthlyTotalCostText: "Monthly Charges: " + monthlyChargeText,
      });
    });
  }

  convertToMoneyFormat(value) {
    return '$' + (parseFloat((Math.round(value * 100) / 100).toString()).toFixed(2));
  }


  getItemImage(itemId: string, itemType: CartItemType) {
    if (itemType === CartItemType.Phone) {
      if (this.phones.length > 0) return _.filter(this.phones, (phone) => phone.featureId === itemId)[0].image;
    } else if (itemType === CartItemType.App) {
      if (this.apps.length > 0) return _.filter(this.apps, (app) => app.appId === itemId)[0].image;
    }
  }
}
