export class Invoice {
    id: string;
    number: string;
    amount: number;
    created: Date;
    currency: string;
    webUrl: string;
    pdfUrl: string;
    plan: string;
}
