<div class="h-100 d-flex flex-column">
  <div class="card shadow p-3 bg-white">
    <!-- heading -->
    <div class="d-flex justify-content-between align-items-center bg-dark text-white rounded p-2">
      <div class="d-flex align-items-center">
          <span class="pr-2 d-flex align-items-center">
              <img src="assets/images/extraline/icons/upgrade-to-annual.svg" class="icon-white">
          </span>
          <span class="text-uppercase font-weight-bold">upgrade to annual</span>
      </div>
    </div>
    <!-- change credit card -->
    <ng-container>
      <div class="d-flex py-3">
        <div class="d-flex align-items-center flex-grow-1" *ngIf="!showCreditCard">
          <div class="h4 m-0"> <span class="text-muted"> Credit Card: </span><span class="font-weight-bold">****
              {{this.cardDetail.last4}}</span>
          </div>
        </div>
        <button class="btn bg-primary text-primary px-4 rounded" (click)="this.stripeService.mountNewCard(true)" *ngIf="!showCreditCard">
          CHANGE
        </button>
        <form (ngSubmit)="onSubmit()" class="d-flex align-items-center" [ngClass]="{'flex-grow-1':showCreditCard}">
          <div #cardElement class="input flex-grow-1"></div>
          <button class="btn bg-primary text-primary px-2 ml-2 rounded" *ngIf="showCreditCard" type="submit">
            <span *ngIf="!cardChangeRequesting">Submit</span>
            <mat-spinner *ngIf="cardChangeRequesting" diameter="22" value="accent" class="white-spinner"></mat-spinner>
          </button>
          <button class="btn bg-dark text-primary px-2 ml-2 rounded" *ngIf="showCreditCard" (click)="onCardChangeCancel()">
            <span>Cancel</span>
          </button>
        </form>
      </div>
    </ng-container>

    <!-- monthly billing -->
    <ng-container>
      <div class="flex-fill d-flex flex-column">
        <!-- header -->
        <div class="bg-dark text-white rounded p-2 d-flex align-items-center">
          <img src="assets/images/clock-circular.png" alt="" height="16px">
          <span class="pl-2">Monthly Billing <span class="font-weight-bold">(Current)</span></span>
        </div>
        <!-- content -->
        <div class="d-flex flex-column flex-grow-1 bg-light-grey">
          <!-- monthly price line -->
          <div class="d-flex justify-content-between p-3">
            <div class="d-flex text-muted align-items-center">
              <i class="far fa-clock pr-2"></i>
              <span>Monthly Price</span>
            </div>
            <ng-container>
              <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
              <div class="font-weight-bold" *ngIf="!loadingBillingDetails">
                {{this.billingDetails.currentSubtotal|formatMoneyValue}}</div>
            </ng-container>
          </div>
          <!-- monthly tax line -->
          <div class="d-flex justify-content-between p-3">
            <div class="d-flex text-muted align-items-center">
              <i class="far fa-percent pr-2"></i>
              <span>Monthly Tax</span>
            </div>
            <ng-container>
              <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
              <div class="font-weight-bold" *ngIf="!loadingBillingDetails">
                {{this.billingDetails.currentTax|formatMoneyValue}}</div>
            </ng-container>
          </div>
        </div>
        <!-- next billing date -->
        <div class="py-1 text-center text-muted" style="background-color: #EFEDEC;">
          <i class="far fa-calendar-alt pr-2"></i> Next Billing:
          {{this.nextBillingDate|date:'MM/dd/yyyy'||'00/00/0000'}}
        </div>
      </div>
    </ng-container>
    <!-- arrows -->
    <div class="m-2 align-self-center">
      <i class="fas fa-chevron-double-right fa-lg text-secondary d-none d-lg-block"></i>
      <i class="fas fa-chevron-double-down fa-lg text-secondary d-lg-none"></i>
    </div>
    <!-- annual billing -->
    <ng-container>
      <div class="flex-fill d-flex flex-column">
        <!-- header -->
        <div class="bg-dark text-white rounded p-2 d-flex align-items-center">
          <img src="assets/images/extraline/icons/upgrade-to-annual.svg" class="icon-white" alt="" height="20px">
          <span class="pl-2">Annual Billing <span class="font-weight-bold">(New)</span></span>
        </div>
        <!-- content -->
        <div class="d-flex flex-column flex-grow-1 bg-light-grey">
          <!-- annual price line -->
          <div class="d-flex justify-content-between p-3">
            <div class="d-flex text-muted align-items-center">
              <i class="far fa-clock pr-2"></i>
              <span>Annual Price</span></div>
            <div class="d-flex justify-content-between" style="width: 45%;">
              <div class="bg-primary-gradient rounded-pill text-primary px-2 py-1 align-self-center" style="font-size: 10px;">20% OFF
              </div>
              <ng-container>
                <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
                <div class="font-weight-bold text-secondary" *ngIf="!loadingBillingDetails">
                  {{this.billingDetails.upgradeSubtotal|formatMoneyValue}}</div>
              </ng-container>
            </div>
          </div>
          <!-- annual tax line -->
          <div class="d-flex justify-content-between p-3">
            <div class="d-flex text-muted align-items-center">
              <i class="far fa-percent pr-2"></i>
              <span>Annual Tax</span>
            </div>
            <div class="d-flex justify-content-between">
              <ng-container>
                <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
                <div class="font-weight-bold text-secondary" *ngIf="!loadingBillingDetails">
                  {{this.billingDetails.upgradeTax|formatMoneyValue}}</div>
              </ng-container>
            </div>
          </div>
          <!-- current month credit line -->
          <div class="d-flex justify-content-between p-3">
            <div class="d-flex text-muted align-items-center">
              <i class="far fa-dollar-sign"></i>
              <span class="pl-1">Current Month Credit</span></div>
            <ng-container>
              <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
              <div class="font-weight-bold text-secondary" *ngIf="!loadingBillingDetails">
                {{this.billingDetails.upgradeCredit|formatMoneyValue}}</div>
            </ng-container>
          </div>
        </div>
        <!-- next billing date -->
        <div class="header justify-content-between h6 font-weight-bold" style="background-color: #EFEDEC; color:#5D5D5D;">
          <div>Total to Pay</div>
          <ng-container>
            <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
            <div *ngIf="!loadingBillingDetails">{{this.billingDetails.upgradeTotal|formatMoneyValue}}</div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <!-- description text -->
    <div class="w-100 text-muted text-center pt-3 px-2">
      Changes will take place immediately. Payment amount will be
      recalculated in order to take into account the time left in your
      current subscription.
    </div>
    <!-- footer images -->
    <div class="footer bg-dark p-2 rounded mt-3">
      <img src="assets/images/secure-payment-alt.png" alt="" style="height: 20px;">
      <img src="assets/images/stripe.png" alt="" style="height: 20px;">
      <img src="assets/images/cards.png" alt="" style="height: 20px;">
    </div>
    <!-- bottom buttons -->
    <div class="mt-auto d-flex">
      <button class="btn bg-light-grey text-black w-50 rounded mt-3 mr-2" (click)="onCardChangeCancel(); back();">Back</button>
      <button class="btn btn-primary text-primary w-50 rounded mt-3 ml-2" [disabled]="loadingBillingDetails" (click)="onChangePaymentPlan()">
        Pay Now
        <mat-spinner *ngIf="confirmButtonClicked" class="d-inline-block white-spinner" diameter="22" value="accent"></mat-spinner>
      </button>
    </div>
  </div>
</div>



