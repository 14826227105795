<div [formGroup]="form">
    <div class="px-4 d-flex flex-column">
        <div class="d-flex align-items-center bg-grey my-2 py-1 rounded">
            <div class="flex-fill">
                <input id="{{ isStripeElement ? 'name' : '' }}" placeholder="Full Name ( First and Last Name)" autocomplete="name" class="bg-grey rounded-lg form-control-solid px-3 w-100 border" style="outline: none;" formControlName="name" name="name" [ngClass]="{ 'is-invalid': ((f.name.touched||submitted) && f.name.errors) }">
            </div>
            <div *ngIf="((f.name.touched||submitted) && f.name.errors)" class="pr-2" style="margin-left: -1px;">
                <div *ngIf="f.name.errors.required">
                    <i class="far fa-exclamation-circle text-danger" tooltip="Please enter name"></i></div>
                <div *ngIf="f.name.errors.name">
                    <i class="far fa-exclamation-circle text-danger" tooltip="Please enter valid name"></i></div>
            </div>
        </div>

        <div class="d-flex align-items-center bg-grey my-2 py-1 rounded">
            <div class="flex-fill">
                <input id="{{ isStripeElement ? 'phone' : '' }}" placeholder="Phone" autocomplete="tel" mask="(000) 000-0000" class="bg-grey rounded-lg form-control-solid px-3 w-100 border" style="outline: none;" formControlName="phone" name="phone" [ngClass]="{ 'is-invalid': ((f.phone.touched||submitted) && f.phone.errors) }">
            </div>
            <div *ngIf="((f.phone.touched||submitted) && f.phone.errors)" class="pr-2" style="margin-left: -1px;">
                <span *ngIf="f.phone.errors.required">
                <i class="far fa-exclamation-circle text-danger" tooltip="Please enter Phone Number"></i></span>
                <span *ngIf="f.phone.errors.phoneNumber">
                <i class="far fa-exclamation-circle text-danger" tooltip="Please enter valid Phone Number"></i></span>
            </div>
        </div>

        <div class="d-flex align-items-center bg-grey my-2 py-1 rounded">
            <div class="flex-fill">
                <input ngx-google-places-autocomplete id="{{ isStripeElement ? 'address' : '' }}" placeholder="Address" autocomplete="address-line1" class="bg-grey rounded-lg form-control-solid px-3 w-100 border" style="outline: none;" formControlName="address" name="address"
                    [ngClass]="{ 'is-invalid': ((f.address.touched||submitted) && f.address.errors) }" (onAddressChange)="handleAddressChange($event)" #placesRef="ngx-places">
            </div>
            <div *ngIf="((f.address.touched||submitted) && f.address.errors)" class="pr-2" style="margin-left: -1px;">
                <span *ngIf="f.address.errors.required">
              <i class="far fa-exclamation-circle text-danger" tooltip="Please enter Address"></i></span>
                <span *ngIf="f.address.errors.address">
              <i class="far fa-exclamation-circle text-danger" tooltip="Please enter valid Address"></i></span>
            </div>
        </div>

        <div class="d-flex align-items-center bg-grey my-2 py-1 rounded">
            <div class="flex-fill">
                <input id="{{ isStripeElement ? 'address-line-2' : '' }}" placeholder="Apt, Unit, Floor, Suite, Building (Optional)" autocomplete="address-line2" class="bg-grey rounded-lg form-control-solid px-3 w-100 border" style="outline: none;" formControlName="address2"
                    name="address2" [ngClass]="{ 'is-invalid': ((f.address2.touched||submitted) && f.address2.errors) }">
            </div>
        </div>

        <div class="d-flex align-items-center bg-grey my-2 py-1 rounded">
            <div class="flex-fill">
                <input id="{{ isStripeElement ? 'city' : '' }}" placeholder="City" autocomplete="address-level2" class="bg-grey rounded-lg form-control-solid px-3 w-100 border" style="outline: none;" formControlName="city" name="city" [ngClass]="{ 'is-invalid': ((f.city.touched||submitted) && f.city.errors) }">
            </div>
            <div *ngIf="((f.city.touched||submitted) && f.city.errors)" class="pr-2" style="margin-left: -1px;">
                <span *ngIf="f.city.errors.required">
          <i class="far fa-exclamation-circle text-danger" tooltip="Please enter City"></i></span>
                <span *ngIf="f.city.errors.alpha">
          <i class="far fa-exclamation-circle text-danger" tooltip="Please enter valid City"></i></span>
            </div>
        </div>

        <div class="row mx-0">
            <div class="col-6 pl-0 pr-1">
                <div class="d-flex align-items-center bg-grey my-2 py-1 rounded">
                    <div class="flex-fill d-flex align-items-center">
                        <div class="select-input" id="b-state">
                            <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
                            <select class="select-custom bg-grey form-control-solid px-3 w-100 border" style="outline: none;" formControlName="state" name="state">
                              <option value="" selected [ngClass]="{ 'is-invalid': ((f.state.touched||submitted) && f.state.errors) }">State</option>
                              <option *ngFor="let state of filteredStates" [value]="state.shortCode" style="color: #212529" [disabled]="state.shortCode == null">{{state.name}}</option>
                            </select>
                        </div>
                        <div *ngIf="((f.state.touched||submitted) && f.state.errors)" class="px-2" style="margin-left: -1px;">
                            <span *ngIf="f.state.errors.required">
                            <i class="far fa-exclamation-circle text-danger" tooltip="Please enter Address"></i></span>
                            <span *ngIf="f.state.errors.state">
                            <i class="far fa-exclamation-circle text-danger" tooltip="Please enter valid Address"></i></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 pr-0 pl-1">
                <div class="d-flex align-items-center bg-grey my-2 py-1 rounded">
                    <div class="flex-fill">
                        <input id="{{ isStripeElement ? 'zip' : '' }}" placeholder="Zip Code" autocomplete="postal-code" class="bg-grey rounded-lg form-control-solid px-3 w-100 border" style="outline: none;" formControlName="zip" name="zip" [value]="this.form.get('zip').value | formatZip"
                            [ngClass]="{ 'is-invalid': ((f.zip.touched||submitted) && f.zip.errors) }">
                    </div>
                    <div *ngIf="((f.zip.touched||submitted) && f.zip.errors)" class="pr-2" style="margin-left: -1px;">
                        <span *ngIf="f.zip.errors.required">
                    <i class="far fa-exclamation-circle text-danger" tooltip="Please enter Zip"></i></span>
                        <span *ngIf="f.zip.errors.zip">
                    <i class="far fa-exclamation-circle text-danger" tooltip="Please enter valid Zip"></i></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center bg-grey my-2 py-1 rounded">
            <div class="flex-fill d-flex align-items-center">
                <div class="select-input" id="b-country">
                    <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
                    <select class="select-custom bg-grey form-control-solid px-3 w-100 border" style="outline: none;" formControlName="country" name="country" placeholder="Country" [ngClass]="{ 'is-invalid': ((f.country.touched||submitted) && f.country.errors) }">
                    <option value="" selected>Country</option>
                    <option *ngFor="let country of countries" [value]="country.countryShortCode">{{country.countryName}}
                    </option>
                  </select>
                </div>
                <div *ngIf="((f.country.touched||submitted) && f.country.errors)" class="px-2" style="margin-left: -1px;">
                    <span *ngIf="f.country.errors.required">
                  <i class="far fa-exclamation-circle text-danger" tooltip="Please enter Address"></i></span>
                    <span *ngIf="f.country.errors.country">
                  <i class="far fa-exclamation-circle text-danger" tooltip="Please enter valid Address"></i></span>
                </div>
            </div>
        </div>
    </div>
</div>