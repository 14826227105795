import { Component, Input, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Select } from '@ngxs/store';
import { AppStateSelectors } from '../../+state';
import { Observable } from 'rxjs';
import { Subscription } from '@voiply/shared/model';
import { OnDestroyCleanup } from '../../generic-utils';

@Component({
  selector: 'common-ui-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.css'],
})
export class SectionHeaderComponent extends OnDestroyCleanup {

  @Input() imagePath: string;
  @Input() title: string;
  @Input() rightSideButton: string;
  @Input() helpText: string;
  @Input() rightSideImagePath: string;
  @Input() rightSideDropDownDefaultValue = ''
  @Input() rightSideDropDownoptions = [];
  showHelpText = false;
  @Output()
  rightSideButtonClick = new EventEmitter<any>();
  @Output()
  rightSideDropDownSelectedValue = new EventEmitter<any>();
  paymentSuccessful = false;
  @ContentChild(TemplateRef)
  template: TemplateRef<any>;
  @Select(AppStateSelectors.subscription) subscription$: Observable<Subscription>;


  constructor() {
    super();
    this.subscriptions$.add(this.subscription$.subscribe((subscription) => {
      if (subscription)
        this.paymentSuccessful = subscription.paymentSuccessful
    }));

  }
}
