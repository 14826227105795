<!--containing in - Lib: product-configuration -->
<!--used by - Component: home-configuration/extraline-settings-home, business-configuration/extraline-settings-business -->
<!--Added for - Apps: Extraline-settings-->

<div class="d-flex flex-column" [formGroup]="form">
    <!-- call forwarding section -->
    <div class="d-flex flex-column">
        <div class="d-flex justify-content-between bg-dark text-white rounded p-2">
            <div class="d-flex align-items-center">
                <!-- heading -->
                <span class="pr-2 d-flex align-items-center">
                    <img src="assets/images/extraline/icons/call-forwarding.svg" class="icon-white">
                </span>
                <span class="text-uppercase font-weight-bold">Call Forwarding</span>
            </div>
            <div>
                <!--TODO change UI for toggle button -->
                <!-- <mat-slide-toggle color="success" formControlName="callForwarding">
                </mat-slide-toggle> -->

                <div class=" btn-group btn-group-toggle bg-light-grey  rounded" data-toggle="buttons">
                    <button type="button" class="btn text-muted"
                        style="border-radius: 0.25rem 0 0 0.25rem; font-size: 12px;"
                        (click)="this.form.get('callForwarding').patchValue(false)"
                        [ngClass]="{'bg-error-gradient text-primary': !this.form.get('callForwarding').value}">
                        <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
                            <input type="radio" autocomplete="off" value="No" formControlName="callForwarding">
                            <span>NO</span>
                        </div>
                    </button>
                    <button type="button" class="btn text-muted"
                        style="border-radius: 0 0.25rem 0.25rem 0; font-size: 12px;"
                        (click)="this.form.get('callForwarding').patchValue(true)"
                        [ngClass]="{'bg-success-gradient text-primary': this.form.get('callForwarding').value}">
                        <div class="d-flex align-items-center justify-content-center font-weight-bold">
                            <input type="radio" autocomplete="off" value="Yes" formControlName="callForwarding">
                            <span>YES</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column mt-3">
            <span class="text-muted">Forwarding Number:</span>
            <input type="text" class="form-control rounded" formControlName="callForwardingNumber"
                mask="(000) 000-0000">
        </div>
    </div>
    <!-- emergency call forward section -->
    <!-- <div class="d-flex flex-column mt-4">
        <div class="d-flex justify-content-between bg-dark text-white rounded p-2">
            <div class="d-flex align-items-center">
                <span class="pr-2 d-flex align-items-center">
                <img src="assets/images/extraline/icons/call-forwarding.svg" class="icon-white">
          </span>
                <span class="text-uppercase font-weight-bold">Emergency Call Forwarding</span>
            </div>
            <div>
                <mat-slide-toggle color="success" formControlName="emergencyCallForwarding">
                </mat-slide-toggle>
            </div>
        </div>
        <div class="d-flex flex-column pt-1">
            <span class="text-muted">Forwarding Number:</span>
            <input type="text" class="form-control rounded" formControlName="emergencyCallForwardingNumber" mask="(000) 000-0000">
        </div>

    </div> -->
</div>