import * as Model from '@voiply/shared/model';
import { EventType } from '@voiply/shared/model';

export enum AppActionTypes {
  // App Action
  SetOrderId = '[App Initialization] Set Order Id',
  FillOrder = '[App Initialization] Fill Order',
  GetCurrentLocation = '[App Initialization] Get Current Location',
  GetPhonesAndApps = '[App Initialization] Get Phones And Apps',
  GetBusinessPhones = 'Get Business Phones',
  GetHomePhones = 'Get Home Phones',
  GetPhoneStatus = '[App Initialization] Get Phones Status',
  SetInitialLoadOrderData = '[App Initialization] Set Initial Load Order Data',
  SaveMetadata = '[App Initialization] Set Metadata',
  UserIsVoiplyTeamMember = '[App Initialization] User Is Voiply Team Member',
  SetCrispSessionId = '[App Initialization] Set Crisp Session Id',
  UpdateMetadata = 'Update Metadata',

  //Header
  UnLockOrder = '[Header] Un Lock Order',
  LockOrder = '[Header] Lock Order',

  // Survery
  SetSurveyBanner = '[Survey Banner] Banner to Show',
  SubmitSurvey = '[Survey] Submit Survey',
  SkipSurvey = '[Survey] Skip Survey',

  // Primary Phone
  SetPrimaryPhoneNumber = '[Primary Phone] Set Phone Number',
  AddCompanyNumber = '[Company Number] Add Company Number',
  UpdateCompanyNumber = '[Company Number] Update Company Number',
  RemoveCompanyNumber = '[Company Number] Remove Company Number',

  // All Form Status actions
  PerformValidationInAllForms = '[Validation] Do Validate Forms',
  SetPrimaryPhoneFormStatus = '[Primary Phone] Set Form Status',
  AddPhonesToCart = '[Phone] Add Phones to Cart',
  AddAppToCart = '[App] Add App to Cart',
  SaveCartItem = '[Product] Save Cart Item',
  UpdateCartItem = '[Product] Update Cart Item',
  UpdatecartItemPaidStatus = '[Products] Update Cart Item Paid Status',
  //Checkout
  UpdateCartItemConfiguration = '[product] Update Cart Item Configuration',
  RemoveCartItem = '[Product] Delete Cart Item',
  ApplyPromoCode = '[Checkout] Apply Promo Code',
  RemovePromoCode = '[Checkout] Remove Promo Code',
  ChangeBillingPlan = '[Checkout] Change Billing Plan',
  ChangeBillingPlanApp = '[App] Change Billing Plan',
  ShippingSameAsBilling = '[Checkout] Shipping Same As Billing',
  ShippingOptionSelect = '[Checkout] Shipping Option Select',
  ShippingAddressChange = '[Checkout] Shipping Address Change',
  BillingAddressChange = '[Checkout] Billing Address Change',
  BillingAddressChangeExtralineSettings='[ExtraLine] Billing Address Change',
  EmergencyAddressChange = '[Checkout] Emergency Address Change',
  CompanyNameChange = '[Checkout] Company Name Change',
  StripeDataChanged = '[Checkout] Stripe Data Changed',
  ShareOrder = '[Checkout] Share Order',
  SetBillingAddressFormStatus = '[BillingAddress] Set Form Status',
  SetShippingAddressFormStatus = '[ShippingAddress] Set Form Status',
  SetProductConfigurationStatus = '[ProductConfiguration] Set Form Status',
  UpdateOrderAction = '[Order] Update Order',
  updateBYODStatus = '[Checkout] Set status for BYOD',
  updateEmergencyStatus = '[Checkout] Set status for EmergencyApp',
  OrderedMorePhones = '[Checkout] Set Ordered More Phone Status',
  ChangeProtectionPlanStatus = '[Checkout] Protection Plan Status Change',
  UpdateCartItemProtectionPlan = '[Checkout] Update CartItem Protection Plan',
  AddCodeForLogin = '[Checkout] Add Code For Login',
  RemoveCodeForLogin = '[Checkout] Remove Code For Login',
  PaymentSuccessful = '[Checkout] Payment Successful',
  //Call Flow Scenerios
  SaveCallFlowSelection = '[Call Flow Scenerios] Save Selection Change',
  SaveDefaultBasicCallFlow = '[Call Flow Scenerios] Save Basic Call Flow',

  //Delete Call Flow
  DeleteCallFlow = '[Call Flow Remove] Delete Call Flow',
  //Call Scenarios
  SaveCallScenarioSelection = '[Call Flow Scenarios] Save Selection Change',
  SaveVirtualReceptionist = '[Virtual Receptionist] Save Virtual Receptionist',
  
  //Internet Providers
  SelectInternetProvider = '[Internet Providers] Save Internet Provider',

  CalculatingTotal = '[Loading] Calculating Total',
  updateShippingOptions = '[Reload] Fetch new shipping option',

  //After Checkout
  UpdateAdditionalNumbers = '[More Numbers] Save Additional Numbers',
  UpdatingPhoneSystem = '[Phone System] Update Phone System',
  AddMorePhones = '[More Phones] Add More Phones Clicked',
  //Last Event Card Process
  CardProcess = '[processing card] waiting to get card save in cosmos',

  //Update primary number in phone number
  UpdatePrimaryNumberInCompanyNumber = '[Update Primary Number]  '
}

// #region 'App Initialization'

export class SetOrderIdAction {
  static readonly type = AppActionTypes.SetOrderId;
  constructor(public readonly orderId: string) { }
}

export class FillOrderAction {
  static readonly type = AppActionTypes.FillOrder;
  constructor(public readonly orderData: any) { }
}

export class GetCurrentLocation {
  static readonly type = AppActionTypes.GetCurrentLocation;
  constructor() { }
}

export class GetPhonesAndApps {
  static readonly type = AppActionTypes.GetPhonesAndApps;
  constructor() { }
}

export class GetBusinessPhones {
  static readonly type = AppActionTypes.GetBusinessPhones;
  constructor() { }
}

export class GetHomePhones {
  static readonly type = AppActionTypes.GetHomePhones;
  constructor() { }
}
export class GetPhoneStatus {
  static readonly type = AppActionTypes.GetPhoneStatus;
  constructor() { }
}

export class SetInitialLoadOrderDataAction {
  static readonly type = AppActionTypes.SetInitialLoadOrderData;
  constructor(public readonly promoCode: string, public readonly email: string, public readonly metaData: any) { }
}

export class SaveMetadataAction {
  static readonly type = AppActionTypes.SaveMetadata;
  constructor(public readonly metaData: any) { }
}

export class UpdateMetadataAction {
  static readonly type = AppActionTypes.UpdateMetadata;
  constructor(public readonly metaData: any) { }
}


export class UserIsVoiplyTeamMemberAction {
  static readonly type = AppActionTypes.UserIsVoiplyTeamMember;
  constructor(public readonly isTrue: boolean) { }
}

export class SetCrispSessionIdAction {
  static readonly type = AppActionTypes.SetCrispSessionId;
  constructor(public readonly crispSessionId: string) { }
}

export class UnLockOrderAction {
  static readonly type = AppActionTypes.UnLockOrder;
  constructor() { }
}


// #endregion

// #region 'Survey'

export class SetSurveyBannerAction {
  static readonly type = AppActionTypes.SetSurveyBanner
  constructor(public readonly surveyBanner: string) { }
}

export class SubmitSurveyAction {
  static readonly type = AppActionTypes.SubmitSurvey;
  constructor(public readonly surveyResponse: Model.Survey, public readonly phoneId: number) { }
}

export class SkipSurveyAction {
  static readonly type = AppActionTypes.SkipSurvey;
  constructor() { }
}

// #endregion

// #region 'Primary Phone'

export class SetPrimaryPhoneFormStatusAction {
  static readonly type = AppActionTypes.SetPrimaryPhoneFormStatus;
  constructor(public readonly isValid: boolean) { }
}

export class SetPrimaryPhoneNumber {
  static readonly type = AppActionTypes.SetPrimaryPhoneNumber;
  constructor(public readonly phoneNumber: Model.PrimaryPhone, public readonly updateInServer?: boolean) { }
}

export class AddCompanyNumberAction {
  static readonly type = AppActionTypes.AddCompanyNumber;
  constructor(public readonly phoneNumber: Model.CompanyNumber) { }
}

export class UpdateCompanyNumberAction {
  static readonly type = AppActionTypes.UpdateCompanyNumber;
  constructor(public readonly phoneNumber: Model.CompanyNumber) { }
}

export class RemoveCompanyNumberAction {
  static readonly type = AppActionTypes.RemoveCompanyNumber;
  constructor(public readonly phoneNumber: Model.CompanyNumber) { }
}

// #endregion

export class SaveCartItemAction {
  static readonly type = AppActionTypes.SaveCartItem;
  constructor(public readonly cartItem: Model.CartItem) { }
}

export class AddPhonesToCartAction {
  static readonly type = AppActionTypes.AddPhonesToCart;
  constructor(public readonly phoneFeatureId: number, public readonly quantity: number, public readonly phoneConfiguration?: Model.PhoneConfiguration, public readonly eventName?: EventType) { }
}

export class AddAppToCartAction {
  static readonly type = AppActionTypes.AddAppToCart;
  constructor(public readonly app: Model.App) { }
}

export class UpdateCartItemAction {
  static readonly type = AppActionTypes.UpdateCartItem;
  constructor(public readonly key: string, public readonly updateFeatureId: number, public readonly eventName?: EventType) { }
}

export class UpdateCartItemConfigurationAction {
  static readonly type = AppActionTypes.UpdateCartItemConfiguration;
  constructor(public readonly key: string, public readonly cartItemConfiguration: Model.PhoneConfiguration) { }
}

export class UpdatecartItemPaidStatusAction {
  static readonly type = AppActionTypes.UpdatecartItemPaidStatus;
  constructor(public readonly cartItems: any) { }
}

export class RemoveCartItemAction {
  static readonly type = AppActionTypes.RemoveCartItem;
  constructor(public readonly cartItemKey: string, public readonly eventName?: EventType) { }
}

export class SaveCallScenarioSelectionAction{
  static readonly type = AppActionTypes.SaveCallScenarioSelection;
  constructor( public readonly callScenarioSelection: Model.CallScenarioModel, public readonly companyNumber?: any){}

}

export class SaveCallFlowSelectionAction {
  static readonly type = AppActionTypes.SaveCallFlowSelection;
  constructor(public readonly callFlowSelection: Model.CallFlow, public readonly companyNumber?: any) { }
}

export class SaveVirtualReceptionistAction{
  static readonly type = AppActionTypes.SaveVirtualReceptionist;
  constructor( public readonly virtualReceptionist: Model.VirtualReceptionModel){}
}

export class SaveDefaultBasicCallFlowAction {
  static readonly type = AppActionTypes.SaveDefaultBasicCallFlow;
  constructor(public readonly callFlowSelection: Model.CallFlow) { }
}

export class DeleteCallFlowAction{
  static readonly type = AppActionTypes.DeleteCallFlow;
  constructor(public readonly checkoutObj){}
}
// #region 'Generic Actions'

export class SetProductConfigurationStatusAction {
  static readonly type = AppActionTypes.SetProductConfigurationStatus;
  constructor(public readonly isValid: boolean) { }
}

export class PerformValidationInAllForms {
  static readonly type = AppActionTypes.PerformValidationInAllForms;
  constructor() { }
}

export class UpdateCartItemProtectionPlanAction {
  static readonly type = AppActionTypes.UpdateCartItemProtectionPlan;
  constructor() { }
}

// #endregion

//#region 'Promo Code
export class ApplyPromoCodeAction {
  static readonly type = AppActionTypes.ApplyPromoCode;
  constructor(public readonly promoCode: string) { }
}

export class RemovePromoCodeAction {
  static readonly type = AppActionTypes.RemovePromoCode;
  constructor() { }
}
//#endregion

//#region Checkout
export class ChangeBillingPlanAction {
  static readonly type = AppActionTypes.ChangeBillingPlan;
  constructor(public readonly isAnnualBilling: boolean) { }
}

export class ChangeBillingPlanAppAction {
  static readonly type = AppActionTypes.ChangeBillingPlanApp;
  constructor(public readonly isAnnualBilling: boolean) { }
}

export class ShippingSameAsBillingAction {
  static readonly type = AppActionTypes.ShippingSameAsBilling;
  constructor(public readonly isTrue: boolean) { }
}

export class SelectShippingOptionAction {
  static readonly type = AppActionTypes.ShippingOptionSelect;
  constructor(public readonly shippingOption: Model.ShippingOptions, public readonly eventName?: EventType) { }
}

export class ShippingAddressChangeAction {
  static readonly type = AppActionTypes.ShippingAddressChange;
  constructor(public readonly shippingAddress: Model.Address) { }
}

export class EmergencyAddressChangeAction {
  static readonly type = AppActionTypes.EmergencyAddressChange;
  constructor(public readonly emergencyAddress: Model.EmergencyAddress) { }
}
export class BillingAddressChangeAction {
  static readonly type = AppActionTypes.BillingAddressChange;
  constructor(public readonly billingAddress: Model.Address) { }
}

export class BillingAddressChangeExtralineSettingsAction {
  static readonly type = AppActionTypes.BillingAddressChangeExtralineSettings;
  constructor(public readonly billingAddress: Model.Address) { }
}

export class CompanyNameChangeAction {
  static readonly type = AppActionTypes.CompanyNameChange;
  constructor(public readonly companyName: string) { }
}

export class StripeDataChangedAction {
  static readonly type = AppActionTypes.StripeDataChanged;
  constructor(public readonly stripe: Model.Stripe) { }
}

export class ShareOrderAction {
  static readonly type = AppActionTypes.ShareOrder;
  constructor(public readonly sharing: Model.ShareOrder) { }
}

export class CalculatingTotalAction {
  static readonly type = AppActionTypes.CalculatingTotal;
  constructor(public readonly isCalculating: boolean) { }
}

export class UpdateShippingOptions {
  static readonly type = AppActionTypes.updateShippingOptions;
  constructor(public readonly fetchOptions: boolean) { }
}

export class SetBillingAddressFormStatusAction {
  static readonly type = AppActionTypes.SetBillingAddressFormStatus;
  constructor(public readonly isValid: boolean) { }
}

export class SetShippingAddressFormStatusAction {
  static readonly type = AppActionTypes.SetShippingAddressFormStatus;
  constructor(public readonly isValid: boolean) { }
}

export class UpdateOrderAction {
  static readonly type = AppActionTypes.UpdateOrderAction;
  constructor(public readonly orderData: any) { }
}

export class UpdateBYODStatusAction {
  static readonly type = AppActionTypes.updateBYODStatus;
  constructor(public readonly hasBYODphone: boolean) { }
}

export class updateEmergencyStatusAction {
  static readonly type = AppActionTypes.updateEmergencyStatus;
  constructor(public readonly hasEmergency999: boolean) { }
}
export class OrderedMorePhonesAction {
  static readonly type = AppActionTypes.OrderedMorePhones;
  constructor(public readonly orderedMorePhones: boolean) { }
}

export class ChangeProtectionPlanStatusAction {
  static readonly type = AppActionTypes.ChangeProtectionPlanStatus;
  constructor(public readonly status: boolean) { }
}

export class AddCodeForLoginAction {
  static readonly type = AppActionTypes.AddCodeForLogin
  constructor(public readonly code: string) { }
}

export class RemoveCodeForLoginAction {
  static readonly type = AppActionTypes.RemoveCodeForLogin
  constructor() { }
}

export class PaymentSuccessfulAction {
  static readonly type = AppActionTypes.PaymentSuccessful
  constructor(public readonly subscriptionInfo: any) { }
}
//#endregion

//#region 'Internet Providers'

export class SelectInternetProviderAction {
  static readonly type = AppActionTypes.SelectInternetProvider;
  constructor(public readonly selectInternetProviderName: string) { }
}

//#endregion

//#region 'After Checkout'
export class UpdateAdditionalNumbersAction {
  static readonly type = AppActionTypes.UpdateAdditionalNumbers;
  constructor(public readonly additionalNumbers: any) { }
}

export class UpdatingPhoneSystemAction {
  static readonly type = AppActionTypes.UpdatingPhoneSystem;
  constructor(public readonly updatingOrder: boolean) { }
}

export class AddMorePhonesAction {
  static readonly type = AppActionTypes.AddMorePhones;
  constructor() { }
}
//#endregion

//#region 'Card Processing'
export class CardProcessAction {
  static readonly type = AppActionTypes.CardProcess;
  constructor(public readonly cardProcess: { isCardProcessing: boolean }) { }
}

//Update primary number in company number

export class UpdatePrimaryNumberInCompanyNumberAction{
  static readonly type = AppActionTypes.UpdatePrimaryNumberInCompanyNumber;
  constructor(public readonly phoneNumber: string,public readonly isNew){};
}

export const AppActions = {
  // App Action
  SetOrderIdAction,
  FillOrderAction,
  SetInitialLoadOrderDataAction,
  SaveMetadataAction,
  UpdateMetadataAction,
  UserIsVoiplyTeamMemberAction,
  SetCrispSessionIdAction,
  CardProcessAction,
  //Header
  UnLockOrderAction,

  // Survery
  SetSurveyBannerAction,
  SubmitSurveyAction,
  SkipSurveyAction,

  // Primary Phone
  SetPrimaryPhoneNumber,
  AddCompanyNumberAction,
  UpdateCompanyNumberAction,
  RemoveCompanyNumberAction,
  //Products
  AddPhonesToCartAction,
  AddAppToCartAction,
  SaveCartItemAction,
  UpdateCartItemAction,
  UpdatecartItemPaidStatusAction,
  UpdateCartItemConfigurationAction,
  RemoveCartItemAction,
  SetProductConfigurationStatusAction,
  UpdateCartItemProtectionPlanAction,

  // All Form Status actions
  SetPrimaryPhoneFormStatusAction,
  PerformValidationInAllForms,

  //Call Flow Scenerios
  SaveCallFlowSelectionAction,
  SaveDefaultBasicCallFlowAction,

  //Delete Call Flow
  DeleteCallFlowAction,
  //Checkout
  ApplyPromoCodeAction,
  RemovePromoCodeAction,
  ChangeBillingPlanAction,
  ChangeBillingPlanAppAction,
  ShippingSameAsBillingAction,
  SelectShippingOptionAction,
  ShippingAddressChangeAction,
  CompanyNameChangeAction,
  BillingAddressChangeAction,
  BillingAddressChangeExtralineSettingsAction,
  EmergencyAddressChangeAction,
  StripeDataChangedAction,
  ShareOrderAction,
  SetBillingAddressFormStatusAction,
  SetShippingAddressFormStatusAction,
  CalculatingTotalAction,
  UpdateOrderAction,
  OrderedMorePhonesAction,
  ChangeProtectionPlanStatusAction,
  AddCodeForLoginAction,
  RemoveCodeForLoginAction,
  PaymentSuccessfulAction,
  //AfterCheckout
  UpdateAdditionalNumbersAction,
  UpdatingPhoneSystemAction,
  AddMorePhonesAction,

  //
  UpdatePrimaryNumberInCompanyNumberAction,
}
