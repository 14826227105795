import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl } from '@angular/forms';
import { OnlineFaxFormControlBase } from '../online-fax-form-control.base';

@Component({
  selector: 'voiply-online-fax-form-control-alt',
  templateUrl: './online-fax-form-control-alt.component.html',
  styleUrls: ['./online-fax-form-control-alt.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlineFaxFormControlAltComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OnlineFaxFormControlAltComponent),
      multi: true
    }
  ]
})
export class OnlineFaxFormControlAltComponent extends OnlineFaxFormControlBase {

}
