import { Component, Input } from '@angular/core';
@Component({
  selector: 'voiply-call-logs',
  templateUrl: './call-logs.component.html',
  styleUrls: ['./call-logs.component.scss']
})
export class CallLogsComponent {
  @Input() orderId: string;

  ngOnInit() { }
}
