import { Component, EventEmitter, Output, Input } from '@angular/core';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import * as RecordRTC from 'recordrtc';

@Component({
  selector: 'voiply-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {


  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  recording=false;
  public record;

  timer;
  timeInSeconds=0;
  timeInMinutes=0;
  currentTimeInSecond;
  currentTimeInMinutes;

  @Input() fileName: string = '';
  @Output() fileAdded: EventEmitter<{ name: string, file: any }>
  constructor() {
    this.options = { concurrency: 1, maxUploads: 1, allowedContentTypes: ['audio/wav'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.fileAdded = new EventEmitter<{ name: string, file: any }>();
    this.humanizeBytes = humanizeBytes;
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      this.getBase64(this.files[0].nativeFile, this.files[0].name);
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    }
  }

  startUpload(): void {
    this.getBase64(this.files[0].nativeFile, this.files[0].name);
  }

  getBase64(file, name) {
    this.fileAdded.emit({ name: name, file: file });
  }

  removeFile() {
    this.files = [];
    this.fileAdded.emit({ name: '', file: null });
  }

  startVoiceRecording(){
    navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true
      }).then((stream) => { 
        this.startTimeInterval();
        this.recording=true;
        this.record = RecordRTC(stream, {
          type: 'audio/wav'
        });
      this.record.startRecording();
    });
  }

  stopVoiceRecording(){
    this.recording=false;
    this.resetTimeInterval();
    this.record.stopRecording(() => {
      let blob = this.record.getBlob();   
      let current_file = new File([blob],"recording.wav",{type:"audio/wav"});
      this.fileAdded.emit({name:'recording.wav',file: current_file}); 
    });
  }

  startTimeInterval(){
    this.timer = setInterval(()=>{
      this.timeInSeconds+=1;
      this.timeInMinutes = Math.floor(this.timeInSeconds/60);
      this.currentTimeInMinutes = this.timeInMinutes < 10 ? '0'+this.timeInMinutes : this.timeInMinutes;
      this.currentTimeInSecond = this.timeInSeconds%60;
      this.currentTimeInSecond = this.currentTimeInSecond<10 ? '0'+this.currentTimeInSecond : this.currentTimeInSecond;
    },1000);  
  }

  resetTimeInterval(){
    clearInterval(this.timer);
    this.timeInSeconds=0;
    this.timeInMinutes=0;
    this.currentTimeInSecond='';
    this.currentTimeInMinutes='';
  }

}
