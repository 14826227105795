import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Pipe({
  name: 'formatPhoneNumber'
})
export class FormatPhoneNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return new AsYouType('US').input(value.toString());
    }
  }

}
