import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ThankyouModalBase } from '../thank-you-modal.base';

@Component({
  selector: 'checkout-thankyou-modal-alt',
  templateUrl: './thankyou-modal-alt.component.html',
  styleUrls: ['./thankyou-modal-alt.component.scss']
})
export class ThankyouModalAltComponent extends ThankyouModalBase {
  @Input() backButtonUrl;
  constructor(private router: Router, private modelService: BsModalService) {
    super()
  }
  back() {
    this.router.navigateByUrl(this.backButtonUrl);
    this.modelService.hide(1)
  }
}
