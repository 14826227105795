export interface PrimaryPhone {
    number: string;
    isNew: boolean;
    isValid: boolean;
    systemSelected: boolean;
    selectedNewNumberInfo: SelectedNewNumberInfo;
}

interface SelectedNewNumberInfo{
    selectedState: string,
    sortedBy: newPrimaryNumberSortedBy,
    selectedAreaCode:string,
    selectedCity:string,
}

export enum newPrimaryNumberSortedBy{
   areaCode= "areaCode",
   city="city"
}