import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonUiModule } from '@voiply/shared/common-ui';

import { COMPONENTS } from './components';
// import { SERVICES } from './services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    CommonUiModule
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS
  ]
  // providers: [
  //   ...SERVICES
  // ]
})
export class SharedNumberSearchModule { }
