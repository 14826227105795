import { Component } from '@angular/core';
import { AppStateSelectors } from '@voiply/shared/common-ui';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Shippment } from '@voiply/shared/model';

@Component({
  selector: 'voiply-call-logs-extraline-settings',
  templateUrl: './call-logs-extraline-settings.component.html',
  styleUrls: ['./call-logs-extraline-settings.component.scss']
})
export class CallLogsExtralineSettingsComponent {
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
}
