import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, Resolve, ActivatedRoute } from '@angular/router';
import { of, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { OrderService, UserService, RouterService, FillOrderAction, RemoveCodeForLoginAction } from '@voiply/shared/common-ui';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { SystemType } from '@voiply/shared/model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

    @Dispatch() saveOrderState = (orderData: any) => new FillOrderAction(orderData);
    @Dispatch() removeCodeForLoginAction = () => new RemoveCodeForLoginAction();
    constructor(private authService: AuthService, private orderService: OrderService, private userService: UserService,
        private routerService: RouterService) {
    }

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return this.resolveUser(route);
    }

    private resolveUser(route) {
        return new Promise<boolean>(async (resolve, reject) => {
            const orderId = route.params.id;
            const codeForLogin = route.queryParams.code || '';
            if (route.queryParams.adminaccess === 'voiply') {
                //sending business type to show popup with social media login for admin
                return this.navigateToLogin(orderId, SystemType.Business, 'password', false, false);
            }

            console.log('[RESOLVER] Order Id is ', orderId);

            if (!orderId || orderId == null) {
                //order is not found, so navigate to create order page
                this.routerService.navigate('/');
                return reject();
            }

            // Get order detail from Cosmos
            const order = await this.orderService.getOrderAsync(orderId);

            if (order == null) {
                //order is not found, so navigate to create order page
                this.routerService.navigate('/');
                return reject();
            }

            if (route.queryParamMap.get('changesignup')) {
                localStorage.setItem('changeSignUp', "true");
            }

            //#region Assign URL for CJ Conversion
            var url = localStorage.getItem('cjUrl');
            if (url) {
                let cjelement = document.getElementById("cjConversion") as HTMLFrameElement;
                cjelement.src = url;
                localStorage.removeItem('cjUrl');
            }
            //#endregion
            let loginMethod = '';
            let firstLogin = false;
            let systemType = order.metadata.systemType;
            let email = order.checkoutDetail.billingAddress.email;
            if (!(order.customer || {}).auth0Id) {
                loginMethod = "password"
                firstLogin = true;
            } else {
                loginMethod = (order.customer || {}).method || 'passwordless'
            }

            let orderAuth0Id = (order.customer || {}).auth0Id;
            if ((order.customer || {}).updatedSignUp) {
                loginMethod = order.customer.updatedSignUp.method;
                orderAuth0Id = order.customer.updatedSignUp.auth0Id;
            }
            const orderPaidAlready = (order.subscription || {}).paymentSuccessful || false;
            console.log('[RESOLVER] Order result received with order auth id.', orderAuth0Id);


            console.log('[RESOLVER] Check user is logged in or not.');
            //This below code is done so if there is no customer object for a order Admin can login to that order
            const adminAuth0Id = localStorage.getItem('adminAuth0Id')
            localStorage.removeItem('adminAuth0Id')
            // If order is locked
            if (orderAuth0Id || adminAuth0Id) {
                // const authResult = await this.authService.checkSessionAsync(orderId);

                const tokenPayload: any = await this.authService.isAuthenticated();
                const userAuth0Id = (tokenPayload || {}).sub;
                if (tokenPayload == null) {
                    // Navigate to login
                    return this.navigateToLogin(orderId, systemType, loginMethod, false, firstLogin);
                }

                if (userAuth0Id === orderAuth0Id) {
                    // console.log(`[RESOLVER] Welcome back ${authResult.idTokenPayload.name}`, authResult);
                    //Save order in state
                    order.orderId = orderId;
                    this.saveOrderState(order);
                    if (order.metadata.codeForLogin)
                        this.removeCodeForLoginAction();
                    return resolve(true);

                } else {

                    // Check is it voiply team
                    console.log(`[RESOLVER] Checking user ${userAuth0Id} is voiply team member.`);
                    const isItVoiplyTeamMember = await this.userService.isVoiplyTeamMember(userAuth0Id);
                    if (isItVoiplyTeamMember.voiplyTeamMember) {
                        console.log(`[RESOLVER] Voiply Member opened order ${tokenPayload.name}`);
                        //Save order in state
                        order.orderId = orderId;
                        this.saveOrderState(order);
                        return resolve(true);
                    } else {
                        console.log(`[RESOLVER] User auth ${userAuth0Id}, order auth ${orderAuth0Id}`);

                        alert('You do not have access to this order. Please login with correct user.');

                        // User not authorized to view this order.
                        return this.navigateToLogin(orderId, systemType, loginMethod, false, firstLogin);
                    }
                }
            } else if (orderPaidAlready) {
                console.log('[RESOLVER] Order is paid but not locked yet. Hence, navigating to login.');
                //This codition is to check if user came with the loginlink sent to their email if not then redirect them to error page
                if (codeForLogin === order.metadata.codeForLogin) {
                    return this.navigateToLogin(orderId, systemType, loginMethod, false, firstLogin, email);
                }
                else {
                    this.routerService.navigate(`${orderId}/error`)
                    resolve(false);
                }
            } else {
                //order is not paid and not locked, so navigate to create order page
                this.routerService.navigate(`/${orderId}`);
                return reject();
            }
        });
    }

    navigateToLogin(orderId: string, systemType, loginMethod = "passwordless", isclosable = true, firstLogin = false, email = '') {
        console.log('Navigate to login');
        localStorage.setItem("OrderId", orderId);
        if (loginMethod === 'passwordless') {
            this.authService.login(isclosable);
        } else {
            //below condition is to show social login in business
            if (systemType === SystemType.Business) {
                if (firstLogin) {
                    this.authService.signUp(true, email)
                } else {
                    this.authService.loginWithPassword(isclosable, true);
                }
            } else {
                if (firstLogin) {
                    this.authService.signUp(false, email)
                } else {
                    this.authService.loginWithPassword(isclosable, false);
                }
            }
        }
    }

}