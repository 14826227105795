import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import * as Model from '@voiply/shared/model';

import { NumberSearchService } from '../../services';

@Component({
  selector: 'voiply-tollfree-number-search-alt',
  templateUrl: './tollfree-number-search-alt.component.html',
  styleUrls: ['./tollfree-number-search-alt.component.css']
})
export class TollfreeNumberSearchAltComponent implements OnInit {

  @Output() numberSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() numberSelectedInfo: EventEmitter<Model.PrimaryPhone> = new  EventEmitter<Model.PrimaryPhone>();

  // Toll free variables
  tollFreeNumbers = [];
  matchingTollFreeNumbers = [];
  tollFreePrefixes = [844, 855, 866, 877, 888];
  tollfreeSearchValue: string = '';
  searching: boolean = true;
  selectedNewNumberInfo;

  constructor(private numberService: NumberSearchService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getTollFreeNumbers(855);
  }

  // Toll Free
  getTollFreeNumbers(prefix) {
    this.tollfreeSearchValue = '';
    this.matchingTollFreeNumbers = [];
    this.numberService.getTollFreeNumbers(prefix).subscribe(
      data => {
        if(data){
        this.tollFreeNumbers = data.numbers;
        this.matchingTollFreeNumbers = _.take(data.numbers, 12);
        }
      },
      (error) => console.error(error),
      () => this.searching = false
    )
  }

  
  onTollFreeSearch(searchTerm) {
    searchTerm = this.numberService.phoneDecode(searchTerm.toUpperCase()).replace(" ", "").replace("-", "");
    let filteredNumbers = _.filter(this.tollFreeNumbers, (number) => {
      return number.toString().indexOf(searchTerm) >= 0;
    });
    filteredNumbers = _.sortBy(filteredNumbers, 'score').reverse();
    this.matchingTollFreeNumbers = _.first(filteredNumbers, 12);
  }

  selectedNumberChanged(number: string) {
    this.selectedNewNumberInfo={
      number: number.toString(),
      isNew: true,
      isValid:true,
      systemSelected:true,
      selectedNewNumberInfo:null
    };
    this.numberSelectedInfo.emit(this.selectedNewNumberInfo);
    this.numberSelected.emit(number);
  }
}
