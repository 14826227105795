import { AfterCheckoutResolver } from './after-checkout.resolver';
import { ExtralineSettingsResolver } from './extraline-settings.resolver';

export const RESOLVERS = [
    AfterCheckoutResolver,
    ExtralineSettingsResolver
];

export { AfterCheckoutResolver } from './after-checkout.resolver';
export { ExtralineSettingsResolver } from './extraline-settings.resolver';
