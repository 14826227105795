<!-- <div class="card mt-4">
    <div class="p-3">
        <div class="d-flex justify-content-between align-items-center">
            <div class="text-secondary font-weight-bold"> Allow Numbers</div>
            <div style="width: 200px">
                <div class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="number" mask="(000) 000-0000" [patterns]="customPatterns" placeholder="Type Number Here">
                    <button class="input-group-append btn bg-black text-primary d-flex align-items-center" type="button" (click)="onAllowNumber(this.number)">
						<i class="fas fa-plus"></i>
					</button>
                </div>

            </div>
        </div>
        <div class="mt-3 text-secondary" style="font-size: 12px;">USE X FOR WILDCARD, EXAMPLE: <span class="font-weight-bold">(866) 5XX-XXXX</span>
        </div>
        <hr class="my-3">
        <div class="allow-numbers">
            <div class="row">
                <div *ngFor="let number of allowededNumberList; let i=index" class="col-xs-12 col-sm-6">
                    <ng-container *ngIf="number">
                        <div class="" *ngIf="i%2==0">
                            <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                            <span class="text-color">
								{{number | mask:['(000) 000-0000',customPatterns]}}</span>
                        </div>
                        <div class="" *ngIf="i%2!=0">
                            <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                            <span class="text-color">
								{{number | mask:['(000) 000-0000',customPatterns]}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!--New UI -->
<div class="card" style="border: none;">
    <div class="d-flex flex-column" style="background-color: rgba(249, 249, 249,1);border-radius: 0px 0px 8px 8px;" >
        <div class="d-flex flex-column p-2 flex-fill">
            <div class="p-2">
                <h4><strong>Allow Numbers</strong></h4>
            </div>
            <div class="p-2 flex-fill">
                <div class="">
                    <div style="width: 100%">
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="number" mask="(000) 000-0000" [patterns]="customPatterns" placeholder="Type Number Here" style="height:45px;">
                            <button class="input-group-append btn bg-black text-primary d-flex align-items-center" type="button" (click)="onAllowNumber(this.number)" style="background-image: linear-gradient(to right, #FB522C, #D22700) !important;">
                                <!-- <i class="fas fa-plus"></i> -->
                                <img class="img-fluid" src='assets/images/allowNumbers.svg'>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="allow-numbers">
                    <div class="row">
                        <div *ngFor="let number of allowededNumberList; let i=index" class="col-xs-12 col-sm-6">
                            <ng-container *ngIf="number">
                                <div class="" *ngIf="i%2==0">
                                    <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                                    <span class="text-color">
                                        {{number | mask:['(000) 000-0000',customPatterns]}}</span>
                                </div>
                                <div class="" *ngIf="i%2!=0">
                                    <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                                    <span class="text-color">
                                        {{number | mask:['(000) 000-0000',customPatterns]}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
