<div class="modal-body py-5">

    <div class="position-relative d-flex align-items-center justify-content-center flex-column">

        <div class="mb-4">
            <h1 class="font-weight-bold text-center m-0" style="font-size: 53px">THANK YOU</h1>
            <h2 class="text-secondary font-weight-bold m-0 text-center" style="font-size: 35px;">FOR YOUR ORDER</h2>
        </div>
        <div class="bg-white py-3 px-3 w-100 mb-4 font-weight-bold text-center" style="font-size: 12px;">
            The Purchase Receipt is going to be emailed to you!
        </div>
        <div class="mb-4 w-100 border-top"></div>
        <a class="btn btn-primary w-100 py-4 d-flex align-items-center justify-content-center" [href]="loginLink">
            <i class="fas fa-user mr-3"></i>
            <h5 class="m-0">Let's Create your Account</h5>
        </a>
    </div>
</div>