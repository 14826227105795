import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { BaseService } from "./base.service";

const humanAttendantFileName = `${location.origin}/assets/humanattendant.json`;

@Injectable({
    providedIn: 'root'
})
export class HumanAttendantConfigService extends BaseService {

    constructor(private http: HttpClient) {
        super()
    }


    getBusinessCategories(): Promise<any> {
        return this.http.get(humanAttendantFileName).pipe(catchError(this.handleError)).toPromise();
    }
}