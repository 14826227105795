<div [formGroup]="form" class="cart-item-background-color">
    <div class="d-flex flex-column flex-lg-row justify-content-between">

        <div class="d-flex flex-row  flex-fill p-2 p-lg-0">
            <span class="mr-2  mt-1 mt-lg-0 align-self-center">Enable SMS Archiving</span>
            <div class=" btn btn-group btn-sm d-flex flex-row m-0 p-0" role="group"
                aria-label="Basic example" style="height: 20px;">
                <button   type="button" style="border-radius: 5px 0px 0px 5px;"
                    class="btn border d-flex flex-row justify-content-between align-items-center px-3 py-3"
                    [ngClass]="!toggle? 'open_color' :'nopen_color'" (click)="onClick(false)">
                    <div class="flex-grow-1">Off</div>
                </button>
                <button  type="button" style="border-radius: 0px 5px 5px 0px;"
                    class="btn border d-flex flex-row justify-content-between align-items-center px-3 py-3"
                    [ngClass]="!toggle? 'nopen_color' :'open_color'" (click)="onClick(true)">
                    <div class="flex-grow-1">On</div>
                </button>
            </div>
        </div>
        <div class="flex-fill p-2 p-lg-0 px-lg-2 py-lg-0 mt-2 mt-lg-0">
            <input type="text" class="form-control" placeholder="Type the Email Address for SMS"
          formControlName="archive_sms">
        </div>
    </div>
</div>
