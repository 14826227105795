import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';

@Component({
  selector: 'voiply-sms-settings-form-control-component',
  templateUrl: './sms-settings-form-control-component.component.html',
  styleUrls: ['./sms-settings-form-control-component.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SMSSettingsFormControlComponentComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SMSSettingsFormControlComponentComponent),
      multi: true
    }
  ]
})
export class SMSSettingsFormControlComponentComponent extends ControlValueAccessorAndValidator implements OnInit, OnChanges {
  toggle;
  @Input()
  isSMSArchivingEnable;
  constructor() { 
    super();
    this.form = new FormGroup({
      isSMSArchivingEnable: new FormControl({value:false}),
      archive_sms: new FormControl({value: ''})
    });

  }

  ngOnInit(): void {
    this.toggle=this.isSMSArchivingEnable;
  }

  ngOnChanges() {
    
  }
  onClick(flag){
    console.log(this.form)
    if(this.toggle !=flag){
      this.toggle=!this.toggle;
      this.form.get("isSMSArchivingEnable").setValue(this.toggle);
      return;
    }
  }

}
