<div class="modal-header p-4">
  <div class="d-flex flex-row align-items-center justify-content-between flex-grow-1">
    <div class="d-flex align-items-center">
      <img src="assets/images/checked.svg" *ngIf="this.troubleShoot.length>0&&this.troubleShoot[0]?.error==='none'"
        class="icon-white" style="width:24px" alt="">
      <i class="fas  fa-exclamation-circle  text-white" style="font-size:20px"
        *ngIf="this.troubleShoot.length>0&&this.troubleShoot[0]?.error!=='none'"></i>
      <i class="fas fa-cogs text-white" style="font-size:20px" *ngIf="this.troubleShoot.length===0"></i>
      <div class="mx-3"
        style="border-left: 1px solid white;border-right: 1px solid white;background-color: white;height: 20px;">
      </div>
      <h4 class="m-0 font-weight-bold text-primary" *ngIf="this.troubleShoot.length===0">Running Voiply Network
        Test</h4>
      <h4 class="m-0 font-weight-bold text-primary"
        *ngIf="this.troubleShoot.length>0&&this.troubleShoot[0]?.error!=='none'">Found Some Issues</h4>
      <h4 class="m-0 font-weight-bold text-primary"
        *ngIf="this.troubleShoot.length>0&&this.troubleShoot[0]?.error==='none'">
        Everything Looks Good</h4>
    </div>
    <button type="button" class=" btn p-0" (click)="bsModalRef.hide();">
      <i class="fas fa-times-circle fa-lg text-primary"></i>
    </button>
  </div>
</div>
<div class="modal-body d-flex justify-content-center align-items-center"
  style="min-height:350px;background-color:#fbfbfb">
  <div *ngIf="showGif">
    <div class="font-weight-bold text-center"
      style="position: absolute; top: 50%;left: 50%;z-index:0;width: 100px;margin-left: -50px;">Please Wait....
    </div>
    <img [src]="gifUrl" alt="Please Wait..." height="300px" width="300px"
      style="position: absolute; top: 50%;left: 50%;z-index:1;margin-left: -150px;margin-top: -150px;">
  </div>
  <div *ngFor="let error of troubleShoot" [ngClass]="error.error==='none'?'border-sucess':'border'"
    class="p-4 d-flex align-items-center justify-content-center mx-2 mx-xl-5 my-2 flex-wrap flex-sm-nowrap position-relative">
    <div *ngIf="error.error!=='none'&&error.type==='device'"
      class="border bg-black text-white px-2 py-1 position-absolute d-flex align-items-center"
      style="top: 0;left: 0;font-size: 10px;">
      <img src="assets/images/phone-with-wire.svg" class="icon-white mr-2" alt="">
      {{error.item}}</div>
    <div *ngIf="error.error!=='none'&&error.type==='number'"
      class="border bg-black text-white px-2 py-1 position-absolute d-flex align-items-center"
      style="top: 0;left: 0;font-size: 10px;">
      <img src="assets/images/landline.svg" class="icon-white mr-2" alt="">
      {{error.item}}</div>
    <div class="d-flex flex-row align-items-center" style="width: 320%;">
      <img src="assets/images/adapter-issue.png" *ngIf="error.error!=='none'&&error.type==='device'" style="width:60px"
        class="mr-4" alt="">
      <img src="assets/images/number-issue.png" *ngIf="error.error!=='none'&&error.type==='number'" style="width:60px"
        class="mr-4" alt="">
      <img src="assets/images/like.svg" *ngIf="error.error==='none'" class="mr-4 icon-success" alt="">
      {{error.error_description}}
    </div>
    <a [href]="error.link" target="_blank" class="w-100 d-flex" style="text-decoration: none;" *ngIf="error.link">
      <button class="btn btn-outline-primary mt-4 mt-sm-0 w-100  ml-sm-4 text-nowrap position-relative">
        <div class="position-absolute d-flex" style="top: 3px;right: 3px;transform: rotate(-45deg);"><i
            class="far fa-long-arrow-right arrow-color" style="font-size:12px;"></i></div>
        More Info
      </button>
    </a>
  </div>
  <!-- <div *ngIf="this.troubleShoot.length===0&&this.errorDescription" class="mx-5 my-2">
    <div class="p-4 d-flex align-items-center justify-content-center border">
      <img src="assets/images/like.svg" class="mr-4 " alt=""> {{this.errorDescription}}</div>
    <div class="mt-3">If you're experiencing call quality issues, dropped calls
      or is unable to make calls, Contact Support.</div>
  </div> -->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="troubleshooting()">Restart
    Test</button>
  <button type="button" class="btn btn-primary" (click)="openCrispChat()">Get Help</button>
</div>