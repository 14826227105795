import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'voiply-conferencing-form-control',
  templateUrl: './conferencing-form-control.component.html',
  styleUrls: ['./conferencing-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConferencingFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConferencingFormControlComponent),
      multi: true
    }
  ]
})
export class ConferencingFormControlComponent extends ControlValueAccessorAndValidator {
  @Input() submitted = false;
  @Input() disableInput = false;
  constructor() {
    super();
    this.form = new FormGroup({
      email: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.email()], updateOn: 'blur' }),
    });
  }

}
