import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { ENVIRONMENT, IEnvironment } from '@voiply/shared/common-ui';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NumberSearchService {

  constructor(@Inject(ENVIRONMENT) private readonly environment: IEnvironment, private http: HttpClient) { }

  getNumbersByState(stateCode: string, city: string, areaCode: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.environment["Ocp-Apim-Subscription-Key"]
      })
    };
    //Set New York as default to get numbers 
    if(stateCode=="")
      stateCode="NY"
    let queryString = `state=${stateCode}`;
    if (city !== '')
      queryString = `&city=${city}`;
    if (areaCode !== '')
      queryString = `&area=${areaCode}`;
    return this.http.get<any>(`${this.environment.voiplyPhoneSearchAPI}?${queryString}`, options)
      .pipe(catchError(this.handleError));
  }

  getTollFreeNumbers(startSquence) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.environment["Ocp-Apim-Subscription-Key"]
      })
    };
    return this.http.get<any>(`${this.environment.voiplyPhoneSearchAPI}?area=${startSquence}`, options)
      .pipe(catchError(this.handleError));
  }

  phoneDecode(input) {
    let output = '';

    const letters = {
      ABC: 2,
      DEF: 3,
      GHI: 4,
      JKL: 5,
      MNO: 6,
      PQRS: 7,
      TUV: 8,
      WXYZ: 9,
    };

    if (input) {
      input = input.split('');

      for (let i = 0, len = input.length; i < len; i++) {
        for (const l in letters) {
          if (l.indexOf(input[i]) > -1) {
            input[i] = letters[l];
          }
        }
      }

      output = input.join('');
    }

    return output;
  }

  // #region 'Private Methods'

  private handleError(error: Response | any) {
    console.error('ERROR HTTP Call', error);
    return of(null);
    // return throwError(error.status);
  }

  // #endregion

}
