import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'extraline-settings-update-credit-card',
  templateUrl: './update-credit-card.component.html',
  styleUrls: ['./update-credit-card.component.scss']
})
export class UpdateCreditCardComponent {
  queryParams;
  constructor(private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
          this.queryParams = params;
      })
  }
}
