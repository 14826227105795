import { Component, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import * as _ from 'lodash';
import { SpamCallBotFormControlBase } from '../spam-call-bot-form-control.base';
import {SpamCallBotSetting} from '@voiply/shared/model';

@Component({
  selector: 'voiply-spam-call-bot-form-control',
  templateUrl: './spam-call-bot-form-control.component.html',
  styleUrls: ['./spam-call-bot-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpamCallBotFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SpamCallBotFormControlComponent),
      multi: true
    }
  ]
})
export class SpamCallBotFormControlComponent extends SpamCallBotFormControlBase implements OnInit {
  constructor() {
    super();
  }
  Setting_Type: typeof SpamCallBotSetting = SpamCallBotSetting;


  setValue(name,onOrOff){
    if(onOrOff=='on'){
      if(this.form.get(name).value=='No'){ 
        this.doStateChange(name,'Yes');
      }
    }
    else{
      if(this.form.get(name).value=='Yes'){
        this.doStateChange(name,'No');
      }
    }
  }

  doStateChange(name,value){
    this.form.setValue(
      {
        extension:this.form.get('extension').value,
        autoscreening:name==this.Setting_Type.autoScreening ? value : this.form.get(this.Setting_Type.autoScreening).value,
        smsscreening: name==this.Setting_Type.smsScreening ? value : this.form.get(this.Setting_Type.smsScreening).value,
        callrejection: name==this.Setting_Type.callRejection? value : this.form.get(this.Setting_Type.callRejection).value,
        isallowList: name==this.Setting_Type.isAllowList ? value : this.form.get(this.Setting_Type.isAllowList).value,
        blockedList: this.blockedList.value,
        allowedList:this.allowedList.value
      });
  }
}
