<!--containing in - Lib: product-configuration -->
<!--used by - Container: product-configuration-business, product-configuration-business-after-checkout -->
<!--Added for Business-->
<form [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{value.heading}}</h4>
        <button type="button" class=" btn pull-right" (click)="bsModalRef.hide()">
            <i class="fas fa-times-circle fa-lg text-primary"></i>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="">
            <ng-container *ngIf="value.type==='phone'">
                <div formGroupName={{key}}>
                    <voiply-phone-form-control [submitted]="formSubmitted" formControlName="phone"
                        [extDisable]=extDisabled [disableInput]="disableInput" (isHideConfig)=onHideConfig($event)>
                    </voiply-phone-form-control>
                    <ng-container>
                        <mat-tab-group animationDuration="0ms" #tabs class="cart-item-background-color"
                            [hidden]="isHideConfiguration===true">
                            <mat-tab label="VoiceMail Settings">
                                <div class="d-flex flex-column flex-lg-row justify-content-between">
                                    <div class="d-flex flex-row pb-3">
                                        <span class="mr-2 align-self-center">Enable Voicemail to Email</span>
                                        <div class=" btn btn-group btn-sm d-flex flex-row m-0 p-0" role="group"
                                            aria-label="Basic example" style="height: 20px;">
                                            <button id="1"  type="button" style="border-radius: 5px 0px 0px 5px;"
                                                class="btn border d-flex flex-row justify-content-between align-items-center px-3 py-3"
                                                [ngClass]="!toggle? 'open_color' :'nopen_color'" (click)="onClick('1')">
                                                <div class="flex-grow-1">Off</div>
                                            </button>
                                            <button id="2" type="button" style="border-radius: 0px 5px 5px 0px;"
                                                class="btn border d-flex flex-row justify-content-between align-items-center px-3 py-3"
                                                [ngClass]="!toggle? 'nopen_color' :'open_color'" (click)="onClick('2')">
                                                <div class="flex-grow-1">On</div>
                                            </button>
                                        </div>
                                    </div>
                                    
    
                                    <voiply-missed-call-form-control  [isDisable]="!toggle" formControlName="ringCount">
                                    </voiply-missed-call-form-control>
                                    
                                </div>
                                <voiply-voicemail-attachment-form-control [isDisable]="!toggle" formControlName="voicemailAttachment">
                                </voiply-voicemail-attachment-form-control>
                                <voiply-includein-company-directory-form-control [isDisable]="!toggle"
                                    formControlName="includeinCompanyDirectory">
                                </voiply-includein-company-directory-form-control>
                                <voiply-voicemail-form-control formControlName="voice" [isDisable]="!toggle"
                                    insertTemplateVariableFrom="{{key}}" class="cart-item-background-color"
                                    audioCategory="VoiceMail" [recordingData]="productConfig">
                                </voiply-voicemail-form-control>
                            </mat-tab>

                            <mat-tab label="Call Forwarding Settings">
                                <voiply-callforwarding-form-control formControlName="callForwarding">
                                </voiply-callforwarding-form-control>
                            </mat-tab>
                            <mat-tab label="Emergency Address" *ngIf="value.paid &&showEmergencyAddress">
                                <voiply-emergency-address-form-control formControlName="emergencyAddress"
                                    [filteredStates]="filteredStates" [submitted]="formSubmitted"
                                    [disableInput]="this.form.controls[this.key]['controls']['emergencyAddress'].value.enableEmergencyAddress">
                                </voiply-emergency-address-form-control>
                            </mat-tab>
                            <mat-tab label="Device Settings" *ngIf="value.itemId===11||userIsVoiplyMember">
                                <voiply-device-settings-form-control formControlName="deviceSettings" [models]="models"
                                    [submitted]="formSubmitted" [disableDeviceSettings]="disableDeviceSettings"
                                    [showDeviceSettingsValidation]="showDeviceSettingsValidation">
                                </voiply-device-settings-form-control>
                            </mat-tab>

                            <mat-tab label="SMS Settings">
                                <voiply-sms-settings-form-control-component formControlName="smsSettings" [isSMSArchivingEnable]="this.form.controls[this.key]['controls']['smsSettings'].value.isSMSArchivingEnable">

                                </voiply-sms-settings-form-control-component>
                            </mat-tab>

                        </mat-tab-group>

                    </ng-container>
                </div>
            </ng-container>
            <div *ngIf="value.heading==='Online Fax'">
                <div formGroupName={{key}}>
                    <voiply-online-fax-form-control [submitted]="formSubmitted" formControlName="onlineFax"
                        [disableInput]="disableInput">
                    </voiply-online-fax-form-control>
                </div>
            </div>
            <div *ngIf="value.heading==='Conferencing'">
                <div formGroupName={{key}}>
                    <voiply-conferencing-form-control [submitted]="formSubmitted" formControlName="conferencing"
                        [disableInput]="disableInput">
                    </voiply-conferencing-form-control>
                </div>
            </div>
            <div *ngIf="value.heading==='Spam Call Bot'">
                <div formGroupName={{key}}>
                    <voiply-spam-call-bot-form-control [submitted]="formSubmitted" formControlName="spamCallBot"
                        [extDisabled]="extDisabled" [blockedNumberList]="productConfig.blockedList || []"
                        [allowedNumberList]="productConfig.allowedList || []">
                    </voiply-spam-call-bot-form-control>
                </div>
            </div>
            <div *ngIf="value.heading==='Call Recording'">
                <div formGroupName="{{key}}">
                    <voiply-call-recording-form-control [submitted]="formSubmitted" formControlName="callRecording"
                        (isHideConfig)=onHideConfig($event)>
                    </voiply-call-recording-form-control>
                    <mat-tab-group animationDuration="0ms" class="cart-item-background-color"
                        [hidden]="isHideConfiguration===true">
                        <mat-tab label="VoiceMail Settings">
                            <voiply-voicemail-form-control formControlName="voice" insertTemplateVariableFrom="{{key}}"
                                audioCategory="CallRecording" [recordingData]="productConfig"
                                class="cart-item-background-color">
                            </voiply-voicemail-form-control>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
            <div *ngIf="value.heading==='Emergency 999'">
                <div formGroupName="{{key}}">
                    <voiply-alert911-form-control formControlName="emergency999"></voiply-alert911-form-control>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer cart-item-background-color px-4">
        <button type="submit" class="btn bg-primary text-primary px-4 py-2">
            Save Changes
        </button>
    </div>
</form>
