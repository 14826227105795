<div class="d-flex align-items-center">
  <div class="flex-fill text-muted d-flex">
    <i class="far fa-percent text-center align-self-center mr-2" style="width: 42px;"></i>
    <span>
      Taxes & Fees
    </span>
  </div>
  <div *ngIf="taxDetail">
    <ng-container *ngIf="calculatingTotal">
      <mat-spinner class="float-right" diameter="18" value="accent"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!calculatingTotal">
      <span class="text-black font-weight-bold">
          {{taxDetail.estimatedTotalTax | formatMoneyValue}}
      </span>
    </ng-container>
  </div>
</div>
