import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppStateSelectors, UpdateCartItemConfigurationAction } from '../../+state';
import * as _ from 'lodash';
import { OnDestroyCleanup } from '../../generic-utils';

@Component({
  selector: 'common-ui-caller-id',
  templateUrl: './caller-id.component.html',
  styleUrls: ['./caller-id.component.scss']
})
export class CallerIdComponent extends OnDestroyCleanup implements OnInit {
  @Input() backButtonUrl;
  userId;
  configuration;

  constructor(private router: Router, private route: ActivatedRoute) {
    super()
    this.userId = route.snapshot.queryParams.u;
  }

  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;
  @Select(AppStateSelectors.companyNumbers) companyNumbers$: Observable<any>;
  @Select(AppStateSelectors.primaryPhoneNumber) primaryPhoneNumber$: Observable<any>;

  @Dispatch() updateCartItemConfigurationAction = (key, cartItemConfiguration) => new UpdateCartItemConfigurationAction(key, cartItemConfiguration);

  callerIdNumbers = [];

  form = new FormGroup({
    name: new FormControl(''),
    selectedNumber: new FormControl('')
  });

  ngOnInit() {

    this.subscriptions$.add(this.cartItems$.subscribe(cartItems => {
      _.forEach(cartItems, (value, key) => {
        this.configuration = value.configuration
        if(this.configuration.userId === this.userId) {
          this.form.patchValue({name: `${this.configuration.firstName} ${this.configuration.lastName}`})
          if(this.configuration.phoneNumber) this.callerIdNumbers.push(this.configuration.phoneNumber)
        }
      })
      if(this.callerIdNumbers.length) this.form.patchValue({selectedNumber: this.callerIdNumbers[0]})
    }));
    this.subscriptions$.add(
      this.primaryPhoneNumber$.subscribe(primaryNnumber => {
        this.callerIdNumbers.push(primaryNnumber)
        if(this.callerIdNumbers.length) this.form.patchValue({selectedNumber: this.callerIdNumbers[0]})
      })
    );
    this.subscriptions$.add(
      this.companyNumbers$.subscribe(companyNumbers => {
        companyNumbers.forEach(number => {
          if(!number.isPrimaryNumber)
            this.callerIdNumbers.push(number.number);
        });
        if(this.callerIdNumbers.length) this.form.patchValue({selectedNumber: this.callerIdNumbers[0]})
      })
    );
  }

  onSubmit() {

  }

  back() {
    this.router.navigateByUrl(this.backButtonUrl);
  }
}
