import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MissedCallFormControlBase } from '../missed-call-form-control.base';

@Component({
  selector: 'voiply-missed-call-form-control',
  templateUrl: './missed-call-form-control.component.html',
  styleUrls: ['./missed-call-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MissedCallFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MissedCallFormControlComponent),
      multi: true
    }
  ]
})
export class MissedCallFormControlComponent extends MissedCallFormControlBase {
  @Input() isDisable: boolean;
  constructor() {
    super();
  }
}
