
<!-- <fieldset>
  <div class="bg-primary">
    <div class="text-primary font-weight-bold inner-header ml-4">Billing address</div>
  </div>
  <form #checkout [formGroup]="form">
    <div class="bg-white">
      <div class="row">
        <div class=" col-3 text-sm-right text-left align-self-center">
          <label for="company-name" [ngClass]="{'text-danger': ((f.companyName.touched) && f.companyName.errors)}"
            class="pl-2">Company</label>
        </div>
        <div class="col-9">
          <input id="company-name" class="form-control" placeholder="Enter your Company Name"
            formControlName="companyName"
            [ngClass]="{ 'is-invalid': ((f.companyName.touched) && f.companyName.errors) }">
          <div *ngIf="((f.companyName.touched) && f.companyName.errors)">
            <span *ngIf="f.companyName.errors.required">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter company"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grey">
      <div class="row">
        <div class=" col-3 text-sm-right text-left align-self-center">
          <label for="email" [ngClass]="{'text-danger': ((f.email.touched) && f.email.errors)}"
            class="pl-2">Email</label>
        </div>
        <div class="col-9">
          <input id="email" formControlName="email" type="email" placeholder="jamieappleseed@gmail.com"
            class="form-control" autocomplete="email"
            [ngClass]="{ 'is-invalid': ((f.email.touched) && f.email.errors) }">
          <div *ngIf="((f.email.touched) && f.email.errors)">
            <span *ngIf="f.email.errors.required">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
            <span *ngIf="f.email.errors.email">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
          </div>
        </div>
      </div>
    </div>
    <checkout-address formControlName="address" isStripeElement="true" [submitted]="formSubmitted"
      [filteredStates]="filteredStates"></checkout-address>
  </form>
</fieldset> -->
<fieldset>
    <div class="inner-header">
        <div class="font-weight-bolder text-uppercase ml-4 h5" style="opacity: 0.6;">Billing address</div>
    </div>
    <form #checkout [formGroup]="form">
      <div class="px-4 d-flex flex-column" *ngIf="showCompanyNameField">
        <div class="d-flex align-items-center bg-grey my-2 py-1 rounded" >
            <div class="flex-fill">
                <input id="company-name" placeholder="Company Name" 
                autocomplete="companyName" 
                class="bg-grey rounded-lg form-control-solid px-3 w-100 border" 
                style="outline: none; background-color: #EEEEEE !important;"
                formControlName="companyName" 
                [ngClass]="{ 'is-invalid': ((f.companyName.touched) && f.companyName.errors) }">
                
            </div>
            <div *ngIf="((f.companyName.touched) && f.companyName.errors)" class="pr-2" style="margin-left: -1px;">
              <span *ngIf="f.companyName.errors.required">
                <i class="far fa-exclamation-circle text-danger" tooltip="Please enter company"></i>
              </span>
            </div>
        </div>
      </div>
  
        <div class="px-4 d-flex flex-column">
            <div class="d-flex align-items-center bg-grey my-2 py-1 rounded">
                <div class="flex-fill">
                    <input id="email" placeholder="Email" autocomplete="email" 
                    class="bg-grey rounded-lg form-control-solid px-3 w-100 border" 
                    style="outline: none; background-color: #EEEEEE !important;" 
                    formControlName="email" [ngClass]="{ 'is-invalid': ((f.email.touched) && f.email.errors) }">
                </div>
                <div *ngIf="((f.email.touched) && f.email.errors)" class="pr-2" style="margin-left: -1px;">
                    <span *ngIf="f.email.errors.required">
            <i class="far fa-exclamation-circle text-danger" tooltip="Please enter Email"></i></span>
                    <span *ngIf="f.email.errors.email">
            <i class="far fa-exclamation-circle text-danger" tooltip="Please enter valid Email"></i></span>
                </div>
            </div>
        </div>
        <checkout-address-new formControlName="address" [submitted]="formSubmitted" [filteredStates]="filteredStates">
        </checkout-address-new>
    </form>
  </fieldset>