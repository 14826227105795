import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '@voiply/shared/common-ui';
import { PrimaryNumberAfterCheckoutBase } from '../primary-number-after-checkout-base';
import { BsModalService } from 'ngx-bootstrap/modal/';
import { ValidationService } from 'libs/shared/common-ui/src/lib/services';
import { AddMorePhonesHomeComponent } from 'libs/checkout/src/lib/containers';

@Component({
  selector: 'voiply-primary-number-home-after-checkout',
  templateUrl: './primary-number-home-after-checkout.component.html',
  styleUrls: ['./primary-number-home-after-checkout.component.scss']
})
export class PrimaryNumberHomeAfterCheckoutComponent extends PrimaryNumberAfterCheckoutBase {

  constructor(orderService: OrderService, toastr: ToastrService, modalService: BsModalService, validateService: ValidationService) {
    super(orderService, toastr, modalService, validateService);
  }


}
