import { Component, forwardRef } from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CallforwardingFormControlBase } from "../call-forwarding-form-control.base";

@Component({
  selector: 'voiply-call-forwarding-form-control-alt',
  templateUrl: './call-forwarding-form-control-alt.component.html',
  styleUrls: ['./call-forwarding-form-control-alt.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CallForwardingFormControlAltComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CallForwardingFormControlAltComponent),
      multi: true
    }
  ]
})
export class CallForwardingFormControlAltComponent extends CallforwardingFormControlBase {
  constructor() {
    super()
  }
}
