import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeepMergeService {

constructor() { }

  merge(source, changes) {
    if (changes != null)
      Object.keys(changes).forEach((key) => {
        if (!source[key]) {
          source[key] = changes[key];
        } else {
          if (changes[key] == null)
            delete source[key];
          else if (Array.isArray(changes[key]))
            source[key] = changes[key];
          else if ((typeof changes[key]) === "object") {
            this.merge(source[key], changes[key]);
          }
          else {
            source[key] = changes[key];
          }
        }
      })
  }
}
