import { Component, forwardRef } from "@angular/core";
import { Form, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SpamCallBotFormControlBase } from "../spam-call-bot-form-control.base";

@Component({
  selector: 'voiply-spam-call-bot-form-control-alt',
  templateUrl: './spam-call-bot-form-control-alt.component.html',
  styleUrls: [ './spam-call-bot-form-control-alt.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpamCallBotFormControlAltComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SpamCallBotFormControlAltComponent),
      multi: true
    }
  ]
})
export class SpamCallBotFormControlAltComponent extends SpamCallBotFormControlBase {
  constructor() {
    super()
  }
}
