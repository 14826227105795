import { Directive, Input } from '@angular/core';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { FormGroup, FormControl } from '@angular/forms';

@Directive({})
export class CallforwardingFormControlBase extends ControlValueAccessorAndValidator {
  @Input() submitted = false;

  constructor() {
    super();

    this.form = new FormGroup({
      callForwarding: new FormControl(false),
      callForwardingNumber: new FormControl('', { updateOn: 'blur' }),
      emergencyCallForwarding: new FormControl(false),
      emergencyCallForwardingNumber: new FormControl('', { updateOn: 'blur' }),
    });
  }








}
