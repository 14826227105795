import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  initialload = true;

  dataplans = `${location.origin}/assets/data-plans.json`;
  constructor(private http: HttpClient, private toastr: ToastrService) { }

  getdata(): Observable<any> {
    return this.http.get(this.dataplans);
  }

  paymentForDataPlans(order_id, package_id): Promise<any> {
    let path = '';
    if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
      path = `${environment.voiplyOnlinePaymentAPI}/airalo-submit-order?code=HDrPMWj/kXs381Dfno6dNaxG5ryPjwKNIeag/wDafJNdzQBvQJiEGQ==&order_id=${order_id}&package_id=${package_id}&dev=true`;
    else
      path = `${environment.voiplyOnlinePaymentAPI}/airalo-submit-order?code=HDrPMWj/kXs381Dfno6dNaxG5ryPjwKNIeag/wDafJNdzQBvQJiEGQ==&order_id=${order_id}&package_id=${package_id}`;

    return this.http.post(path, '').pipe(catchError((error) => {
      if (error.status === 400) {
        this.toastr.warning('Something Went Wrong. Please Try Again Later');
      }
      console.error('ERROR HTTP Call', error);
      return of(null);
    })).toPromise();;
  }

}
