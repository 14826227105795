<div class="d-flex flex-column h-100">
  <div class="card shadow p-3 bg-white">
    <!-- heading -->
    <div class="d-flex bg-dark text-white rounded p-2">
      <span class="pr-2 d-flex align-items-center">
        <img
          src="assets/images/extraline/icons/caller-id.svg"
          class="icon-white"
        />
      </span>
      <span class="text-uppercase font-weight-bold">Caller ID</span>
    </div>
    <!-- body -->
    <div class="pt-3">
      <form [formGroup]="form">
        <!-- Name field -->
        <div class="d-flex flex-column pt-1">
          <span class="text-muted">Name</span>
          <span class="d-flex">
            <input
              type="text"
              class="form-control rounded bg-light-grey"
              style="box-shadow: none !important; border: none !important;"
              formControlName="name"
              readonly
            />
          </span>
        </div>
        <!-- number(s) field dropdown -->
        <div class="d-flex flex-column pt-3">
          <span class="text-muted">Change Caller ID</span>
       
                <div class="select-input w-100">
                  <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
                  <select class="select-custom font-weight-bold form-control bg-light-grey" style="border-radius: 0.25rem !important; border: none !important; box-shadow: none !important;" formControlName="selectedNumber">
                    <option [value]="callerIdNumber" *ngFor="let callerIdNumber of callerIdNumbers"> {{ callerIdNumber | mask:"(000) 000-0000" }}</option>
                  </select>
                </div>
          </div>
      </form>
    </div>
  </div>
  <div class="d-flex w-100 mt-auto">
    <button type="button" class="btn bg-grey text-black rounded w-100" (click)="back()">Back</button>
    <!-- <button type="submit" class="btn bg-primary text-primary rounded ml-4 w-50" (click)="onSubmit()">Confirm</button> -->
  </div>
</div>
