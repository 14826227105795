<form [formGroup]="form">
    <div class="mt-2 position-relative">
        <input type="text" class="form-control " placeholder="NAME" formControlName="name">
        <div *ngIf="((f.name.touched||submitted) && f.name.errors)">
            <span *ngIf="f.name.errors.required">
                <i class="far fa-exclamation-circle is-error" tooltip="Please enter name"></i></span>
            <span *ngIf="f.name.errors.name">
                <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid name"></i></span>
        </div>
    </div>
    <div class="mt-2 position-relative">
        <input type="text" class="form-control" placeholder="PHONE" autocomplete="tel" mask="(000) 000-0000"
            formControlName="phone">
        <div *ngIf="((f.phone.touched||submitted) && f.phone.errors)">
            <span *ngIf="f.phone.errors.required">
                <i class="far fa-exclamation-circle is-error" tooltip="Please enter Phone Number"> </i></span>
            <span *ngIf="f.phone.errors.phoneNumber">
                <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Phone Number"> </i></span>
        </div>
    </div>
    <div class="mt-2 position-relative">
        <input type="text" ngx-google-places-autocomplete class="form-control"
            (onAddressChange)="handleAddressChange($event)" placeholder="ADDRESS" formControlName="address">
        <div *ngIf="((f.address.touched||submitted) && f.address.errors)">
            <span *ngIf="f.address.errors.required">
                <i class="far fa-exclamation-circle is-error" tooltip="Please enter Address"> </i></span>
            <span *ngIf="f.address.errors.address">
                <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Address"></i></span>
        </div>
    </div>
    <div class="mt-2 position-relative">
        <input type="text" class="form-control" placeholder="CITY" formControlName="city">
        <div *ngIf="((f.city.touched||submitted) && f.city.errors)">
            <!-- <i class="far fa-exclamation-circle is-error" tooltip="Please enter City"> </i> -->
            <span *ngIf="f.city.errors.required">
                <i class="far fa-exclamation-circle is-error" tooltip="Please enter City"> </i></span>
            <span *ngIf="f.city.errors.alpha">
                <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid City"> </i></span>
        </div>
    </div>
    <div class="d-flex flex-row mt-2 ">
        <div class="select-input" id="b-state"
            [ngClass]="((f.state.touched||submitted) && f.state.errors)?'is-invalid':''">
            <i class=" fal fa-chevron-down d-flex dropdown-img icon-grey-alt"></i>
            <select class="select-custom bg-grey form-control-solid px-3 w-100 border" style="outline: none;"
                formControlName="state" name="state">
                <option value="" selected>STATE</option>
                <option *ngFor="let state of filteredStates" [value]="state.shortCode" style="color: #212529"
                    [disabled]="state.shortCode == null">{{state.name}}</option>
            </select>
        </div>
        <div class="position-relative ml-2 w-100">
            <input type="text" class="form-control " placeholder="ZIP CODE" formControlName="zip">
            <div *ngIf="((f.zip.touched||submitted) && f.zip.errors)" class="pr-2" style="margin-left: -1px;">
                <span *ngIf="f.zip.errors.required">
                    <i class="far fa-exclamation-circle text-danger" tooltip="Please enter Zip"></i></span>
                <span *ngIf="f.zip.errors.zip">
                    <i class="far fa-exclamation-circle text-danger" tooltip="Please enter valid Zip"></i></span>
            </div>
        </div>
    </div>
    <div class="select-input mt-2 " [ngClass]="((f.country.touched||submitted) && f.country.errors)?'is-invalid':''">
        <i class=" fal fa-chevron-down d-flex dropdown-img icon-grey-alt"></i>
        <select class="select-custom bg-grey form-control-solid px-3 w-100 border" style="outline: none;"
            formControlName="country" name="country" placeholder="COUNTRY">
            <option value="" selected>COUNTRY</option>
            <option *ngFor="let country of countries" [value]="country.countryShortCode">
                {{country.countryName}}
            </option>
        </select>
    </div>
</form>