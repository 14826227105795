import { Directive } from "@angular/core";
import {
  ControlValueAccessorAndValidator,
  phoneNumberValidator,
  COUNTRIES,
  checkZipCodeIsValid
} from '@voiply/shared/common-ui';
import {
  FormGroup,
  FormControl,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormatZipPipe } from 'libs/shared/common-ui/src/lib/pipes';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Directive()
export class AddressBase extends ControlValueAccessorAndValidator
implements OnInit {
  @Input()
  isStripeElement: boolean;
  @Input()
  filteredStates: any = [];
  @Input()
  submitted = false;
  countries = COUNTRIES;
  formatZip = new FormatZipPipe();

  constructor() {
    super()
    this.form = new FormGroup(
      {
        name: new FormControl('', {
          validators: [
            RxwebValidators.required(),
            RxwebValidators.pattern({
              expression: { name: /^[a-zA-Z 0-9._-]*$/ }
            })
          ],
          updateOn: 'blur'
        }),
        phone: new FormControl('', {
          validators: [RxwebValidators.required(), phoneNumberValidator('min')],
          updateOn: 'blur'
        }),
        address: new FormControl('', {
          validators: [
            RxwebValidators.required(),
            RxwebValidators.pattern({
              expression: { address: /^[a-zA-Z 0-9 $&+,:;=?@#|'<>.^*()%!-]*$/ }
            })
          ],
          updateOn: 'blur'
        }),
        address2: new FormControl('', {
          validators: [
            RxwebValidators.pattern({
              expression: { address: /^[a-zA-Z 0-9 $&+,:;=?@#|'<>.^*()%!-]*$/ }
            })
          ],
          updateOn: 'blur'
        }),
        city: new FormControl('', {
          validators: [
            RxwebValidators.required(),
            RxwebValidators.pattern({
              expression: { city: /^[a-zA-Z \-]*$/ }
            })
          ],
          updateOn: 'blur'
        }),
        state: new FormControl('', {
          validators: [RxwebValidators.required()],
          updateOn: 'blur'
        }),
        country: new FormControl('', {
          validators: [RxwebValidators.required()]
        }),
        zip: new FormControl('', {
          validators: [RxwebValidators.required()],
          updateOn: 'blur'
        })
      },
      this.validateZip()
    );
  }

  ngOnInit() {
    this.form.controls['country'].valueChanges.subscribe(changes => {
      this.filteredStates = (
        _.filter(this.countries, { countryShortCode: changes })[0] || {}
      ).regions;
      this.form.controls['state'].patchValue('');
    });

    this.form.controls['zip'].statusChanges.subscribe(status => {});
  }

  validateZip() {
    return (group: FormGroup): { [key: string]: any } => {
      if (!group.controls['zip'].value) {
        return { required: true };
      } else {
        if (
          group.controls['country'].value === 'US' ||
          group.controls['country'].value === 'CA'
        ) {
          //This is done to convert canada zip, example a1a1a1 to A1A 1A1 format.
          const zip = this.formatZip.transform(group.controls['zip'].value);
          if (checkZipCodeIsValid(zip)) {
            return { zip: false };
          } else {
            return { zip: true };
          }
        } else {
          return { zip: false };
        }
      }
    };
  }

  public handleAddressChange(address: Address) {
    if (!address.address_components) return;

    this.form
      .get('country')
      .patchValue(
        (address.address_components.find(c => c.types.includes('country')) || {short_name: ''})
          .short_name
      );

    this.form
      .get('city')
      .patchValue(
        (address.address_components.find(c =>
          c.types.includes('administrative_area_level_2')) || {long_name: ''})
        .long_name || ''
      );

    if (
      ((address.address_components.find(c => c.types.includes('country')) || {short_name: ''})
        .short_name || '') == 'US'
    )
      this.form
        .get('city')
        .patchValue(
          (address.address_components.find(c => c.types.includes('locality')) || {short_name: ''})
            .short_name || ''
        );

    this.form
      .get('state')
      .patchValue(
        (address.address_components.find(c => c.types.includes('administrative_area_level_1')) || {short_name: ''})
          .short_name || ''
      );

    this.form
      .get('zip')
      .patchValue(
        (address.address_components.find(c => c.types.includes('postal_code')) || {short_name: ''})
          .short_name || ''
      );

    let fullAddress: string = `${address.formatted_address}`;
    if (!fullAddress.includes(address.name))
      fullAddress = `${address.name}, ${fullAddress}`;

    // let addressComponent = fullAddress.split(',');
    // let addressLine1 = '';
    // let addressLine2 = '';

    // if(addressComponent[0] && addressComponent[1])
    //   addressLine1 = `${addressComponent[0]}, ${addressComponent[1]}`;
    // else if(addressComponent[0])
    //   addressLine1 = `${addressComponent[0]}`;

    //#region temporary code to fix the address autocompletion
    fullAddress = fullAddress
      .replace(
        (address.address_components.find(c =>
          c.types.includes('administrative_area_level_2')) || {long_name: ''})
        .long_name,
        ''
      )
      .replace(
        (address.address_components.find(c =>
          c.types.includes('administrative_area_level_2')) || {short_name: ''})
        .short_name,
        ''
      )
      .replace(
        (address.address_components.find(c =>
          c.types.includes('administrative_area_level_1')) || {long_name: ''})
        .long_name,
        ''
      )
      .replace(
        (address.address_components.find(c =>
          c.types.includes('administrative_area_level_1')) || {short_name: ''})
        .short_name,
        ''
      )
      .replace(
        (address.address_components.find(c =>
          c.types.includes('country')) || {long_name: ''})
        .long_name,
        ''
      )
      .replace(
        (address.address_components.find(c =>
          c.types.includes('country')) || {short_name: ''})
        .short_name,
        ''
      )
      .replace(
        (address.address_components.find(c =>
          c.types.includes('postal_code')) || {long_name: ''})
        .long_name,
        ''
      );

    if (
      (address.address_components.find(c => c.types.includes('country')) || {short_name: ''})
        .short_name == 'US'
    )
      fullAddress = fullAddress.replace(
        (address.address_components.find(c => c.types.includes('locality')) || {long_name: ''})
          .long_name,
        ''
      );

    if(fullAddress[fullAddress.length - 1] >= 'A' && fullAddress[fullAddress.length - 1] <= 'Z')
      fullAddress = fullAddress.substr(0, fullAddress.length - 1);
    while (fullAddress[fullAddress.length - 1] == ' ' || fullAddress[fullAddress.length - 1] == ',') {
      fullAddress = fullAddress.substr(0, fullAddress.length - 1);
      console.log('called')
    }
    //#endregion

    this.form.get('address').patchValue(fullAddress);
  }
}
