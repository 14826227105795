<!--containing in - Lib: product-configuration -->
<!--used by - Component: business-configuration/business-configuration-modal, business-configuration/user-settings-business, -->
<!--Added for - App: Business, User-settings-->
<div [formGroup]="form" class="cart-item-background-color">
  <mat-slide-toggle color="primary" formControlName="enableEmergencyAddress">
    Enable Emergency Address
  </mat-slide-toggle>
  <div class="mt-3" style="position: relative;">
    <input type="text" class="form-control" placeholder="Name" formControlName="name"
      [ngClass]="{ 'is-invalid': ((f.name.touched || submitted) && f.name.errors) }"
      [readonly]="!this.form.controls['enableEmergencyAddress'].value">
    <div *ngIf="((f.name.touched||submitted) && f.name.errors)">
      <span *ngIf="f.name.errors.required">
        <i class="far fa-exclamation-circle is-error" tooltip="Please enter name"></i></span>
    </div>
  </div>
  <div class="mt-3" style="position: relative;">
    <input type="text" class="form-control" placeholder="Address Line 1" formControlName="addressLine1"
      [ngClass]="{ 'is-invalid': ((f.addressLine1.touched || submitted) && f.addressLine1.errors) }"
      [readonly]="!this.form.controls['enableEmergencyAddress'].value">
    <div *ngIf="((f.addressLine1.touched||submitted) && f.addressLine1.errors)">
      <i class="far fa-exclamation-circle is-error" tooltip="Please enter Address"> </i>
    </div>
  </div>
  <div class="mt-3">
    <input type="text" class="form-control" placeholder="Address Line 2" formControlName="addressLine2"
      [readonly]="!this.form.controls['enableEmergencyAddress'].value">
  </div>
  <div class="mt-3" style="position: relative;">
    <input type="text" class="form-control" placeholder="City" formControlName="city"
      [ngClass]="{ 'is-invalid': ((f.city.touched || submitted) && f.city.errors) }"
      [readonly]="!this.form.controls['enableEmergencyAddress'].value">
    <div *ngIf="((f.city.touched||submitted) && f.city.errors)">
      <span *ngIf="f.city.errors.required">
        <i class="far fa-exclamation-circle is-error" tooltip="Please enter City"> </i></span>
      <span *ngIf="f.city.errors.alpha">
        <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid City"> </i></span>
    </div>
  </div>
  <div class="mt-3 position-relative">
    <div *ngIf="!this.form.controls['enableEmergencyAddress'].value" class="position-absolute h-100 w-100 bg-black"
      style="opacity: 0.1; z-index: 2;"></div>
    <div class="select-input">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom form-control" formControlName="state">
        <option value="" selected>Select State</option>
        <option *ngFor="let state of filteredStates" [value]="state.shortCode" style="color: #212529"
          [disabled]="state.shortCode == null">
          {{state.name}}</option>
      </select>
    </div>
  </div>
  <div class="mt-3 position-relative">
    <div *ngIf="!this.form.controls['enableEmergencyAddress'].value" class="position-absolute h-100 w-100 bg-black"
      style="opacity: 0.1; z-index: 2;"></div>
    <div class="select-input">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom form-control" formControlName="country">
        <option selected value="">Select Country
        </option>
        <option *ngFor="let country of countries" [value]="country.countryShortCode">{{country.countryName}}
          <!-- <option value="US">U.S.A.</option>
        <option value="CA">Canada</option> -->
      </select>
    </div>
  </div>
  <div class="mt-3" style="position: relative;">
    <input type="text" class="form-control" placeholder="ZIP" formControlName="zip"
      [ngClass]="{ 'is-invalid': ((f.zip.touched||submitted) && f.zip.errors) }"
      [readonly]="!this.form.controls['enableEmergencyAddress'].value">
    <div *ngIf="((f.zip.touched||submitted) && f.zip.errors)">
      <span *ngIf="f.zip.errors.required">
        <i class="far fa-exclamation-circle is-error" tooltip="Please enter Zip"> </i></span>
      <span *ngIf="f.zip.errors.zip">
        <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Zip Code"> </i></span>
    </div>
  </div>
</div>
