import { ChangeDetectorRef, Component } from "@angular/core";
import { StripeService } from "../../../services";
import { ToastrService } from "ngx-toastr";
import { ChangeStripeCardBaseComponent } from "../change-stripe-card.base";

@Component({
  selector: 'common-ui-change-stripe-card',
  templateUrl: './change-stripe-card.component.html',
  styleUrls: ['./change-stripe-card.component.scss']
})
export class ChangeStripeCardComponent extends ChangeStripeCardBaseComponent {

  constructor(cd: ChangeDetectorRef, stripeService: StripeService, tostr: ToastrService) {
    super(cd, stripeService, tostr);
  }

}
