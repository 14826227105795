import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, FormGroup } from '@angular/forms';
import { ControlValueAccessorAndValidator, STATES, COUNTRIES } from '@voiply/shared/common-ui';
import * as _ from 'lodash';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'voiply-emergency-address-form-control',
  templateUrl: './emergency-address-form-control.component.html',
  styleUrls: ['./emergency-address-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmergencyAddressFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmergencyAddressFormControlComponent),
      multi: true
    }
  ]
})
export class EmergencyAddressFormControlComponent extends ControlValueAccessorAndValidator implements OnInit {
  @Input()
  filteredStates: any;
  @Input()
  submitted = false;
  @Input()
  disableInput = false;
  countries = COUNTRIES;
  constructor() {
    super();
    this.form = new FormGroup({
      enableEmergencyAddress: new FormControl(''),
      name: new FormControl('', { validators: [RxwebValidators.required()], updateOn: 'blur' }),
      addressLine1: new FormControl('', { validators: [RxwebValidators.required()], updateOn: 'blur' }),
      addressLine2: new FormControl(''),
      city: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.alpha({ allowWhiteSpace: true })], updateOn: 'blur' }),
      state: new FormControl('', { validators: [RxwebValidators.required()] }),
      country: new FormControl('', { validators: [RxwebValidators.required()] }),
      zip: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.pattern({ expression: { 'zip': /^(\d{5})$|^([A-CEGHJ-NPRSTVXYa-ceghj-nprstvxy]\d[A-CEGHJ-NPRSTV-Za-ceghj-nprstv-z]( )?\d[A-CEGHJ-NPRSTV-Za-ceghj-nprstv-z]\d)$/ } })], updateOn: 'blur' })
    });


    this.form.controls['country'].valueChanges.subscribe((changes) => {
      this.filteredStates = (_.filter(this.countries, { 'countryShortCode': changes })[0] || {}).regions;
      if (!_.find(this.filteredStates, { shortCode: this.form.controls['state'].value })) {
        this.form.controls['state'].patchValue('');
      }
    });


  }

  ngOnInit() {
  }

}
