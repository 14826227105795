import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ThemePalette } from '@angular/material/core';
import { ControlValueAccessorAndValidator } from '../../generic-utils';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'voiply-voicemail-attachment-form-control',
  templateUrl: './voicemail-attachment-form-control.component.html',
  styleUrls: ['./voicemail-attachment-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VoicemailAttachmentFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => VoicemailAttachmentFormControlComponent),
      multi: true
    }
  ]
})
export class VoicemailAttachmentFormControlComponent extends ControlValueAccessorAndValidator implements OnInit {
  @Input() isDisable: boolean;
  constructor(private toastr: ToastrService) {
    super()
  }
  color: ThemePalette = 'primary';
  selectable = true;
  removable = true;
  addOnBlur = true;
  visible = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  ngOnInit() {
    this.form = new FormGroup({
      attachVoicemail: new FormControl(''),
      ccEmail: new FormArray([])
    });
  }
  get ccEmail() {
    return this.form.get('ccEmail') as FormArray;
  }


  writeValue(v: any) {
    for (let value of v.ccEmail)
      this.ccEmail.push(new FormControl(value))
    v && this.form.setValue(v, { emitEvent: false });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (this.validateEmail(value)) {
        this.ccEmail.push(new FormControl(value.trim()));
      } else {
        this.toastr.error('Please enter valid Email')
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  remove(index): void {
    if (index >= 0) {
      this.ccEmail.removeAt(index);
    }
  }
}
