import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Select } from "@ngxs/store";
import { AppStateSelectors, OnDestroyCleanup, EmergencyAddressChangeAction } from "@voiply/shared/common-ui";
import { EmergencyAddress } from "@voiply/shared/model";
import { Observable } from "rxjs";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: 'voiply-e911-extraline-settings',
  templateUrl: './e911-extraline-settings.component.html',
  styleUrls: ['./e911-extraline-settings.component.scss']
})
export class E911ExtralineSettingsComponent extends OnDestroyCleanup{
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<any>;
  @Select(AppStateSelectors.shippingAddress) shippingAddress$: Observable<any>;
  currentEmergencyAddress = new EmergencyAddress();

  @Dispatch() emergencyAddressChange = (emergencyAddress) => new EmergencyAddressChangeAction(emergencyAddress);
  @Input() backButtonUrl;

  constructor(private router:Router) {
    super();

    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      if (checkoutDetail.shippingAddress.country !== 'US' && checkoutDetail.shippingAddress.country !== 'CA') return;
      else if (checkoutDetail.emergencyAddress.name) {
          this.currentEmergencyAddress = checkoutDetail.emergencyAddress;
      }
      else {
          //subscribe to Shipping Address only when emergency Address is not available.
          this.subscriptions$.add(this.shippingAddress$.pipe(takeWhile(data => !checkoutDetail.emergencyAddress.name)).subscribe(async shippingAddress => {
              if (shippingAddress) {
                  this.currentEmergencyAddress.name = shippingAddress.name;
                  this.currentEmergencyAddress.addressLine1 = shippingAddress.address;
                  this.currentEmergencyAddress.addressLine2 = "";
                  this.currentEmergencyAddress.state = shippingAddress.state;
                  this.currentEmergencyAddress.country = shippingAddress.country;
                  this.currentEmergencyAddress.city = shippingAddress.city;
                  this.currentEmergencyAddress.zip = shippingAddress.zip
                  this.emergencyAddressChange(this.currentEmergencyAddress);
              }
          }))
      }
    }))
  }

  onEmergencyAddressChange(address){
    this.emergencyAddressChange(address);
    this.router.navigateByUrl(this.backButtonUrl);
  }
}
