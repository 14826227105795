import { Component } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { FormatPhoneNumberPipe } from 'libs/shared/common-ui/src/lib/pipes/format-phone-number.pipe';
import { MaskApplierService } from 'ngx-mask';
import { SpamCallAllowedNumbersBase } from '../spam-call-allowed-numbers.base';


@Component({
  selector: 'voiply-spam-call-allowed-numbers-alt',
  templateUrl: './spam-call-allowed-numbers-alt.component.html',
  styleUrls: ['./spam-call-allowed-numbers-alt.component.scss'],
  providers: [FormatPhoneNumberPipe]
})
export class SpamCallAllowedNumbersAltComponent extends SpamCallAllowedNumbersBase {
   constructor(protected toastr: ToastrService, protected maskService: MaskApplierService) {
    super(toastr, maskService);
  }
}
