import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'voiply-home-phone-form-control',
  templateUrl: './home-phone-form-control.component.html',
  styleUrls: ['./home-phone-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HomePhoneFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HomePhoneFormControlComponent),
      multi: true
    }
  ]
})
export class HomePhoneFormControlComponent extends ControlValueAccessorAndValidator implements OnInit {

  @Input() submitted = false;
  @Output() isHideConfig: EventEmitter<any> = new EventEmitter();
  isHideConfiguration = false;

  constructor() {
    super();

    this.form = new FormGroup({
      firstName: new FormControl('', { updateOn: 'blur' }),
      lastName: new FormControl('', { updateOn: 'blur' }),
      email: new FormControl('', { updateOn: 'blur' }),
      userId: new FormControl(''),
      extension: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.pattern({ expression: { 'extension': /^[0-9]{2,5}$/ } })], updateOn: 'blur' }),
      phoneNumber: new FormControl('', { updateOn: 'blur' }),
      isNewNumber: new FormControl(''),
      timezone: new FormControl('')
    });

  }
  ngOnInit() {
    this.isHideConfig.emit(this.isHideConfiguration);
  }
  toggleConfig() {
    this.isHideConfiguration = !this.isHideConfiguration;
    this.isHideConfig.emit(this.isHideConfiguration);
  }

}
