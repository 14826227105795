import { Component, OnInit } from '@angular/core';
import { CartTotalBase } from '../cart-total-base';

@Component({
  selector: 'checkout-cart-total-after-checkout-alt',
  templateUrl: './cart-total-after-checkout-alt.component.html',
  styleUrls: ['./cart-total-after-checkout-alt.component.scss']
})
export class CartTotalAfterCheckoutAltComponent extends CartTotalBase {



}
