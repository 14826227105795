import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { AppStateSelectors, OnDestroyCleanup } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import { CommonService } from '../../services/common.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'voiply-data-plans-container',
  templateUrl: './data-plans-container.component.html',
  styleUrls: ['./data-plans-container.component.scss']
})
export class DataPlansContainerComponent extends OnDestroyCleanup implements OnInit {

  queryParams;
  country: any;
  data: any;
  public device: string;
  dataPlanInfo:any;

  @Select(AppStateSelectors.billingAddress) billingAddress$: Observable<any>;

  constructor(private route: ActivatedRoute,
    public commonService: CommonService,
    private router: Router,
    private toastr: ToastrService) {
    super();
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    });
    this.subscriptions$.add(this.billingAddress$.subscribe((billingData) => {
      this.country = billingData.country;
    }))

  }

  ngOnInit(): void {
    this.device = localStorage.getItem("extralineDevice");
    this.commonService.getdata().subscribe((data) => {
      this.dataPlanInfo=data;
      if (this.country == "CA") {
        _.each(data, (val, key) => {
          if (val.country == "CA") {
            this.data = val;
            return false;
          }

        });
      }
      else  {
        _.each(data, (val, key) => {
          if (val.country == "US") {
            this.data = val;

            return false;
          }

        });
      }

    })
  }
  back() {
    this.router.navigateByUrl("/?o=" + this.queryParams.o + "&u=" + this.queryParams.u);
  }
  async payDataPlanClicked(event) {
    const payment = await this.commonService.paymentForDataPlans(this.queryParams.o, event.planID);
    if (payment != null) {
      this.toastr.success("Payment Successful");
      this.back();
    }
  }

  countryChanged(value){
    value=='US' ? this.data=this.dataPlanInfo[0] : this.data=this.dataPlanInfo[1];
  }
}
