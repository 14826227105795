<div class="bg-primary">
  <div class="text-primary font-weight-bold inner-header ml-4">Billing Details</div>
</div>
<div class="shadow">
  <div class="card px-4 py-3" *ngIf="checkoutDetail.billingAddress; let billingAddress">
    <h6 class="m-0">
      <span *ngIf="!checkoutDetail.companyName">{{checkoutDetail.companyName}}</span>
      <span *ngIf="checkoutDetail.companyName">{{billingAddress.name}}</span>
    </h6>
    <div *ngIf="!checkoutDetail.companyName">{{billingAddress.name}}</div>
    <div>{{billingAddress.address}}</div>
    <div>{{billingAddress.city}} {{billingAddress.state}} {{billingAddress.zip}}</div>
    <div>{{billingAddress.phone}}</div>
    <div>{{billingAddress.email}}</div>
    <div *ngIf="billingAddress.country==='CA'">Canada</div>
    <div *ngIf="billingAddress.country==='US'">USA</div>
  </div>
  <div class="card px-4 py-3 border-top-0" id="updateCard">
    <div *ngIf="!showCreditCard">
      <span>Payment method: <span class="font-weight-bold">CREDIT CARD</span></span>
    </div>
    <div *ngIf="cardDetail && !showCreditCard">
      <ng-container [ngSwitch]="cardDetail.brand">
        <i *ngSwitchCase="'Visa'" class="fab fa-cc-visa"></i>
        <i *ngSwitchCase="'MasterCard'" class="fab fa-cc-mastercard"></i>
        <i *ngSwitchCase="'JCB'" class="fab fa-cc-jcb"></i>
        <i *ngSwitchCase="'Discover'" class="fab fa-cc-discover"></i>
        <i *ngSwitchCase="'Diners Club'" class="fab fa-cc-diners-club"></i>
        <i *ngSwitchCase="'American Express'" class="fab fa-cc-amex"></i>
        <i *ngSwitchDefault class="fab fa-cc-stripe"></i>
      </ng-container>
      Card ending with <span class="font-weight-bold">{{cardDetail.last4}}</span> expires on <span
        class="font-weight-bold">{{cardDetail.exp_month}}/{{cardDetail.exp_year}}</span>
    </div>
    <form (ngSubmit)="onSubmit()" class="py-2 d-flex">
      <div #cardElement class="input flex-grow-1"></div>

      <button class="btn bg-primary text-primary px-2 ml-2" *ngIf="showCreditCard" type="submit">
        <span *ngIf="!cardChangeRequesting">Submit</span>
        <mat-spinner *ngIf="cardChangeRequesting" diameter="26" value="accent" class="white-spinner"></mat-spinner>
      </button>
      <button class="btn bg-primary text-primary px-2 ml-2" *ngIf="showCreditCard" (click)="onCardChangeCancel()">
        <span>Cancel</span>
      </button>
    </form>

    <button class="btn bg-secondary-gradient text-primary w-100 font-weight-bold border-0 py-2" type="button"
      (click)="this.stripeService.mountNewCard(false)">
      CHANGE CARD</button>
  </div>
</div>