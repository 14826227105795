import { Component } from '@angular/core';
import { ShippingAddressBase } from '../shipping-address.base';

@Component({
  selector: 'checkout-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.css']
})
export class ShippingAddressComponent extends ShippingAddressBase {
  constructor() {
    super();
  }
}
