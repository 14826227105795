import { Routes } from "@angular/router";
import { RootPageComponent } from './root-page/root-page.component';
import { ExtralineSettingsResolver } from '@voiply/shared/common-ui';
import { NumberPortingComponent } from "./number-porting/number-porting.component";
import { UpdateCreditCardComponent } from "./update-credit-card/update-credit-card.component";
import { ViewInvoices } from "./view-invoices/view-invoices.component";
import { E911Component } from "./e911/e911.component";
import { ProductConfiguration } from "./product-configuration/product-configuration.component";
import { UpgradeToAnnualComponent } from "./upgrade-to-annual/upgrade-to-annual.component";
import { UpgradeToHome } from "./upgrade-to-home/upgrade-to-home.component";
import { UpgradeToBusiness } from "./upgrade-to-business/upgrade-to-business.component";
import { CallerIdComponent } from "./caller-id/caller-id.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { OrderStatusComponent } from "./order-status/order-status.component";
import { DataPlansContainerComponent } from "./data-plans-container/data-plans-container.component";

const routes: Routes = [
  { path: 'upgrade-to-annual', component: UpgradeToAnnualComponent, resolve: { key: ExtralineSettingsResolver } },
  { path: 'upgrade-to-home', component: UpgradeToHome, resolve: { key: ExtralineSettingsResolver } },
  { path: 'upgrade-to-business', component: UpgradeToBusiness, resolve: { key: ExtralineSettingsResolver } },
  { path: 'caller-id', component: CallerIdComponent, resolve: { key: ExtralineSettingsResolver } },
  { path: 'number-porting', component: NumberPortingComponent, resolve: { key: ExtralineSettingsResolver } },
  { path: 'update-creditcard', component: UpdateCreditCardComponent, resolve: { key: ExtralineSettingsResolver } },
  { path: 'e911', component: E911Component, resolve: { key: ExtralineSettingsResolver } },
  { path: 'view-invoices', component: ViewInvoices, resolve: { key: ExtralineSettingsResolver } },
  { path: 'voicemail', component: ProductConfiguration, resolve: { key: ExtralineSettingsResolver } },
  { path: 'call-forwarding', component: ProductConfiguration, resolve: { key: ExtralineSettingsResolver } },
  { path: 'spam-call-bot', component: ProductConfiguration, resolve: { key: ExtralineSettingsResolver } },
  { path: 'online-fax', component: ProductConfiguration, resolve: { key: ExtralineSettingsResolver } },
  { path: 'call-recording', component: ProductConfiguration, resolve: { key: ExtralineSettingsResolver } },
  { path: 'e999', component: ProductConfiguration, resolve: { key: ExtralineSettingsResolver } },
  { path: 'order-status', component: OrderStatusComponent, resolve: { key: ExtralineSettingsResolver } },
  { path: 'data-plans', component: DataPlansContainerComponent, resolve: { key: ExtralineSettingsResolver } },
  { path: 'error', component: ErrorPageComponent },
  { path: '', component: RootPageComponent, resolve: { key: ExtralineSettingsResolver } },
  { path: '**', redirectTo: "error", pathMatch: "full" },

];
export const AppRoutes = routes;
