
<form [formGroup]="form" class="mt-3">
                <div class=" d-flex flex-column">
                    <div class="flex-fill">
                        <input id="email" placeholder="Email" autocomplete="email" class="form-control w-100 border"
                            formControlName="email" [ngClass]="{ 'is-invalid': ((f.email.touched) && f.email.errors) }">
                    </div>
                    <div *ngIf="((f.email.touched) && f.email.errors)" class="pr-2" style="margin-left: -1px;">
                        <span *ngIf="f.email.errors.required">
                            <i class="far fa-exclamation-circle text-danger" tooltip="Please enter Email"></i></span>
                        <span *ngIf="f.email.errors.email">
                            <i class="far fa-exclamation-circle text-danger"
                                tooltip="Please enter valid Email"></i></span>
                    </div>
                </div>
                <checkout-address-extraline formControlName="address" [submitted]="formSubmitted"
                    [filteredStates]="filteredStates">
                </checkout-address-extraline>
</form>

     