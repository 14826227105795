<div class="d-flex flex-row justify-content-between align-items-center">

  <div class="btn-group w-100" dropdown style="max-width: 250px;">
    <button
      class="btn w-100 py-2 btn-outline-dark dropdown-toggle bg-white d-flex justify-content-between align-items-center rounded-0"
      style="border:1px solid #dee2e6 !important;" type="button" dropdownToggle type="button"
      aria-controls="dropdown-basic" id="dropdown-context-lable">
      <div class="d-flex align-items-center flex-wrap">
        <span class="font-weight-bold text-secondary mr-1" style="font-size:12px;">
          ({{selectedShippingOption.displayTitle}})</span>
        <span class="text-color" style="font-size: 12px;"> {{selectedShippingOption.displayDate}} </span>

      </div>
    </button>

    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="button-basic">
      <ng-container *ngFor="let shippingOption of shippingOptions; let i of index">
        <li role="menuitem"><button class="dropdown-item w-100" (click)="onShippingOptionChange(shippingOption)">
            <div class="d-flex align-items-center flex-wrap" style="font-size: 12px;">

              <span class="font-weight-bold mr-1">
                ({{shippingOption.displayTitle}})</span>
              <span>{{shippingOption.displayDate}}</span>
            </div>
          </button>
        </li>
      </ng-container>
    </ul>
  </div>


  <div>
    <ng-container *ngIf="calculatingTotal">
      <mat-spinner class="float-right" diameter="18" value="accent"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!calculatingTotal">
      <span class="text-secondary">
        <h4 class="font-weight-bold m-0" style="font-size: 15px;">
          {{checkoutDetail.discountedShippingCharges | formatMoneyValue}}
        </h4>
      </span>
    </ng-container>
  </div>
</div>