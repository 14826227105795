import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { FormGroup } from '@angular/forms';
import { CartItem, CartItemType, PhoneConfiguration, OnlineFax, Conferencing, SpamCallBot, CallRecording } from '@voiply/shared/model';
import { APPSCONST, checkProductConfigurationPhoneIsValid } from '../constants';
import { Emergency999 } from 'libs/shared/model/src/lib/emergency999.model';

export function Validate<T>(type: { new(): T; }, obj: T): boolean {
  const rr: T = new type();
  Object.assign(rr, obj);
  const formBuilder: RxFormBuilder = new RxFormBuilder();
  const form: FormGroup = formBuilder.formGroup(rr);
  return form.valid;
}

export function ValidateCartItem(item: CartItem) {
  let isValidCartItem;
  let isValidPhoneNumber = true;
  const config: any = item.configuration;
  if (item.type === CartItemType.Phone) {
    isValidCartItem = Validate(PhoneConfiguration, config);
    isValidPhoneNumber = checkProductConfigurationPhoneIsValid(config.phoneNumber);
  }
  else if (item.heading === APPSCONST.ONLINEFAX) {
    isValidCartItem = Validate(OnlineFax, config);
    if (config.mobile)
      isValidPhoneNumber = checkProductConfigurationPhoneIsValid(config.mobile);
  }
  else if (item.heading === APPSCONST.CONFERENCING) {
    isValidCartItem = Validate(Conferencing, item.configuration);
  }
  else if (item.heading === APPSCONST.SPAMCALLBOT) {
    isValidCartItem = Validate(SpamCallBot, config);
  }
  else if (item.heading === APPSCONST.CALLRECORDING) {
    isValidCartItem = Validate(CallRecording, config);
  }
  else if (item.heading === APPSCONST.EMERGENCY) {
    isValidCartItem = true
  }
  if (!isValidCartItem || !isValidPhoneNumber)
    return false;

  return true;
}