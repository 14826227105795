<!--Lib: product-configuration -->
<!--Component: home-configuration/extraline-setting-home, business-configuration/extraline-setting-business components-->
<!--Added for Extraline Settings-->

<div [formGroup]="form" class="d-flex flex-column">
    <div class="d-flex justify-content-start bg-dark text-white rounded p-2">
        <!-- heading -->
        <span class="pr-2 d-flex align-items-center">
            <img src="assets/images/extraline/icons/e999.svg" class="icon-white">
        </span>
        <span class="text-uppercase font-weight-bold">Emergency 999</span>
    </div>

    <div class="mt-3 mb-1" style="font-size: small; color: #B5B5B5">
        <span> <span class="font-weight-bold"> Voiply Emergency 999 </span> is an alert service that puts you in touch
            with a dispatcher who
            can summon emergency help and contact a friend or family member while offering immediate support.
        </span>
    </div>

    <div class="my-3 border-top"></div>
    <div class="d-flex flex-row justify-content-between align-items-center ">
        <div> Add Emergency Contacts</div>
        <button class="btn p-0 d-flex" *ngIf="!showAddContactForm" (click)="showAddContactForm=!showAddContactForm">
            <i class="fas fa-plus"></i>
        </button>
        <button class="btn p-0 d-flex" *ngIf="showAddContactForm" (click)="showAddContactForm=!showAddContactForm">
            <i class="fas fa-minus"></i>
        </button>
    </div>
    <form #customForm="ngForm" class="mt-3" *ngIf="showAddContactForm">
        <div class="d-flex flex-row flex-wrap flex-md-nowrap">
            <div style="min-width: 50%; " class="position-relative flex-grow-1 flex-sm-grow-0">
                <input type="text" class="form-control bg-light-grey border-0" [(ngModel)]="contactName"
                    [ngModelOptions]="{standalone: true}" #name="ngModel" placeholder="Type Name Here" required>
                <div *ngIf="((name.touched) && name.errors)">
                    <span *ngIf="name.errors.required">
                        <i class="far fa-exclamation-circle is-error" tooltip="Please enter Name"></i></span>
                </div>
            </div>
            <div class="d-flex flex-grow-1 flex-sm-grow-0 flex-wrap flex-sm-nowrap mt-3 mt-sm-0"
                style="min-width: 50%;">
                <div class="position-relative w-100">
                    <input type="text" class="form-control bg-light-grey  border-0" [(ngModel)]="contactNumber"
                        [ngModelOptions]="{standalone: true}" #number="ngModel" placeholder="Type Phone Here"
                        mask="(000)000-0000" required>
                    <div *ngIf="((number.touched) && number.errors)">
                        <span *ngIf="number.errors.required">
                            <i class="far fa-exclamation-circle is-error" tooltip="Please enter Number"></i></span>
                        <span *ngIf="!number.errors.required">
                            <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Number">
                            </i></span>
                    </div>
                </div>
                <div class="flex-grow-1 flex-sm-grow-0 mt-3 mt-sm-0">
                    <button class="btn bg-black text-primary px-3 w-100" style="height: 46px;" type="button"
                        (click)="addNewContact(name,number,customForm)"
                        *ngIf="this.form.controls['emergencyContacts'].value.length<10">
                        <i class="fas fa-plus d-none d-sm-block"></i><span class="d-sm-none">ADD CONTACT</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div class="mt-2">
        <div *ngFor="let emergencycontact of this.form.controls['emergencyContacts'].value; let i=index"
            class=" d-flex align-items-center">
            <div class="bg-light-grey py-2 px-3 d-flex flex-row justify-content-between w-100 mt-2">
                <div>{{emergencycontact.name}}</div>
                <div class="d-flex flex-row align-items-center">
                    <div class="mr-3">{{emergencycontact.phone|mask:'0(000)000-0000'}}</div>
                    <button class="btn p-0 d-flex" (click)="removeContact(i)">
                        <i class="fal fa-times text-secondary"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>