import { Component, OnInit } from '@angular/core';
import { ShippingOptionsBase } from '../shipping-options-base';

@Component({
  selector: 'checkout-shipping-options-after-checkout',
  templateUrl: './shipping-options-after-checkout.component.html',
  styleUrls: ['./shipping-options-after-checkout.component.scss']
})
export class ShippingOptionsAfterCheckoutComponent extends ShippingOptionsBase {



}
