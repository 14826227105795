import { Injectable } from '@angular/core';
// import { Store  } from '@ngxs/store';
// import { Navigate } from '@ngxs/router-plugin';
import { Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  constructor(private router: Router) { }

  navigate(path: string, queryparams: Params = {}) {
    this.router.navigate([path], { queryParams: queryparams });
  }
}
