import { Component, OnInit } from '@angular/core';
import { ShippingOptionsBase } from '../shipping-options-base';

@Component({
  selector: 'checkout-shipping-options-after-checkout-alt',
  templateUrl: './shipping-options-after-checkout-alt.component.html',
  styleUrls: ['./shipping-options-after-checkout-alt.component.scss']
})
export class ShippingOptionsAfterCheckoutAltComponent extends ShippingOptionsBase {



}
