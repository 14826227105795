<!-- <div [formGroup]="form" class="cart-item-background-color p-4">
    <div class="row">
        <div class="col-12 col-lg-6" style="padding-right: 12px;">
            <div style="position: relative;">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-primary text-primary spam-bot-prepend">
              BOT <br> EXT
            </span>
                    </div>
                    <input type="text" class="form-control text-secondary font-weight-bold spam-bot-input" placeholder="Direct Dial" formControlName="extension" disabled [ngClass]="{ 'is-invalid': ((f.extension.touched || submitted) && f.extension.errors) }">
                </div>
                <div *ngIf="((f.extension.touched || submitted) && f.extension.errors)">
                    <span *ngIf="f.extension.errors.required">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter extension"></i></span>
                    <span *ngIf="f.extension.errors.extension">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter a 2 to 5 digit extension number.">
            </i></span>
                </div>
            </div>
            <div class="text-color pt-3">
                Works with Nomorobo to block unsolicited robocalls or telemarketers and automatically transfers them our bot Harold. Talk with Harold by calling (646) 779-4777.
            </div>
            <div class="d-flex justify-content-between pt-2">
                <span class="font-weight-bold text-secondary">Screen All Telemarketers</span>
                <div>
                    <label class="radio-container mr-3">
            <input type="radio" value="No" formControlName="autoscreening">
            <span class="checkmark"></span>
            <span>No</span>
          </label>
                    <label class="radio-container">
            <input type="radio" value="Yes" formControlName="autoscreening">
            <span class="checkmark"></span>
            <span>Yes</span>
          </label>
                </div>
            </div>

            <hr class="my-3">

            <div class="text-color">
                When SMS Screening is enabled, spam texts will be filtered out so that you can stay focused.
            </div>
            <div class="d-flex justify-content-between pt-2">
                <span class="font-weight-bold text-secondary">Enable SMS Screening</span>
                <div>
                    <label class="radio-container  mr-3">
            <input type="radio" value="No" formControlName="smsscreening">
            <span class="checkmark"></span>
            <span>No</span>
          </label>
                    <label class="radio-container">
            <input type="radio" value="Yes" formControlName="smsscreening">
            <span class="checkmark"></span>
            <span>Yes</span>
          </label>
                </div>
            </div>

            <hr class="my-3">

            <div class="text-color">
                Anonymous Call Rejection automatically rejects calls from numbers with Caller ID blocked.
            </div>
            <div class="d-flex justify-content-between pt-2">
                <span class="font-weight-bold text-secondary">Anonymous Call Rejection</span>
                <div>
                    <label class="radio-container  mr-3">
            <input type="radio" value="No" formControlName="callrejection">
            <span class="checkmark"></span>
            <span>No</span>
          </label>
                    <label class="radio-container">
            <input type="radio" value="Yes" formControlName="callrejection">
            <span class="checkmark"></span>
            <span>Yes</span>
          </label>
                </div>
            </div>

            <hr class="my-3">

            <div class="d-flex justify-content-between pt-2">
                <span class="font-weight-bold text-secondary">Allow List Only</span>
                <div>
                    <label class="radio-container mr-3">
                        <input type="radio" value="No" formControlName="isallowList">
                        <span class="checkmark"></span>
                        <span>No</span>
                    </label>
                    <label class="radio-container">
                        <input type="radio" value="Yes" formControlName="isallowList">
                        <span class="checkmark"></span>
                        <span>Yes</span>
                    </label>
                </div>
            </div>

            <hr class="my-3">

            <div *ngIf="isNumberRepeating" class="text-danger">
                <i class="far fa-exclamation-circle"></i> Blocked Numbers will be taken priority for the Numbers that are added in both Blocked Numbers and Allowed Numbers
            </div>
        </div>
        <div class="col-12 col-lg-6 pt-3 pt-lg-0" style="padding-left: 12px;">
            <voiply-spam-call-blocked-numbers [blockedNumberList]="this.form.controls['blockedList'].value" (addBlockedNumber)="onAddBlockedNumber($event)" (removeBlockedNumber)="onRemoveBlockedNumber($event)">
            </voiply-spam-call-blocked-numbers>

            <voiply-spam-call-allowed-numbers [allowededNumberList]="this.form.controls['allowedList'].value" (addAllowedNumber)="onAddAllowedNumber($event)" (removeAllowedNumber)="onRemoveAllowedNumber($event)">
            </voiply-spam-call-allowed-numbers>
        </div>
    </div>

</div> -->

<!-- New UI -->
<div [formGroup]="form" class="cart-item-background-color p-4">
    <div class="d-flex  flex-column flex-lg-row">
        <div class="d-flex flex-column" style="flex: 1;">
            <div class="" style="border-radius:8px;">
                <voiply-spam-call-blocked-numbers [blockedNumberList]="this.form.controls['blockedList'].value" (addBlockedNumber)="onAddBlockedNumber($event)" (removeBlockedNumber)="onRemoveBlockedNumber($event)">
                </voiply-spam-call-blocked-numbers>
                <voiply-spam-call-allowed-numbers [allowededNumberList]="this.form.controls['allowedList'].value" (addAllowedNumber)="onAddAllowedNumber($event)" (removeAllowedNumber)="onRemoveAllowedNumber($event)">
                </voiply-spam-call-allowed-numbers>
            </div>
        </div>
        <div class="d-flex flex-column pl-2 mt-2 mt-lg-0" style="flex: 1;">
            <div class="p-2 mb-2 flex-fill" style="background-color: rgba(249, 249, 249,1) !important;border-radius: 8px;min-height:124px;">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row justify-content-between">
                        <h5 class="mb-0 align-self-center">Screen All Telemarketers</h5>

                        <div class="btn btn-group btn-sm d-flex flex-row m-0 p-1" role="group" style="min-height: 50px;"
                            aria-label="Basic example" id='autoscreening'>
                            <button id="1" type="button" 
                                class="btn border d-flex flex-row justify-content-between align-items-center py-0 px-3"
                                [ngClass]="this.form.get(Setting_Type.autoScreening).value=='Yes'? 'open_color' :'nopen_color'" (click)="setValue(Setting_Type.autoScreening,'on')">
                                
                                <div class="flex-grow-1">ON</div>
                            </button>
                            <button id="2" type="button"
                                class="btn border d-flex flex-row justify-content-between align-items-center py-0 px-3"
                                [ngClass]="!(this.form.get(Setting_Type.autoScreening).value==('No'))? 'nopen_color' :'open_color'" (click)="setValue(Setting_Type.autoScreening,'off')">
                                <div class="flex-grow-1 p-0">OFF</div>
                            </button>
                        </div>
                    </div>

                    <div>
                        <span>Works with Nomorobo to block unsolicited robocalls or telemarketers and automatically transfers them our bot Harold. Talk with Harold by calling (646) 779-4777.</span>
                    </div>
                </div>
            </div>

            <div class="p-2 mb-2 flex-fill" style="background-color: rgba(249, 249, 249,1) !important; border-radius: 8px;min-height:124px;">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row justify-content-between">
                        <h5 class="mb-0 align-self-center">Anonymous Call Rejection</h5>

                        <div class="btn btn-group btn-sm d-flex flex-row m-0 p-1" role="group" style="min-height: 50px;"
                            aria-label="Basic example" id='callrejection'>
                            <button id="1" type="button"
                                class="btn border d-flex flex-row justify-content-between align-items-center py-0 px-3"
                                [ngClass]="this.form.get(Setting_Type.callRejection).value=='Yes'? 'open_color' :'nopen_color'" (click)="setValue(Setting_Type.callRejection,'on')">
                                
                                <div class="flex-grow-1">ON</div>
                            </button>
                            <button id="2" type="button"
                                class="btn border d-flex flex-row justify-content-between align-items-center py-0 px-3"
                                [ngClass]="!(this.form.get(Setting_Type.callRejection).value=='No')? 'nopen_color' :'open_color'" (click)="setValue(Setting_Type.callRejection,'off')">
                                <div class="flex-grow-1 p-0">OFF</div>
                            </button>
                        </div>
                    </div>

                    <div>
                        <span>Anonymous Call Rejection automatically rejects calls from numbers with Caller ID blocked.</span>
                    </div>
                </div>
            </div>

            <div class="p-2 mb-2 flex-fill" style="background-color: rgba(249, 249, 249,1) !important; border-radius: 8px;min-height:124px;">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row justify-content-between">
                        <h5 class="mb-0 align-self-center">Enable SMS Screening</h5>

                        <div class="btn btn-group btn-sm d-flex flex-row m-0 p-1" role="group" style="min-height: 50px;"
                            aria-label="Basic example" id='smsscreening'>
                            <button id="1" type="button"
                                class="btn border d-flex flex-row justify-content-between align-items-center py-0 px-3"
                                [ngClass]="this.form.get(Setting_Type.smsScreening).value=='Yes'? 'open_color' :'nopen_color'" (click)="setValue(Setting_Type.smsScreening,'on')">
                                
                                <div class="flex-grow-1">ON</div>
                            </button>
                            <button id="2" type="button"
                                class="btn border d-flex flex-row justify-content-between align-items-center py-0 px-3"
                                [ngClass]="!(this.form.get(Setting_Type.smsScreening).value=='No')? 'nopen_color' :'open_color'" (click)="setValue(Setting_Type.smsScreening,'off')">
                                <div class="flex-grow-1 p-0">OFF</div>
                            </button>
                        </div>
                    </div>

                    <div class="flex-fill align-self-center">
                        <span>When SMS Screening is enabled, spam texts will be filtered out so that you can stay focused.</span>
                    </div>
                </div>
            </div>

            <div class="p-2 flex-fill" style="background-color: rgba(249, 249, 249,1) !important; border-radius: 8px;min-height:124px;">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row justify-content-between">
                        <h5 class="mb-0 align-self-center">Allow List Only</h5>

                        <div class="btn btn-group btn-sm d-flex flex-row m-0 p-1" role="group"
                            aria-label="Basic example"style="min-height: 50px;" >
                            <button id="1" type="button" id='isallowList'
                                class="btn border d-flex flex-row justify-content-between align-items-center py-0 px-3"
                                [ngClass]="this.form.get(Setting_Type.isAllowList).value=='Yes'? 'open_color' :'nopen_color'" (click)="setValue(Setting_Type.isAllowList,'on')">
                                
                                <div class="flex-grow-1">ON</div>
                            </button>
                            <button id="2" type="button"
                                class="btn border d-flex flex-row justify-content-between align-items-center py-0 px-3"
                                [ngClass]="!(this.form.get(Setting_Type.isAllowList).value=='No')? 'nopen_color' :'open_color'" (click)="setValue(Setting_Type.isAllowList,'off')">
                                <div class="flex-grow-1 p-0">OFF</div>
                            </button>
                        </div>
                    </div>

                    <div>
                        <span>Active your Allow Numbers List, to make sure it will never be blocked.</span>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>