import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MissedCallFormControlBase } from '../missed-call-form-control.base';

@Component({
  selector: 'voiply-missed-call-form-control-alt',
  templateUrl: './missed-call-form-control-alt.component.html',
  styleUrls: ['./missed-call-form-control-alt.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MissedCallFormControlAltComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MissedCallFormControlAltComponent),
      multi: true
    }
  ]
})
export class MissedCallFormControlAltComponent extends MissedCallFormControlBase {

  constructor() {
    super();
  }
}
