<!-- VOIPLY SPINNER -->
<div class="center-ring" *ngIf="!childComponentLoaded">
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<!-- VOIPLY SPINNER END -->
<div class="p-3 bg-light-grey h-100">
  <router-outlet (activate)="childComponentLoaded = true"></router-outlet>
</div>