<div class="card mt-4" *ngIf="false">
    <div class="p-3">
        <div class="d-flex justify-content-between align-items-center">
            <div class="text-secondary font-weight-bold"> Allow Numbers</div>
            <div style="width: 200px">
                <div class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="number" mask="(000) 000-0000" [patterns]="customPatterns" placeholder="Type Number Here">
                    <button class="input-group-append btn bg-black text-primary d-flex align-items-center" type="button" (click)="onAllowNumber(this.number)">
          <i class="fas fa-plus"></i>
        </button>
                </div>

            </div>
        </div>
        <div class="mt-3 text-secondary" style="font-size: 12px;">USE X FOR WILDCARD, EXAMPLE: <span class="font-weight-bold">(866) 5XX-XXXX</span>
        </div>
        <hr class="my-3">
        <div class="allow-numbers">
            <div class="row">
                <div *ngFor="let number of allowededNumberList; let i=index" class="col-xs-12 col-sm-6">
                    <ng-container *ngIf="number">
                        <div class="" *ngIf="i%2==0">
                            <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                            <span class="text-color">
              {{number | mask:['(000) 000-0000',customPatterns]}}</span>
                        </div>
                        <div class="" *ngIf="i%2!=0">
                            <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                            <span class="text-color">
              {{number | mask:['(000) 000-0000',customPatterns]}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-column">
    <div class="font-weight-bold py-3">Allow Numbers</div>
    <div class="bg-light-grey px-2">
        <div class="py-3 border-bottom">
            <span class="d-flex align-items-center justify-content-center border overflow-auto rounded w-100" style="width: fit-content;">
            <span class="flex-grow-1">
              <input type="text" class="form-control border-0" style="box-shadow: none !important;" [(ngModel)]="number" mask="(000) 000-0000" [patterns]="customPatterns" placeholder="Type Number Here">
            </span>
            <div style="margin-left: -1px" class="p-2 bg-white" (click)="onAllowNumber(this.number)">
                <i class="fas fa-plus"></i>
            </div>
            </span>
        </div>
        <div>
            <div class="row">
                <div *ngFor="let number of allowededNumberList; let i=index" class="col-xs-12 col-sm-6 col-md-4 py-2">
                    <ng-container *ngIf="number">
                        <div class="bg-grey px-2 rounded" style="width: fit-content;" *ngIf="i%2==0">
                            <span>
                            {{number | mask:['(000) 000-0000',customPatterns]}}
                        </span>
                            <i class="far fa-times text-secondary p-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                        </div>
                        <div class="bg-grey px-2 rounded" style="width: fit-content;" *ngIf="i%2!=0">
                            <span>
                            {{number | mask:['(000) 000-0000',customPatterns]}}
                        </span>
                            <i class="far fa-times text-secondary p-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
