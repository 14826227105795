<div class="d-flex flex-column bg-light-grey">
  <div class="d-flex justify-content-start bg-dark text-white rounded p-2">
      <!-- heading -->
      <span class="pr-2 d-flex align-items-center">
        <img src="assets/images/extraline/icons/add-more-phone.svg" class="icon-white">
      </span>
      <span class="text-uppercase font-weight-bold">Add More Phones</span>
  </div>
  <!-- TODO: change this.unMountCard to true when we close add more phones section-->
  <common-ui-change-stripe-card [orderId]="orderId" [unMountCard]="unMountCard"
    (paymentMethodChange)="this.paymentMethodId = $event" [checkoutDetail]="this.shippingService.checkoutDetail">
  </common-ui-change-stripe-card>
  <!-- INFO: select-phone heading  -->
  <div class="d-flex justify-content-between bg-dark text-white rounded p-2 mt-3">
    <div class="d-flex">
      <span class="pr-2 d-flex align-items-center">
        <img src="assets/images/extraline/icons/add-more-phone.svg" class="icon-white">
      </span>
      <span class="text-uppercase font-weight-bold">Select Phones</span>
    </div>
    <div>
      <i class="far fa-plus text-white pr-2 cursor-pointer" (click)="onAddPhoneToCart()"></i>
    </div>
  </div>
  <!-- INFO: select-phone body -->
  <div class="d-flex flex-column flex-grow-1" *ngIf="this.activePhones.length">
    <div class="d-flex flex-row p-3">
      <div class="btn-group mr-3 flex-fill" dropdown>
        <button
          class="btn border w-100 text-center py-2 px-2 mb-0 font-weight-bold  dropdown-toggle d-flex justify-content-between align-items-center bg-white rounded-lg"
          type="button" dropdownToggle type="button" aria-controls="dropdown-basic" id="dropdown-context-lable">
          {{this.selectedPhone.heading}}
        </button>

        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <ng-container *ngFor="let phone of activePhones">
            <li role="menuitem"><button class="dropdown-item px-3"
                *ngIf="phoneAvailableInCountry(phone)&&!phone.outOfStock" (click)="phoneChange(phone)">
                {{phone.heading}}
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="btn-group mr-3" style="width:50px;" dropdown>
        <button
          class="btn w-100 border text-center py-2 px-2 mb-0 font-weight-bold flex-fill dropdown-toggle d-flex justify-content-between align-items-center bg-white rounded-lg"
          type="button" dropdownToggle type="button" aria-controls="dropdown-basic" id="dropdown-context-lable">
          {{phoneQuantity || 1 }}
        </button>

        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" style="width:50px;" role="menu"
          aria-labelledby="button-basic">
          <ng-container *ngFor="let number of [1,2,3,4,5,6,7,8,9,10]">
            <li role="menuitem"><button class="dropdown-item px-3 " (click)="this.phoneQuantity=number">
                {{number}}
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <!-- INFO: cart-summary heading -->
  <div class="bg-primary-gradient rounded p-2 mt-3 text-white">
    <i class="fas fa-shopping-cart pr-2"></i>
    <span class="text-uppercase font-weight-bold">Cart Summary</span>
  </div>
  <!-- INFO:cart-summary body-->
  <div class="d-flex flex-column flex-grow-1">
    <ul class="list-group list-group-flush  border-bottom">
      <li class="list-group-item p-3" *ngIf="groupedCartItems.length == 0" style="background-color: #F9F9F9;">
        <div class="totalrat rat-detail">
          <div class="total-left">
            <h6 class="m-0">YOUR CART ITEM IS EMPTY</h6>
          </div>
        </div>
      </li>

      <li class="list-group-item p-3" style="background-color: #F9F9F9;" *ngFor="let cartItem of groupedCartItems">
        <div class="d-flex flex-row align-items-center">
          <div class="phone-image d-flex align-items-center justify-content-center position-relative"
            style="background-color:#e4e5e7;">
            <img [src]="getItemImage(cartItem.itemId, cartItem.type)" alt="" style="height: 42px; width: 42px;">
            <i class="far fa-times-circle  position-absolute cursor-pointer text-secondary"
              (click)="onRemoveCartItem(cartItem)" style="top:0;left:0;font-size:12px"></i>
          </div>
          <div class="flex-fill pl-3 d-flex flex-column">
            <span class="font-weight-bold" style="font-size: 14px;">{{cartItem.qty}}x
              {{cartItem.heading}}</span>
            <span *ngIf="cartItem.monthlyCharge > 0" class="text-muted m-0" style="font-size: 75% !important"
              [innerHTML]="cartItem.monthlyTotalCostText">
            </span>
          </div>
          <div class="text-secondary">
            <div class="font-weight-bold m-0" *ngIf="!cartItem.phoneUnavailable">
              <span *ngIf="cartItem.totalDiscountedCost !== cartItem.totalCost && cartItem.totalCost != 'Free'"
                style="font-size: 50%; text-decoration: line-through; float: right; margin-top: -15px">
                {{cartItem.totalCost | formatMoneyValue}}
              </span>
              {{cartItem.totalDiscountedCost | formatMoneyValue}}
            </div>

            <div class="font-weight-bold m-0 text-right" *ngIf="cartItem.phoneUnavailable">
              NOT AVAILABLE
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="px-3 py-2 border-bottom" style="background-color: #F9F9F9;">
      <checkout-tax-and-fees-after-checkout [taxDetail]="taxDetail"
        [payAnnually]="shippingService.checkoutDetail.payAnnually" [calculatingTotal]="calculatingTotal">
      </checkout-tax-and-fees-after-checkout>
    </div>
    <div class="px-3 py-2 border-bottom" style="background-color: #F9F9F9;" *ngIf="hasCartItems">
      <checkout-sub-total-after-checkout [calculatingTotal]="calculatingTotal"
        [checkoutDetail]="shippingService.checkoutDetail">
      </checkout-sub-total-after-checkout>
    </div>
    <div class="px-3 py-2 border-bottom" style="background-color: #F9F9F9;"
      *ngIf="hasCartItems && cartHasHardware && shippingService.shippingOptions.length > 0">
      <checkout-shipping-options-after-checkout [checkoutDetail]="shippingService.checkoutDetail"
        [calculatingTotal]="calculatingTotal" (shippingChange)="shippingChange($event)">
      </checkout-shipping-options-after-checkout>
    </div>
    <div>
      <checkout-cart-total-after-checkout [calculatingTotal]="calculatingTotal"
        [checkoutDetail]="shippingService.checkoutDetail"></checkout-cart-total-after-checkout>
    </div>
  </div>
  <!-- INFO: footer -->
  <div class="mt-3 d-flex flex-column">
    <div class="text-center" style="color: #B5B5B5;">
      New devices will be added immediately. You can configure them after placing the order.
    </div>
    <div class="footer mt-3">
      <img src="assets/images/secure-payment-alt.png" alt="" class="footer-image">
      <img src="assets/images/stripe.png" alt="" class="footer-image">
      <img src="assets/images/cards.png" alt="" class="footer-image">
    </div>
  </div>

  <button [disabled]="calculatingTotal  ||PayNowClicked ||shippingService.checkoutDetail.orderTotal<=0"
    class="btn bg-primary-gradient text-primary w-100 p-2 font-weight-bold d-flex align-items-center justify-content-center"
    (click)="onPayNow()">PAY NOW <mat-spinner *ngIf="PayNowClicked" class="d-inline-block white-spinner ml-3"
      diameter="22" value="accent">
    </mat-spinner>
  </button>
</div>
