<div class="d-flex flex-column">
    <div class="d-flex justify-content-between bg-dark text-white rounded p-2">
        <div class="d-flex align-items-center">
            <!-- heading -->
            <span class="pr-2 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="14.503" height="14.414" viewBox="0 0 14.503 14.414">
                <g id="invoice" transform="translate(0 -1.568)">
                  <g id="Group_497" data-name="Group 497" transform="translate(2.453 14.915)">
                    <g id="Group_496" data-name="Group 496">
                      <rect id="Rectangle_17213" data-name="Rectangle 17213" width="9.384" height="1.066" fill="#fff"/>
                    </g>
                  </g>
                  <g id="Group_499" data-name="Group 499" transform="translate(5.012 6.331)">
                    <g id="Group_498" data-name="Group 498" transform="translate(0 0)">
                      <path id="Path_1608" data-name="Path 1608" d="M180.762,169.726h-3.288a.533.533,0,1,0,0,1.066h3.288a.533.533,0,1,0,0-1.066Z" transform="translate(-176.941 -169.726)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="Group_501" data-name="Group 501" transform="translate(5.012 8.819)">
                    <g id="Group_500" data-name="Group 500" transform="translate(0 0)">
                      <path id="Path_1609" data-name="Path 1609" d="M183.25,257.569h-5.776a.533.533,0,1,0,0,1.066h5.776a.533.533,0,0,0,0-1.066Z" transform="translate(-176.941 -257.569)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="Group_503" data-name="Group 503" transform="translate(0 1.568)">
                    <g id="Group_502" data-name="Group 502" transform="translate(0 0)">
                      <path id="Path_1610" data-name="Path 1610" d="M14.189,1.632a.534.534,0,0,0-.572.087L12.123,3.04,10.628,1.7a.533.533,0,0,0-.711,0L8.425,3.038,6.931,1.7a.533.533,0,0,0-.711,0L4.726,3.04,3.233,1.719a.533.533,0,0,0-.887.4v9.491H.533A.533.533,0,0,0,0,12.143v1.3a2.544,2.544,0,0,0,2.542,2.542V14.915A1.477,1.477,0,0,1,1.066,13.44v-.764H9.42v.764a2.542,2.542,0,1,0,5.083,0V2.119A.533.533,0,0,0,14.189,1.632ZM13.437,13.44a1.475,1.475,0,0,1-2.95,0v-1.3a.533.533,0,0,0-.533-.533H3.412V3.3l.962.851a.533.533,0,0,0,.709,0L6.576,2.816,8.069,4.151a.533.533,0,0,0,.711,0l1.493-1.335,1.493,1.335a.533.533,0,0,0,.709,0l.962-.851Z" transform="translate(0 -1.567)" fill="#fff"/>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span class="text-uppercase font-weight-bold">View Invoices</span>
        </div>
        <div>
            <!--TODO: place a dropdown here so one can filter the invoices -->
        </div>
    </div>
    <div class="d-flex flex-column pt-3">
        <mat-spinner class="align-self-center" diameter="25" *ngIf="!customerInvoices"></mat-spinner>
        <ng-container *ngIf="customerInvoices">
            <div class="d-flex justify-content-between p-2" *ngFor="let invoice of customerInvoices; let i = index" [ngStyle]="{'background-color': i%2 == 0 ?  '#F8F8F8' : '#ffffff'}">
                <span>{{invoice.created | date:'MMM dd, yyyy' }}</span>
                <span>
            <span class="pr-3">{{ invoice.amount /100 | formatMoneyValue}}</span>
                <a [href]="invoice.webUrl"><i class="fal fa-eye text-secondary pr-3"></i></a>
                <a [href]="invoice.pdfUrl"><i class="far fa-arrow-to-bottom text-secondary"></i></a>

                </span>
            </div>
        </ng-container>
    </div>

</div>
