import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, FormGroup, FormArray, NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Alert911Base } from '../alert911-form-control.base';

@Component({
  selector: 'voiply-alert911-form-control',
  templateUrl: './alert911-form-control.component.html',
  styleUrls: ['./alert911-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Alert911FormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => Alert911FormControlComponent),
      multi: true
    }
  ]
})
export class Alert911FormControlComponent extends Alert911Base {

  constructor(toastr: ToastrService) {
    super(toastr);
  }
}
