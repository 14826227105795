// ANGULAR
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';


// Material
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaterialModule } from './material-module'
import { MatMenuModule } from '@angular/material/menu';

// Bootstrap
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// State - NGXS
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
// Other Node libs

// Current module components
import { DIRECTIVES } from './directives';
import { AppState } from './+state/app.state';
import { PIPES } from './pipes';
import { COMPONENTS, AnnualBillingModalComponent, CompanyNumberNameChangeModalComponent, TroubleshootingComponent } from './components';

// NGX Modules
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    ...PIPES,
    ...DIRECTIVES,
    ...COMPONENTS],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,

    // Ngxs - State
    NgxsModule.forRoot([AppState]),
    NgxsDispatchPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    // Internationalization Module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    // Material Modules
    MaterialModule,
    MatProgressSpinnerModule,
    MatMenuModule,

    //NGX Modules
    NgxUploaderModule,
    NgxMaskModule,
    //Toast Module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true, maxOpened: 5
    }),
    ToastContainerModule,

    // Bootstrap
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  exports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule,
    NgxsDispatchPluginModule,

    // All material modules
    MaterialModule,

    // All bootstrap modules
    TooltipModule,
    ModalModule,
    PopoverModule,

    //Toast Module
    ToastrModule,
    ToastContainerModule,

    // Voiply Components
    ...DIRECTIVES,
    ...PIPES,
    ...COMPONENTS
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [TranslateService], multi: true }
  ],
  entryComponents: [
    AnnualBillingModalComponent,
    CompanyNumberNameChangeModalComponent,
    TroubleshootingComponent
  ]
})
export class CommonUiModule { }

export function init_app(translate: TranslateService) {
  return () => {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    if (translate.getBrowserLang() !== undefined) {
      translate.use(translate.getBrowserLang());
    }
    else {
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use('en');
    }
  };
}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}