import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Voiply Modules
import { CommonUiModule } from '@voiply/shared/common-ui';

import { COMPONENTS } from './components';
import { CONTAINERS } from './container';

@NgModule({
  imports: [CommonModule, CommonUiModule],
  exports: [
    ...CONTAINERS
  ],
  declarations: [
    ...COMPONENTS,
    ...CONTAINERS,
  ]
})
export class OrderSummaryModule { }
