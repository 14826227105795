<fieldset>
  <div class="bg-primary">
    <div class="text-primary font-weight-bold inner-header ml-4">Billing address</div>
  </div>
  <form #checkout [formGroup]="form">
    <div class="bg-white">
      <div class="row">
        <div class=" col-3 text-sm-right text-left align-self-center">
          <label for="company-name" [ngClass]="{'text-danger': ((f.companyName.touched) && f.companyName.errors)}"
            class="pl-2">Company</label>
        </div>
        <div class="col-9">
          <input id="company-name" class="form-control" placeholder="Enter your Company Name"
            formControlName="companyName"
            [ngClass]="{ 'is-invalid': ((f.companyName.touched) && f.companyName.errors) }">
          <div *ngIf="((f.companyName.touched) && f.companyName.errors)">
            <span *ngIf="f.companyName.errors.required">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter company"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grey">
      <div class="row">
        <div class=" col-3 text-sm-right text-left align-self-center">
          <label for="email" [ngClass]="{'text-danger': ((f.email.touched) && f.email.errors)}"
            class="pl-2">Email</label>
        </div>
        <div class="col-9">
          <input id="email" formControlName="email" type="email" placeholder="jamieappleseed@gmail.com"
            class="form-control" autocomplete="email"
            [ngClass]="{ 'is-invalid': ((f.email.touched) && f.email.errors) }">
          <div *ngIf="((f.email.touched) && f.email.errors)">
            <span *ngIf="f.email.errors.required">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
            <span *ngIf="f.email.errors.email">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
          </div>
        </div>
      </div>
    </div>
    <checkout-address formControlName="address" isStripeElement="true" [submitted]="formSubmitted"
      [filteredStates]="filteredStates"></checkout-address>

    <!-- <div id="example5-card" #cardInfo class="input bg-white" style="height: 46px; padding:12px 0 10px 12px;"></div>
    <div class="bg-white" *ngIf="showPaymentButton">
      <div class="row px-3 py-2">
        <div class="col-12 col-sm-6">
          <span class="radio-container">
            <input type="radio" name="payButtons" [(ngModel)]="payViaBrowser" (click)="changePayViaBrowser(true)"
              [value]="true" [ngModelOptions]="{standalone: true}">
            Google/Apple Pay
            <span (click)="changePayViaBrowser(true)" class="checkmark"></span>
          </span>
        </div>
        <div class="col-12 col-sm-6">
          <span class="radio-container">
            <input style="margin-right: 5px" type="radio" name="payButtons" [(ngModel)]="payViaBrowser"
              (click)="changePayViaBrowser(false)" [value]="false" [ngModelOptions]="{standalone: true}">
            Pay via Credit Card
            <span (click)="changePayViaBrowser(false)" class="checkmark"></span>
          </span>
        </div>
      </div>
    </div> -->
  </form>
</fieldset>