import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';



import { CommonUiModule } from '@voiply/shared/common-ui';
import { SharedNumberSearchModule } from '@voiply/shared/number-search';


import { COMPONENTS, } from './components';
import { CONTAINERS } from './container';
import { BusinessConfigurationModalComponent } from "./components/business-configuration/business-configuration-modal/business-configuration-modal.component";
import { HomeConfigurationModalComponent } from './components/home-configuration/home-configuration-modal/home-configuration-modal.component';

import { NgxMaskModule } from 'ngx-mask';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';


import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CommonUiModule,
    SharedNumberSearchModule,
    NgxMaskModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule,
    NgxPaginationModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  exports: [
    ...COMPONENTS,
    ...CONTAINERS
  ],
  declarations: [
    ...COMPONENTS,
    ...CONTAINERS
  ],
  entryComponents: [BusinessConfigurationModalComponent, HomeConfigurationModalComponent],
  providers:[
    BsModalRef
  ]
})
export class ProductConfigurationModule { }

