import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'voiply-wistia-player',
  templateUrl: './voiply-wistia-player.component.html',
  styleUrls: ['./voiply-wistia-player.component.scss']
})
export class VoiplyWistiaPlayerComponent implements OnInit {

  @Input() videoId: string;
  loadVideoScript = false;
  constructor() { }

  ngOnInit() {
  }

  loadVideo(){
    this.loadVideoScript=true;
  }
}
