<div class="upload-box text-center d-flex flex-column flex-grow-1">
  <div class="upload-box-content d-flex flex-column flex-grow-1">
    <div class="drop-container d-flex flex-column flex-grow-1" ngFileDrop [options]="options"
      (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
      <h4 *ngIf="files.length == 0 && (fileName == '' || fileName == undefined)"
        class="text-secondary font-weight-bold m-0 px-5">
        Drag files here or
        <label class="upload-button">
          <input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput" accept=".wav"> browse
        </label>
        to upload.
      </h4>
      <div *ngIf="files.length == 0 && fileName != '' && fileName != undefined">{{fileName}}</div>
      <div *ngFor="let f of files; let i = index;"> {{ f.name }}</div>
      <button type="button" class="btn btn-link" (click)="removeFile()"
        *ngIf="files.length > 0 || (fileName != '' && fileName != undefined)">
        <i class="far fa-trash-alt pl-2"></i>
      </button>
      <h4 class="text-secondary font-weight-bold m-0 px-5" *ngIf="recording"> {{currentTimeInMinutes}}:{{currentTimeInSecond}}</h4>
    </div>
  </div>
</div>
<div style="position:absolute;right:0px;bottom:0px;"class="d-block">
  <button class="btn-lg bg-white" type="button" [ngClass]="{'btnPositionBefore':fileName=='' , 'btnPositionAfter':fileName!==''}">
    <i *ngIf="!recording" (click)="startVoiceRecording()" class="fas fa-microphone"></i>
    <i *ngIf="recording" (click)="stopVoiceRecording()" class="fas fa-pause"></i>
  </button>
</div>