<div class="h-100 d-flex flex-column">
    <div class="card shadow p-3 bg-white">
        <div class="d-flex flex-column">
            <div class="d-flex justify-content-start bg-dark text-white rounded p-2">
                <div class="d-flex align-items-center">
                    <!-- heading -->
                    <span class="pr-2 d-flex align-items-center">
                        <img src="assets/images/extraline/icons/order-status.svg" class="icon-white">
                    </span>
                    <span class="text-uppercase font-weight-bold">Order Status</span>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center pt-3">
                <div class="w-100 mb-4">
                    <div class="w-100 text-center font-weight-bold text-uppercase rounded-pill py-3"
                        style="color: #CCAA00; background-color: #FFF6D8;">
                        {{shipment.status}}
                    </div>
                </div>
                <div class="border-bottom w-100 mb-4"></div>
                <div class="position-relative w-100" *ngIf="shipment.status!=='Delivered'">
                    <a [attr.href]="shipment.shipperTrackingLink != '' ? shipment.shipperTrackingLink : null"
                        target="_blank">
                        <i class="fal fa-external-link-square text-primary"
                            style="position: absolute; top: 3px; right: 3px;"></i>
                        <div class="btn btn-primary py-3 rounded w-100"><span
                                class="font-weight-bold text-uppercase h5">Track package</span></div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="btn bg-grey rounded w-100 mt-auto text-black" (click)="back()">Back</div>

</div>