export enum ExtralineScreen {
  Navigation,
  CallForwarding,
  VoiceMail,
  CallerId,
  E911,
  NumberPorting,
  OrderStatus,
  CallLogs,
  UpdateCreditCard,
  ViewInvoices,
  UpgradeToAnnual,
  UpgradeToResidential,
  UpgradeToBusiness,
  NewPhoneNumber,
  AddMorePhone,
  SpamBot,
  Fax,
  Emergency999,
  CallRecording,
  ActivateDataPlan,
  DataUsage
}
