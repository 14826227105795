import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@voiply/auth0';
import { CrispService, ShippingService, UserService, ConversionService, Validate, BillingAddressChangeExtralineSettingsAction, OrderService } from '@voiply/shared/common-ui';
import { SystemType, Address } from '@voiply/shared/model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CheckoutBase } from '../checkout.base';
import { Location } from '@angular/common';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';


@Component({
  selector: 'checkout-update-billing-extraline-settings',
  templateUrl: './update-billing-extraline-settings.component.html',
  styleUrls: ['./update-billing-extraline-settings.component.scss']
})
export class UpdateBillingExtralineSettingsComponent extends CheckoutBase {

  selectedCountry = 'US';
  newBillingAddress: Address;
  showBillingAddressInput = false;
  @Dispatch() onBillingAddressChangeExtraline = (billingAddress) => new BillingAddressChangeExtralineSettingsAction(billingAddress);

  constructor(
    crispService: CrispService,
    shippingService: ShippingService,
    tostr: ToastrService,
    authService: AuthService,
    modalService: BsModalService,
    renderer: Renderer2,
    userService: UserService,
    conversionService: ConversionService,
    router: Router,
    route: ActivatedRoute,
    orderService: OrderService,
    location: Location
  ) {
    super(crispService, shippingService, SystemType.App, tostr, authService, modalService, renderer, userService, conversionService, orderService,router, route, location);
    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      this.selectedCountry = checkoutDetail.shippingAddress.country;
      this.newBillingAddress = checkoutDetail.billingAddress;
    }));
  }

  onSaveNewBillingAddress() {
    const isValidBillingAddress = Validate(Address, this.newBillingAddress);
    if (!isValidBillingAddress)
      return false;
    this.onBillingAddressChangeExtraline(this.newBillingAddress);
    this.showBillingAddressInput = !this.showBillingAddressInput;
  }


  canPayForOrder() { }

}
