import { Component, Input } from '@angular/core';
import { AppStateSelectors } from '@voiply/shared/common-ui';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Shippment } from '@voiply/shared/model';

@Component({
  selector: 'voiply-order-status-extraline-settings',
  templateUrl: './order-status-extraline-settings.component.html',
  styleUrls: ['./order-status-extraline-settings.component.scss']
})
export class OrderStatusExtralineSettingsComponent {

  @Input() backButtonUrl;
  @Select(AppStateSelectors.shipment) shipment$: Observable<Shippment>;
  constructor() {
  }

}
