import { ControlValueAccessor, Validator, FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';

export class ControlValueAccessorAndValidator implements ControlValueAccessor, Validator {

  form: FormGroup;

  // convenience getter for easy access to form fields
  get f(): any { return this.form.controls; }

  // #region 'ControlValueAccessor & Validator'

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    val && this.form.setValue(val, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { invalidForm: { valid: false, message: "Form is invalid." } };
  }
  // #endregion

}