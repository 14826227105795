import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'voiply-includein-company-directory-form-control',
  templateUrl: './includein-company-directory-form-control.component.html',
  styleUrls: ['./includein-company-directory-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncludeinCompanyDirectoryFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IncludeinCompanyDirectoryFormControlComponent),
      multi: true
    }
  ]
})
export class IncludeinCompanyDirectoryFormControlComponent extends ControlValueAccessorAndValidator implements OnInit {
  color: ThemePalette = 'primary';
  @Input() isDisable: boolean;
  constructor() {
    super();
    this.form = new FormGroup({
      includeinCompanyDirectory: new FormControl('')
    });
  }

  ngOnInit() {
  }

}
