import { Component, OnInit, ViewChild } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppStateSelectors, OnDestroyCleanup, UserService, StripeDataChangedAction, AnnualBillingModalComponent } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import { Address, CheckoutDetail, Invoice, Card, Stripe } from '@voiply/shared/model';
import * as _ from 'lodash'
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';

@Component({
  selector: 'voiply-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: [ './invoices.component.scss' ]
})
export class InvoicesComponent extends OnDestroyCleanup implements OnInit {
  customerInvoices: Invoice[];
  orderId: string;

  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;

  constructor(private userService: UserService) {
    super()
  }

  ngOnInit() {
    this.orderId$.pipe(take(1)).subscribe(id => {
      this.orderId = id;
      this.fetchData();
    });
  }
  fetchData() {
    if (this.orderId) {
      //Past Invoices
      this.userService.getCustomerInvoices(this.orderId).subscribe(invoices => {
        this.customerInvoices = [];
        if (invoices.length) {
          this.customerInvoices = invoices.map(o => {
            const invoice = new Invoice()
            invoice.id = o.id;
            invoice.amount = o.amount;
            invoice.created = new Date(0); invoice.created.setUTCSeconds(o.created);
            invoice.currency = o.currency;
            invoice.number = o.number;
            invoice.pdfUrl = o.pdfUrl;
            invoice.webUrl = o.webUrl;
            invoice.plan = o.plan;
            return invoice;
          });
        }
      });
    }
  }
}
