import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OrderTrackingBase } from '../order-tracking.base';

@Component({
  selector: 'voiply-order-tracking-new',
  templateUrl: './order-tracking-new.component.html',
  styleUrls: ['./order-tracking-new.component.css']
})
export class OrderTrackingNewComponent extends OrderTrackingBase {

  constructor(_renderer2: Renderer2, @Inject(DOCUMENT) _document,  modalService: BsModalService,  domSanitize: DomSanitizer){
    super(_renderer2,_document,modalService,domSanitize)
  }

}
