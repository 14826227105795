<div class="d-flex flex-row justify-content-between align-items-center">
  <div class="d-flex">
    <div class="text-center align-self-center mr-2" style="width: 42px;">
      <img src="assets/images/fast-delivery-dark.svg">
    </div>
    <div class="text-muted">
      Shipping
    </div>
  </div>
  <div>
    <ng-container *ngIf="calculatingTotal">
      <mat-spinner class="float-right" diameter="18" value="accent"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!calculatingTotal">
      <span class="text-black font-weight-bold">
        {{checkoutDetail.discountedShippingCharges | formatMoneyValue}}
      </span>
    </ng-container>
  </div>
</div>
