<div [formGroup]="form" class="shadow">
    <div class="bg-white">
        <div class="row">
            <div class=" col-3 text-sm-right text-left align-self-center">
                <label for="name" [ngClass]="{'text-danger': ((f.name.touched||submitted) && f.name.errors)}" class="pl-2">Name</label>
            </div>
            <div class="col-9">
                <input id="{{ isStripeElement ? 'name' : '' }}" placeholder="Jamie Appleseed" autocomplete="name" class="form-control" formControlName="name" name="name" [ngClass]="{ 'is-invalid': ((f.name.touched||submitted) && f.name.errors) }">
                <div *ngIf="((f.name.touched||submitted) && f.name.errors)">
                    <span *ngIf="f.name.errors.required">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter name"></i></span>
                    <span *ngIf="f.name.errors.name">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid name"></i></span>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-grey">
        <div class="row">
            <div class=" col-3 text-sm-right text-left align-self-center">
                <label for="phone" [ngClass]="{'text-danger': ((f.phone.touched||submitted) && f.phone.errors)}" class="pl-2">Phone</label>
            </div>
            <div class="col-9">
                <input id="{{ isStripeElement ? 'phone' : '' }}" class="form-control" type="tel" placeholder="(555) 555-5555" autocomplete="tel" formControlName="phone" igxInput mask="(000) 000-0000" name="phone" [ngClass]="{ 'is-invalid': ((f.phone.touched||submitted) && f.phone.errors) }">
                <div *ngIf="((f.phone.touched||submitted) && f.phone.errors)">
                    <span *ngIf="f.phone.errors.required">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter Phone Number"> </i></span>
                    <span *ngIf="f.phone.errors.phoneNumber">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Phone Number"> </i></span>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white">
        <div class="row">
            <div class=" col-3 text-sm-right text-left align-self-center">
                <label for="address" [ngClass]="{'text-danger': ((f.address.touched||submitted) && f.address.errors)}" class="pl-2">Address</label>
            </div>
            <div class="col-9">
                <input ngx-google-places-autocomplete id="{{ isStripeElement ? 'address' : '' }}" class="form-control" placeholder="185 Berry St, Unit 313" autocomplete="address-line1" formControlName="address" name="address" [ngClass]="{ 'is-invalid': ((f.address.touched||submitted) && f.address.errors) }"
                    (onAddressChange)="handleAddressChange($event)" #placesRef="ngx-places">
                <div *ngIf="((f.address.touched||submitted) && f.address.errors)">
                    <span *ngIf="f.address.errors.required">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter Address"> </i></span>
                    <span *ngIf="f.address.errors.address">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Address"></i></span>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-grey">
        <div class="row">
            <div class=" col-3 text-sm-right text-left align-self-center">
                <label for="city" [ngClass]="{'text-danger': ((f.city.touched||submitted) && f.city.errors)}" class="pl-2">City</label>
            </div>
            <div class="col-9">
                <input id="{{ isStripeElement ? 'city' : '' }}" class="form-control" placeholder="San Francisco" autocomplete="address-level2" formControlName="city" name="city" [ngClass]="{ 'is-invalid': ((f.city.touched||submitted) && f.city.errors) }">
                <div *ngIf="((f.city.touched||submitted) && f.city.errors)">
                    <!-- <i class="far fa-exclamation-circle is-error" tooltip="Please enter City"> </i> -->
                    <span *ngIf="f.city.errors.required">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter City"> </i></span>
                    <span *ngIf="f.city.errors.alpha">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid City"> </i></span>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white">
        <div class="row">
            <div class=" col-3 text-sm-right text-left align-self-center">
                <label for="b-state" [ngClass]="{'text-danger': f.state.errors}" class="pl-2">State</label>
            </div>
            <div class="col-9">
                <div class="select-input" id="b-state">
                    <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
                    <select class="select-custom form-control" formControlName="state" name="state">
            <option value="" selected>Select State</option>
            <option *ngFor="let state of filteredStates" [value]="state.shortCode" style="color: #212529"
              [disabled]="state.shortCode == null">
              {{state.name}}</option>
          </select>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-grey">
        <div class="row">
            <div class=" col-3 text-sm-right text-left align-self-center">
                <label for="b-state" [ngClass]="{'text-danger': f.country.errors}" class="pl-2">Country</label>
            </div>
            <div class="col-9">
                <div class="select-input" id="b-state">
                    <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
                    <select class="select-custom form-control" formControlName="country" name="country">
            <option value="" selected>Select Country</option>
            <option *ngFor="let country of countries" [value]="country.countryShortCode">{{country.countryName}}
            </option>
            <!-- <option value="US" style="color: #212529">U.S.A.</option> -->
            <!-- <option value="CA" style="color: #212529">Canada</option> -->
          </select>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white">
        <div class="row">
            <div class=" col-3 text-sm-right text-left align-self-center">
                <label for="zip" [ngClass]="{'text-danger': ((f.zip.touched||submitted) && this.form.errors.zip)}" class="pl-2">ZIP</label>
            </div>
            <div class="col-9">
                <input id="{{ isStripeElement ? 'zip': '' }}" class="form-control" placeholder="94107" autocomplete="postal-code" formControlName="zip" [value]="this.form.get('zip').value | formatZip" name="zip" [ngClass]="{ 'is-invalid': ((f.zip.touched||submitted) && (this.form.errors.zip||this.form.errors.required)) }">
                <div *ngIf="((f.zip.touched||submitted))">
                    <!-- <i class="far fa-exclamation-circle is-error" tooltip="Please enter Zip"></i> -->
                    <span *ngIf="this.form.errors.required">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter Zip"> </i></span>
                    <span *ngIf="this.form.errors.zip">
            <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Zip Code"> </i></span>
                </div>
            </div>
        </div>
    </div>
</div>
