import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AddressBase } from '../address.base';

@Component({
  selector: 'checkout-address-new',
  templateUrl: './address-new.component.html',
  styleUrls: ['./address-new.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressNewComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressNewComponent),
      multi: true
    }
  ]
})
export class AddressNewComponent extends AddressBase {
    constructor() {
    super();
  }
}
