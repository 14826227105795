import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductConfigurationBase } from '../product-configuration.base';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { PhoneConfiguration, SystemType, CartItemType } from '@voiply/shared/model';
import { BusinessConfigurationModalComponent } from '../../components';

@Component({
  selector: 'voiply-product-configuration-user-settings',
  templateUrl: './product-configuration-user-settings.component.html',
  styleUrls: ['./product-configuration-user-settings.component.scss']
})
export class ProductConfigurationUserSettingsComponent extends ProductConfigurationBase implements OnInit {
  @Input() userId = '';
  @Input() systemType = ''
  @Input() selectedMatTab = 1
  value = new PhoneConfiguration();
  key = '';

  constructor(toastr: ToastrService, modalService: BsModalService) {
    super(toastr, modalService);
  }
  ngOnInit(): void {
    this.addAndUpdateProductConfiguration();

    this.performValidation$.subscribe(newvalue => {
      if (newvalue)
        this.submitted = true;
    })
    if (this.systemType === SystemType.Business)
      this.showBusinessUserSettings();
    else
      this.showHomeUserSettings();
  }
  addAndUpdateProductConfiguration() {
    this.productConfigurations$.subscribe((data) => {
      this._productconfigutrations = data;
      this._productConfigurationLength = Object.keys(this._productconfigutrations).length;
      // loop through all cart items
      _.each(this._productconfigutrations, (value, key) => {
        if (value.type === CartItemType.Phone)
          this.phonesconfiguration[key] = value;
        else if (value.type === CartItemType.App)
          this.appsconfiguration[key] = value;
      });
      this._phonesconfigurationLength = Object.keys(this.phonesconfiguration).length;
      this._appsconfigurationLength = Object.keys(this.appsconfiguration).length;
    });
  }

  showBusinessUserSettings() {
    _.each(this._productconfigutrations, (value, key) => {
      if (value.configuration.userId === this.userId) {
        this.key = key,
          this.value = value,
          this.extDisabled = this.extDisabled,
          this.userIsVoiplyMember = this.userIsVoiplyMember,
          this.devices = this.devices,
          this.orderId = this.orderId,
          this.emergencyAddress = this.emergencyAddress,
          this.showEmergencyAddress = this.showEmergencyAddress
      }
    });
  }
  showHomeUserSettings() {
    _.each(this._productconfigutrations, (value, key) => {
      if (value.configuration.userId === this.userId) {
        this.key = key,
          this.value = value,
          this.extDisabled = this.extDisabled,
          this.userIsVoiplyMember = this.userIsVoiplyMember,
          this.devices = this.devices,
          this.orderId = this.orderId
      }
    });
  }


}
