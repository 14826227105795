import { OnInit,  Directive } from '@angular/core';
import {  FormGroup, FormControl, FormArray } from '@angular/forms';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
@Directive()
export class Alert911Base extends ControlValueAccessorAndValidator implements OnInit {
   //This is Emergency 999 form Component
   contactName = '';
   contactNumber = '';
   constructor(private toastr: ToastrService) {
     super();
   }
 
   ngOnInit() {
     this.form = new FormGroup({
       emergencyContacts: new FormArray([])
     });
   }
   get emergencyContacts() {
     return this.form.get('emergencyContacts') as FormArray;
   }
   addNewContact(name, number) {
     if (!name.errors && !number.errors) {
       if (!_.find(this.emergencyContacts.value, { phone: '1' + number.value })) {
         this.emergencyContacts.push(new FormControl({ name: name.value, phone: '1' + number.value }))
         this.contactName = ''
         this.contactNumber = ''
         name.control.markAsUntouched();
         number.control.markAsUntouched();
       } else {
         this.toastr.error('Number Already in the List');
       }
     }
   }
 
   writeValue(v) {
     for (let value of v.emergencyContacts)
       this.emergencyContacts.push(new FormControl(value))
     v && this.form.setValue(v, { emitEvent: false });
   }
   removeContact(index) {
     this.emergencyContacts.removeAt(index);
   }
}
