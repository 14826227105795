<!--containing in - Lib: product-configuration -->
<!--used by - Component: home-configuration/home-configuration-modal, home-configuration/user-settings-home -->
<!--Added for - App: Home, user-settings -->
<div [formGroup]="form" class="cart-item-background-color">
  <div class="d-flex p-3 flex-row flex-wrap flex-md-nowrap">
    <div class="p-1 flex-fill" style="position: relative;">
      <input type="text" formControlName="firstName" class="form-control" placeholder="User Name"
        [ngClass]="{ 'is-invalid': ((f.firstName.touched || submitted) && f.firstName.errors) }">
      <div *ngIf="((f.firstName.touched || submitted) && f.firstName.errors)">
        <span *ngIf="f.firstName.errors.required">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter firstname"></i></span>
        <span *ngIf="f.firstName.errors.userName">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid firstname"> </i></span>
      </div>
    </div>
    <div class="p-1 flex-fill">
      <input type="text" formControlName="lastName" class="form-control" placeholder="Last Name">
    </div>
    <div class="p-1 flex-fill" style="position: relative;">
      <input type="email" formControlName="email" class="form-control" placeholder="Email"
        [ngClass]="{ 'is-invalid': ((f.email.touched || submitted) && f.email.errors) }">
      <div *ngIf="((f.email.touched || submitted) && f.email.errors)">
        <span *ngIf="f.email.errors.required">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
        <span *ngIf="f.email.errors.email">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
      </div>
    </div>
    <div class="p-1">
      <div class="select-input">
        <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
        <select class="select-custom" formControlName="timezone">
          <option value="" class="placeholder" selected disabled hidden>Time-Zone</option>
          <option value="est">EST</option>
          <option value="mst">MST</option>
          <option value="cst">CST</option>
          <option value="pst">PST</option>
          <option value="hst">HST</option>

        </select>
      </div>
    </div>
    <!-- <div class="p-1  d-none d-md-none d-lg-block">
      <button type="button" class="btn bg-primary font-weight-bold text-primary" style="" (click)="toggleConfig()">
        <ng-container *ngIf="!isHideConfiguration">
          Hide Configurations
        </ng-container>
        <ng-container *ngIf="isHideConfiguration">
          Show Configurations
        </ng-container>
      </button>
    </div> -->
    <div class="p-1 ">
      <button type="button" class="btn bg-primary font-weight-bold text-primary h-100" (click)="toggleConfig()">
        <!-- <ng-container *ngIf="!isHideConfiguration"> -->
        <i *ngIf="!isHideConfiguration" class="fa fa-eject" aria-hidden="true"></i>
        <!-- </ng-container>
      <ng-container *ngIf="isHideConfiguration"> -->
        <i *ngIf="isHideConfiguration" class="fa fa-cog" aria-hidden="true"></i>
        <!-- </ng-container> -->
      </button>
    </div>
  </div>
</div>
