import { Injectable, Inject } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { IEnvironment } from '../environment.interface';
import { ENVIRONMENT } from '../constants';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ValidationService extends BaseService {

    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {
        super();
    }

    validateEmergencyAddress(address): Promise<any> {
        return this.http.post(`${this.environment.rootApi}/validate-address`, { address }).toPromise();
    }
}
