<div class="h-100">
    <div class="card" style="border-radius: 5px 5px 0 0;">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center bg-dark text-white rounded p-2">
                <div class="d-flex align-items-center">
                    <!-- heading -->
                    <span class="pr-2 d-flex align-items-center">
                        <img src="assets/images/extraline/icons/upgrade-to-business.svg" class="icon-white">
                    </span>
                    <span class="text-uppercase font-weight-bold">Billing Address</span>
                </div>
                <i class="far fa-pencil-alt pr-2" (click)="showBillingAddressInput=!showBillingAddressInput"></i>

            </div>

            <div *ngIf="!showBillingAddressInput">
                <div class="mt-3" *ngIf="this.shippingService.checkoutDetail.billingAddress; let billingAddress">
                    <h6 class="m-0">
                        <span
                            *ngIf="!this.shippingService.checkoutDetail.companyName">{{this.shippingService.checkoutDetail.companyName}}</span>
                        <span *ngIf="this.shippingService.checkoutDetail.companyName">{{billingAddress.name}}</span>
                    </h6>
                    <div *ngIf="!this.shippingService.checkoutDetail.companyName">{{billingAddress.name}}</div>
                    <div>{{billingAddress.address}}</div>
                    <div>{{billingAddress.city}} {{billingAddress.state}} {{billingAddress.zip}}</div>
                    <div>{{billingAddress.phone}}</div>
                    <div>{{billingAddress.email}}</div>
                    <div *ngIf="billingAddress.country==='CA'">Canada</div>
                    <div *ngIf="billingAddress.country==='US'">USA</div>
                </div>
            </div>
            <div *ngIf="showBillingAddressInput">
                <checkout-billing-extraline [billingAddress]="billingAddress"
                    (billingAddressChange)="this.newBillingAddress=$event">
                </checkout-billing-extraline>

                <div class="d-flex pt-3 w-100">
                    <button class="btn bg-light-grey text-black rounded w-100"
                        (click)="showBillingAddressInput=!showBillingAddressInput">
                        <span>Cancel</span>
                    </button>
                    <button class="d-flex btn bg-primary text-primary rounded ml-2 justify-content-center w-100"
                        type="submit" (click)="onSaveNewBillingAddress()">
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>