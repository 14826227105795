<div class="d-flex flex-column">
    <div class="font-weight-bold py-3">Block Numbers</div>
    <div class="bg-light-grey px-2">
        <div class="py-3 border-bottom">
            <span class="d-flex align-items-center justify-content-center border  rounded w-100"
                style="width: fit-content;">
                <span class="flex-grow-1">
                    <intl-tel-input-new [cssClass]="'custom ignore-ng-invalid form-control'"
                        [preferredCountries]="['us']" [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                        background="bg-white" name="phone" [(ngModel)]="number">
                    </intl-tel-input-new>
                </span>
                <div style="margin-left: -1px" class="p-2 bg-white" (click)="onBlockNumber(this.number)">
                    <i class="fas fa-plus"></i>
                </div>
            </span>
        </div>
        <div>
            <div class="row">
                <div *ngFor="let number of blockedNumberList; let i=index" class="col-xs-12 col-sm-6 col-md-4 py-2">
                    <ng-container *ngIf="number">
                        <div class="bg-grey px-2 rounded" style="width: fit-content;" *ngIf="i%2==0">
                            <span>
                                {{formatInternationalNumber(number)}}
                            </span>
                            <i class="far fa-times text-secondary p-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                        </div>
                        <div class="bg-grey px-2 rounded" style="width: fit-content;" *ngIf="i%2!=0">
                            <span>
                                {{formatInternationalNumber(number)}}
                            </span>
                            <i class="far fa-times text-secondary p-2" (click)="onDeleteNumber(i)" *ngIf="number"></i>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>