import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';

import { ENVIRONMENT } from '../constants';
import { IEnvironment } from '../environment.interface';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService extends BaseService {
  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT) private environment: IEnvironment
  ) {
    super();
  }

  getQrCodes(orderId: string) {
    return this.http.get(
      `${this.environment.voiplyExtraLineAPI}/qrcode`,
      {
        params: new HttpParams()
          .set(
            'code',
            'Z7bjzviSY9jbEsCaykecT6uWV6bhHBqwVwQJYDxPK5IfvmOThotvvg=='
          )
          .set('organization_id', orderId),
        responseType: 'text'
      }
    );
  }
}
