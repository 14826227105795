<div class="intl-tel-input allow-dropdown d-flex align-items-center w-100" ngClass={{background}}>
    <div class="flag-container" dropdown [ngClass]="{'disabled': disabled}">
        <div class="selected-flag dropdown-toggle" dropdownToggle>
            <div class="iti-flag" [ngClass]="selectedCountry.flagClass"></div>
            <div class="iti-arrow"></div>
            <div style="padding-left: 10px">+{{selectedCountry.dialCode}}</div>
        </div>
        <ul class="country-list dropdown-menu" *dropdownMenu>
            <li class="country" *ngFor="let country of preferredCountriesInDropDown" (click)="onCountrySelect(country, focusable)">
                <div class="flag-box">
                    <div class="iti-flag" [ngClass]="country.flagClass"></div>
                </div>
                <span class="country-name">{{country.name}}</span>
                <span class="dial-code">+{{country.dialCode}}</span>
            </li>
            <li class="divider" *ngIf="preferredCountriesInDropDown?.length"></li>
            <li class="country" *ngFor="let country of allCountries" (click)="onCountrySelect(country, focusable)">
                <div class="flag-box">
                    <div class="iti-flag" [ngClass]="country.flagClass"></div>
                </div>
                <span class="country-name">{{country.name}}</span>
                <span class="dial-code">+{{country.dialCode}}</span>
            </li>
        </ul>
    </div>
    <input type="tel" id="phone" #phone autocomplete="off" (blur)="onTouched()" (keypress)="onInputKeyPress($event)" [(ngModel)]="phoneNumber" (ngModelChange)="onPhoneNumberChange()" [disabled]="disabled" [placeholder]="selectedCountry.placeHolder" #focusable
        [style.padding-left.px]="textboxPadding" style="outline: 0;" [ngClass]="{'rounded-top': !phone.pristine, 'rounded': phone.pristine}" class="py-2 border-0 {{background || 'bg-light'}}">

    <!-- <input type="tel" id="phone" autocomplete="off" [ngClass]="cssClass" (blur)="onTouched()" (keypress)="onInputKeyPress($event)" [(ngModel)]="phoneNumber" (ngModelChange)="onPhoneNumberChange()" [disabled]="disabled" [placeholder]="selectedCountry.placeHolder"
        #focusable [style.padding-left.px]="textboxPadding"> -->
</div>
