<div class=" mt-3" *ngIf="userIsVoiplyMember$ | async" id="checkout">
  <common-ui-section-header imagePath="/assets/images/online-store-shopping-cart.png" title="Express Checkout"
    rightSideImagePath="/assets/images/secure-payment.png">
  </common-ui-section-header>
  <div class="row">
    <!-- Billing Address, stripe and payment section -->
    <div class="col-md-12 col-lg-6 col-sm-12 pt-3">
      <div *ngIf="checkoutDetail">
        <div class="billing info">
          <checkout-billing-business [billingAddress]="billingAddress" [companyName]="checkoutDetail.companyName"
            (companyNameChange)="onCompanyNameChange($event)" (billingAddressChange)="billingAddressChange($event)">
          </checkout-billing-business>

          <div class="bg-white mt-2 custom-checkbox">
            <!-- Billing same as shipping -->
            <div class="pl-2 bg-primary">
              <label class="checkbox-container text-primary mb-1 font-weight-bold inner-header">Shipping same as Billing
                Address
                <input type="checkbox" #shippingSameAsBillingCheckbox name="shippingSameAsBilling"
                  [checked]="checkoutDetail.isShippingSameAsBillingAddress"
                  (change)="onShippingSameAsBillingChanged(shippingSameAsBillingCheckbox.checked)">
                <span class="checkmark mt-3"></span>
              </label>
            </div>

            <checkout-shipping-address [shippingAddress]="shippingAddress"
              (shippingAddressChange)="shippingAddressChange($event)"
              *ngIf="!checkoutDetail.isShippingSameAsBillingAddress"></checkout-shipping-address>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>