<!--containing in - Lib: product-configuration -->
<!--used by - Component: home-configuration/home-configuration-modal, home-configuration/user-settings-home, business-configuration/business-configuration-modal, business-configuration/user-settings-business -->
<!--Added for - App: Home, Business user-settings -->
<div [formGroup]="form" class="cart-item-background-color" [ngClass]="{'disableBackground':isDisable}">
    <div class="d-flex align-items-center pb-3">
        <label class="mr-2 mb-0">All&nbsp;<span class="font-italic font-weight-bold">missed calls</span>&nbsp;go to
      Voicemail
      after</label>
        <!-- <select class="form-control rounded-left-border select-custom" style="max-width: 105px"
      formControlName="voicemailRingTimeoutCount">
      <option [value]="1">1 Ring</option>
      <option [value]="2">2 Rings</option>
      <option [value]="3">3 Rings</option>
      <option [value]="4" selected>4 Rings</option>
      <option [value]="5">5 Rings</option>
      <option [value]="6">6 Rings</option>
      <option [value]="7">7 Rings</option>
      <option [value]="8">8 Rings</option>
      <option [value]="9">9 Rings</option>
    </select> -->
        <div class="select-input" style="max-width: 105px">
            <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
            <select class="select-custom font-weight-bold text-secondary form-control" [attr.disabled]="isDisable ? 'disabled' : null" formControlName="voicemailRingTimeoutCount">
        <!-- ngif condition is added to show option only if it is previously selected -->
        <option [value]="1" *ngIf="this.form.controls.voicemailRingTimeoutCount.value==='1'">1 Ring</option>
        <option [value]="2" *ngIf="this.form.controls.voicemailRingTimeoutCount.value==='2'">2 Rings</option>
        <option [value]="3">3 Rings</option>
        <option [value]="4" selected>4 Rings</option>
        <option [value]="5">5 Rings</option>
        <option [value]="6">6 Rings</option>
        <option [value]="7">7 Rings</option>
        <option [value]="8">8 Rings</option>
        <!-- <option [value]="60">60 Seconds</option> -->
        <option [value]="10">No Voicemail</option>
      </select>
        </div>
    </div>
</div>
