<div class="py-3 d-flex flex-row justify-content-between">
  <div class="d-flex align-items-center flex-grow-1" *ngIf="!showCreditCard">
    <div class="h5 m-0">
      <span class="text-color"> Credit Card: </span
      ><span class="font-weight-bold">**** {{ this.cardDetail.last4 }}</span>
    </div>
  </div>
  <button
    class="btn bg-primary text-primary px-3 rounded"
    (click)="this.stripeService.mountNewCard(true)"
    *ngIf="!showCreditCard"
  >
    CHANGE
  </button>
  <form
    (ngSubmit)="onSubmit()"
    class="d-flex align-items-center"
    [ngClass]="{ 'flex-grow-1': showCreditCard }"
  >
    <div #cardElement class="input flex-grow-1"></div>
    <button
      class="btn bg-primary text-primary px-2 ml-2 rounded"
      *ngIf="showCreditCard"
      type="submit"
    >
      <span *ngIf="!cardChangeRequesting">Submit</span>
      <mat-spinner
        *ngIf="cardChangeRequesting"
        diameter="22"
        value="accent"
        class="white-spinner"
      ></mat-spinner>
    </button>
    <button
      class="btn bg-dark text-primary px-2 ml-2 rounded"
      *ngIf="showCreditCard"
      (click)="onCardChangeCancel()"
    >
      <span>Cancel</span>
    </button>
  </form>
</div>
