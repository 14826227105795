<!-- <div class="card">
    <div class="p-3">
        <div class="d-flex justify-content-between align-items-center">
            <div class="text-secondary font-weight-bold"> Block Numbers</div>
            <div style="width: 200px">
                <div class="input-group flex-nowrap">
                    <intl-tel-input [cssClass]="'custom ignore-ng-invalid form-control'" [preferredCountries]="['us']"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="true" name="phone" [(ngModel)]="number">
                    </intl-tel-input>
                    Comment this lone on when you remove comment <input type="text" class="form-control" [(ngModel)]="number" mask="(000) 000-0000" [patterns]="customPatterns" placeholder="Type Number Here">
                    <button class="input-group-append btn bg-black text-primary d-flex align-items-center" type="button"
                        (click)="onBlockNumber(this.number)">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>

            </div>
        </div>
        <div class="mt-3 text-secondary" style="font-size: 12px;">USE X FOR WILDCARD, EXAMPLE: <span
                class="font-weight-bold">(866) 5XX-XXXX</span>
        </div>
        <hr class="my-3">
        <div class="block-numbers">
            <div class="row">
                <div *ngFor="let number of blockedNumberList; let i=index" class="col-xs-12 col-sm-6">
                    <ng-container *ngIf="number">
                        <div class="" *ngIf="i%2==0">
                            <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)"
                                *ngIf="number"></i>
                            <span class="text-color">
                                +{{formatInternationalNumber(number)}}
                            </span>
                        </div>
                        <div class="" *ngIf="i%2!=0">
                            <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)"
                                *ngIf="number"></i>
                            <span class="text-color">
                                +{{formatInternationalNumber(number)}}

                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="card " style="border: none; border-radius: 8px 8px 0px 0px;border-bottom: 3px solid rgba(112, 112, 112, 0.3);">
    <div class="d-flex flex-column" style="background-color: rgba(249, 249, 249,1);border-radius: 8px 8px 0px 0px;" >
        <div class="d-flex flex-column p-2 flex-fill">
            <div class="p-2">
                <h4><strong>Block Numbers</strong></h4>
            </div>
            <div class="p-2 flex-fill">
                <div class="">
                    <div class="input-group flex-nowrap">
                        <intl-tel-input [cssClass]="'custom ignore-ng-invalid form-control'" [preferredCountries]="['us']"
                            [enableAutoCountrySelect]="false" [enablePlaceholder]="true" name="phone" [(ngModel)]="number" style="width: 100%;height:45px;">
                        </intl-tel-input>
                        
                        <button class="input-group-append btn bg-black text-primary d-flex align-items-center" type="button" style="background-image: linear-gradient(to right, #FB522C, #D22700) !important;"
                            (click)="onBlockNumber(this.number)">
                            <!-- <i class="fas fa-plus"></i> -->
                            <img class="img-fluid" src='assets/images/blockNumber.svg'>
                        </button>
                    </div>
    
                </div>
                <div class="block-numbers ">
                    <div class="row my-2  ">
                        <div *ngFor="let number of blockedNumberList; let i=index" class="col-xs-12 col-sm-4 " [ngClass]="" style="border-right: 2px solid black;">
                            <ng-container *ngIf="number">
                                <div class="" *ngIf="i%2==0">
                                    <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)"
                                        *ngIf="number"></i>
                                    <span class="text-color">
                                        +{{formatInternationalNumber(number)}}
                                    </span>
                                </div>
                                <div class="" *ngIf="i%2!=0">
                                    <i class="fas fa-times-circle text-secondary py-2" (click)="onDeleteNumber(i)"
                                        *ngIf="number"></i>
                                    <span class="text-color">
                                        +{{formatInternationalNumber(number)}}
        
                                    </span>
                                </div>
                            </ng-container>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>


</div>