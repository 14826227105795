import { SystemType } from '@voiply/shared/model';

export const environment = {
  production: true,
  systemType: SystemType.App,

  primaryApiUrl: 'https://voiplycheckout.azurefd.net/',

  signalRAccessKey: 'fT412rywuFPn8hsACQ5rWnh88QdDErwySp0PjZsGYOs=', // voiply
  signalRUrl: 'https://voiplycheckoutb.service.signalr.net/api/v1',
  signalRHubName: 'checkoutupdate',

  voiplyPhoneSearchAPI: 'https://voiplyapi1.azure-api.net/phoneNumbers/numbers',
  voiplyPhonePortabilityCheckAPI: 'https://voiplyapi1.azure-api.net/phoneNumbers/isportable',
  blobStoragePath: 'https://onlinecheckout.blob.core.windows.net/recordings/',
  'Ocp-Apim-Subscription-Key': 'a6e965dd08024f90bef5ddc0891a2f4e',
  ipstackAPI: 'https://api.ipstack.com/check?access_key=474289dcc8cd2e457f24bbb54b949b05',
  internationalCallRateApi: 'https://online-app-international.azurewebsites.net/api/rate',
  rootApi: 'https://voiplycheckout.azurefd.net/api',

  grandstreamAPI: 'https://online-app-grandstream.azurewebsites.net/api',
  voiplyExtraLineAPI: 'https://online-app-softphone.azurewebsites.net/api',
  voiplyOnlinePaymentAPI:'https://voiply-online-payment.azurewebsites.net/api',
};
