import { OnInit, Input, Output, ViewChild, EventEmitter, ElementRef, ChangeDetectorRef, Directive } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BillingDetailService, UserService, StripeService } from '../../services';
import * as _ from 'lodash'
import { BillingDetails, Card, Stripe, Invoice, Phone } from '@voiply/shared/model';
import { ToastrService } from 'ngx-toastr';
import { Select } from '@ngxs/store';
import { AppStateSelectors, StripeDataChangedAction, ChangeBillingPlanAction } from '../../+state';
import { Observable } from 'rxjs';
import { OnDestroyCleanup } from '../../generic-utils';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { skip } from 'rxjs/operators';


@Directive()
export class AnnualBillingModalBase extends OnDestroyCleanup implements OnInit {
  @ViewChild('cardElement') cardInfo: ElementRef;
  @Input() orderId: string;
  @Input() checkoutDetail;
  cardDetail: Card;
  @Output()
  billingDetails = new BillingDetails();
  stripeDataChanged = new EventEmitter<Stripe>();
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  showCreditCard = false;
  cardChangeRequesting = false;
  customerInvoices: Invoice[];
  nextBillingDate: Date;
  loadingBillingDetails = true;
  confirmButtonClicked = false;
  cartItems;
  phones;
  @Select(AppStateSelectors.cardDetails) cardDeatils$: Observable<Card>;
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;
  @Select(AppStateSelectors.phones) phones$: Observable<Phone[]>;
  @Dispatch() onStripeDataChanged = (stripe) => new StripeDataChangedAction(stripe);

  constructor(protected billingDetailService: BillingDetailService,
    protected cd: ChangeDetectorRef, protected tostr: ToastrService, protected userService: UserService, public stripeService: StripeService) {
    super();

    this.subscriptions$.add(this.cardDeatils$.subscribe((cardDetail) => this.cardDetail = cardDetail));
    this.subscriptions$.add(this.stripeService.emitMountNewCard.subscribe(async (isCalledFromAnnualModal) => {
      await this.onCardChangeCancel();
      if (isCalledFromAnnualModal)
        this.mountStripeCard();
    }));

  }

  ngOnInit() {

    this.subscriptions$.add(this.phones$.pipe(skip(1)).subscribe(phone => {
      this.phones = phone;}));
    this.billingDetailService.annualBillingDetails(this.orderId).subscribe((data) => {
      this.billingDetails = _.mapKeys(data, (value, key) => _.camelCase(key))
      this.loadingBillingDetails = false
    });
    this.userService.getCustomerInvoices(this.orderId).subscribe(invoices => {
      this.customerInvoices = [];
      if (invoices) {
        this.customerInvoices = invoices.map(o => {
          const invoice = new Invoice()
          invoice.id = o.id;
          invoice.amount = o.amount;
          invoice.created = new Date(0); invoice.created.setUTCSeconds(o.created);
          invoice.currency = o.currency;
          invoice.number = o.number;
          invoice.pdfUrl = o.pdfUrl;
          invoice.webUrl = o.webUrl;
          return invoice;
        });
      }
      const latestInvoice = _.max(invoices, o => o.created).created;
      this.nextBillingDate = new Date(0);
      const date = new Date(0);
      date.setUTCSeconds(latestInvoice)
      if (date.getMonth() === 0) {
        this.nextBillingDate.setUTCSeconds(latestInvoice + 28 * 24 * 60 * 60);
      } else
        this.nextBillingDate.setUTCSeconds(latestInvoice + 30 * 24 * 60 * 60);


    })

    this.subscriptions$.add(this.cartItems$.subscribe(data => {
      this.cartItems = _.pickBy(data, (value, key) => !value.paid);
    }))
    
  }

  mountStripeCard() {
    if (this.card)
      return;

    this.card = elements.create('card', {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: '#d3d4d5',
          color: '#212529',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          ':-webkit-autofill': {
            color: '#212529',
          },
          '::placeholder': {
            color: '#2125298a',
          },
        },
        invalid: {
          iconColor: 'red',
          color: 'red',
        },
      },
      hidePostalCode: true
    });

    console.log('Mounting card in order summary');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);

    this.showCreditCard = true;
  }


  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit() {
    this.cardChangeRequesting = true;


    const { token, error } = await stripe.createToken(this.card);
    if (error) {
      this.tostr.error(error.message);
    } else {
      console.log('Success!', token);
      this.stripeService.changeCard(this.orderId, token).then((response) => {
        if (response.result == "success") {
          this.tostr.success('Card updated successfully.');
          this.onStripeDataChanged({
            stripeToken: token.id, card: {
              exp_month: token.card.exp_month, exp_year: token.card.exp_year,
              id: token.card.id, last4: token.card.last4, brand: token.card.brand
            }
          });

          this.cardDetail = {
            exp_month: token.card.exp_month, exp_year: token.card.exp_year,
            id: token.card.id, last4: token.card.last4, brand: token.card.brand
          };
          this.showCreditCard = false;
          this.card.removeEventListener('change', this.cardHandler);
          this.card.destroy();
          this.card = null;
        }
        this.cardChangeRequesting = false;
      })

    }
  }
  onCardChangeCancel() {
    if (this.card) {
      this.showCreditCard = false;
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
      this.card = null;
    }
  }

}
