import { Injectable, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { v4 as uuid } from 'uuid';
import { OrderService, CrispService, AudioService, RouterService } from '../services';
import { FillOrderAction, SetOrderIdAction, GetCurrentLocation, GetPhonesAndApps, AppStateSelectors, SetInitialLoadOrderDataAction, SaveMetadataAction, CalculatingTotalAction, AddPhonesToCartAction, BillingAddressChangeAction, SetSurveyBannerAction, AddAppToCartAction, UpdateShippingOptions } from '../+state';
import { Phone, PhoneConfiguration, Address, App, SystemType } from '@voiply/shared/model';
import { Select } from '@ngxs/store';
import { Observable, zip } from 'rxjs';
import { OnDestroyCleanup } from '../generic-utils';
import { IEnvironment } from '../environment.interface';
import { APPSCONST, ENVIRONMENT } from '../constants';
import * as LogRocket from 'logrocket';
import { SignalRService } from '../services/signal-r.service';
import { HeadingService } from '../services/heading.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializationGuard extends OnDestroyCleanup implements CanActivate {
  defaultCartItemAdded: boolean;
  //defaultPhone = 'Voiply Adapter';
  defaultPhone='Voiply Online';
  phoneConfiguration: PhoneConfiguration = new PhoneConfiguration();
  defaultApp = '';

  @Select(AppStateSelectors.phones) phones$: Observable<Phone[]>;
  @Select(AppStateSelectors.apps) apps$: Observable<App[]>;

  @Dispatch() saveOrderState = (orderData: any) => new FillOrderAction(orderData);
  @Dispatch() setOrderId = (orderId: string) => new SetOrderIdAction(orderId);
  @Dispatch() getCurrentLocation = () => new GetCurrentLocation();
  @Dispatch() getPhonesAndApps = () => new GetPhonesAndApps();
  @Dispatch() addAppToCart = (app: App) => new AddAppToCartAction(app);
  @Dispatch() addPhonesToCart = (phoneFeatureId: number, quantity: number, phoneConfiguration: PhoneConfiguration) => new AddPhonesToCartAction(phoneFeatureId, quantity, phoneConfiguration);
  @Dispatch() setInitialLoadOrderData = (promoCode: string, email: string, meta: any) => new SetInitialLoadOrderDataAction(promoCode, email, meta);
  @Dispatch() saveMetadata = (meta: any) => new SaveMetadataAction(meta);
  @Dispatch() calculatingTotal = (isCalculating: boolean) => new CalculatingTotalAction(isCalculating);
  @Dispatch() onBillingAddressChange = (billingAddress) => new BillingAddressChangeAction(billingAddress);
  @Dispatch() setSurveyBanner = (surveyBanner) => new SetSurveyBannerAction(surveyBanner);
  @Dispatch() updateShippingOptions = (fetchOptions: boolean) => new UpdateShippingOptions(fetchOptions);

  constructor(private location: Location, private router: RouterService,
    private orderService: OrderService,
    private crispService: CrispService,
    private audioService: AudioService,
    private signalRService: SignalRService,
    private headingService: HeadingService,
    @Inject(ENVIRONMENT) private environment: IEnvironment) {

    super();

    LogRocket.init('uyjcld/online-checkout', ({
      console: {
        shouldAggregateConsoleErrors: true
      }
    }) as any);


  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    // Process order id
    return new Promise<boolean>(async (resolve, reject) => {


      // Check order id is there or not
      let orderId = route.paramMap.get("id");
      if (!orderId || orderId === 'null') {
        // if order id not available, then generate new one
        orderId = uuid();
        const queryString = Object.keys(route.queryParams).map(key => key + '=' + route.queryParams[key]).join('&');
        // Navigate to new ID
        setTimeout(() => {
          // TODO:VINIT Temp hack to show url in browser, change to more reliable way. #Hack
          this.location.go(orderId, queryString);
        }, 10000);
      }


      // Dispatch action to fetch phones and apps data
      this.getPhonesAndApps();


      const firstPromoter_ref = route.queryParamMap.get('fp_ref');
      const CJEVENT = route.queryParamMap.get('CJEVENT');
      if (route.queryParamMap.get('d')) {
        this.defaultPhone = route.queryParamMap.get('d');
      }
      if (route.queryParamMap.get('da')) {
        this.defaultApp = route.queryParamMap.get('da');
      }
      if (route.queryParamMap.get('mac')) {
        this.phoneConfiguration.mac = route.queryParamMap.get('mac');
      }
      if (route.queryParamMap.get('serial')) {
        this.phoneConfiguration.serial = route.queryParamMap.get('serial');
      }
      if (route.queryParamMap.get('model')) {
        this.phoneConfiguration.model = route.queryParamMap.get('model');
      }
      if (route.queryParamMap.get('banner')) {
        this.setSurveyBanner(route.queryParamMap.get('banner'));
      }
      if (route.queryParamMap.get('h')) {
        this.headingService.heading = route.queryParamMap.get('h');
        await this.headingService.getHeading();
      }
      // Get object from Cosmos
      const orderData: any = await this.orderService.getOrderAsync(orderId);
      // Order found!!
      if (orderData) {
        const orderPaidAlready = (orderData.subscription || {}).paymentSuccessful || false;
        if (orderPaidAlready) {
          // if order is already paid then navigate to after checkout page
          this.router.navigate(`/${orderId}/aftercheckout`, route.queryParams);
          resolve(true)
        } else {
          //init crisp session
          this.crispService.initCrisp(orderId);
        }

        // Set data in state and let app load normally
        orderData.orderId = orderId;
        orderData.serverObjectInitialized = true;
        this.saveOrderState(orderData);
        this.updateShippingOptions(true);

        if (firstPromoter_ref) {
          const metadata = {
            ...route.queryParams,
            fp_ref: firstPromoter_ref,
            promoCode: firstPromoter_ref,
            site: location.origin
          };

          this.saveMetadata(metadata);
        }

      } else {
        // This is first time load after id generation

        //init crisp session
        this.crispService.initCrisp(orderId);
        // Order not found for ID
        this.setOrderId(orderId); // Set State

        LogRocket.startNewSession();
        LogRocket.track("order:new");
        // Dispatch action to fetch current location and save billing address
        this.getCurrentLocation();
        //Save referal code in state
        //add metadata object
        const metadata = {
          ...route.queryParams,
          fp_ref: firstPromoter_ref,
          promoCode: firstPromoter_ref,
          referrer: document.referrer,
          site: location.origin,
          systemType: route.data.systemType,
          CJEVENT: CJEVENT
        };
        this.setInitialLoadOrderData(firstPromoter_ref, route.queryParams.email, metadata);
        //Add default cart item
        this.addDefaultCartItem();
        if (route.queryParamMap.get('email')) {
          const billingAddress = new Address();
          billingAddress.email = route.queryParamMap.get('email');
          this.onBillingAddressChange(billingAddress);
        }

      }

      //logRocket trait
      LogRocket.identify(orderId, { order_from: route.data.systemType });

      if (route.data.systemType == SystemType.Business) { //We want signalr functionality only for business site.
        //signal r
        this.signalRService.startSignalR(orderId);
        this.signalRService.startConnection(orderId);
      }

      // save billing address


      return resolve(true);
    });
  }

  private addDefaultCartItem() {

    zip(this.phones$, this.apps$).subscribe(data => {
      if (data[0].length > 0 && !this.defaultCartItemAdded) {
        this.defaultCartItemAdded = true; //mark this as true so we will not add multiple default cart items
        if (this.defaultApp) {
          const app: App = data[1].filter(o => (o.title === this.defaultApp && o.isActive))[0];
          if (app)
            this.addAppToCart(app);
        }
        if (this.defaultApp !== APPSCONST.ONLINEFAX && this.defaultApp !== APPSCONST.SPAMCALLBOT && this.defaultApp !==APPSCONST.WEBROOT && this.defaultApp !== APPSCONST.IDENTITYTHEFTPRORECTION && this.defaultApp !==APPSCONST.BUREAUCREDITMONITORING &&this.defaultApp !==APPSCONST.EMERGENCY) {
          let phone: Phone = data[0].filter(o => (o.heading === this.defaultPhone && o.isActive))[0];
          if (!phone) {
            phone = data[0].filter(o => (o.isActive))[0];
          }
          const quantity = 1
          // save cart item to state
          this.addPhonesToCart(phone.featureId, quantity, this.phoneConfiguration);

          if (phone.heading === "Deluxe Phone") {
            this.crispService.setSessionEvent("sales:phone:deluxe", { "added-new-deluxe-phone": true });
            LogRocket.track("sales:phone:deluxe");
          }
        }
      }
    });

  }
}