import { Component, Input, OnInit } from '@angular/core';
import { AppStateSelectors, OnDestroyCleanup } from '@voiply/shared/common-ui';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CheckoutDetail, NumberPorting } from '@voiply/shared/model';

@Component({
  selector: 'voiply-number-porting-extraline-settings',
  templateUrl: './number-porting-extraline-settings.component.html',
  styleUrls: ['./number-porting-extraline-settings.component.scss']
})
export class NumberPortingExtralineSettingsComponent extends OnDestroyCleanup implements OnInit {
  @Select(AppStateSelectors.numberPorting) numberPorting$: Observable<NumberPorting>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
  checkoutDetail: CheckoutDetail;

  @Input() backButtonUrl;
  constructor() {
    super()
  }

  ngOnInit(): void {
    this.subscriptions$.add(this.checkoutDetail$.subscribe(data => {
      this.checkoutDetail = data;
    }))
  }
}
