import { Component } from "@angular/core";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Select } from "@ngxs/store";
import { AppStateSelectors, StripeDataChangedAction } from "@voiply/shared/common-ui";
import { Card, Stripe } from "@voiply/shared/model";
import { Observable } from "rxjs";

@Component({
  selector: 'voiply-update-credit-card-extraline-settings',
  templateUrl: './update-credit-card-extraline-settings.component.html',
  styleUrls: [ './update-credit-card-extraline-settings.component.scss' ]
})
export class UpdateCreditCardExtralineSettings {
  card: Card;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.cardDetails) cardDeatils$: Observable<Card>;
  @Dispatch() onStripeDataChanged = (stripe) => new StripeDataChangedAction(stripe);

  stripeDataChanged(stripe: Stripe) {
    this.onStripeDataChanged(stripe);
  }
}
