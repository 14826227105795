
<div class="h-100 d-flex flex-column">
  <div class="card shadow p-3 bg-white mb-3">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-start bg-dark text-white rounded p-2">
          <!-- heading -->
          <span class="pr-2 d-flex align-items-center">
            <img src="assets/images/extraline/icons/e911.svg" class="icon-white">
          </span>
          <span class="text-uppercase font-weight-bold">E911</span>
      </div>
      <div class="pt-3">
        <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
              <label class="text-muted">Name</label>
              <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required">Name is required.</div>
              </div>
          </div>
          <div class="form-group">
              <label class="text-muted">Address Line 1</label>
              <input type="text" formControlName="addressLine1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.addressLine1.errors }" />
              <div *ngIf="submitted && f.addressLine1.errors" class="invalid-feedback">
                  <div *ngIf="f.addressLine1.errors.required">Address is required.</div>
              </div>
          </div>

          <div class="form-group">
              <label class="text-muted">Address Line 2</label>
              <input type="text" formControlName="addressLine2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.addressLine2.errors }" />

          </div>

          <div class="d-flex">
            <div class="form-group mr-2 w-50">
                <label class="text-muted">City</label>
                <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                    <div *ngIf="f.city.errors.required">City is required.</div>
                </div>
            </div>
            <div class="form-group ml-2 w-50">
                <label class="text-muted">State</label>
                <div class="select-input">
                    <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
                    <select class="select-custom form-control" style="border: none !important" formControlName="state" [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                      <option value="" selected>Select State</option>
                      <option *ngFor="let state of filteredStates" [value]="state.shortCode">{{state.name}}</option>
                    </select>
                </div>
                <div *ngIf="submitted && f.state.errors" class="dropdown-error">
                    <div *ngIf="f.state.errors.required">State is required.</div>
                </div>
            </div>
          </div>

          <div class="form-group">
              <label class="text-muted">Country</label>
              <div class="select-input">
                  <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
                  <select class="select-custom form-control" style="border: none !important" formControlName="country" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                    <option selected value="">Select Country</option>
                    <option *ngFor="let country of countries" [value]="country.countryShortCode">{{country.countryName}}

                      <!-- <option value="US">U.S.A.</option>
                    <option value="CA">Canada</option> -->
                  </select>
              </div>
              <div *ngIf="submitted && f.country.errors" class="dropdown-error">
                  <div *ngIf="f.country.errors.required">Country is required.</div>
              </div>
          </div>

          <div class="form-group">
              <label class="text-muted">Zip</label>
              <input type="text" formControlName="zip" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" />
              <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                  <div *ngIf="f.zip.errors.required">Zip is required.</div>
              </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="d-flex w-100 mt-auto">
    <button type="button" class="btn bg-grey text-black rounded mr-4 w-50" (click)="back()">Back</button>
    <button type="button" class="btn bg-primary text-primary rounded ml-4 w-50" (click)="onSubmit()">Save</button>
  </div>
</div>
