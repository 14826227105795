import { Component, OnInit, ViewChild } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppStateSelectors, OnDestroyCleanup, UserService, StripeDataChangedAction, AnnualBillingModalComponent } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import { Address, CheckoutDetail, Invoice, Card, Stripe } from '@voiply/shared/model';
import * as _ from 'lodash'
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'voiply-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent extends OnDestroyCleanup implements OnInit {
  bsModalRef: BsModalRef;
  billingAddress: Address;
  checkoutDetail: CheckoutDetail;
  customerInvoices: Invoice[];
  nextBillingDate: Date;
  orderId: string;
  currentBillingDate: Date;
  card: Card;
  isPayAnually = false;
  monthlyTotalAnnualPrePay: string;
  currentBillingTotal: number;
  nextBillingTotal: number;

  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
  @Select(AppStateSelectors.monthlyTotalAnnualPrePay) monthlyTotalAnnualPrePay$: Observable<string>;
  @Select(AppStateSelectors.isPayAnually) isPayAnually$: Observable<boolean>;
  @Select(AppStateSelectors.orderedMorePhones) orderedMorePhones$: Observable<boolean>;

  @Select(AppStateSelectors.billingAddress) billingAddress$: Observable<Address>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.cardDetails) cardDeatils$: Observable<Card>;
  @Dispatch() onStripeDataChanged = (stripe) => new StripeDataChangedAction(stripe);
  constructor(private userService: UserService, private modalService: BsModalService) {
    super()
  }

  ngOnInit() {
    this.subscriptions$.add(this.billingAddress$.subscribe((billingAddress) => this.billingAddress = billingAddress));
    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => this.checkoutDetail = checkoutDetail));
    this.subscriptions$.add(this.isPayAnually$.subscribe((isPayAnually) => {
      this.isPayAnually = isPayAnually;
      this.fetchData();
    }));
    this.subscriptions$.add(this.orderedMorePhones$.subscribe((orderedMorePhones) => {
      if (orderedMorePhones)
        this.fetchData();
    }));
    this.subscriptions$.add(this.monthlyTotalAnnualPrePay$.subscribe((monthlyTotalAnnualPrePay) => this.monthlyTotalAnnualPrePay = monthlyTotalAnnualPrePay));
    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
    this.subscriptions$.add(this.cardDeatils$.subscribe((card) => this.card = card));
    this.fetchData();
  }
  fetchData() {
    if (this.orderId) {
      //Past Invoices
      this.userService.getCustomerInvoices(this.orderId).subscribe(invoices => {
        this.customerInvoices = [];
        if (invoices?.length) {
          this.customerInvoices = invoices.map(o => {
            const invoice = new Invoice()
            invoice.id = o.id;
            invoice.amount = o.amount;
            invoice.created = new Date(0); invoice.created.setUTCSeconds(o.created);
            invoice.currency = o.currency;
            invoice.number = o.number;
            invoice.pdfUrl = o.pdfUrl;
            invoice.webUrl = o.webUrl;
            invoice.plan = o.plan;
            return invoice;
          });
          //Filter out emergency999 invoice
          _.remove(this.customerInvoices, (invoice) => {
            return (invoice.plan === 'plan_GvWq7Cuhr5c8Rg' || invoice.plan === 'plan_GvWEdzflW842th')
          });

          const latestInvoice = _.max(this.customerInvoices, o => o.created);
          this.currentBillingTotal = latestInvoice.amount / 100;
          this.currentBillingDate = latestInvoice.created;

        }
      });


      //Next Invoice
      this.userService.getUpcomingInvoice(this.orderId).subscribe(response => {
        if (response) {
          this.nextBillingTotal = (response.invoice || {}).total / 100;
          this.nextBillingDate = new Date(0);
          this.nextBillingDate.setUTCSeconds(response.invoice.created);
        }
      });
    }
  }
  annualBillingComponent() {
    const initialState = {
      orderId: this.orderId,
      checkoutDetail: this.checkoutDetail
    };
    this.bsModalRef = this.modalService.show(AnnualBillingModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  stripeDataChanged(stripe: Stripe) {
    this.onStripeDataChanged(stripe);
  }
}
