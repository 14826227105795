import { Injectable, Inject, EventEmitter } from '@angular/core';
import jsonwebtoken from 'jsonwebtoken';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IEnvironment } from '../environment.interface';
import { ENVIRONMENT } from '../constants';
import { v4 as uuid } from 'uuid';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { UpdateOrderAction } from '../+state/app.actions';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  userid = uuid().replace("-", "");
  // connectionEstablished = new EventEmitter<Boolean>();
  // isConnecting = false;
  
  private _hubConnection: HubConnection;
  
  @Dispatch() updateOrderState = (orderData: any) => new UpdateOrderAction(orderData);

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) { }

  public startSignalR(orderid: string) {
    this.createConnection();
    this.registerOnServerEvents(orderid);
  }

  private createConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.environment.rootApi}/user/${this.userid}`)
      .build();
  }

  
  private registerOnServerEvents(orderId: string): void {
    this._hubConnection.on('orderDataChanged', (data) => {
      if (data.userId === this.userid)
        return;

      console.log('Data received', data);
      this.updateOrderState(data.changes);
    });
    this._hubConnection.onclose((err: Error) => {
      // this.isConnecting = false;
      console.warn('Connection lost', err);
      this.startConnection(orderId);
    });
  }

  public startConnection(orderId: string): void {
    console.log('Starting signalR connection');

    // if (this.isConnecting)
    //   return;

    // this.isConnecting = true;
    this._hubConnection
      .start()
      .then((response) => {
        console.log('Hub connection started');

        this.assignUserToGroup(orderId).subscribe(() => {
          console.log('User added to group');
          // this.isConnecting = false;
          // this.connectionEstablished.emit(true);
        });

      })
      .catch(err => {
        // this.isConnecting = false;
        console.log('SignalR Connection failed.', err);
        setTimeout(() => {
          this.startConnection(orderId);
        }, 5000);

        // this.connectionEstablished.emit(false);
      });
  }

  assignUserToGroup(groupName: string) {
    const url = `${this.environment.signalRUrl}/hubs/${this.environment.signalRHubName}/groups/${groupName}/users/${this.userid}`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.generateToken(url)}`
      })
    };
    return this.http.put(url, null, options);
  }

  generateToken(url: string) {
    return jsonwebtoken.sign({
      nameid: this.userid
    }, this.environment.signalRAccessKey, {
        algorithm: 'HS256', expiresIn: 60 * 2, notBefore: 0,
        audience: url
      });
  }
}
