import { required, email } from '@rxweb/reactive-form-validators';
import { VoiceMail } from './enums/voice-mail.enum';
import { Gender } from './enums/gender.enum';

export class CallRecording {
    @required()
    @email()
    email: string;
    recordIncoming: string;
    recordOutgoing: string;
    showRecording: boolean;
    selectedGender: Gender.Male;
    audioText: string;
    sendVoiceMailTo: VoiceMail.EmailAndPhone;
    blobFileName: string;
    fileName: string;
    voicemailRingTimeoutCount: number;
    callForwarding: boolean;
    isNewNumber: boolean;
    uploadRecording: boolean;
    callForwardingNumber: string;
}