import { OnChanges, Input, SimpleChanges, Directive } from '@angular/core';
import { TaxDetail } from '@voiply/shared/model';
import * as _ from 'lodash';

@Directive()
export class TaxAndFeesBase implements OnChanges {
    @Input()
    taxDetail: TaxDetail;
    @Input()
    payAnnually: boolean;
    @Input()
    calculatingTotal = false;

    hardwareTaxes: any[] = [];
    voipAndServiceTaxes: any[] = [];
    hardwareTaxTotal = 0.00;
    voipAndServiceTaxTotal = 0.00;

    constructor() { }
    ngOnChanges(changes: SimpleChanges) {
        const temp: any = {};
        this.hardwareTaxTotal = 0;
        this.voipAndServiceTaxTotal = 0;
        if ((this.taxDetail || temp).taxBreakup) {
            this.hardwareTaxes = _.filter(this.taxDetail.taxBreakup, item => item.hardwareTax);
            this.voipAndServiceTaxes = _.filter(this.taxDetail.taxBreakup, item => !item.hardwareTax);

            this.hardwareTaxTotal = _.reduce(_.map(this.hardwareTaxes, "tax_amount"), (lastTotal, newAmount) => lastTotal + newAmount);
            this.voipAndServiceTaxTotal = _.reduce(_.map(this.voipAndServiceTaxes, "tax_amount"), (lastTotal, newAmount) => lastTotal + newAmount);
        }
    }
}