<!--containing in - Lib: product-configuration -->
<!--used by - Component: business-configuration/business-configuration-modal, business-configuration/user-settings-business -->
<!--Added for - App: Business, user-settings -->
<div [formGroup]="form" class="cart-item-background-color">
  <div class="d-flex px-3 pt-3 flex-wrap flex-row">
    <div class="p-1 flex-fill" style="position: relative;">
      <input type="text" formControlName="firstName" class="form-control" placeholder="User Name"
        [ngClass]="{ 'is-invalid': ((f.firstName.touched || submitted) && f.firstName.errors) }">
      <div *ngIf="((f.firstName.touched || submitted) && f.firstName.errors)">
        <span *ngIf="f.firstName.errors.required">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter firstname"></i></span>
        <span *ngIf="f.firstName.errors.userName">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid firstname"> </i></span>
      </div>
      <!-- <div *ngIf="f.firstName.errors && submitted" class="text-danger">
        <div *ngIf="f.firstName.errors.required || f.firstName.errors.userName">Please enter User Name.</div>
      </div> -->
    </div>
    <div class="p-1 flex-fill">
      <input type="text" formControlName="lastName" class="form-control" placeholder="Last Name">
    </div>
    <div class="p-1 flex-fill" style="position: relative;">
      <input type="email" formControlName="email" class="form-control" placeholder="Email"
        [ngClass]="{ 'is-invalid': ((f.email.touched || submitted) && f.email.errors) }">
      <div *ngIf="((f.email.touched || submitted) && f.email.errors)">
        <span *ngIf="f.email.errors.required">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
        <span *ngIf="f.email.errors.email">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
      </div>
    </div>
  </div>
  <div class="d-flex pb-3 pr-3 pl-3 flex-wrap flex-row">
    <div class="p-1 flex-grow-1 flex-md-shrink-1">
      <div class="input-group" style="position: relative;">
        <input type="text" class="form-control" placeholder="Direct Dial" formControlName="phoneNumber"
          mask="(000) 000-0000" [readOnly]="disableInput"
          [ngClass]="{ 'is-invalid': ((f.phoneNumber.touched || submitted) && f.phoneNumber.errors) }">
        <div *ngIf="((f.phoneNumber.touched || submitted) && f.phoneNumber.errors)">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Phone Number"> </i>
        </div>
        <div class="input-group-append" *ngIf="!disableInput">
          <i class="fas fa-align-center bg-primary text-primary input-group-text clean"
            (click)="toggleNewNumber();"></i>
        </div>
      </div>
    </div>
    <div class="p-1 flex-fill">
      <div class="d-flex flex-row">
        <div class="flex-grow-1 flex-lg-shrink-1" style="position: relative;">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text bg-primary text-primary">Ext</span>
            </div>
            <input type="text" class="form-control" placeholder="101" formControlName="extension"
              [readOnly]="extDisable"
              [ngClass]="{ 'is-invalid': ((f.extension.touched || submitted) && f.extension.errors) }">
          </div>
          <div *ngIf="((f.extension.touched || submitted) && f.extension.errors)">
            <span *ngIf="f.extension.errors.required">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter extension"></i></span>
            <span *ngIf="f.extension.errors.extension">
              <i class="far fa-exclamation-circle is-error" tooltip="Please enter a 2 to 5 digit extension number.">
              </i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-1 flex-fill">
      <div class="select-input">
        <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
        <select class="select-custom" formControlName="timezone">
          <option value="" class="placeholder" selected disabled hidden>Time-Zone</option>
          <option value="est">EST</option>
          <option value="mst">MST</option>
          <option value="cst">CST</option>
          <option value="pst">PST</option>
          <option value="hst">HST</option>

        </select>
      </div>
    </div>
    <div class="p-1">
      <!-- <div class="d-none d-lg-block flex-fill">
        <button type="button" class="btn bg-primary font-weight-bold text-primary h-100 w-100" (click)="toggleConfig()">
          <ng-container *ngIf="!isHideConfiguration">
            Hide Configurations
          </ng-container>
          <ng-container *ngIf="isHideConfiguration">
            Show Configurations
          </ng-container>
        </button>
      </div> -->
      <div class="">
        <button type="button" class="btn bg-primary font-weight-bold text-primary h-100" (click)="toggleConfig()">
          <!-- <ng-container *ngIf="!isHideConfiguration"> -->
          <i *ngIf="!isHideConfiguration" class="fa fa-eject" aria-hidden="true"></i>
          <!-- </ng-container>
          <ng-container *ngIf="isHideConfiguration"> -->
          <i *ngIf="isHideConfiguration" class="fa fa-cog" aria-hidden="true"></i>
          <!-- </ng-container> -->
        </button>
      </div>
    </div>
  </div>
</div>

<!-- New Number Modal -->
<!-- <div class="modal fade" bsModal #staticModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title font-weight-bold" id="exampleModalLongTitle">Select a New Number</h3>
      </div>
      <div class="modal-body">

        <tabset [justified]="true">
          <tab heading="LOCAL NUMBERS">
            <div class="mt-4">
              <voiply-local-number-search (numberSelected)="selectedNumber = $event"></voiply-local-number-search>
            </div>
          </tab>
          <tab heading="TOLL FREE NUMBERS">
            <div class="mt-4">
              <voiply-tollfree-number-search (numberSelected)="selectedNumber = $event"></voiply-tollfree-number-search>
            </div>
          </tab>
        </tabset>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark px-4 py-2" data-dismiss="modal"
          (click)="staticModal.hide()">Cancel</button>
        <button type="button" class="btn bg-primary text-primary px-4 py-2"
          (click)="staticModal.hide();onNumberSelected();">
          Select Number
        </button>
      </div>
    </div>
  </div>
</div> -->
<ng-container *ngIf="isHideNewNumber">
  <mat-tab-group animationDuration="0ms" #tabs class="cart-item-background-color">
    <mat-tab label="LOCAL NUMBERS">
      <div class="">
        <voiply-local-number-search (numberSelected)="selectedNumber = $event"></voiply-local-number-search>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-2" data-dismiss="modal"
          (click)="toggleNewNumber()">close</button>
        <button type="button" class="btn bg-primary text-primary" (click)="toggleNewNumber();onNumberSelected();">
          Select Number
        </button>
      </div>
    </mat-tab>

    <mat-tab label="TOLL FREE NUMBERS">
      <div class="">
        <voiply-tollfree-number-search (numberSelected)="selectedNumber = $event"></voiply-tollfree-number-search>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-2" data-dismiss="modal"
          (click)="toggleNewNumber()">Close</button>
        <button type="button" class="btn bg-primary text-primary" (click)="toggleNewNumber();onNumberSelected();">
          Select Number
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-container>
