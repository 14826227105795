import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { ENVIRONMENT } from '../constants';
import { IEnvironment } from '../environment.interface';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class HeadingService extends BaseService {
    heading = 'default';
    reviews = [];
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private readonly environment: IEnvironment) {
        super();
    }
    async getHeading() {
        await this.http.get(`${this.environment.rootApi}/heading-get?heading=${this.heading}`).pipe(catchError(this.handleError)).toPromise().then((reviews) => { if (reviews) this.reviews = reviews.selectedReview })
    };
}