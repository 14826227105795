<div style="position: relative;">
  <i class="d-none d-lg-block fal fa-long-arrow-right section-arrow d-none d-lg-block" style="position: absolute;
              left: -60px;font-size: 60px; color:#D3D4D5" *ngIf="!paymentSuccessful"></i>
  <div class="d-flex flex-wrap flex-sm-nowrap">
    <div class="d-flex flex-grow-1">
      <div class="section-header-image d-flex align-items-center justify-content-center bg-primary">
        <img [src]="imagePath" alt="" height="27px">
      </div>
      <div class='section-header-title flex-grow-1 d-flex align-items-center  bg-secondary'>
        <h5 class="text-primary m-0 pl-3 font-weight-bold">{{title}}</h5>
      </div>
    </div>
    <div *ngIf="rightSideButton" class="d-flex flex-grow-1 justify-content-end bg-secondary">
      <button class="btn bg-primary text-primary px-3 my-2 mx-3" (click)="rightSideButtonClick.emit()">
        <h6 class="m-0 font-weight-bold" style="font-size: 14px;">{{rightSideButton}}</h6>
      </button>
    </div>
    <div class="flex-grow-1 d-flex align-items-center justify-content-end bg-secondary pr-3 "
      *ngIf="rightSideImagePath">
      <img [src]="rightSideImagePath" class="d-none d-sm-block" alt="" height="34px">
    </div>
    <div class="d-flex align-items-center justify-content-end bg-secondary pr-3 flex-grow-1"
      *ngIf="rightSideDropDownDefaultValue">
      <span class="text-primary text-nowrap pr-3">Select Number</span>
      <div class="select-input my-1" style="max-width: 170px;">
        <i class="fas fa-caret-down dropdown-img d-flex align-items-center text-color"></i>
        <select class="select-custom form-control  text-color" [ngModel]="rightSideDropDownDefaultValue"
          (change)="rightSideDropDownSelectedValue.emit($event.target.value)">
          <option *ngFor="let option of rightSideDropDownoptions" [value]="option">{{option|mask:'(000)000-0000'}}
          </option>
        </select>
      </div>
    </div>
    <img *ngIf="helpText" (click)="showHelpText=!showHelpText" src="assets/images/question-mark.png" style="width: 22px; position:absolute;right: -10px;
      top: -10px;">
    <div>
      <ng-container *ngTemplateOutlet="template"> </ng-container>
    </div>
  </div>
  <div class="bg-white p-4" *ngIf="showHelpText">
    {{helpText}}
  </div>

</div>