<div class="bg-primary">
  <div class="text-primary font-weight-bold inner-header ml-4">Subscription</div>
</div>
<div class="shadow">
  <div class="card px-4 py-3">
    <div class="d-flex flex-row m-0">
      <div class="flex-grow-1">Next Billing Date</div>
      <mat-spinner *ngIf="!nextBillingDate" class="float-right" diameter="22" value="accent"></mat-spinner>
      <span class="font-weight-bold">{{nextBillingDate | date}}</span>
    </div>
  </div>
  <div class="card px-4 py-3 border-top-0 border-bottom-0">
    <div class="d-flex flex-row m-0">
      <div class="flex-grow-1">Next Billing Total</div>
      <mat-spinner *ngIf="!nextBillingTotal" class="float-right" diameter="22" value="accent"></mat-spinner>
      <span class="font-weight-bold">{{nextBillingTotal | formatMoneyValue}}</span>
    </div>
  </div>
  <div class="py-2 px-4 border-left border-right" style="background-color: #F9F8F8;">
    <span class="text-color font-weight-bold">LAST TRANSACTION</span>
  </div>
  <div class="card px-4 py-3 border-top-0">
    <div class="d-flex flex-row m-0">
     <div class="flex-grow-1">{{currentBillingDate|date:'MMMM'}} Billing Date</div>
      <mat-spinner *ngIf="!currentBillingDate" class="float-right" diameter="22" value="accent"></mat-spinner>
      <span class="font-weight-bold">{{currentBillingDate | date}}</span>
    </div>
  </div>
  <div class="card px-4 py-3 border-top-0">
    <div class="d-flex flex-row mb-4">
      <div class="flex-grow-1">{{currentBillingDate|date:'MMMM'}} Billing Total</div>
      <mat-spinner *ngIf="!currentBillingTotal" class="float-right" diameter="22" value="accent"></mat-spinner>
      <span class="font-weight-bold">{{currentBillingTotal | formatMoneyValue}}</span>
    </div>
    <div *ngIf="(invoices || []).length == 0" class="m-0">No Invoices Found</div>
    <div *ngIf="(invoices || []).length > 0">
      <button class="btn bg-secondary-gradient text-primary w-100 font-weight-bold border-0 py-2" type="button"
        (click)="invoicesModal.show()">PREVIOUS INVOICES</button>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #invoicesModal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">

  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #fe330a; color: #ffffff;">
        <h3 class="text-center m-0" style="width: 100%">
          Previous Invoices
        </h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="invoicesModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h6 class="d-flex flex-row" *ngFor="let invoice of invoices">
          <div style="flex-grow: 1">
            <span class="mr-2">{{invoice.created | date:'MMM dd, yyyy' }}</span>|
            <a [href]="invoice.pdfUrl" target="_blank" style="cursor: pointer; color: #fe330a"
              class="mx-2">Download</a>|
            <a [href]="invoice.webUrl" target="_blank" style="cursor: pointer; color: #fe330a" class="mx-2">View</a>
          </div>
          <div>{{ invoice.amount /100 | formatMoneyValue}}</div>
        </h6>
      </div>
    </div>
  </div>

</div>