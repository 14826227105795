import { Component, OnInit, Input } from '@angular/core';
import { Invoice } from '@voiply/shared/model';

@Component({
  selector: 'voiply-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  @Input() invoices: Invoice[] = [];
  @Input() nextBillingDate: Date = null;
  @Input() currentBillingDate: Date = null;
  @Input() nextBillingTotal = 0.00;
  @Input() currentBillingTotal = 0.00;
  constructor() { }

  ngOnInit() {
  }

}
