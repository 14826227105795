import { Injectable, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT } from '../constants';
import { IEnvironment } from '../environment.interface';

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    changeCardApi = `${this.environment.rootApi}/card-change/{id}`;
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) { }

    emitMountNewCard = new EventEmitter<any>();
    mountNewCard(isCalledFromAnnualModal: boolean) {
        this.emitMountNewCard.emit(isCalledFromAnnualModal);
    }
    changeCard(orderId: string, stripeToken: any): Promise<any> {
        if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
            return this.http.post(`${this.changeCardApi.replace("{id}", orderId)}?dev=true`, { stripeToken: stripeToken }).toPromise();
        else
            return this.http.post(`${this.changeCardApi.replace("{id}", orderId)}`, { stripeToken: stripeToken }).toPromise();
    }

}
