import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'voiply-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {

  queryParams;
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    })
  }

  ngOnInit(): void {
  }

}
