<div class="d-flex align-items-center justify-content-between text-black p-3 rounded-bottom" style="background-color: #DFDFDF;">
  <div class="pl-2">
    <div class="m-0 font-weight-bold text-uppercase">Total to Pay</div>
  </div>
  <div class="d-flex align-items-center">

    <ng-container *ngIf="calculatingTotal">
      <mat-spinner diameter="24" value="accent" class="float-right"></mat-spinner>
    </ng-container>
    <ng-container *ngIf="!calculatingTotal">
      <div class="mr-3">
        <div class="" *ngIf="checkoutDetail.orderTotal != checkoutDetail.withoutDiscountTotal"
          style="text-decoration: line-through; margin-bottom: 0" class="text-color">
          {{checkoutDetail.withoutDiscountTotal | formatMoneyValue}}
        </div>
      </div>

      <div class="m-0 font-weight-bold">
        {{checkoutDetail.orderTotal | formatMoneyValue}}
      </div>

    </ng-container>

  </div>
</div>
