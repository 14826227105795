import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { CallRecordingFormControlBase } from '../call-recording-form-control.base';

@Component({
  selector: 'voiply-call-recording-form-control',
  templateUrl: './call-recording-form-control.component.html',
  styleUrls: ['./call-recording-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CallRecordingFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CallRecordingFormControlComponent),
      multi: true
    }
  ]
})
export class CallRecordingFormControlComponent extends CallRecordingFormControlBase {
  constructor() {
    super();
  }
}
