<div [formGroup]="form" class="pb-3 border-bottom">
  <div class=" mb-3">
    <div class="input-group rounded overflow-auto">
      <input type="text" class="form-control border-0 bg-light-grey" style="box-shadow: none !important;"
        placeholder="Email" formControlName="email"
        [ngClass]="{ 'is-invalid': ((f.email.touched || submitted) && f.email.errors) }">
      <div *ngIf="((f.email.touched || submitted) && f.email.errors)">
        <span *ngIf="f.email.errors.required">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
        <span *ngIf="f.email.errors.email">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
      </div>

    </div>
  </div>
  <mat-slide-toggle class="example-margin" [color]="color" formControlName="attachVoicemail">
    Attach Voicemail in Emails
  </mat-slide-toggle>
</div>
<div class="mb-3"></div>