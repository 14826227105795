import { Component, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { BillingBase } from '../billing.base';
import { FormControl, FormGroup } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'checkout-billing-business',
  templateUrl: './billing-business.component.html',
  styleUrls: ['./billing-business.component.scss']
})
export class BillingBusinessComponent extends BillingBase {
  
  private _companyName: string;
  @Input()
  set companyName(value: string) {
    this._companyName = value;
    this.form.patchValue({ companyName: this._companyName });
    setTimeout(() => {
      this.form.updateValueAndValidity();      // Temp hack as initial value is not getting validated
    }, 1);
  }

  @Output()
  companyNameChange = new EventEmitter<string>();
  
  constructor() {
    super();
  }

  public getForm(): FormGroup {
    const companyName = new FormControl('', { validators: [RxwebValidators.required()], updateOn: 'blur' });

    this.subscriptions$.add(companyName.valueChanges.subscribe((changes) => {
      if (this._companyName !== changes) {
        this.companyNameChange.emit(changes);
        
        this._companyName = changes;
      }
    }));

    return new FormGroup({
      companyName: companyName,
      ...this.getCommonFormControls()
    });
  }
}
