import { NumberSearchService } from './number-search.service';
import { SetupInternationalNumberService } from './setup-international-number.service';

export const SERVICES = [
    NumberSearchService,
    SetupInternationalNumberService
];

export { NumberSearchService } from './number-search.service';
export { SetupInternationalNumberService } from './setup-international-number.service';
