<div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center bg-dark text-white rounded p-2">
        <div class="d-flex align-items-center">
            <!-- heading -->
            <span class="pr-2 d-flex align-items-center">
                <img src="assets/images/extraline/icons/credit-card.svg" class="icon-white">
            </span>
            <span class="text-uppercase font-weight-bold">Update Credit Card</span>
        </div>
        <i class="far fa-pencil-alt pr-2" (click)="this.stripeService.mountNewCard(false)"></i>
    </div>
    <div class="pt-3">
        <div *ngIf="!showCreditCard">
            <span>Payment method: <span class="font-weight-bold">CREDIT CARD</span></span>
        </div>
        <div *ngIf="cardDetail && !showCreditCard">
            <ng-container [ngSwitch]="cardDetail.brand">
                <i *ngSwitchCase="'Visa'" class="fab fa-cc-visa"></i>
                <i *ngSwitchCase="'MasterCard'" class="fab fa-cc-mastercard"></i>
                <i *ngSwitchCase="'JCB'" class="fab fa-cc-jcb"></i>
                <i *ngSwitchCase="'Discover'" class="fab fa-cc-discover"></i>
                <i *ngSwitchCase="'Diners Club'" class="fab fa-cc-diners-club"></i>
                <i *ngSwitchCase="'American Express'" class="fab fa-cc-amex"></i>
                <i *ngSwitchDefault class="fab fa-cc-stripe"></i>
            </ng-container>
            Card ending with <span class="font-weight-bold">{{cardDetail.last4}}</span> expires on <span class="font-weight-bold">{{cardDetail.exp_month}}/{{cardDetail.exp_year}}</span>
        </div>
        <form (ngSubmit)="onSubmit()" class="d-flex flex-column align-items-center">
            <div #cardElement [hidden]="!showCreditCard" class="input bg-light-grey rounded p-3 w-100"></div>
            <div class="d-flex pt-3 w-100">
                <button class="btn bg-light-grey text-black rounded flex-grow-1" *ngIf="showCreditCard" (click)="onCardChangeCancel()">
                <span>Cancel</span>
              </button>
                <button class="btn bg-primary text-primary rounded ml-2 flex-grow-1" *ngIf="showCreditCard" type="submit">
                <span *ngIf="!cardChangeRequesting">Submit</span>
                <mat-spinner *ngIf="cardChangeRequesting" diameter="26" value="accent" class="white-spinner"></mat-spinner>
              </button>
            </div>
        </form>
    </div>
</div>
