import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APPSCONST, AppStateSelectors, CrispService, ENVIRONMENT, IEnvironment, OnDestroyCleanup } from '@voiply/shared/common-ui';
import { CheckoutDetail, ExtralineScreen, SystemType } from '@voiply/shared/model';
import { Location } from '@angular/common';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import * as LogRocket from 'logrocket';
import { CommonService } from '../../services/common.service';
import packageInfo from '../../../../../package.json'
@Component({
  selector: 'voiply-root-page',
  templateUrl: './root-page.component.html',
  styleUrls: ['./root-page.component.scss']
})
export class RootPageComponent extends OnDestroyCleanup implements OnInit {
  currentScreen: ExtralineScreen;
  extraLineScreen: typeof ExtralineScreen = ExtralineScreen;
  SystemType: typeof SystemType = SystemType;
  showNumberPorting = false;
  showSpamCallBot = false;
  showCallRecording = false;
  showFax = false;
  showe999 = false;
  user;
  userId;
  queryParams;
  systemType;
  primaryNumber;
  orderId;
  version;
  get nativeWindow(): any {
    return window;
  }
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
  @Select(AppStateSelectors.isPayAnually) isPayAnually$: Observable<boolean>;
  @Select(AppStateSelectors.metadata) metadata$: Observable<any>;
  @Select(AppStateSelectors.primaryPhone) primaryPhone$: Observable<any>;
  @Select(AppStateSelectors.primaryPhoneNumber) primaryPhoneNumber$: Observable<any>;
  @Select(AppStateSelectors.cartHasHardwareAfterCheckout) hasHardware$: Observable<boolean>;

  totalCartItems=0;
  constructor(private activatedRoute: ActivatedRoute, private location: Location, private commonService: CommonService,private crispService: CrispService) {
    super();

    this.version=packageInfo.version;
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;

      if (commonService.initialload) {
        commonService.initialload = false;
        LogRocket.startNewSession();
        LogRocket.track("order:settings");
        //logRocket trait
        LogRocket.identify(this.queryParams.o, { order_from: this.systemType });
      }
    })
    this.subscriptions$.add(this.metadata$.subscribe((metadata) => {
      this.systemType = metadata.systemType
      localStorage.setItem("extralineDevice", metadata.device);
    }));
    this.subscriptions$.add(this.primaryPhone$.subscribe((primaryPhone) => {
      this.primaryNumber = primaryPhone;
      this.showNumberPorting = !(primaryPhone.isNew || primaryPhone.systemSelected);
    }));
    this.subscriptions$.add(this.cartItems$.subscribe((cartItems) => {
      this.totalCartItems=Object.keys(cartItems).length;
      this.showSpamCallBot = _.some(cartItems, ['heading', APPSCONST.SPAMCALLBOT]);
      this.showFax = _.some(cartItems, ['heading', APPSCONST.ONLINEFAX]);
      this.showCallRecording = _.some(cartItems, ['heading', APPSCONST.CALLRECORDING]);
      this.showe999 = _.some(cartItems, ['heading', APPSCONST.EMERGENCY]);

    }));
    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
  }
  ngOnInit(): void {
    this.crispService.initCrisp(this.orderId);
    setTimeout(() => {
      this.nativeWindow.$crisp.push(["set", "message:text", [""]])
      this.nativeWindow.$crisp.push(["do", "chat:open"]);
    }, 1000)

  }
  changeUrl() {
    this.location.replaceState(`/${this.activatedRoute.snapshot.params.id}?screen=${this.currentScreen}`);
  }
  cancelOrderUrl() {
    this.nativeWindow.$crisp.push(["set", "message:text", ["I would like to cancel my ExtraLine app"]])
    this.nativeWindow.$crisp.push(['do', 'chat:open']);
    this.nativeWindow.$crisp.push(['do', 'chat:show']);
    this.nativeWindow.$crisp.push(["on", "chat:closed", () => {
      this.nativeWindow.$crisp.push(['do', 'chat:hide']);
    }])
    //return `https://voiply.typeform.com/to/Gwe2T079#organization_id=${this.queryParams.o}&number=${this.primaryNumber.number}`;
  }

  openCrisp() {
    this.nativeWindow.$crisp.push(["set", "message:text", [""]])
    this.nativeWindow.$crisp.push(['do', 'chat:open']);
    this.nativeWindow.$crisp.push(['do', 'chat:show']);
    this.nativeWindow.$crisp.push(["on", "chat:closed", () => {
      this.nativeWindow.$crisp.push(['do', 'chat:hide']);
    }])
  }
}
