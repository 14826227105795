import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiModule } from '@voiply/shared/common-ui';

// import { GUARDS } from './guards';
import { CONTAINERS } from './container';
// import { AuthService } from './services/auth.service';

@NgModule({
  imports: [
    CommonModule,
    CommonUiModule
  ],
  declarations: [
    ...CONTAINERS,
  ],
  exports: [
    ...CONTAINERS
  ]
  // providers: [
  //   ...GUARDS,
  //   AuthService
  // ]
})
export class Auth0Module { }
