import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'extraline-settings-number-porting',
  templateUrl: 'number-porting.component.html',
  styleUrls: ['./number-porting.component.scss']
})
export class NumberPortingComponent {
  queryParams;
  constructor(private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
          this.queryParams = params;
      })
  }
}
