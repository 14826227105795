<div class="modal-header p-4">
  <div class="d-flex flex-row align-items-center justify-content-between flex-grow-1">
    <div class="d-flex align-items-center">
      <i class="fas fa-cart-plus mr-3" style="font-size: 22px;"></i>
      <h4 class="m-0 font-weight-bold text-primary">Add More Phones</h4>
    </div>
    <button type="button" class=" btn p-0" (click)="bsModalRef.hide(); this.unMountCard=true">
      <i class="fas fa-times-circle fa-lg text-primary"></i>
    </button>
  </div>
</div>

<div>

  <common-ui-change-stripe-card [orderId]="orderId" [unMountCard]="unMountCard"
    (paymentMethodChange)="this.paymentMethodId = $event" [checkoutDetail]="this.shippingService.checkoutDetail">
  </common-ui-change-stripe-card>

  <div class="p-4 border-top-0 border-bottom d-flex flex-column flex-lg-row" style=" background-color: #F9F9F9;">
    <section style="min-width: 48%;">
      <div *ngIf="showBillingAddressInput">
        <checkout-billing-business [billingAddress]="billingAddress"
          [companyName]="shippingService.checkoutDetail.companyName" (companyNameChange)="this.newCompanyName=$event"
          (billingAddressChange)="this.newBillingAddress=$event">
        </checkout-billing-business>

        <div class="d-flex flex-row justify-content-end mt-2">
          <button (click)="showBillingAddressInput=false" class="bg-primary text-primary btn mr-2">Cancel</button>
          <button (click)="newBillingAddressChange()" class="bg-primary text-primary btn">Save Changes</button>
        </div>
      </div>
      <div *ngIf="!showBillingAddressInput">
        <div class="bg-primary d-flex flex-row justify-content-between inner-header px-4">
          <div class="text-primary font-weight-bold">Billing address</div>
          <button class="btn rounded-0 text-primary p-0 cursor-pointer"
            (click)="showBillingAddressInput=!showBillingAddressInput">
            <div class="bg-white rounded-circle d-flex align-items-center text-black text-secondary"
              style="width: 21px; margin:auto;">
              <i class="fas fa-pencil-alt" style="font-size: 11px; padding:5px;"></i>
            </div>
          </button>
        </div>
        <div class="shadow">
          <div class="card px-4 py-3" *ngIf="shippingService.checkoutDetail.billingAddress; let billingAddress">
            <h6 class="m-0">
              <span
                *ngIf="!shippingService.checkoutDetail.companyName">{{shippingService.checkoutDetail.companyName}}</span>
              <span *ngIf="shippingService.checkoutDetail.companyName">{{billingAddress.name}}</span>
            </h6>
            <div *ngIf="!shippingService.checkoutDetail.companyName">{{billingAddress.name}}</div>
            <div>{{billingAddress.address}}</div>
            <div>{{billingAddress.city}} {{billingAddress.state}} {{billingAddress.zip}}</div>
            <div>{{billingAddress.phone}}</div>
            <div>{{billingAddress.email}}</div>
            <div *ngIf="billingAddress.country==='CA'">Canada</div>
            <div *ngIf="billingAddress.country==='US'">USA</div>
          </div>
        </div>
      </div>
    </section>
    <section class="m-2 align-self-center">
      <i class="far fa-chevron-double-right fa-lg text-secondary d-none d-lg-block"></i>
      <i class="far fa-chevron-double-down fa-lg text-secondary d-lg-none"></i>
    </section>
    <section class="d-flex flex-column" style="min-width: 48%;">
      <div class="d-flex flex-column flex-grow-1">
        <div class="bg-primary d-flex flex-row justify-content-between inner-header px-4">
          <div class="text-primary font-weight-bold">Shipping address</div>
          <button class="btn rounded-0 text-primary p-0 cursor-pointer"
            (click)="showShippingAddressInput=!showShippingAddressInput" *ngIf="!showShippingAddressInput">
            <div class="bg-white rounded-circle d-flex align-items-center text-black text-secondary"
              style="width: 21px; margin:auto;">
              <i class="fas fa-pencil-alt" style="font-size: 11px; padding:5px;"></i>
            </div>
          </button>
        </div>
        <div class="card px-4 py-3 shadow flex-grow-1"
          *ngIf="!showShippingAddressInput&&shippingService.checkoutDetail.shippingAddress; let shippingAddress">
          <div *ngIf="!shippingService.checkoutDetail.companyName">{{shippingAddress.name}}</div>
          <div>{{shippingAddress.address}}</div>
          <div>{{shippingAddress.city}} {{shippingAddress.state}} {{shippingAddress.zip}}</div>
          <div>{{shippingAddress.phone}}</div>
          <div>{{shippingAddress.email}}</div>
          <div *ngIf="shippingAddress.country==='CA'">Canada</div>
          <div *ngIf="shippingAddress.country==='US'">USA</div>
        </div>
        <div *ngIf="showShippingAddressInput">
          <checkout-shipping-address [shippingAddress]="shippingAddress"
            (shippingAddressChange)="this.newShippingAddress=$event;">
          </checkout-shipping-address>
          <div class="d-flex flex-row justify-content-end mt-2">
            <button (click)="showShippingAddressInput=false" class="bg-primary text-primary btn mr-2">Cancel</button>
            <button (click)="newShippingAddressChange()" class="bg-primary text-primary btn">Save Changes</button>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="card py-3 px-4 d-flex flex-column flex-lg-row   border-bottom border-top-0">
    <section class="d-flex flex-column" style="min-width: 48%;">
      <div class="h6 header" style="background-color: #5D5D5D;">
        <i class="fas fa-phone-office"></i>
        <span class="ml-2
        ">Select Phones </span>
      </div>
      <div class="d-flex flex-column flex-grow-1" *ngIf="this.activePhones.length">
        <div class="d-flex flex-row p-3" style="background-color: #F9F9F9;">
          <div class="btn-group mr-3 flex-fill" dropdown>
            <button
              class="btn border w-100 text-center py-2 px-2 mb-0 font-weight-bold  dropdown-toggle d-flex justify-content-between align-items-center bg-white"
              type="button" dropdownToggle type="button" aria-controls="dropdown-basic" id="dropdown-context-lable">
              {{this.selectedPhone.heading}}
            </button>

            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <ng-container *ngFor="let phone of activePhones">
                <li role="menuitem"><button class="dropdown-item px-3"
                    *ngIf="phoneAvailableInCountry(phone)&&!phone.outOfStock" (click)="phoneChange(phone)">
                    {{phone.heading}}
                  </button>
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="btn-group mr-3" style="width:50px;" dropdown>
            <button
              class="btn w-100 border text-center py-2 px-2 mb-0 font-weight-bold flex-fill dropdown-toggle d-flex justify-content-between align-items-center bg-white"
              type="button" dropdownToggle type="button" aria-controls="dropdown-basic" id="dropdown-context-lable">
              {{phoneQuantity || 1 }}
            </button>

            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" style="width:50px;" role="menu"
              aria-labelledby="button-basic">
              <ng-container *ngFor="let number of [1,2,3,4,5,6,7,8,9,10]">
                <li role="menuitem"><button class="dropdown-item px-3 " (click)="this.phoneQuantity=number">
                    {{number}}
                  </button>
                </li>
              </ng-container>
            </ul>
          </div>
          <button class="bg-primary px-3 text-primary btn" (click)="onAddPhoneToCart()">Add</button>
        </div>
        <div class="text-center bg-primary-gradient text-primary w-100 px-4 py-1">PHONE FEATURES</div>
        <div class="d-flex flex-row p-2 align-items-center flex-wrap flex-md-nowrap">
          <div class="mr-1 d-flex align-items-center justify-content-center h-100 w-100"
            style="background-color: #E9E9E9;" *ngIf="_rerenderVideo">
            <!-- <voiply-wistia-player [videoId]="this.selectedPhone.featureVideo" *ngIf="this.selectedPhone.featureVideo"
              style="left:0;top:0;width:100%;">
            </voiply-wistia-player> -->
            
            <div class="flex-fill "*ngIf="this.selectedPhone.featureVideo && this.selectedPhone.featureId==4" style="padding:56.25% 0 0 0;position:relative;">
              <iframe src="https://player.vimeo.com/video/571628030?h=1ab76e5f14&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Voiply Extra Line - V2 30secs.mp4"></iframe>
            </div>
            <div class="flex-fill " *ngIf="this.selectedPhone.featureVideo && this.selectedPhone.featureId==5" style="padding:56.25% 0 0 0;position:relative;">
              <iframe src="https://player.vimeo.com/video/612585715?h=c1ceff5e03&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Voiply Adapter"></iframe>
            </div>
            <div class="flex-fill " *ngIf="this.selectedPhone.featureVideo && this.selectedPhone.featureId==6" style="padding:56.25% 0 0 0;position:relative;">
              <iframe src="https://player.vimeo.com/video/566174266?h=bd95fb8873&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Wi-Fi Desk Phone"></iframe>
            </div>
            <div class="flex-fill " *ngIf="this.selectedPhone.featureVideo && this.selectedPhone.featureId==11" style="padding:56.25% 0 0 0;position:relative;">
              <iframe src="https://player.vimeo.com/video/571628030?h=1ab76e5f14&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Voiply Extra Line - V2 30secs.mp4"></iframe>
            </div>

            <img [src]="this.selectedPhone.image" *ngIf="!this.selectedPhone.featureVideo" alt="" width="70%">
          </div>
          <div class="d-flex flex-column flex-fill mt-2 mt-md-0" style="width: 180px;">
            <div class="bg-grey-gradient p-2 text-truncate feature-margin"
              *ngFor="let feature of this.selectedPhone.features"
              style="border-left: 4px solid #7B7B7B; color: #6B6B6B; font-size: 12px;">{{feature.name.toUpperCase()}}
            </div>
          </div>
        </div>
      </div>

    </section>
    <section class="m-2 align-self-center">
      <i class="far fa-chevron-double-right fa-lg text-secondary d-none d-lg-block"></i>
      <i class="far fa-chevron-double-down fa-lg text-secondary d-lg-none"></i>
    </section>
    <section class="flex-fill d-flex flex-column h-100" style="min-width: 48%;">
      <div class="bg-primary-gradient header h6">
        <i class="fas fa-shopping-cart"></i>
        <span class="ml-2">Cart Summary</span>
      </div>
      <div>
        <ul class="list-group list-group-flush  border-bottom">
          <li class="list-group-item p-3" *ngIf="groupedCartItems.length == 0" style="background-color: #F9F9F9;">
            <div class="totalrat rat-detail">
              <div class="total-left">
                <h6 class="m-0">YOUR CART ITEM IS EMPTY</h6>
              </div>
            </div>
          </li>

          <li class="list-group-item p-3" style="background-color: #F9F9F9;" *ngFor="let cartItem of groupedCartItems">
            <div class="d-flex flex-row align-items-center">
              <div class="phone-image d-flex align-items-center justify-content-center position-relative"
                style="background-color:#e4e5e7;">
                <img [src]="getItemImage(cartItem.itemId, cartItem.type)" alt="" style="height: 42px; width: 42px;">
                <i class="far fa-times-circle  position-absolute cursor-pointer text-secondary"
                  (click)="onRemoveCartItem(cartItem)" style="top:0;left:0;font-size:12px"></i>
              </div>
              <div class="flex-fill pl-3 d-flex flex-column">
                <span class="font-weight-bold" style="font-size: 14px;">{{cartItem.qty}}x
                  {{cartItem.heading}}</span>
                <span *ngIf="cartItem.monthlyCharge > 0" class="text-muted m-0" style="font-size: 75% !important"
                  [innerHTML]="cartItem.monthlyTotalCostText">
                </span>
              </div>
              <div class="text-secondary">
                <div class="font-weight-bold m-0" *ngIf="!cartItem.phoneUnavailable">
                  <span *ngIf="cartItem.totalDiscountedCost !== cartItem.totalCost && cartItem.totalCost != 'Free'"
                    style="font-size: 50%; text-decoration: line-through; float: right; margin-top: -15px">
                    {{cartItem.totalCost | formatMoneyValue}}
                  </span>
                  {{cartItem.totalDiscountedCost | formatMoneyValue}}
                </div>
                <div class="font-weight-bold m-0 text-right" *ngIf="cartItem.phoneUnavailable">
                  NOT AVAILABLE
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="px-3 py-2 border-bottom" style="background-color: #F9F9F9;">
        <checkout-tax-and-fees-after-checkout [taxDetail]="taxDetail"
          [payAnnually]="shippingService.checkoutDetail.payAnnually" [calculatingTotal]="calculatingTotal">
        </checkout-tax-and-fees-after-checkout>
      </div>
      <div class="px-3 py-2 border-bottom" style="background-color: #F9F9F9;" *ngIf="hasCartItems">
        <checkout-sub-total-after-checkout [calculatingTotal]="calculatingTotal"
          [checkoutDetail]="shippingService.checkoutDetail">
        </checkout-sub-total-after-checkout>
      </div>
      <div class="px-3 py-2 border-bottom" style="background-color: #F9F9F9;"
        *ngIf="hasCartItems && cartHasHardware && shippingService.shippingOptions.length > 0">
        <checkout-shipping-options-after-checkout [checkoutDetail]="shippingService.checkoutDetail"
          [calculatingTotal]="calculatingTotal" (shippingChange)="shippingChange($event)">
        </checkout-shipping-options-after-checkout>
      </div>
      <div>
        <checkout-cart-total-after-checkout [calculatingTotal]="calculatingTotal"
          [checkoutDetail]="shippingService.checkoutDetail"></checkout-cart-total-after-checkout>
      </div>
    </section>
  </div>

  <div
    class="card p-4 d-flex flex-row justify-content-between align-items-center border-top-0 flex-wrap flex-md-nowrap">
    <div style="color: #959595;" class="mr-3">
      New Devices will be added immediately. You can Configure them after placing the order
    </div>
    <div class="w-100 mt-2 mt-md-0" style="max-width: 270px;">
      <button [disabled]="calculatingTotal ||PayNowClicked ||shippingService.checkoutDetail.orderTotal<=0"
        class="btn bg-primary-gradient text-primary w-100 p-4 font-weight-bold d-flex align-items-center justify-content-center"
        (click)="onPayNow()">PAY NOW <mat-spinner *ngIf="PayNowClicked" class="d-inline-block white-spinner ml-3"
          diameter="22" value="accent">
        </mat-spinner>
      </button>
    </div>
  </div>

</div>

<div class="footer">
  <img src="assets/images/secure-payment-alt.png" alt="" class="footer-image">
  <img src="assets/images/stripe.png" alt="" class="footer-image">
  <img src="assets/images/cards.png" alt="" class="footer-image">
</div>