import { Directive, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Directive()
export class OnDestroyCleanup implements OnDestroy {
	public subscriptions$: Subscription = new Subscription();

	ngOnDestroy(): void {
		this.subscriptions$.unsubscribe();
	}
}
