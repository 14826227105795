import { CallScenarioActionTypeModel } from "./enums";
import { CallScenarioRingUserModel } from "./call-scenario-ringUser-model";
import { CallScenarioVoicemailModel } from "./call-scenario-voicemail-model";
import { CallScenarioCallForwardingModel } from "./call-scenario-callForward-model";

export class ActionModel{
    selectedAction: CallScenarioActionTypeModel = CallScenarioActionTypeModel.RingUser;
    ringUser: CallScenarioRingUserModel = new CallScenarioRingUserModel();
    voiceMail: CallScenarioVoicemailModel = null;
    callForwarding: CallScenarioCallForwardingModel = null;
    virtualReceptionist:string=null;
}