import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ProductConfigurationBase } from '../product-configuration.base';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import {
  PhoneConfiguration,
  SystemType,
  CartItemType,
  ExtralineScreen
} from '@voiply/shared/model';
import { BusinessConfigurationModalComponent } from '../../components';
import { Select } from '@ngxs/store';
import { APPSCONST, AppStateSelectors, RouterService } from '@voiply/shared/common-ui';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'voiply-product-configuration-extraline-settings',
  templateUrl: './product-configuration-extraline-settings.component.html',
  styleUrls: ['./product-configuration-extraline-settings.component.scss']
})
export class ProductConfigurationExtralineSettingsComponent
  extends ProductConfigurationBase
  implements OnInit {
  @Input()
  displayFormControl: ExtralineScreen;
  @Input()
  systemType: SystemType;
  @Input()
  backButtonUrl: string;
  key: string;
  value: any;
  constructor(toastr: ToastrService, modalService: BsModalService, private route: ActivatedRoute, private router: Router) {
    super(toastr, modalService);
  }
  addAndUpdateProductConfiguration() {
    this.subscriptions$.add(
      this.productConfigurations$.subscribe(data => {
        this._productconfigutrations = data;
        this._productConfigurationLength = Object.keys(
          this._productconfigutrations
        ).length;
        // loop through all cart items
        _.each(this._productconfigutrations, (value, key) => {
          if (value.type === CartItemType.Phone)
            this.phonesconfiguration[key] = value;
          else if (value.type === CartItemType.App)
            this.appsconfiguration[key] = value;
        });
        this._phonesconfigurationLength = Object.keys(
          this.phonesconfiguration
        ).length;
        this._appsconfigurationLength = Object.keys(
          this.appsconfiguration
        ).length;

        if (this.displayFormControl === ExtralineScreen.VoiceMail) {
          const userId = this.route.snapshot.queryParams.u;
          _.each(this._productconfigutrations, (values, keys) => {
            if (values.type === CartItemType.Phone && values.configuration.userId === userId) {
              this.key = keys;
              this.value = values;
              return;
            }
          });
        }

        if (this.displayFormControl === ExtralineScreen.CallForwarding) {
          const userId = this.route.snapshot.queryParams.u;
          _.each(this._productconfigutrations, (values, keys) => {
            if (values.type === CartItemType.Phone && values.configuration.userId === userId) {
              this.key = keys;
              this.value = values;
              return;
            }
          });
        }

        if (this.displayFormControl === ExtralineScreen.SpamBot) {
          _.each(this._productconfigutrations, (values, keys) => {
            if (values.heading === APPSCONST.SPAMCALLBOT) {
              this.key = keys;
              this.value = values;
              return;
            }
          });
        }

        if (this.displayFormControl === ExtralineScreen.Fax) {
          _.each(this._productconfigutrations, (values, keys) => {
            if (values.heading === APPSCONST.ONLINEFAX) {
              this.key = keys;
              this.value = values;
              return;
            }
          });
        }

        if (this.displayFormControl === ExtralineScreen.CallRecording) {
          _.each(this._productconfigutrations, (values, keys) => {
            if (values.heading === APPSCONST.CALLRECORDING) {
              this.key = keys;
              this.value = values;
              return;
            }
          });
        }
        if (this.displayFormControl === ExtralineScreen.Emergency999) {
          _.each(this._productconfigutrations, (values, keys) => {
            if (values.heading === APPSCONST.EMERGENCY) {
              this.key = keys;
              this.value = values;
              return;
            }
          });
        }
      })
    );
  }
  onSaveClicked() {
    this.router.navigateByUrl(this.backButtonUrl)
  }
}
