<!-- <div class="card p-3 bg-white">
    <ng-container>
        <ng-container *ngTemplateOutlet="navBar"> </ng-container>
        <div class="btn btn-primary w-100 text-center my-2 rounded-lg"><span class="py-2">CHAT WITH SUPPORT</span></div>
    </ng-container>
    <voiply-order-status-extraline-settings *ngIf="currentScreen == extraLineScreen.OrderStatus"></voiply-order-status-extraline-settings>
    <voiply-call-logs-extraline-settings *ngIf="currentScreen == extraLineScreen.CallLogs"></voiply-call-logs-extraline-settings>
    <checkout-add-more-phones-extraline-settings [orderId]="orderId$ | async" *ngIf="currentScreen == extraLineScreen.AddMorePhone"></checkout-add-more-phones-extraline-settings>
</div> -->

<div class="h-100 d-flex flex-column">
    <div class="card p-3 bg-white extraline-settings">
        <mat-tab-group #tabs>
            <mat-tab label="ACCOUNT">
                <!-- navigation item: callforwarding-->
                <div class="navigation-section-item btn" routerLink="call-forwarding"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/CallFowarding.png" class="image-height-width">
                        </span>
                        <span>Call Forwarding</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: voicemail-->
                <div class="navigation-section-item btn" routerLink="voicemail"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/Voicemail.png" class="image-height-width">
                        </span>
                        <span>Voicemail</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: caller ID-->
                <div class="navigation-section-item btn" routerLink="caller-id"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/CallerID.png" class="image-height-width">
                        </span>
                        <span>Caller ID</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: E911 -->
                <div *ngIf="totalCartItems<=2" class="navigation-section-item btn" routerLink="e911"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/E911.png" class="image-height-width">
                        </span>
                        <span>E911</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: Number Porting -->
                <div class="navigation-section-item btn" routerLink="number-porting" *ngIf="showNumberPorting && totalCartItems<=2"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/number-porting.svg" class="image-height-width">
                        </span>
                        <span>Number Porting</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: Order Status -->
                <div class="navigation-section-item btn" routerLink="order-status" *ngIf="(hasHardware$ | async) && totalCartItems<=2"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/order-status.svg" class="image-height-width">
                        </span>
                        <span>Order Status</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: SpamCallBot-->
                <div class="navigation-section-item btn" routerLink="spam-call-bot" *ngIf="showSpamCallBot && totalCartItems<=2"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/spam-bot.svg" class="image-height-width">
                        </span>
                        <span>Spam Bot</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: Fax-->
                <div class="navigation-section-item btn" routerLink="online-fax" *ngIf="showFax"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/fax.svg" class="image-height-width">
                        </span>
                        <span>Fax</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: Call Recording-->
                <div class="navigation-section-item btn" routerLink="e999" *ngIf="showe999"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/e999.svg" class="image-height-width">
                        </span>
                        <span>Emergency 999</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: Call Recording-->
                <div class="navigation-section-item btn" routerLink="call-recording" *ngIf="showCallRecording"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/call-recording.svg" class="image-height-width">
                        </span>
                        <span>Call Recording</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
            </mat-tab>
            <mat-tab label="BILLING" *ngIf="totalCartItems<=2">
                <!-- navigation item: update credit card -->
                <div class="navigation-section-item btn" routerLink="/update-creditcard"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/UpdateCC.svg" class="image-height-width">
                        </span>
                        <span>Update Credit Card</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: view invoices -->
                <div class="navigation-section-item btn" routerLink="/view-invoices"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/ViewInvoices.svg" class="image-height-width">
                        </span>
                        <span>View Invoices</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: upgrade to annual -->
                <div class="navigation-section-item btn" routerLink="/upgrade-to-annual"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}" *ngIf="!(isPayAnually$ | async)">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/UptoAnnual.svg" class="image-height-width">
                        </span>
                        <span>Upgrade to Annual</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: upgrade to residential -->
                <div class="navigation-section-item btn" routerLink="/upgrade-to-home"
                    *ngIf="systemType===SystemType.App" [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/UptoResidential.svg" class="image-height-width">
                        </span>
                        <span>Upgrade to Residential</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: upgrade to business -->
                <div class="navigation-section-item btn" routerLink="/upgrade-to-business"
                    *ngIf="systemType===SystemType.App" [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/UptoBusiness.svg" class="image-height-width">
                        </span>
                        <span>Upgrade to Business</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: Mobile Data Plan -->
                <div class="d-none navigation-section-item btn" routerLink="/data-plans"
                    [queryParams]="{o: this.queryParams.o, u: this.queryParams.u}">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/DataUsage.svg" class="image-height-width">
                        </span>
                        <span class="text-secondary" >Mobile Data Plan</span>
                    </div>
                    <i class="far fa-chevron-right text-secondary"></i>
                </div>
                <!-- navigation item: Cancel Account -->
                <div class="navigation-section-item btn">
                    <div class="d-flex align-items-center text-muted">
                        <span class="pr-3">
                            <img src="assets/images/extraLine/icons/CancelAccount.svg" class="image-height-width">
                        </span>
                        <a target="_blank" (click)="cancelOrderUrl()"><span>Cancel
                                Account</span></a>
                    </div>
                    <i class=" far fa-chevron-right text-secondary"></i>
                </div>

            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="btn btn-primary w-100 text-center mb-2 rounded-lg mt-auto" (click)="openCrisp()">
        <span class="py-2">CHAT WITH SUPPORT</span>
    </div>

    <div class="align-self-center">
        {{version}}
    </div>
</div>