<!--containing in - Lib: product-configuration -->
<!--used by - Component: home-configuration/extraline-settings-home, business-configuration/extraline-settings-business -->
<!--Added for - App: Extraline-settings -->
<div [formGroup]="form" class="cart-item-background-color">
    <div class="d-flex flex-column pb-3">
        <label class="mr-2 mb-0 text-muted">Rings to Voicemail</label>
        <div class="select-input w-100">
            <i class="fas fa-caret-down dropdown-img d-flex align-items-center"></i>
            <select class="select-custom form-control bg-light-grey" formControlName="voicemailRingTimeoutCount" style="box-shadow: none !important; border: none !important; border-radius: 0.25rem !important">
        <!-- ngif condition is added to show option only if it is previously selected -->
        <option [value]="1" *ngIf="this.form.controls.voicemailRingTimeoutCount.value==='1'">1 Ring</option>
        <option [value]="2" *ngIf="this.form.controls.voicemailRingTimeoutCount.value==='2'">2 Rings</option>
        <option [value]="3">3 Rings</option>
        <option [value]="4" selected>4 Rings</option>
        <option [value]="5">5 Rings</option>
        <option [value]="6">6 Rings</option>
        <option [value]="7">7 Rings</option>
        <option [value]="8">8 Rings</option>
        <!-- <option [value]="60">60 Seconds</option> -->
        <option [value]="10">No Voicemail</option>
      </select>
        </div>
    </div>
</div>
