import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TroubleShootingService } from '../../services/troubleshooting.service';
@Component({
  selector: 'common-ui-troubleshooting',
  templateUrl: './troubleshooting.component.html',
  styleUrls: ['./troubleshooting.component.scss']
})
export class TroubleshootingComponent implements OnInit {
  @Input() orderId: string;
  showGif = true;
  gifUrl = `assets/images/troubleshoot.gif?a=${Math.random()}`
  troubleShoot: { error: '', error_description: '', type: '', item: '', link: '' }[] = [];
  get nativeWindow(): any {
    return window;
  }
  constructor(public bsModalRef: BsModalRef, private troubleShootingService: TroubleShootingService) { }

  ngOnInit() {
    this.troubleshooting();
  }

  async troubleshooting() {
    this.gifUrl = `assets/images/troubleshoot.gif?a=${Math.random()}`
    this.troubleShoot = []
    this.showGif = true;
    await this.troubleShootingService.troubleshoot(this.orderId).then((result: any) => {
      this.showGif = false;
      this.troubleShoot = result.issues;
    }).catch((err) => { console.log(err) })
  }

  openCrispChat() {
    this.nativeWindow.$crisp.push(["set", "message:text", ["I've tried to troubleshoot but still having problems."]])
    this.nativeWindow.$crisp.push(["do", "chat:open"]);
  }
}
