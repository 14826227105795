import { Inject, Injectable } from '@angular/core';
import { Auth0LockPasswordless } from 'auth0-lock';
import { Auth0Lock } from 'auth0-lock';
import * as auth0 from 'auth0-js';
import { AUTH_CONFIG } from '../auth0-variables';
import { ENVIRONMENT, IEnvironment, RouterService } from '@voiply/shared/common-ui';
import { EventType } from '@voiply/shared/model';
import { async } from '@rxweb/reactive-form-validators';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private options = null;
  private lock = null;
  public webAuth = null;
  public userProfile;
  Auth0;
  constructor(
    public router: RouterService,
    private http: HttpClient,
    @Inject(ENVIRONMENT) private environment: IEnvironment
  ) {
    console.log('AUTH_CONFIG', AUTH_CONFIG, location.toString().substring(0, location.toString().lastIndexOf("/")));

    // password less lock options
    this.options = {
      autoclose: true,
      configurationBaseUrl: 'https://cdn.auth0.com',
      theme: {
        logo: 'https://build.voiply.com/assets/images/logo.png',
        primaryColor: '#f25d4c',
        foregroundColor: '#f25d4c'
      },
      languageDictionary: {
        title: 'Just One More Thing',
        signUpTitle: 'Create Your Account',
        forgotPasswordAction: 'Forgot password?',
        submitLabel: 'CONFIRM',
        signUpTerms: '',
        passwordlessSMSAlternativeInstructions: `Please verify your identity with either a social media account above or SMS below to complete your order.`,
        phoneNumberInputPlaceholder: 'your mobile number'
      },
      auth: {
        redirectUrl: location.origin + "/callback",
        responseType: 'token id_token',
        params: {
          scope: 'openid email profile'
        }
      },
      socialButtonStyle: 'small',
      allowedConnections: ['sms', 'facebook', 'google-oauth2', 'linkedin', 'windowslive'],
      passwordlessMethod: 'code',
      rememberLastLogin: false,
      method: "passwordless",
      redirectUri: location.origin + "/callback",
      responseType: 'token id_token'
    };

    this.Auth0 = new auth0.WebAuth(AUTH_CONFIG);

  }

  public login(isclosable = true): void {
    this.options.closable = isclosable;
    this.options.allowSignUp = false;
    this.options.allowLogin = true;
    this.options.method = "passwordless"
    this.options.languageDictionary = JSON.stringify(this.options.languageDictionary);
    this.options.theme = JSON.stringify(this.options.theme);
    this.options.auth = JSON.stringify(this.options.auth);
    this.options.screen_hint = 'login';
    this.Auth0.authorize(this.options);
    // this.lock = new Auth0LockPasswordless(AUTH_CONFIG.clientID, AUTH_CONFIG.domain, this.options);
    // this.lock.on("authenticated", function (authResult) {

    //   // TODO: Can be improved in terms of UX for user.
    //   //  window.location.href = location.origin + "/callback" //no need to to redirect again as redirectURL is metioned in options above.
    // });
    // this.lock.show();
  }

  public signUpWithPassword() {
    //this method is for changesignUp
    this.options.languageDictionary = {
      title: 'Change Your Sign In Method',
      signUpTitle: 'Change Your Sign In Method',
      forgotPasswordAction: 'Forgot password?',
    },
      this.options.allowedConnections = ['Username-Password-Authentication']
    this.options.allowSignUp = true;
    this.options.allowLogin = true;
    this.options.initialScreen = 'signUp';
    this.options.auth = {
      redirect: false,
    }
    this.options.method = "password";
    this.options.languageDictionary = JSON.stringify(this.options.languageDictionary);
    this.options.theme = JSON.stringify(this.options.theme);
    this.options.auth = JSON.stringify(this.options.auth);
    this.options.screen_hint = 'signup';
    this.Auth0.authorize(this.options);
    // this.lock = new Auth0Lock(AUTH_CONFIG.clientID, AUTH_CONFIG.domain, this.options);
    // this.lock.show();

    // this.lock.on('signin submit', (o) => {
    //   this.webAuth = new auth0.WebAuth({
    //     clientID: AUTH_CONFIG.clientID,
    //     domain: AUTH_CONFIG.domain,
    //     scope: 'openid email profile',
    //     audience: AUTH_CONFIG.audience,
    //     responseType: 'token id_token',
    //     redirectUri: location.origin + "/callback",
    //   });
    //   setTimeout(() => {
    //     this.webAuth.authorize();
    //   }, 5000);
    // });
    // this.lock.on('signup submit', (o) => {
    //   this.webAuth = new auth0.WebAuth({
    //     clientID: AUTH_CONFIG.clientID,
    //     domain: AUTH_CONFIG.domain,
    //     scope: 'openid email profile',
    //     audience: AUTH_CONFIG.audience,
    //     responseType: 'token id_token',
    //     redirectUri: location.origin + "/callback",
    //   });
    //   setTimeout(() => {
    //     this.webAuth.authorize();
    //   }, 5000);

    // })
  }

  signUp(socialLogin, email) {
    if (socialLogin) {
      this.options.allowedConnections = ['Username-Password-Authentication', 'facebook', 'google-oauth2', 'linkedin', 'windowslive',]
    } else {
      this.options.allowedConnections = ['Username-Password-Authentication']
    }
    this.options.usernameStyle = "email"
    this.options.prefill = {
      email: email
    }
    this.options.allowSignUp = true;
    this.options.allowLogin = false;
    if (location.origin.indexOf("localhost") > -1) {
      //show login popup in local to not create unneccessary accounts while testing
      this.options.allowLogin = true
      this.options.allowedConnections = ['Username-Password-Authentication', 'facebook', 'google-oauth2', 'linkedin', 'windowslive',]

    }
    this.options.closable = false;
    this.options.method = "password";
    this.options.languageDictionary = JSON.stringify(this.options.languageDictionary);
    this.options.theme = JSON.stringify(this.options.theme);
    this.options.auth = JSON.stringify(this.options.auth);
    this.options.prefill = JSON.stringify(this.options.prefill);
    this.options.screen_hint = 'signup';
    this.Auth0.authorize(this.options);
    // this.lock = new Auth0Lock(AUTH_CONFIG.clientID, AUTH_CONFIG.domain, this.options);
    // this.lock.show();
  }


  public loginWithPassword(isclosable = true, socialLogin) {
    if (socialLogin) {
      this.options.allowedConnections = ['Username-Password-Authentication', 'facebook', 'google-oauth2', 'linkedin', 'windowslive']
    } else {
      this.options.allowedConnections = ['Username-Password-Authentication']
    }
    this.options.allowSignUp = false;
    this.options.allowLogin = true;
    this.options.closable = isclosable;
    this.options.method = "password"
    this.options.languageDictionary = JSON.stringify(this.options.languageDictionary);
    this.options.theme = JSON.stringify(this.options.theme);
    this.options.auth = JSON.stringify(this.options.auth);
    this.options.screen_hint = 'login';
    this.Auth0.authorize(this.options);

    // this.lock = new Auth0Lock(AUTH_CONFIG.clientID, AUTH_CONFIG.domain, this.options);
    // this.lock.show();
  }


  public setSession(authResult): void {

    console.log('[AUTH_RESULT]', authResult);
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

    const tokenPayload = (authResult || {}).idTokenPayload;
    const userAuth0Id = (tokenPayload || {}).sub;
    if (userAuth0Id)
      localStorage.setItem('auth0Id', userAuth0Id);

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public logout(params = {}): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('auth0Id');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    let logoutUrl;
    if (_.isEmpty(params))
      logoutUrl = location.origin + '/logout';
    else {
      const myUrlWithParams = new URL(location.origin + "/logout");
      _.each(params, (value, key) => {
        myUrlWithParams.searchParams.append(key, value);
      })
      logoutUrl = myUrlWithParams.href;
    }
    this.Auth0.logout({
      returnTo: logoutUrl,
      client_id: AUTH_CONFIG.clientID
    });
    // Go back to the home route
  }

  public checkSessionAsync(orderId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.Auth0.checkSession({}, function (err, authResult) {
        if (err) {
          if (err.code === 'consent_required' || err.code === 'interaction_required') {
            return this.Auth0.authorize();
          }
          console.log('Error while checking auth session:', err);
          return resolve(null);
        } else {
          this.setSession(authResult);
          return resolve(authResult);
        }
      });
    });
  }

  public getProfile(authResult: any): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      this.Auth0.client.userInfo(authResult.accessToken, function (err, profile) {
        if (profile) {
          // Set the time that the access token will expire at
          const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
          const userAuth0Id = (profile || {}).sub;
          if (userAuth0Id)
            localStorage.setItem('auth0Id', userAuth0Id);

          localStorage.setItem('access_token', authResult.accessToken);
          localStorage.setItem('id_token', authResult.idToken);
          localStorage.setItem('expires_at', expiresAt);
          return resolve(profile);
        } else {
          return resolve(null);
        }
      });
    });
  }

  async isAuthenticated() {
    let authResult: any = {}
    authResult.accessToken = localStorage.getItem('access_token');
    authResult.expiresIn = localStorage.getItem('expires_at');
    if (new Date() < authResult.expiresIn) {
      return this.getProfile(authResult);
    } else {
      return null
    }
  }

  deleteUser(authId): Promise<any> {
    return this.http.post(`${this.environment.rootApi}/delete-auth-user`, { authId }).toPromise();
  }




}
