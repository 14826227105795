import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class TroubleShootingService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    troubleshoot(orderId) {
        return this.http.get(`https://online-app-gdms.azurewebsites.net/api/troubleshoot?order_id=${orderId}`).toPromise();
    }
}