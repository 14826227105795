import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'extraline-settings-caller-id',
  templateUrl: './caller-id.component.html',
  styleUrls: ['./caller-id.component.scss']
})
export class CallerIdComponent {
  queryParams;
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
  })
  }
}
