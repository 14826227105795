import { Injectable, EventEmitter, Inject } from '@angular/core';
import { SetCrispSessionIdAction } from '../+state/app.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { HttpClient } from '@angular/common/http';
import { IEnvironment } from '../environment.interface';
import { ENVIRONMENT } from '../constants';
import { even } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { SystemType } from '@voiply/shared/model';

@Injectable({
  providedIn: 'root'
})
export class CrispService {

  sessionLoaded: EventEmitter<string> = new EventEmitter<string>();
  isUserVoiplyTeamMember = false;

  @Dispatch() setCrispSessionId = (crispSessionId) => new SetCrispSessionIdAction(crispSessionId);

  get nativeWindow(): any {
    return window;
  }

  get nativeDocument(): any {
    return document;
  }

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {
  }
  public initCrisp(orderId) {
    console.log('[CRISP] Loading Crisp session');
    this.sessionLoaded.subscribe((value) => {
      // Crisp is loaded
      this.setCrispSessionId(value);
      // this.fillPrimaryInformation(orderData);

      if (this.nativeDocument.referrer)
        this.setSessionData("Referrer", this.nativeDocument.referrer);
    });
    this.loadScript(orderId);
  }

  public loadScript(orderId) {
    const emptyArray: any = [];
    this.nativeWindow.$crisp = emptyArray;
    this.nativeWindow.CRISP_TOKEN_ID = orderId;
    this.nativeWindow.CRISP_WEBSITE_ID = "753507e1-7291-4511-9d45-6754be72ceac";
    this.nativeWindow.CRISP_RUNTIME_CONFIG = {
      session_merge: true
    };

    const body = <HTMLDivElement>this.nativeDocument.body;
    const script = this.nativeDocument.createElement('script');
    script.innerHTML = '';
    script.src = 'https://client.crisp.chat/l.js';
    script.async = true;
    script.defer = true;
    const that = this;
    script.onload = function () {
      that.nativeWindow.$crisp.push(["on", "session:loaded", (value) => {
        console.log("CRISP SESSION LOADED, session id is", value);
        that.sessionLoaded.emit(value);
      }]);

      if (this.environment.systemType === SystemType.App) {
        this.nativeWindow.$crisp.push(['do', 'chat:hide']);
      }
      that.nativeWindow.$crisp.push(["do", "chat:close"]);
      that.nativeWindow.$crisp.push(["set", "session:data", [[["quote_id", orderId]]]]);
    }.bind(this);
    body.appendChild(script);
  }

  public fillPrimaryInformation(data) {

    const billingAddress = (data.checkoutDetail || {}).billingAddress || {};
    if (billingAddress.email)
      this.CrispUserEmail = billingAddress.email;
    if (billingAddress.name)
      this.CrispUserName = billingAddress.name;
    if (billingAddress.phone)
      this.CrispUserPhone = billingAddress.phone;

    const companyName = (data.checkoutDetail || {}).companyName;
    if (companyName)
      this.CrispUserCompany = companyName;
  }

  public set CrispUserEmail(value) {
    if (this.isUserVoiplyTeamMember)
      return;
    console.log('CRISP: set user email', value);
    this.nativeWindow.$crisp.push(["set", "user:email", [value]]);
  }

  public set CrispUserPhone(value) {
    if (this.isUserVoiplyTeamMember)
      return;
    console.log('CRISP: set user phone', value);
    this.nativeWindow.$crisp.push(["set", "user:phone", [value]]);
  }

  public set CrispUserCompany(value) {
    if (this.isUserVoiplyTeamMember)
      return;
    console.log('CRISP: set user company', value);
    this.nativeWindow.$crisp.push(["set", "user:company", [value]]);
  }

  public set CrispUserName(value) {
    if (this.isUserVoiplyTeamMember)
      return;
    console.log('CRISP: set user nickname', value);
    this.nativeWindow.$crisp.push(["set", "user:nickname", [value]])
  }

  public setSessionData(key, value) {
    if (this.isUserVoiplyTeamMember)
      return;
    console.log('CRISP: set session data,', key, value);
    this.nativeWindow.$crisp.push(["set", "session:data", [[[key.toString(), value.toString()]]]]);
  }

  public setSessionEvent(event, data) {
    if (this.isUserVoiplyTeamMember)
      return;
    console.log('CRISP: set session event,', event, data);
    this.nativeWindow.$crisp.push(["set", "session:event", [[[event, data, "orange"]]]])
  }

  public setUserEvent(event, userData): Observable<any> {
    console.log('CRISP: set user event,', event, userData);
    return this.http.post(`${this.environment.rootApi}/crisp-event-admin`, { event: event, userData: userData, color: "green" });

  }
}
