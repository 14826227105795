import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppStateSelectors } from '../../+state';
import { OnDestroyCleanup } from '../../generic-utils';
import { CrispService } from '../../services';

@Component({
  selector: 'common-ui-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent extends OnDestroyCleanup implements OnInit {

  get nativeWindow(): any {
    return window;
  }
  orderId = '';
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  constructor(private crispService: CrispService) {
    super()
    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
  }

  ngOnInit(): void {
    this.crispService.initCrisp(this.orderId);
    setTimeout(() => {
      this.nativeWindow.$crisp.push(["set", "message:text", ["I am having issues with Login for my order"]])
      this.nativeWindow.$crisp.push(["do", "chat:open"]);
    }, 1000)

  }

}
