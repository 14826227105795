import { Directive, Input, OnInit } from "@angular/core";
import { CountryCode } from './country-codes';
import * as lpn from 'google-libphonenumber';

export interface Country {
    name: string;
    iso2: string;
    dialCode: string;
    priority: number;
    areaCodes?: string[];
    flagClass: string;
    placeHolder: string;
}
@Directive()
export class InternationalPhoneNumberBase implements OnInit {

    @Input() value = '';
    @Input() preferredCountries: Array<string> = [];
    @Input() enablePlaceholder = true;
    @Input() cssClass = 'form-control';
    @Input() onlyCountries: Array<string> = [];
    @Input() enableAutoCountrySelect = false;
    @Input() filterOutCountries = [];
    phoneNumber = '';
    allCountries: Array<Country> = [];
    preferredCountriesInDropDown: Array<Country> = [];
    selectedCountry: Country;
    phoneUtil = lpn.PhoneNumberUtil.getInstance();
    disabled = false;
    errors: Array<any> = ['Phone number is required.'];

    textboxPadding: string = '72px';

    onTouched = () => { };
    propagateChange = (_: any) => { };

    constructor(
        private countryCodeData: CountryCode
    ) { }

    ngOnInit() {

        this.fetchCountryData();

        if (this.preferredCountries.length) {
            this.preferredCountries.forEach(iso2 => {
                const preferredCountry = this.allCountries.filter((c) => {
                    return c.iso2 === iso2;
                });

                this.preferredCountriesInDropDown.push(preferredCountry[0]);
            });
        }
        if (this.onlyCountries.length) {
            this.allCountries = this.allCountries.filter(c => this.onlyCountries.includes(c.iso2));
        }
        if (this.preferredCountriesInDropDown.length) {
            this.selectedCountry = this.preferredCountriesInDropDown[0];
        } else {
            this.selectedCountry = this.allCountries[0];
        }
        const codeLength = this.selectedCountry.dialCode.length;
        this.textboxPadding = codeLength == 1 ? '72' : codeLength == 2 ? '84' : codeLength == 3 ? '92' : '100';
        const dialCode = this.selectedCountry.dialCode.length > 3 ? '1 ' + this.selectedCountry.dialCode.substring(1) + '-' : this.selectedCountry.dialCode;
        this.selectedCountry.placeHolder = this.selectedCountry.placeHolder.replace('+' + dialCode, '');
    }

    public onPhoneNumberChange(): void {
        this.value = this.phoneNumber;

        let number: lpn.PhoneNumber;
        try {
            number = this.phoneUtil.parse(this.phoneNumber, this.selectedCountry.iso2.toUpperCase());
        } catch (e) {
        }

        let countryCode = this.selectedCountry.iso2;
        // auto select country based on the extension (and areaCode if needed) (e.g select Canada if number starts with +1 416)
        if (this.enableAutoCountrySelect) {
            countryCode = number && number.getCountryCode()
                ? this.getCountryIsoCode(number.getCountryCode(), number)
                : this.selectedCountry.iso2;
            if (countryCode !== this.selectedCountry.iso2) {
                const newCountry = this.allCountries.find(c => c.iso2 === countryCode);
                if (newCountry) {
                    this.selectedCountry = newCountry;
                    const codeLength = this.selectedCountry.dialCode.length;
                    this.textboxPadding = codeLength == 1 ? '72' : codeLength == 2 ? '84' : codeLength == 3 ? '92' : '100';
                    const dialCode = this.selectedCountry.dialCode.length > 3 ? '1 ' + this.selectedCountry.dialCode.substring(1) + '-' : this.selectedCountry.dialCode;
                    this.selectedCountry.placeHolder = this.selectedCountry.placeHolder.replace('+' + dialCode, '');
                }
            }
        }
        countryCode = countryCode ? countryCode : this.selectedCountry.iso2;

        if (!this.value) {
            // tslint:disable-next-line:no-null-keyword
            this.propagateChange(null);
        } else {
            const internationalNumberValue = number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL) : this.selectedCountry.dialCode;
            this.propagateChange({
                number: this.value,
                internationalNumber: internationalNumberValue,
                nationalNumber: number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.NATIONAL) : '',
                countryCode: countryCode.toUpperCase(),
                placeHolderExample: this.selectedCountry.placeHolder
            });
        }
    }

    public onCountrySelect(country: Country, el): void {
        this.selectedCountry = country;
        const codeLength = this.selectedCountry.dialCode.length;
        this.textboxPadding = codeLength == 1 ? '72' : codeLength == 2 ? '84' : codeLength == 3 ? '92' : '100';
        const dialCode = this.selectedCountry.dialCode.length > 3 ? '1 ' + this.selectedCountry.dialCode.substring(1) + '-' : this.selectedCountry.dialCode;
        this.selectedCountry.placeHolder = this.selectedCountry.placeHolder.replace('+' + dialCode, '');

        {
            this.value = this.phoneNumber;

            let number: lpn.PhoneNumber;
            try {
                number = this.phoneUtil.parse((this.phoneNumber ? this.phoneNumber : this.selectedCountry.dialCode), this.selectedCountry.iso2.toUpperCase());
            } catch (e) {
            }

            const internationalNumberValue = number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL) : this.selectedCountry.dialCode;
            this.propagateChange({
                number: this.value,
                internationalNumber: internationalNumberValue,
                nationalNumber: number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.NATIONAL) : '',
                countryCode: this.selectedCountry.iso2.toUpperCase()
            });
        }
        el.value=""
        el.focus();
    }

    public onInputKeyPress(event): void {
        const pattern = /[0-9\-xX\ ]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    protected fetchCountryData(): void {
        this.countryCodeData.allCountries.forEach(c => {
            const country: Country = {
                name: c[0].toString(),
                iso2: c[1].toString(),
                dialCode: c[2].toString(),
                priority: +c[3] || 0,
                areaCodes: c[4] as string[] || undefined,
                flagClass: c[1].toString().toLocaleLowerCase(),
                placeHolder: ''
            };

            if (this.enablePlaceholder) {
                country.placeHolder = this.getPhoneNumberPlaceHolder(country.iso2.toUpperCase());
            }

            //filterout countries that are present in the fileroutcountriesarray
            if (!this.filterOutCountries.includes(country.iso2)) {
                this.allCountries.push(country);
            };

        });
    }

    protected getPhoneNumberPlaceHolder(countryCode: string): string {
        try {
            return this.phoneUtil.format(this.phoneUtil.getExampleNumber(countryCode), lpn.PhoneNumberFormat.INTERNATIONAL);
        } catch (e) {
            return e;
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj) {
            this.phoneNumber = obj;
            setTimeout(() => {
                this.onPhoneNumberChange();
            }, 1);
        }
    }

    private getCountryIsoCode(countryCode: number, number: lpn.PhoneNumber): string | undefined {
        // Will use this to match area code from the first numbers
        const rawNumber = number.values_['2'].toString();
        // List of all countries with countryCode (can be more than one. e.x. US, CA, DO, PR all have +1 countryCode)
        const countries = this.allCountries.filter(c => c.dialCode === countryCode.toString());
        // Main country is the country, which has no areaCodes specified in country-code.ts file.
        const mainCountry = countries.find(c => c.areaCodes === undefined);
        // Secondary countries are all countries, which have areaCodes specified in country-code.ts file.
        const secondaryCountries = countries.filter(c => c.areaCodes !== undefined);
        let matchedCountry = mainCountry ? mainCountry.iso2 : undefined;

        /*
            Interate over each secondary country and check if nationalNumber starts with any of areaCodes available.
            If no matches found, fallback to the main country.
        */
        secondaryCountries.forEach(country => {
            country.areaCodes.forEach(areaCode => {
                if (rawNumber.startsWith(areaCode)) {
                    matchedCountry = country.iso2;
                }
            });
        });

        return matchedCountry;
    }

}