<div class="mt-5 bg-light-grey componet-container" *ngFor="let item of data_plans?.data">
    <div class="border-bottom py-3 pl-md-4" style="position: relative;padding-left: 10px;">
        <div class="py-2 font-weight-bolder voiply-mobile-font">Voiply Mobile</div>
        <span style="color: #828282;" class="container-common-font">prepaid</span>
        <div class="container-card"
            [ngClass]="{'card-image2':(data_plans.country=='US'),'card-image1':(data_plans.country=='CA')}">
            <div style="position:relative;height:25px;top:45px;"
                class="bg-white d-flex justify-content-between align-items-center px-2">
                <div class="font-weight-bolder"><span style="font-size: 20px;">{{item.data}}</span><span
                        style="font-size: 10px;position: relative;bottom: 3px;">PREPAID</span>
                </div>
                <div style="color: #BF1338;font-size:20px;" class="font-weight-bolder">
                    {{data_plans.country=='US'?'US':''}}
                </div>
            </div>
            <div style="position:relative;top:-10px;padding-left:5px;">
                <img src="assets/images/es-voiplytag.svg" alt="voiply-tag" style="height:21px;">
            </div>
            <div style="position: relative;top: 21px;">
                <div class="text-white pl-1" style="font-size:7.5px;">Affordable & Fast eSim</div>
                <div class="text-white pl-2" style="font-size:7.5px;">Internet for everyone</div>
            </div>

        </div>
    </div>
    <div
        class="border-bottom py-4 pl-md-4 d-flex justify-content-between align-items-center component-container-other-divs ">
        <div>
            <img src="assets/images/world.svg" alt="world" class="pb-1 mr-2 component-container-other-divs-logos">
            DATA
        </div>
        <div class="font-weight-bolder">
            {{item.data}}
        </div>
    </div>
    <div
        class="border-bottom py-4 pl-md-4 d-flex justify-content-between align-items-center component-container-other-divs">
        <div>
            <img src="assets/images/calendar.svg" alt="calendar" class="pb-1 mr-2 component-container-other-divs-logos">
            VALIDITY
        </div>
        <div class="font-weight-bolder">
            {{item.day}} DAYS
        </div>
    </div>
    <div class="py-4 px-4">
        <button class="btn btn-lg rounded btn-block border bg-white" style="font-size: 22px;" (click)="onPayClicked(item)">${{item.price}} - Buy
            Now</button>
    </div>
</div>