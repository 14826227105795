import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { CheckoutDetail, Card, Stripe } from '@voiply/shared/model';
import { ToastrService } from 'ngx-toastr';
import { StripeService } from 'libs/shared/common-ui/src/lib/services';
import { Subscription } from 'rxjs';
import { OnDestroyCleanup } from '@voiply/shared/common-ui';

@Component({
  selector: 'voiply-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent extends OnDestroyCleanup implements OnInit {

  @Input()
  checkoutDetail: CheckoutDetail;
  @Input()
  cardDetail: Card;
  @Input()
  orderId: string;

  @Output()
  stripeDataChanged = new EventEmitter<Stripe>();
  @ViewChild('cardElement') cardInfo: ElementRef;
  card: any;

  cardHandler = this.onChange.bind(this);
  error: string;
  showCreditCard = false;
  cardChangeRequesting = false;


  constructor(private cd: ChangeDetectorRef, private tostr: ToastrService, public stripeService: StripeService, private toastr: ToastrService) {
    super();
    this.subscriptions$.add(this.stripeService.emitMountNewCard.subscribe(async (isCalledFromAnnualModal) => {
      await this.onCardChangeCancel();
      if (!isCalledFromAnnualModal)
        this.mountStripeCard();
    }));
  }

  ngOnInit() {
  }

  mountStripeCard() {
    if (this.card)
      return;

    this.card = elements.create('card', {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: '#d3d4d5',
          color: '#212529',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          ':-webkit-autofill': {
            color: '#212529',
          },
          '::placeholder': {
            color: '#2125298a',
          },
        },
        invalid: {
          iconColor: 'red',
          color: 'red',
        },
      },
      hidePostalCode: true
    });

    console.log('Mounting card in order summary');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);

    this.showCreditCard = true;
  }


  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit() {
    this.cardChangeRequesting = true;

    const { token, error } = await stripe.createToken(this.card);
    if (error) {
      this.tostr.error(error.message);
    this.cardChangeRequesting = false;
    } else {
      console.log('Success!', token);
      this.stripeService.changeCard(this.orderId, token).then((response) => {
        if (response.result == "success") {
          this.tostr.success('Card updated successfully.');
          this.stripeDataChanged.emit({
            stripeToken: token.id, card: {
              exp_month: token.card.exp_month, exp_year: token.card.exp_year,
              id: token.card.id, last4: token.card.last4, brand: token.card.brand
            }
          });

          this.cardDetail = {
            exp_month: token.card.exp_month, exp_year: token.card.exp_year,
            id: token.card.id, last4: token.card.last4, brand: token.card.brand
          };
          this.showCreditCard = false;
          this.card.removeEventListener('change', this.cardHandler);
          this.card.destroy();
          this.card = null;
        }
        this.cardChangeRequesting = false;
      })

    }



  }



  onCardChangeCancel() {
    if (this.card) {
      this.showCreditCard = false;
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
      this.card = null;
    }
  }
}
