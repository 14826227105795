export class BillingDetails {
    orderId: string;
    subscriptionId: string;
    currentSubtotal = 0;
    currentTax = 0;
    currentTotal = 0;
    upgradeSubtotal = 0;
    upgradeCredit = 0;
    upgradeTax = 0;
    upgradeTaxPercent = 0;
    upgradeTotal = '0';
    upgradeTaxBreakDown = '0';
    upgradeSubmissionId: string;
}