<!--containing in - Lib: product-configuration -->
<!--used by - Component: home-configuration/home-configuration-model, business-configuration/business-configuration-model, home-configuration/user-settings, business-configuration/user-settings -->
<!--Added for Home, Business, User-settings -->
<div [formGroup]="form" class="cart-item-background-color">
  <div class="d-flex flex-row flex-wrap flex-lg-nowrap">
    <div class="border d-flex flex-column mr-0 mr-lg-3 mb-3 mb-lg-0 box">
      <div class="d-flex flex-row align-items-center justify-content-between border-bottom py-2 px-3">
        <div class="d-flex flex-row align-items-center">
          <img src="assets/images/call-forward.png" width="18px" class="mr-2" alt="">
          <span class="text-secondary" style="font-size: 14px;">CALL FORWARDING</span>
        </div>
        <mat-slide-toggle class="" color="primary" formControlName="callForwarding">

        </mat-slide-toggle>
      </div>
      <div class="p-3">
        <input type="text" class="form-control" placeholder="Call Forwarding Number"
          formControlName="callForwardingNumber" mask="(000) 000-0000">
      </div>
    </div>
    <div class="border d-flex flex-column box">
      <div class="d-flex flex-row align-items-center justify-content-between border-bottom py-2 px-3">
        <div class="d-flex flex-row align-items-center">
          <img src="assets/images/wifi-exclamation.png" width="18px" class="mr-2" alt="">
          <span class="text-secondary" style="font-size: 14px;">EMERGENCY CALL FORWARDING</span>
        </div>
        <mat-slide-toggle class="" color="primary" formControlName="emergencyCallForwarding">

        </mat-slide-toggle>
      </div>
      <div class="p-3">
        <input type="text" class="form-control" placeholder="Emergency Call Forwarding Number"
          formControlName="emergencyCallForwardingNumber" mask="(000) 000-0000">
      </div>
    </div>
  </div>
</div>
