<!--containing in - Lib: product-configuration -->
<!--used by - Component: business-configuration/business-configuration-modal, business-configuration/user-settings-business, home-configuration/home-configuration-modal, home-configuration/user-settings-home, -->
<!--Added for - App: Home, Business, User-settings-->
<div [formGroup]="form" class="cart-item-background-color d-flex flex-column flex-lg-row">
    <div class="flex-fill">
        <div class="position-relative">
            <input type="text" class="form-control" placeholder="MAC Address" formControlName="mac">
            <div *ngIf="((f.mac.touched || submitted) && this.form.errors?.mismatchedLast6Digits)">
                <span *ngIf="(this.form.errors?.mismatchedLast6Digits)">
          <i class="far fa-exclamation-circle is-error" tooltip="Last 6 Digits Should be Same as Serial Number"></i>
        </span>
            </div>
        </div>

        <div class="mt-3 position-relative">
            <input type="text" class="form-control" [placeholder]="getPlaceHolder()" formControlName="serial">
            <div *ngIf="((f.serial.touched || submitted) && this.form.errors?.mismatchedLast6Digits)">
                <span *ngIf="(this.form.errors?.mismatchedLast6Digits)">
          <i class="far fa-exclamation-circle is-error" tooltip="Last 6 Digits Should be Same as MacAddress"></i>
        </span>
            </div>
        </div>

        <div class="mt-3">
            <div>
                <div class="btn-group" dropdown class="w-100 position-relative" [isDisabled]="this.form.controls['manufacturer'].disabled">
                    <button id="button-basic2" dropdownToggle type="button" class="btn btn-light w-100 d-flex justify-content-between align-items-center dropdown-toggle py-2" aria-controls="dropdown-basic2">
            <span *ngIf="this.form.controls['manufacturer'].value" class="d-flex align-items-center">
              <img src="assets/images/manufacturers/{{this.form.controls['manufacturer'].value}}.png" width="30px"
                class="mr-2" alt=""><span>{{
                capitalizeFirstLetter(this.form.controls['manufacturer'].value)}}</span></span>
            <span *ngIf="!this.form.controls['manufacturer'].value">
              Select Manufacturer
            </span> <span class="caret"></span>
          </button>

                    <ul id="dropdown-basic2" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="button-basic2">
                        <!-- <li role="menuitem" (click)="changeManufacturer('cisco')">
              <span class="dropdown-item">
                <img src="assets/images/manufacturers/cisco.png" width="30px" class="mr-2" alt="">
                Cisco
              </span></li> -->
                        <li role="menuitem" (click)="changeManufacturer('grandstream')">
                            <span class="dropdown-item">
                <img src="assets/images/manufacturers/grandstream.png" width="30px" class="mr-2" alt="">
                Grandstream
              </span>
                        </li>
                        <!-- <li role="menuitem" (click)="changeManufacturer('polycom')">
              <span class="dropdown-item">
                <img src="assets/images/manufacturers/polycom.png" width="30px" class="mr-2" alt="">
                Polycom
              </span></li>
            <li role="menuitem" (click)="changeManufacturer('snom')">
              <span class="dropdown-item">
                <img src="assets/images/manufacturers/snom.png" width="30px" class="mr-2" alt="">
                Snom
              </span></li> -->
                        <!-- Show yealink option only if any user has previously selected this value -->
                        <li role="menuitem" (click)="changeManufacturer('yealink')" *ngIf="this.form.controls['manufacturer'].value==='yealink'">
                            <span class="dropdown-item">
                <img src="assets/images/manufacturers/yealink.png" width="30px" class="mr-2" alt="">
                Yealink
              </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <div class="btn-group" dropdown class="w-100  position-relative" [isDisabled]="this.form.controls['model'].disabled">
                <button id="button-basic1" dropdownToggle type="button" class="btn btn-light w-100 d-flex justify-content-between align-items-center dropdown-toggle py-2" aria-controls="dropdown-basic1">
          <span *ngIf="this.form.controls['model'].value" class="d-flex align-items-center"> <img
              src="assets/images/{{this.form.controls['manufacturer'].value}}/{{ this.form.controls['model'].value.toLowerCase()}}.png"
              alt="" style="width: 30px;" class="mr-2"> {{ this.form.controls['model'].value }}</span>
          <span *ngIf="!this.form.controls['model'].value">
            <ng-container *ngIf="this.models.length">Select Device</ng-container>
            <ng-container *ngIf="!this.models.length">No Supported Models</ng-container>
          </span> <span class="caret"></span>
        </button>

                <ul id="dropdown-basic1" *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="button-basic1">
                    <li role="menuitem" *ngFor="let option of this.models" (click)="changeModel(option.model)">
                        <span class="dropdown-item cursor-pointer">
              <img src="assets/images/{{this.form.controls['manufacturer'].value}}/{{ option.model.toLowerCase()}}.png"
                alt="" style=" width: 30px;" class="mr-2"> {{ option.model }}
            </span>
                    </li>

                </ul>
            </div>
        </div>

    </div>
    <div class="m-2" *ngIf="this.form.get('model').value"></div>
    <div class="flex-fill" style="" *ngIf="this.form.get('model').value">
        <div class="card h-100">
            <div class="d-flex align-items-center justify-content-center h-100 m-4">
                <img src="assets/images/{{this.form.controls['manufacturer'].value}}/{{ this.form.controls['model'].value.toLowerCase()}}.png" alt="" style="width: 100px;">
            </div>
            <a class="btn w-100 py-2 text-primary" style="background-color: #414141;" [href]="openPdf()" target="_blank">READ
        THE
        INSTRUCTIONS</a>
        </div>
    </div>
</div>
