import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { NumberSearchService } from '../../services';
import { STATES, OnDestroyCleanup, AppStateSelectors } from '@voiply/shared/common-ui';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as Model from '@voiply/shared/model';

@Component({
  selector: 'voiply-local-number-search-alt',
  templateUrl: './local-number-search-alt.component.html',
  styleUrls: ['./local-number-search-alt.component.css']
})
export class LocalNumberSearchComponentAlt extends OnDestroyCleanup {

  @Select(AppStateSelectors.currentLocation) currentLocation$: Observable<any>;
 
  @Input() currentLocation: { stateCode: string, StateName: string, cityName: string } = { stateCode: null, StateName: null, cityName: null };
  @Output() numberSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() numberSelectedInfo: EventEmitter<Model.PrimaryPhone> = new  EventEmitter<Model.PrimaryPhone>();
  @Input() primaryPhoneInfo;
  // Local number variables
  
  sortNumbersByCity = false;
  cities: string[] = [];
  areaCodes: string[] = [];
  selectedNewNumberInfo: Model.PrimaryPhone;
  searchValue = '';
  selectedState = '';
  selectedCity = '';
  selectedAreaCode = '';
  matchingNumbers = [];
  allNumbers: string[] = [];
  searching = false;

  STATES = STATES;

  constructor(private numberService: NumberSearchService) {
    super();
    this.subscriptions$.add(this.currentLocation$.subscribe(currentLocation => {
      if (currentLocation.stateCode) {
        this.selectedState = currentLocation.stateCode;
        this.onStateChange(currentLocation.stateCode);
      }
    }));
  }

  ngOnInit(){

    this.onStateChange(this.selectedState);
    if(this.selectedCity!=='')
      this.onCityChange(this.selectedCity);
    else if(this.selectedAreaCode!==''){
      this.onAreaCodeChange(this.selectedAreaCode);
    }  
    else{

    }
  }

  ngOnChanges(){

    console.log(this.primaryPhoneInfo)
    if(this.primaryPhoneInfo!== undefined && this.primaryPhoneInfo!==null && this.primaryPhoneInfo.selectedNewNumberInfo!==null){
      if(this.primaryPhoneInfo.isNew){
               this.searchValue = this.primaryPhoneInfo.number;
             }
             else{
               this.searchValue = '';
             }
             this.selectedState = (this.primaryPhoneInfo.selectedNewNumberInfo||{}).selectedState || '';
             this.selectedCity = (this.primaryPhoneInfo.selectedNewNumberInfo||{}).selectedCity || '';
             this.selectedAreaCode = (this.primaryPhoneInfo.selectedNewNumberInfo||{}).selectedAreaCode || '';
             if(((this.primaryPhoneInfo.selectedNewNumberInfo||{}).sortedBy || '')=="areaCode"){
               this.sortNumbersByCity=false;
             }
             else{
               this.sortNumbersByCity=true;
             }
    }
  }


  // Local Number
  onStateChange(stateCode) {
    _.filter(this.STATES, (state) => {
      if (state && state.code == stateCode) {
        this.cities = state.cities;
        this.areaCodes = state.areaCode;
      }
    });
    this.selectedState=stateCode;
    this.fetchNumbers(stateCode, '', '', this.searchValue);
  }

  onNumberSearch(searchTerm) {
    this.handleSearch(this.numberService.phoneDecode(searchTerm.toUpperCase()));
  }

  onAreaCodeChange(areaCode) {
    this.selectedAreaCode = areaCode;
    this.selectedCity='';
    this.fetchNumbers(this.selectedState, '', areaCode, this.searchValue);
  }

  onCityChange(cityName) {
    this.selectedCity = cityName;
    this.selectedAreaCode='';
    this.fetchNumbers(this.selectedState, cityName, '', this.searchValue);
  }

  selectedNumberChanged(number: string) {
    if(this.primaryPhoneInfo!==undefined){
      this.selectedNewNumberInfo={
        number: number.toString(),
        isNew: this.primaryPhoneInfo.isNew,
        isValid:this.primaryPhoneInfo.isValid,
        systemSelected:this.primaryPhoneInfo.systemSelected,
        selectedNewNumberInfo:{
          selectedState:this.selectedState,
          sortedBy: this.selectedAreaCode !=='' ? Model.newPrimaryNumberSortedBy.areaCode : Model.newPrimaryNumberSortedBy.city,
          selectedAreaCode:this.selectedAreaCode,
          selectedCity:this.selectedCity,
        }
      };
      this.numberSelectedInfo.emit(this.selectedNewNumberInfo); 
    }
    
    this.numberSelected.emit(number);
  }

  private fetchNumbers(stateCode: string, city: string, areaCode: string, searchTerm: string) {
    this.matchingNumbers = [];
    this.searching = true;
    this.numberService.getNumbersByState(stateCode, city, areaCode).subscribe(
      (data) => {
        if (data) {
          this.allNumbers = data.numbers;
          if (areaCode === '' && city === '') {
            this.areaCodes = data.area;
            this.cities = data.cities;
          }
        }
        this.handleSearch(searchTerm);
      },
      (error) => { console.error(`Error Fetching number, state ${stateCode}, city: ${city}, areaCode: ${areaCode}`) },
      () => this.searching = false
    );
  }

  private handleSearch(searchTerm) {
    // Filter number
    let filteredNumbers = this.allNumbers;
    if (searchTerm.trim() !== "") {
      searchTerm = searchTerm.replace(" ", "").replace("-", "");
      filteredNumbers = _.filter(filteredNumbers, (number) => {
        return number.toString().indexOf(searchTerm) >= 0;
      });
    }

    filteredNumbers = _.sortBy(filteredNumbers, 'score').reverse();
    this.matchingNumbers = _.take(filteredNumbers, 12);
  }
}
