<!--containing in - Lib: product-configuration -->
<!--used by - Component: home-configuration/home-configuration-model, business-configuration/business-configuration-model, home-configuration/user-settings-home business-configuration/user-settings-business -->
<!--Added for Home, Business, user-Settings-->
<div class="d-flex p-4 flex-column justify-content-center" [formGroup]="form">

  <div class="d-flex flex-column flex-lg-row">
    <div class="d-flex flex-column" style="min-width: 60%;">
      <span> <span class="font-weight-bold"> Voiply Emergency 999 </span> is an alert service that puts you in touch
        with a dispatcher who
        can summon emergency help and contact a friend or family member while offering immediate support.
      </span>
      <div class="my-3 border-top"></div>
      <div class="d-flex flex-row flex-wrap justify-content-between">
        <div class="features p-2 flex-grow-1 flex-sm-grow-0"><img src="assets/images/24_7.png" height="16px"
            class="pr-2" alt=""><span class="">24/7 Emergency
            Response</span></div>
        <div class="features p-2 flex-grow-1 flex-sm-grow-0 mt-3 mt-md-0"><img src="assets/images/alert.png"
            height="16px" class="pr-2" alt="">
          <span class="">Alert Emergency
            Contacts</span></div>
      </div>
      <div class="d-flex flex-row flex-wrap justify-content-between mt-3">

        <div class="features p-2 flex-grow-1 flex-sm-grow-0"><img src="assets/images/logo.png" height="20px"
            class="pr-2" alt=""><span class="">Works w/
            Voiply Phones</span></div>
        <div class="features p-2 flex-grow-1 flex-sm-grow-0 mt-3 mt-md-0"><img src="assets/images/groupchat.png"
            height="16px" class="pr-2" alt=""><span class="">Free for 90 Days</span></div>
      </div>
    </div>
    <div class="m-2"></div>
    <div class="w-100">
      <!-- <voiply-wistia-player videoId="8uif98dvwl" style="height:100%;left:0;top:0;width:100%;"></voiply-wistia-player> -->
      <div style="padding:56.25% 0 0 0;position:relative;">
        <iframe src="https://player.vimeo.com/video/571633196?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;portrait=0&amp;byline=0&amp;title=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Emergency999_AllVideos.mp4"></iframe>
      </div>
      <div style="background-color: #363636;" class="px-5 py-1"><img src="assets/images/noonlight.png" class="w-100">
      </div>
    </div>
  </div>



  <div class="d-flex flex-column flex-lg-row justify-content-center mt-3">
    <!--Manage Emergency Contacts-->

    <div class="d-flex flex-column flex-grow-1">
      <div class="w-100 py-3 mb-3 bg-primary-gradient d-flex align-items-center justify-content-center">
        <span class="h5 m-0 text-primary font-weight-bold">
          Manage Emergency Contacts</span>
      </div>
      <form #customForm="ngForm">
        <div class="d-flex flex-row flex-wrap flex-md-nowrap">
          <div style="min-width: 50%; " class="position-relative flex-grow-1 flex-sm-grow-0">
            <input type="text" class="form-control " [(ngModel)]="contactName" [ngModelOptions]="{standalone: true}"
              #name="ngModel" placeholder="Type Name Here" required>
            <div *ngIf="((name.touched) && name.errors)">
              <span *ngIf="name.errors.required">
                <i class="far fa-exclamation-circle is-error" tooltip="Please enter Name"></i></span>
            </div>
          </div>
          <div class="d-flex flex-grow-1 flex-sm-grow-0 flex-wrap flex-sm-nowrap mt-3 mt-sm-0" style="min-width: 50%;">
            <div class="position-relative w-100">
              <input type="text" class="form-control" [(ngModel)]="contactNumber" [ngModelOptions]="{standalone: true}"
                #number="ngModel" placeholder="Type Phone Here" mask="(000)000-0000" required>
              <div *ngIf="((number.touched) && number.errors)">
                <span *ngIf="number.errors.required">
                  <i class="far fa-exclamation-circle is-error" tooltip="Please enter Number"></i></span>
                <span *ngIf="!number.errors.required">
                  <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Number"> </i></span>
              </div>
            </div>
            <div class="flex-grow-1 flex-sm-grow-0 mt-3 mt-sm-0">
              <button class="btn bg-black text-primary px-3 w-100" style="height: 46px;" type="button"
                (click)="addNewContact(name,number,customForm)"
                *ngIf="this.form.controls['emergencyContacts'].value.length<10">
                <i class="fas fa-plus d-none d-sm-block"></i><span class="d-sm-none">ADD CONTACT</span>
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="d-flex flex-column mt-3" *ngIf="this.form.controls['emergencyContacts'].value.length">
        <div class="tableheadrow d-flex border-top border-bottom align-items-center">
          <div class="py-2 tableheaddata font-weight-bold">Contact Name</div>
          <div class="py-2 tableheaddata border-left font-weight-bold">Emergency Number</div>
        </div>
        <div *ngFor="let emergencycontact of this.form.controls['emergencyContacts'].value; let i=index"
          class="tablebodyrow d-flex align-items-center">
          <div class="py-2 first d-flex justify-content-center position-relative">
            <button type="button" class=" btn p-0 position-absolute" style="top:7px;left:11px;"
              (click)="removeContact(i)">
              <i class="fas fa-times-circle text-secondary"></i>
            </button>
            <div style="width: 70%;" class="text-truncate"> {{emergencycontact.name}}</div>
          </div>
          <div class="py-2 last border-left">{{emergencycontact.phone|mask:'0(000)000-0000'}}</div>
        </div>
        <div class="tableheadrow d-flex border-top border-bottom justify-content-center align-items-center">
          <div class="py-2 text-secondary" *ngIf="this.form.controls['emergencyContacts'].value.length">
            {{this.form.controls['emergencyContacts'].value.length}} <span
              *ngIf="this.form.controls['emergencyContacts'].value.length>1">Contacts</span> <span
              *ngIf="this.form.controls['emergencyContacts'].value.length<=1">Contact</span> Added</div>
        </div>
      </div>
    </div>
    <!--Phone Image && Group SMS-->
    <div class="d-flex flex-column mt-3 mt-lg-0">
      <!-- <div class="d-flex align-items-center justify-content-center mb-3">
        <span
          class="mr-3 h5 mb-0 text-secondary font-weight-bold">
          Group
          SMS</span>
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label [ngClass]="this.form.controls['enableEmergency'].value ? 'btn btn-primary':'btn btn-secondary'"
            class="px-3 py-1">
            <input type="radio" autocomplete="off" formControlName="enableEmergency" [value]=true>
            On
          </label>
          <label [ngClass]="!this.form.controls['enableEmergency'].value ? 'btn btn-primary':'btn btn-secondary'"
            class="px-3 py-1">
            <input type="radio" autocomplete="off" formControlName="enableEmergency" [value]=false>
            Off
          </label>
        </div>
      </div> -->
      <div class="mt-3 mt-lg-0 d-flex justify-content-center"> <img src="assets/images/mobile-phone.png"
          style="height: 377px;"> </div>
    </div>
  </div>
</div>
