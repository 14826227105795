<div class="d-flex align-items-center">
  <div class="flex-fill">
    <span *ngIf="!checkoutDetail.payAnnually" class="text-secondary font-weight-bold" style="font-size: 12px;">MONTHLY
      SUBTOTAL</span>
    <span *ngIf="checkoutDetail.payAnnually" class="text-secondary font-weight-bold" style="font-size: 12px;">ANNUAL
      SUBTOTAL</span>
  </div>

  <div>
    <ng-container *ngIf="calculatingTotal">
      <mat-spinner diameter="18" value="accent" class="float-right white-spinner"></mat-spinner>
    </ng-container>
    <span class="text-secondary" *ngIf="!calculatingTotal">
      <h4 *ngIf="!checkoutDetail.payAnnually" class="font-weight-bold m-0" style="font-size: 15px;">
        {{checkoutDetail.monthlyTotal | formatMoneyValue}}</h4>
      <h4 *ngIf="checkoutDetail.payAnnually" class="font-weight-bold m-0" style="font-size: 15px;">
        {{(checkoutDetail.monthlyTotal * 12) | formatMoneyValue}}</h4>
    </span>
  </div>
</div>