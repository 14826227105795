<!--Lib: product-configuration -->
<!--Component: home-configuration/extraline-setting-home, business-configuration/extraline-setting-business components-->
<!--Added for Extraline Settings-->

<div [formGroup]="form" class="d-flex flex-column">
  <div class="d-flex justify-content-start bg-dark text-white rounded p-2">
    <!-- heading -->
    <span class="pr-2 d-flex align-items-center">
      <img src="assets/images/extraline/icons/call-recording.svg" class="icon-white">
    </span>
    <span class="text-uppercase font-weight-bold">Call Recording</span>
  </div>
  <div class="input-group rounded overflow-auto mt-3">
    <input type="text" class="form-control border-0 bg-light-grey" style="box-shadow: none !important;"
      placeholder="Email" formControlName="email"
      [ngClass]="{ 'is-invalid': ((f.email.touched || submitted) && f.email.errors) }">
    <div *ngIf="((f.email.touched || submitted) && f.email.errors)">
      <span *ngIf="f.email.errors.required">
        <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
      <span *ngIf="f.email.errors.email">
        <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
    </div>

  </div>
  <div class="mt-3 mb-1" style="font-size: small; color: #B5B5B5">
    All call recording can be downloaded from the e-mail added above for up to 30 days.
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <span class="font-weight-bold">Record All Incoming Calls</span>
    <!-- * toggle buttons -->
    <div class=" btn-group btn-group-toggle bg-light-grey border rounded" data-toggle="buttons">
      <button type="button" (click)="this.form.get('recordIncoming').patchValue('No')" class="btn text-muted"
        style="border-radius: 0.25rem 0 0 0.25rem; font-size: 12px;"
        [ngClass]="{'bg-error-gradient text-primary': this.form.get('recordIncoming').value === 'No'}">
        <div class="d-flex align-items-center justify-content-center h-100 font-weight-bold">
          <input type="radio" autocomplete="off" value="No" formControlName="recordIncoming">
          <span>NO</span>
        </div>
      </button>
      <button type="button" (click)="this.form.get('recordIncoming').patchValue('Yes')" class="btn text-muted"
        style="border-radius: 0 0.25rem 0.25rem 0; font-size: 12px;"
        [ngClass]="{'bg-success-gradient text-primary': this.form.get('recordIncoming').value === 'Yes'}">
        <div class="d-flex align-items-center justify-content-center font-weight-bold">
          <input type="radio" autocomplete="off" value="Yes" formControlName="recordIncoming">
          <span>YES</span>
        </div>
      </button>
    </div>
  </div>
</div>