import { Directive, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Select } from "@ngxs/store";
import { APPSCONST, AppStateSelectors, CrispService, OnDestroyCleanup, QrCodeService } from "@voiply/shared/common-ui";
import { CheckoutDetail, Users, Shippment, NumberPorting, FirstPromoter } from "@voiply/shared/model";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from 'lodash';
@Directive()
export class HeaderOrderTrackingBase extends OnDestroyCleanup implements OnInit {

  public checkoutDetail: CheckoutDetail;
  hasOnlyFax = false;

  @Select(AppStateSelectors.users) users$: Observable<Users[]>;
  @Select(AppStateSelectors.shipment) shipment$: Observable<Shippment>;
  @Select(AppStateSelectors.numberPorting) numberPorting$: Observable<NumberPorting>;
  @Select(AppStateSelectors.firstPromoter) firstPromoter$: Observable<FirstPromoter>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  @Select(AppStateSelectors.cartHasHardwareAfterCheckout) cartHasHardware$: Observable<boolean>;
  @Select(AppStateSelectors.showVoiplyOnlineApp) showVoiplyOnlineApp$: Observable<boolean>;
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<boolean>;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
  qrCodeData;

  constructor(private crispService: CrispService, private toastr: ToastrService, public qrCodeService: QrCodeService, private domSanitizer: DomSanitizer) {
    super();
    this.subscriptions$.add(this.checkoutDetail$.subscribe(data => {
      this.checkoutDetail = data;
    }))
    this.subscriptions$.add(this.cartItems$.subscribe(data => {
      this.hasOnlyFax = _.every(data, ['heading', APPSCONST.ONLINEFAX]);
    }))
  }

  ngOnInit() {
    this.orderId$.pipe(take(1)).subscribe(id => {
      this.qrCodeService
        .getQrCodes(id)
        .pipe(take(1))
        .subscribe(data => this.qrCodeData = this.domSanitizer.bypassSecurityTrustHtml(data));
    });

  }
  sendInvite(event) {
    let userData = [];
    event.forEach(element => {
      userData.push({ "email": element.email, "data": { "user_id": element.id, "name": element.firstName } })
    });
    if (userData.length > 0)
      this.crispService.setUserEvent("order:appinvitation", userData).subscribe();
    this.toastr.success('Invitation sent Successfully');
  }

  sendRefer(referEmail) {
    this.crispService.setSessionEvent('checkout:referrer', referEmail);
    this.toastr.success('Invitation sent Successfully');
  }
}