import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select } from "@ngxs/store";
import { AppStateSelectors } from "@voiply/shared/common-ui";
import { CheckoutDetail } from "@voiply/shared/model";
import { Observable } from "rxjs";

@Component({
  selector: 'extraline-settings-upgrade-to-annual',
  templateUrl: './upgrade-to-annual.component.html',
  styleUrls: ['./upgrade-to-annual.component.scss']
})
export class UpgradeToAnnualComponent {
  queryParams;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;
  constructor(private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
          this.queryParams = params;
      })
  }
}
