import { Component, OnInit, ChangeDetectorRef, EventEmitter, ViewChild, ElementRef, Input, Renderer2 } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal/';
import { ToastrService } from 'ngx-toastr';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import * as _ from 'lodash';
import { Card, Stripe, Phone, CheckoutDetail, CartItem, CartItemType, SystemType, Address, ShippingAddress, PhoneConfiguration, IAddMorePhoneTemplate } from '@voiply/shared/model';
import { OnDestroyCleanup, AppStateSelectors, StripeDataChangedAction, ChangeBillingPlanAction, UserService, StripeService, CrispService, ShippingService, Validate, AddPhonesToCartAction, UpdateShippingOptions, RemoveCartItemAction, UpdatecartItemPaidStatusAction, OrderedMorePhonesAction, ConversionService, OrderService } from '@voiply/shared/common-ui';
import { CheckoutBase } from '../checkout.base';
import { AuthService } from '@voiply/auth0';
import * as LogRocket from 'logrocket';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'checkout-add-more-phones-home',
  templateUrl: './add-more-phones-home.component.html',
  styleUrls: ['./add-more-phones-home.component.scss']
})
export class AddMorePhonesHomeComponent extends CheckoutBase {

  @Input() orderId: string;
  PayNowClicked = false;
  activePhones = [];
  selectedCountry = 'US';
  phoneQuantity = 1;
  selectedPhone: Phone;
  previousVideo = '';
  _rerenderVideo = true;
  groupedCartItems: any[];
  showBillingAddressInput = false;
  showShippingAddressInput = false;
  newBillingAddress: Address;
  newShippingAddress: Address;
  unMountCard = false;
  paymentMethodId = '';
  phones = [];
  @Select(AppStateSelectors.cardDetails) cardDeatils$: Observable<Card>;
  @Select(AppStateSelectors.phones) phones$: Observable<Phone[]>;
  @Dispatch() removeCartItemAction = (CartItemKey: string) => new RemoveCartItemAction(CartItemKey);
  @Dispatch() updateShippingOptions = (fetchOptions: boolean) => new UpdateShippingOptions(fetchOptions);
  @Dispatch() updatecartItemPaidStatus = (cartItems: any) => new UpdatecartItemPaidStatusAction(cartItems);
  @Dispatch() orderedMorePhones = (orderedMorePhones: boolean) => new OrderedMorePhonesAction(orderedMorePhones);
  @Dispatch() addPhonesToCart = (phoneFeatureId: number, quantity: number, phoneConfiguration: PhoneConfiguration) => new AddPhonesToCartAction(phoneFeatureId, quantity, phoneConfiguration,);
  @Dispatch() onStripeDataChanged = (stripe) => new StripeDataChangedAction(stripe);
  constructor(
    public bsModalRef: BsModalRef,
    cd: ChangeDetectorRef,
    stripeService: StripeService,
    crispService: CrispService,
    shippingService: ShippingService,
    tostr: ToastrService,
    authService: AuthService,
    userService: UserService,
    modalService: BsModalService,
    orderService: OrderService,
    renderer: Renderer2,
    public conversionService: ConversionService,
    router: Router,
    route: ActivatedRoute,
    location: Location
  ) {
    super(crispService, shippingService, SystemType.Home, tostr, authService, modalService, renderer, userService, conversionService, orderService, router, route, location);
    this.updateShippingOptions(true);


    this.subscriptions$.add(this.phones$.subscribe((phones) => {
      this.phones = phones;
      this.activePhones = _.filter(phones, (phone1) => phone1.isActive)
      this.selectedPhone = this.activePhones[0];
    }));
    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      this.selectedCountry = checkoutDetail.shippingAddress.country;
      this.newBillingAddress = checkoutDetail.billingAddress;
      this.newShippingAddress = checkoutDetail.shippingAddress;
    }));

    this.subscriptions$.add(this.cartItems$.subscribe((data) => {
      const cartItems = _.pickBy(data, (value, key) => !value.paid);
      // tslint:disable-next-line: forin
      for (const key in cartItems) {
        this.cartHasHardware = cartItems[key].type === CartItemType.Phone && (cartItems[key].itemId !== 4 && cartItems[key].itemId !== 11);
      }
      this.groupedCartItems = [];

      const cartItemArray: CartItem[] = [];
      // tslint:disable-next-line: forin
      for (const key in cartItems) {
        cartItemArray.push({ key: key, ...cartItems[key] });
      }

      //Process phones
      const phoneItems = _.chain(cartItemArray).filter((item) => item.type === CartItemType.Phone).groupBy(item => item.itemId).value();
      _.each(phoneItems, (items) => {
        const monthlyChargeText = this.convertToMoneyFormat(items[0].monthlyCharge * items.length);
        let phoneUnavailable = false;
        if (!_.some(this.activePhones, (phone) => phone.featureId === items[0].itemId)) {
          phoneUnavailable = true;
        }
        if (items[0].itemId === 4) {
          const totalPhones = _.reduce(cartItemArray, (result, cartItem) => result + (cartItem.type === CartItemType.Phone && cartItem.itemId !== 4 ? 1 : 0), 0);

          this.groupedCartItems.push({
            ...Object.assign({}, items[0]), qty: items.length + totalPhones, totalCost: '', totalDiscountedCost: 0,
            monthlyTotalCostText: `Monthly Charges: ${items.length}x ${monthlyChargeText} & ${totalPhones}x INCLUDED`, phoneUnavailable
          });
        } else {
          this.groupedCartItems.push({
            ...Object.assign({}, items[0]), qty: items.length,
            totalCost: items[0].price > 0 ? items[0].price * items.length : 'FREE',
            totalDiscountedCost: items[0].discountedPrice > 0 ? items[0].discountedPrice * items.length : 'FREE',
            monthlyTotalCostText: "Monthly Charges: " + monthlyChargeText, phoneUnavailable
          });
        }
      });
    }));
    this.subscriptions$.add(this.updateShipping$.subscribe((fetchOptions) => {
      if (fetchOptions) this.shippingService.fetchShippingOptionsHome(this.orderId)
    }));
  }
  canPayForOrder() { }

  getItemImage(itemId: string, itemType: CartItemType) {
    if (this.phones.length > 0) return _.filter(this.phones, (phone) => phone.featureId === itemId)[0]?.image || '';
  }

  convertToMoneyFormat(value) {
    return '$' + (parseFloat((Math.round(value * 100) / 100).toString()).toFixed(2));
  }

  phoneChange(phone) {
    this.selectedPhone = phone;
    //This is done to force rerender the Video
    setTimeout(() => this._rerenderVideo = false);
    setTimeout(() => this._rerenderVideo = true);
  }

  phoneAvailableInCountry(phone) {
    if (phone.onlyAvailableInCountry.length) {
      if (_.includes(phone.onlyAvailableInCountry, this.selectedCountry)) {
        return true
      } else return false
    } else return true
  }



  async onPayNow() {
    this.PayNowClicked = true;
    const unpaidCartItems = _.pickBy(this.cartItems, (value, key) => !value.paid);
    const phonesQty = _.size(unpaidCartItems);
    try {
      await this.userService.chargePaymentPhones(this.orderId, this.shippingService.checkoutDetail.orderTotal, phonesQty, this.taxDetail, this.paymentMethodId, this.taxDetail.submissionId);
      this.updatecartItemPaidStatus(unpaidCartItems);
      this.orderedMorePhones(true);
      this.PayNowClicked = false;
      // this.crispService.setUserEvent("order:additional", [{ "email": this.shippingService.checkoutDetail.billingAddress.email, "data": { "orderId": this.orderId } }]).subscribe();
      let data: IAddMorePhoneTemplate = {
        substitution_data: {
          id: this.orderId,
          name: this.shippingService.checkoutDetail.companyName,
          shipping_address: {
            name: this.shippingService.checkoutDetail.shippingAddress.name,
            address_line: this.shippingService.checkoutDetail.shippingAddress.address,
            city: this.shippingService.checkoutDetail.shippingAddress.city,
            state: this.shippingService.checkoutDetail.shippingAddress.state,
            country: this.shippingService.checkoutDetail.shippingAddress.country,
            zip: this.shippingService.checkoutDetail.shippingAddress.zip,
          },
          product_details: [],
          order_payment: {
            invoice_onetime: null,
            invoice_recurring: this.shippingService.checkoutDetail.monthlyTotal,
            text_shipping: this.shippingService.checkoutDetail.shippingOption,
            invoice_shipping: this.shippingService.checkoutDetail.shippingCharges,
            invoice_tax: this.shippingService.checkoutDetail.taxDetail.estimatedTotalTax,
            invoice_total: Math.round(this.shippingService.checkoutDetail.orderTotal),
            invoice_total_decimal: Math.round((this.shippingService.checkoutDetail.orderTotal - Math.round(this.shippingService.checkoutDetail.orderTotal)) * 100),
          },
        },
        metadata: {},
        options: {},
        email: 'support@voiply.com'
      };
      _.forIn(unpaidCartItems, (value, key) => {
        data.substitution_data.product_details.push(
          {
            name: value.heading,
            quantity: value.qty,
            rate_in_dollar: value.discountedPrice,
            image_path: `${location.protocol}//${location.host}/${this.getItemImage(value.itemId, value.type)}`,
          },
        )
      });
      data.substitution_data.order_payment.invoice_onetime = _.sumBy(data.substitution_data.product_details, 'rate_in_dollar');
      this.orderService.postAddMorePhoneTemplate(data).subscribe();

      this.tostr.success('Payment Successful! You can now click on "Manage Your Phones" to the left');
      this.bsModalRef.hide();
    } catch (e) {
      this.PayNowClicked = false;
      console.log(e);
      this.tostr.error(e.error.error);
    }
  }
  newBillingAddressChange() {
    this.onShippingSameAsBillingChanged(false);
    const isValidBillingAddress = Validate(Address, this.newBillingAddress);
    if (!isValidBillingAddress)
      return false;
    this.billingAddressChange(this.newBillingAddress);
    this.showBillingAddressInput = false;
  }

  newShippingAddressChange() {
    this.onShippingSameAsBillingChanged(false);
    const isValidShippingAddress = Validate(ShippingAddress, this.newShippingAddress);
    if (!isValidShippingAddress)
      return false;
    this.shippingAddressChange(this.newShippingAddress);
    this.showShippingAddressInput = false;
    // this.updateShippingOptions(true);
  }

  onAddPhoneToCart() {
    const phone = this.selectedPhone;
    const quantity = this.phoneQuantity;
    const phoneConfiguration = new PhoneConfiguration();
    if (this.billingAddress.name) {
      const a = this.billingAddress.name.lastIndexOf(' ')  // last occurence of space
      phoneConfiguration.firstName = (a === -1) ? this.billingAddress.name : this.billingAddress.name.substring(0, a);
      phoneConfiguration.lastName = (a === -1) ? '' : this.billingAddress.name.substring(a + 1);
    }
    phoneConfiguration.email = this.billingAddress.email || '';
    // save cart item to state
    this.addPhonesToCart(phone.featureId, quantity, phoneConfiguration);


    if (phone.heading === "Deluxe Phone") {
      this.crispService.setSessionEvent("sales:phone:deluxe", { "added-new-deluxe-phone": true });
      LogRocket.track("sales:phone:deluxe");
    }

    this.tostr.success(` ${quantity} ${phone.heading} added to your cart.`);
  }

  onRemoveCartItem(cartItem) {
    this.removeCartItemAction(cartItem.key);
  }

}
