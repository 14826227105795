import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { UserIsVoiplyTeamMemberAction, SetOrderIdAction, FillOrderAction, AppStateSelectors, } from "../+state";
import { UserService, OrderService, CrispService } from "../services";

@Injectable({
    providedIn: 'root'
})
export class ExtralineSettingsResolver implements Resolve<any> {

    @Dispatch() userIsVoiplyTeamMember = (isTrue) => new UserIsVoiplyTeamMemberAction(isTrue);
    @Dispatch() saveOrderId = (orderId: any) => new SetOrderIdAction(orderId);
    @Dispatch() saveOrderState = (orderData: any) => new FillOrderAction(orderData);

    constructor(
        private userService: UserService,
        private orderService: OrderService, 
        private crispService: CrispService, 
        private toastr: ToastrService,
        private store:Store,
        private router:Router
        ) {}
    
    async resolve(route: ActivatedRouteSnapshot) {
         // Check order id is there or not
      let orderId = route.queryParamMap.get('o');
      if (!orderId) {
        // this.toastr.error("order Id is Missing")
        console.log("Order id is missing: ", orderId);
        this.router.navigateByUrl('/error?err=orderId');
        return false;
      }
      const userId = route.queryParamMap.get('u');
      if (!userId) {
        // this.toastr.error("User Id is Missing")
        console.log("user id is missing: ", userId);
        this.router.navigateByUrl('/error?err=userId');
        return false;
      }
      if(this.store.selectSnapshot<string>(AppStateSelectors.orderId)){
          return true
      }
      const orderData: any = await this.orderService.getOrderAsync(orderId);
      if (!orderData) {
        //   this.toastr.error('Order not found');
        console.log('Order not found');
        this.router.navigateByUrl('/error?err=orderData');
        return false
      }

      const auth0Id = localStorage.getItem('auth0Id');
      if (auth0Id) {
        this.userService.isVoiplyTeamMember(auth0Id).then(value => {
          this.userIsVoiplyTeamMember(value.voiplyTeamMember);
          console.log(`[USER] User ${auth0Id} ${value.voiplyTeamMember ? 'is' : 'is not'} Voiply Team Member.`);

          if (!value.voiplyTeamMember) {
            this.crispService.initCrisp(orderId);
          }
        });
      } else {
        this.crispService.initCrisp(orderId);
      }

      // Get object from Cosmos
      //Save order in state
      this.saveOrderId(orderId);
      this.saveOrderState(orderData);

      return true;
    }
 
}