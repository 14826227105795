import { required, email } from '@rxweb/reactive-form-validators';

export class OnlineFax {
    @required()
    @email()
    email: string;
    @required()
    mobile: number;
    isNewNumber: boolean;
    coverpage: string;
}