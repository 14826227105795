import { AudioRecording } from './audio-recording.model';
import { OutsideBusinessHoursType, RingType } from './enums';

export class AutoAttendantRingCallFlow {
    opens24_7 = true;
    timeZone = 'est';
    businessHours = {};
    outsideBusinessHours = {
        type: OutsideBusinessHoursType.RingOne,
        ringOneUser: '',
        voiceMailUser: '',
        voicemailRingTimeoutCount: 4,
        ringType: RingType.Simultaneous,
        users: {},
        app: '',
        voicemailRecording: new AudioRecording(),
        announcementRecording: new AudioRecording()
    }
}