import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { CallforwardingFormControlBase } from '../call-forwarding-form-control.base';

@Component({
  selector: 'voiply-callforwarding-form-control',
  templateUrl: './call-forwarding-form-control.component.html',
  styleUrls: ['./call-forwarding-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CallforwardingFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CallforwardingFormControlComponent),
      multi: true
    }
  ]
})
export class CallforwardingFormControlComponent extends CallforwardingFormControlBase {
  constructor() {
    super();
  }
}
