<div class=" mt-3">
  <common-ui-section-header imagePath="/assets/images/online-store-shopping-cart.png" title="Order Summary"
    id="orderSummary">
  </common-ui-section-header>

  <div class="row">
    <div class="col-12 col-md-6 pt-3">
      <voiply-billing-details [checkoutDetail]="checkoutDetail" [cardDetail]="card" [orderId]="orderId"
        (stripeDataChanged)="stripeDataChanged($event)"></voiply-billing-details>
    </div>
    <div class="col-12 col-md-6 pt-3">
      <voiply-subscription [invoices]="customerInvoices" [nextBillingDate]="nextBillingDate"
        [nextBillingTotal]="nextBillingTotal" [currentBillingDate]="currentBillingDate"
        [currentBillingTotal]="currentBillingTotal"></voiply-subscription>
    </div>
  </div>
  <div class="d-flex flex-row p-4 align-items-center justify-content-between bg-primary-gradient mt-3"
    *ngIf="!isPayAnually">
    <div class="d-flex flex-column">
      <div class="font-weight-bold text-primary">Save 20% a Year by Switching to Annual Billing</div>
      <div class="text-primary">Your Price will Drop to <span
          class="font-weight-bold">${{monthlyTotalAnnualPrePay}}/Month. YAY!</span> </div>
    </div>
    <button type="button" class="btn btn-outline-light px-4 py-2 font-weight-bold"
      (click)="this.annualBillingComponent()">Switch To Annual</button>
  </div>
</div>