import { Input, Directive } from '@angular/core';
import { CheckoutDetail } from '@voiply/shared/model';

@Directive()
export class SubTotalBase {
    @Input()
    checkoutDetail: CheckoutDetail;
    @Input()
    calculatingTotal = false;

    constructor() { }
} 