import { OnInit, forwardRef, Input, EventEmitter, Output, Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl } from '@angular/forms';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Directive()
export class CallRecordingFormControlBase extends ControlValueAccessorAndValidator implements OnInit {
  @Input() submitted = false;
  @Output() isHideConfig: EventEmitter<any> = new EventEmitter();
  isHideConfiguration = false;

  constructor() {
    super();
    this.form = new FormGroup({
      email: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.email()], updateOn: 'blur' }),
      recordIncoming: new FormControl(''),
      recordOutgoing: new FormControl('')
    });
  }

  ngOnInit() {
    this.isHideConfig.emit(this.isHideConfiguration);
  }

  toggleConfig() {
    this.isHideConfiguration = !this.isHideConfiguration
    this.isHideConfig.emit(this.isHideConfiguration);
  }
}
