import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';

import { ActivatedRouteSnapshot } from '@angular/router';
import { UserService, OrderService, CrispService } from '../services';
import { EMPTY } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { UserIsVoiplyTeamMemberAction, GetPhonesAndApps, GetPhoneStatus } from '../+state';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TroubleshootingComponent } from '../components';

@Injectable({
    providedIn: 'root'
})
export class AfterCheckoutResolver implements Resolve<any> {
    bsModalRef: BsModalRef;
    @Dispatch() getPhonesAndApps = () => new GetPhonesAndApps();
    @Dispatch() getPhoneStatus = () => new GetPhoneStatus();
    @Dispatch() userIsVoiplyTeamMember = (isTrue) => new UserIsVoiplyTeamMemberAction(isTrue);
    constructor(private userService: UserService, private orderService: OrderService, private crispService: CrispService, private modalService: BsModalService) { }

    async resolve(route: ActivatedRouteSnapshot) {

        const orderId = route.paramMap.get("id");
        // Dispatch action to fetch phones and apps data
        this.getPhonesAndApps();

        //Dispatch action to fetch phone statuses.
        this.getPhoneStatus();

        //TODO: invoices is commented as it was not used in old project. confirm and uncomment below code
        // const invoices: Invoice[] = await this.userService.getCustomerInvoicesAsync(orderId);
        // if(invoices) {
        //   _.forEach(invoices, (v, k) => {
        //     v.created = new Date(0); v.created.setUTCSeconds(v.created);
        //   });
        // }
        if (route.queryParamMap.get('troubleshooting')) {
            this.openTroubleShooting(orderId);
        }
        const auth0Id = localStorage.getItem('auth0Id');
        if (auth0Id) {
            await this.userService.isVoiplyTeamMember(auth0Id).then(value => {
                this.userIsVoiplyTeamMember(value.voiplyTeamMember);
                console.log(`[USER] User ${auth0Id} ${value.voiplyTeamMember ? 'is' : 'is not'} Voiply Team Member.`);
                if (!value.voiplyTeamMember) {
                    this.crispService.initCrisp(orderId);
                    //when customer is logged in portal
                    this.crispService.setSessionData('portal_auth', 'success');
                } else {
                    this.crispService.isUserVoiplyTeamMember = true
                }
            });
        } else {
            this.crispService.initCrisp(orderId);
            //when customer is logged in portal
            this.crispService.setSessionData('portal_auth', 'success');
        }



        return true;
    }

    openTroubleShooting(orderId) {
        const initialState = {
            orderId: orderId
        };
        this.bsModalRef = this.modalService.show(TroubleshootingComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
    }


}