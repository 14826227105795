import { Component, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BillingDetailService, UserService, StripeService } from '../../../services';
import { ToastrService } from 'ngx-toastr';
import { AnnualBillingModalBase } from '../annual-billing-modal.base.';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ChangeBillingPlanAction } from '../../../+state/app.actions';


@Component({
  selector: 'common-ui-annual-billing-modal',
  templateUrl: './annual-billing-modal.component.html',
  styleUrls: ['./annual-billing-modal.component.scss']
})
export class AnnualBillingModalComponent extends AnnualBillingModalBase {
  @Dispatch() onChangeBillingPlanAction = (isAnnualBilling) => new ChangeBillingPlanAction(isAnnualBilling);

  constructor(public bsModalRef: BsModalRef, protected billingDetailService: BillingDetailService,
    protected cd: ChangeDetectorRef, protected tostr: ToastrService, protected userService: UserService, public stripeService: StripeService) {
    super(billingDetailService, cd, tostr, userService, stripeService);
  }

  onChangePaymentPlan() {
    this.confirmButtonClicked = true;
    this.billingDetailService.changePaymentPlan(this.billingDetails).then((response) => {
      if (response) {
        this.confirmButtonClicked = false;
        this.onChangeBillingPlanAction(true);
        this.tostr.success("Subscription upgraded to Annual successfully.");
      }
      this.bsModalRef.hide();
    });
  }
}
