import { RingType } from "./enums";

export class CallScenarioRingUserModel{
    ring: {
        type: RingType,
        users: any
    }
        = { type: RingType.Simultaneous, users: {} };
    voiceMailUser: string = '';
    ringOneUser: string = '';
    voicemailRingTimeoutCount: number = 4;
    timeZone = 'est';
    callForward = false;
    callForwardNumbers = [];
}