import { BaseService } from './base.service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as _ from "lodash";
import { ENVIRONMENT } from '../constants';
import { IEnvironment } from '../environment.interface';

@Injectable({
    providedIn: 'root'
})
export class BillingDetailService extends BaseService {
    billingDetails: Observable<any>;
    constructor(private http: HttpClient,@Inject(ENVIRONMENT) private environment: IEnvironment) {
        super();
    }
    annualBillingDetails(orderId: string): Observable<any> {
        if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
            return this.http.get(`${this.environment.voiplyOnlinePaymentAPI}/subscription-get-data?code=f3L2baSnYLQDW446YPgta60sm4eo6nnZsaU0KvOzDUqZm7dtgwq7nw==&id=${orderId}&dev=true`).pipe(catchError(this.handleError))
        else
            return this.http.get(`${this.environment.voiplyOnlinePaymentAPI}/subscription-get-data?code=f3L2baSnYLQDW446YPgta60sm4eo6nnZsaU0KvOzDUqZm7dtgwq7nw==&id=${orderId}`).pipe(catchError(this.handleError))
    }

    changePaymentPlan(billingDetail): Promise<any> {
        const billingDetails = _.mapKeys(billingDetail, (value, key) => _.snakeCase(key))
        if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
            return this.http.post(`${this.environment.voiplyOnlinePaymentAPI}/subscription-upgrade?code=f3L2baSnYLQDW446YPgta60sm4eo6nnZsaU0KvOzDUqZm7dtgwq7nw==&dev=true`, billingDetails, { responseType: 'text' }).pipe(catchError(this.handleError)).toPromise();
        else
            return this.http.post(`${this.environment.voiplyOnlinePaymentAPI}/subscription-upgrade?code=f3L2baSnYLQDW446YPgta60sm4eo6nnZsaU0KvOzDUqZm7dtgwq7nw==`, billingDetails, { responseType: 'text' }).pipe(catchError(this.handleError)).toPromise();
    }
    
    extralineUpgrade(plan: string, order_id: string) { //biz for business and res for home
        if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
            return this.http.post(`${this.environment.voiplyOnlinePaymentAPI}/upgrade/${plan}/${order_id}?code=HDrPMWj/kXs381Dfno6dNaxG5ryPjwKNIeag/wDafJNdzQBvQJiEGQ==&dev=true`, '').toPromise();
        else
            return this.http.post(`${this.environment.voiplyOnlinePaymentAPI}/upgrade/${plan}/${order_id}?code=HDrPMWj/kXs381Dfno6dNaxG5ryPjwKNIeag/wDafJNdzQBvQJiEGQ==`, '').toPromise();
    }
}