import { OnChanges, Input, Output, EventEmitter, SimpleChanges, Directive } from '@angular/core';
import { CheckoutDetail, ShippingOptions } from '@voiply/shared/model';
import { ShippingService } from '@voiply/shared/common-ui';
import * as _ from 'lodash';

@Directive()
export class ShippingOptionsBase implements OnChanges {
    @Input()
    checkoutDetail: CheckoutDetail;
    @Input()
    calculatingTotal = false;
    @Output()
    shippingChange = new EventEmitter<ShippingOptions>();

    selectedShippingOption: ShippingOptions;

    get shippingOptions(): ShippingOptions[] {
        return this.shippingService.shippingOptions;
    }
    constructor(public shippingService: ShippingService) { }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.checkoutDetail) {
            _.each(this.shippingOptions, (data) => {
                if (data.charges === this.checkoutDetail.shippingCharges) {
                    this.selectedShippingOption = data;
                }
            });
        }
    }

    onShippingOptionChange(shippingOption: ShippingOptions) {
        this.shippingChange.emit(shippingOption);
    }
}