import { Component, Input, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { AddCodeForLoginAction, AddPhonesToCartAction, AppStateSelectors, BillingAddressChangeAction, CalculatingTotalAction, CompanyNameChangeAction, CrispService, GetBusinessPhones, RemoveCartItemAction, sanitizePhoneNumber, SelectShippingOptionAction, ShippingService, StripeDataChangedAction, StripeService, UpdateCartItemAction, UpdateCartItemConfigurationAction, UpdatecartItemPaidStatusAction, UpdateMetadataAction, UpdateShippingOptions, UserService, Validate } from "@voiply/shared/common-ui";
import { Address, CartItemType, CheckoutDetail, EventType, IAddMorePhoneTemplate, Phone, PhoneConfiguration, Stripe, SystemType, TaxDetail, CallFlow, CallScenarioModel } from "@voiply/shared/model";
import { Observable } from "rxjs";
import * as _ from "lodash"
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { ToastrService } from 'ngx-toastr';
import * as LogRocket from 'logrocket';
import { skip } from "rxjs/operators";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ThankyouModalAltComponent } from "../../components";
import { Location } from "@angular/common";
import uuid from 'uuid';
import { BillingDetailService, ConversionService, OrderService} from "libs/shared/common-ui/src/lib/services";
import { CheckoutBase } from "../checkout.base";
import { AuthService } from "@voiply/auth0";

@Component({
  selector: 'checkout-upgrade-to-business',
  templateUrl: './upgrade-to-business.component.html',
  styleUrls: ['./upgrade-to-business.component.scss']
})
export class UpgradeToBusiness extends CheckoutBase {
  
  @Input() backButtonUrl;
  orderId: string;
  activePhones = [];
  selectedPhone: Phone;
  cartItems: any;
  allCartItems: any;
  showCreditCard = false;
  phones = [];
  groupedCartItems: any[] = [];
  cartHasHardware: boolean;
  hasCartItems: boolean;
  taxDetail: TaxDetail;
  payNowDisable = false;
  calculatingTotal: boolean;
  selectedCountry = 'US';
  phoneQuantity = 1;
  billingAddress: Address;
  shippingAddress: Address;
  PayNowClicked = false;
  paymentMethodId = '';
  cartItemsLoaded: boolean = false;
  phonesLoaded: boolean = false;
  unMountCard = false;
  loginLink = '';
  modalRef: BsModalRef;
  @Select(AppStateSelectors.phones) phones$: Observable<Phone[]>;
  @Select(AppStateSelectors.cartItems) cartItems$: Observable<any>;
  @Select(AppStateSelectors.cartHasHardware) cartHasHardware$: Observable<boolean>;
  @Select(AppStateSelectors.checkoutDetails) checkoutDetail$: Observable<CheckoutDetail>;
  @Select(AppStateSelectors.calculatingTotal) calculatingTotal$: Observable<boolean>;
  @Select(AppStateSelectors.orderId) orderId$: Observable<string>;

  @Dispatch() getBusinessPhones = () => new GetBusinessPhones();
  @Dispatch() addPhonesToCart = (phoneFeatureId: number, quantity: number, phoneConfiguration: PhoneConfiguration, eventName: EventType) => new AddPhonesToCartAction(phoneFeatureId, quantity, phoneConfiguration, eventName);
  @Dispatch() onStripeDataChanged = (stripe) => new StripeDataChangedAction(stripe);
  @Dispatch() updatecartItemPaidStatus = (cartItems: any) => new UpdatecartItemPaidStatusAction(cartItems);
  @Dispatch() recalculatingTotal = (isCalculating: boolean) => new CalculatingTotalAction(isCalculating);
  @Dispatch() updateCartItemAction = (key: string, updateFeatureId: number, eventName) => new UpdateCartItemAction(key, updateFeatureId, eventName);
  @Dispatch() updateMetadataAction = (metaData) => new UpdateMetadataAction(metaData);
  @Dispatch() updateCartItemConfigurationAction = (key, cartItemConfiguration) => new UpdateCartItemConfigurationAction(key, cartItemConfiguration);
  // This Dispatch action is implemented by inheriting from checkout.base.ts so commented it
  // @Dispatch() selectShippingOption = (shippingOption, eventName) => new SelectShippingOptionAction(shippingOption, eventName);
  @Dispatch() addCodeForLogin = (codeForLogin) => new AddCodeForLoginAction(codeForLogin);
  @Dispatch() onBillingAddressChange = (billingAddress) => new BillingAddressChangeAction(billingAddress);
  @Dispatch() companyNameChange = (companyName) => new CompanyNameChangeAction(companyName);
  constructor(public router: Router, 
    public shippingService: ShippingService,
    public stripeService: StripeService, 
    public tostr: ToastrService,
    public crispService: CrispService, 
    public userService: UserService, 
    public modalService: BsModalService, 
    public renderer: Renderer2, 
    public location: Location, 
    private billingService:BillingDetailService,
    public authService: AuthService,
    public conversionService: ConversionService,
    public orderService: OrderService,
    public route: ActivatedRoute) {

    super(crispService,shippingService,SystemType.Business,tostr,authService,modalService,renderer,userService,conversionService,orderService,router,route,location)
    this.getBusinessPhones();


    this.subscriptions$.add(this.phones$.pipe(skip(1)).subscribe(phone => {
      this.phones = phone;
      this.activePhones = _.filter(phone, (phone) => phone.isActive)


      this.subscriptions$.add(this.cartItems$.subscribe(data => {
        this.allCartItems = data;
        this.cartItems = _.pickBy(data, (value, key) => !value.paid);

        //removing every unpaid cart items when we load it for first time
        if (!this.cartItemsLoaded) {
          this.cartItemsLoaded = true;
          _.forEach(this.cartItems, (value, key) => {
            this.removeCartItemAction(key, EventType.UpgradeToBusiness);
          });
        }
        if (!this.phonesLoaded) {
          this.phonesLoaded = true;
          this.selectedPhone = this.activePhones[0];
          this.onAddPhoneToCart();
        }

        //checking cart has any hardware or not(if yes then we need to calculate shipping details for it).
        // tslint:disable-next-line: forin
        for (const key in this.cartItems) {
          this.cartHasHardware = this.cartItems[key].type === CartItemType.Phone && (this.cartItems[key].itemId !== 4 && this.cartItems[key].itemId !== 11);
        }

        //converting cartItems object to an array
        const cartItemArray = [];
        // tslint:disable-next-line: forin
        for (const key in this.cartItems) {
          cartItemArray.push({ key: key, ...this.cartItems[key] });
        }

        this.groupedCartItems = [];
        //formatting the cart items
        const phoneItems = _.chain(cartItemArray).filter((item) => item.type === CartItemType.Phone).groupBy(item => item.itemId).value();
        _.each(phoneItems, (items) => {
          const monthlyChargeText = this.convertToMoneyFormat(items[0].monthlyCharge * items.length);
          let phoneUnavailable = false;
          if (!_.some(this.activePhones, (phone) => phone.featureId === items[0].itemId)) {
            phoneUnavailable = true;
          }
          this.groupedCartItems.push({
            ...Object.assign({}, items[0]), qty: items.length,
            totalCost: items[0].price > 0 ? items[0].price * items.length : 'FREE',
            totalDiscountedCost: items[0].discountedPrice > 0 ? items[0].discountedPrice * items.length : 'FREE',
            monthlyTotalCostText: "Monthly Charges: " + monthlyChargeText, phoneUnavailable
          });
        });

        this.hasCartItems = _.some(_.pickBy(this.cartItems, (value, key) => !value.paid));
      }));
    }));

    this.subscriptions$.add(this.cartHasHardware$.subscribe((cartHasHardware) => this.cartHasHardware = cartHasHardware));

    this.subscriptions$.add(this.checkoutDetail$.subscribe((checkoutDetail) => {
      this.selectedCountry = checkoutDetail.shippingAddress.country;
      this.shippingService.checkoutDetail = checkoutDetail;
      this.billingAddress = {...checkoutDetail.billingAddress};
      this.shippingAddress = checkoutDetail.shippingAddress;
      this.shippingService.shippingAddress = checkoutDetail.shippingAddress;
      this.taxDetail = { ...checkoutDetail.taxDetail };
      if (checkoutDetail.orderTotal === 0) {
        this.payNowDisable = true
      } else {
        this.payNowDisable = false
      }
    }));

    this.subscriptions$.add(this.calculatingTotal$.subscribe((calculatingTotal) => this.calculatingTotal = calculatingTotal));

    this.subscriptions$.add(this.orderId$.subscribe((orderId) => this.orderId = orderId));
    this.shippingService.fetchShippingOptionsBusiness(this.orderId);
    this.subscriptions$.add(this.shippingService.selectShipping.subscribe(() => {
      this.selectShipping()
    }));
  }

  ngOnInit(){
    //Add primary number in company number if it is not there
    const sanitizedPhoneNumber = sanitizePhoneNumber(this.primaryPhoneDetails.number.toString());
    if(this.companyNumbers.length<1){ 
      this.addCompanyNumber({ number: sanitizedPhoneNumber, isNew: this.primaryPhoneDetails.isNew, systemSelected: this.primaryPhoneDetails.systemSelected, isPrimaryNumber: true, name: 'Phone Number',/* callFlow: new CallFlow,*/ callScenario: new CallScenarioModel,isSelectedCallScenario: true})
    }
  }

  //To verify Company name and billing Address is correct or not
  canPayForOrder() {
    //this.getDefaultRingOneCallFlow();
    const isValidCompanyName = Validate(CheckoutDetail, this.shippingService.checkoutDetail)
    if (!isValidCompanyName) {
      return false;
    }
    const isValidBillingAddress = Validate(Address, this.billingAddress);
    if (!isValidBillingAddress) {
      return false;
    }
    else{
      return true;
    }
  }

  convertToMoneyFormat(value) {
    return '$' + (parseFloat((Math.round(value * 100) / 100).toString()).toFixed(2));
  }

  back() {
    this.router.navigateByUrl(this.backButtonUrl);
  }

  selectShipping() {
    if (_.some(this.shippingService.shippingOptions, option => option.id === this.shippingService.checkoutDetail.shippingOption)) {
      const shipping = _.filter(this.shippingService.shippingOptions, option => option.id === this.shippingService.checkoutDetail.shippingOption)[0];
      if (shipping.charges !== this.shippingService.checkoutDetail.shippingCharges
        || shipping.charges !== this.shippingService.checkoutDetail.discountedShippingCharges
        || shipping.label !== this.shippingService.checkoutDetail.shipperMethod)
        this.selectShippingOption(shipping, EventType.UpgradeToBusiness);
    } else {
      const shipping = _.sortBy(this.shippingService.shippingOptions, (option) => option.charges)[0];
      this.selectShippingOption(shipping, EventType.UpgradeToBusiness);
    }
  }

  phoneAvailableInCountry(phone) {
    if (phone.onlyAvailableInCountry.length) {
      if (_.includes(phone.onlyAvailableInCountry, this.selectedCountry)) {
        return true
      } else return false
    } else return true
  }

  getItemImage(itemId: string, itemType: CartItemType) {
    if (this.phones.length > 0) return _.filter(this.phones, (phone) => phone.featureId === itemId)[0]?.image || '';
  }

  onCartItemdropdownOptionChange() {
    if (this.groupedCartItems.length === 0) { this.onAddPhoneToCart(); return; }
    this.recalculatingTotal(true);
    this.selectShipping();
    this.updateCartItemAction(this.groupedCartItems[0].key, this.selectedPhone.featureId, EventType.UpgradeToBusiness)
  }
  onAddPhoneToCart() {
    if (!this.selectedPhone) return;
    const phone = this.selectedPhone;
    const quantity = this.phoneQuantity;
    const phoneConfiguration = new PhoneConfiguration();
    if (this.billingAddress?.name) {
      const a = this.billingAddress.name.lastIndexOf(' ')  // last occurence of space
      phoneConfiguration.firstName = (a === -1) ? this.billingAddress.name : this.billingAddress.name.substring(0, a);
      phoneConfiguration.lastName = (a === -1) ? '' : this.billingAddress.name.substring(a + 1);
    }
    phoneConfiguration.email = this.billingAddress?.email || '';
    // save cart item to state
    this.addPhonesToCart(phone.featureId, quantity, phoneConfiguration, EventType.UpgradeToBusiness);


    if (phone.heading === "Deluxe Phone") {
      this.crispService.setSessionEvent("sales:phone:deluxe", { "added-new-deluxe-phone": true });
      LogRocket.track("sales:phone:deluxe");
    }

    this.tostr.success(` ${quantity} ${phone.heading} added to your cart.`);
  }

  async onPayNow() {
    this.performValidation();
    setTimeout(() => {
      const section = document.getElementsByClassName("is-invalid");
      // console.log("section =", section[0].parentElement.scrollIntoView());
      if (section !== undefined && section.length > 0)
        window.scrollTo({
          top: section[0].getBoundingClientRect().top + window.scrollY - 200,
          left: window.pageXOffset,
          behavior: 'smooth'
        });
    }, 1000);
    if(this.canPayForOrder()){
      this.PayNowClicked = true;
      const unpaidCartItems = _.pickBy(this.cartItems, (value, key) => !value.paid);
      const phonesQty = _.size(unpaidCartItems);
    try {
        await this.userService.chargePaymentPhones(this.orderId, this.shippingService.checkoutDetail.orderTotal, phonesQty, this.taxDetail, this.paymentMethodId, this.taxDetail.submissionId);
        this.updateMetadataAction({ systemType: SystemType.Business });
        this.updatecartItemPaidStatus(unpaidCartItems);
        //upgrade in stripe
        this.billingService.extralineUpgrade('biz',this.orderId);

        const key = _.findKey(this.allCartItems, (o) => o.heading === "Voiply Extra Line")
        const unpaidCartItemKey = Object.keys(unpaidCartItems)[0];
        this.updateCartItemConfigurationAction(unpaidCartItemKey, this.allCartItems[key].configuration);
        this.removeCartItemAction(key, EventType.UpgradeToBusiness);

        this.PayNowClicked = false;
        this.tostr.success('You\'ve successfully upgraded to Business');

      const codeForLogin = uuid()
      this.addCodeForLogin(codeForLogin);
      this.loginLink = `https://build.voiply.com/${this.orderId}?code=${codeForLogin}`
      this.openThankYouModal();
      this.back();

      let data: IAddMorePhoneTemplate = {
        substitution_data: {
          id: this.orderId,
          name: this.shippingService.checkoutDetail.companyName,
          shipping_address: {
            name: this.shippingService.checkoutDetail.shippingAddress.name,
            address_line: this.shippingService.checkoutDetail.shippingAddress.address,
            city: this.shippingService.checkoutDetail.shippingAddress.city,
            state: this.shippingService.checkoutDetail.shippingAddress.state,
            country: this.shippingService.checkoutDetail.shippingAddress.country,
            zip: this.shippingService.checkoutDetail.shippingAddress.zip,
          },
          product_details: [],
          order_payment: {
            invoice_onetime: null,
            invoice_recurring: this.shippingService.checkoutDetail.monthlyTotal,
            text_shipping: this.shippingService.checkoutDetail.shippingOption,
            invoice_shipping: this.shippingService.checkoutDetail.shippingCharges,
            invoice_tax: this.shippingService.checkoutDetail.taxDetail.estimatedTotalTax,
            invoice_total: Math.round(this.shippingService.checkoutDetail.orderTotal),
            invoice_total_decimal: Math.round((this.shippingService.checkoutDetail.orderTotal - Math.round(this.shippingService.checkoutDetail.orderTotal)) * 100),
          },
        },
        metadata: {},
        options: {},
        email: 'support@voiply.com'
      };
      _.forIn(unpaidCartItems, (value, key) => {
        data.substitution_data.product_details.push(
          {
            name: value.heading,
            quantity: value.qty,
            rate_in_dollar: value.discountedPrice,
            image_path: `${location.protocol}//${location.host}/${this.getItemImage(value.itemId, value.type)}`,
          },
        )
      });
      
      this.orderService.postExtraLineUpgradeTemplate(data).subscribe();
    } catch (e) {
        this.PayNowClicked = false;
        console.log(e);
        this.tostr.error(e.error.error);
        this.back();
      }
    }
    else {
        this.tostr.error("Please Enter Details Correctly");
    }
    
  }

  stripeDataChanged(stripe: Stripe) {

    this.onStripeDataChanged(stripe);

    localStorage.setItem('paymentMethodId', stripe.paymentMethodId);   // Backup if stripe paymentMethodId is not saved in cosmos, we can use this to charge customer.
    LogRocket.track("checkout:payment-initiated");

    // Once saved to Cosmos, go for authentication, but make sure last call for SendMessage is complete, which is to save token in cosmos, otherwise it will faile.
    localStorage.setItem("OrderId", this.orderId);

  }

  openThankYouModal() {
    this.renderer.addClass(document.body, 'payment-successful-modal');
    this.modalRef = this.modalService.show(ThankyouModalAltComponent, {
      ignoreBackdropClick: true, initialState: {
        loginLink: this.loginLink,
        backButtonUrl: this.backButtonUrl
      }, class: 'h-100 m-auto d-flex align-items-center', keyboard: false
    });
  }

}


