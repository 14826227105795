<div class="h-100 d-flex flex-column">
  <div class="card shadow bg-white rounded-bottom">
    <div class="p-3">
      <!-- heading -->
      <div class="d-flex justify-content-between align-items-center bg-dark text-white rounded p-2">
        <div class="d-flex align-items-center">
            <span class="pr-2 d-flex align-items-center">
                <img src="assets/images/extraLine/icons/upgrade-to-residential-dark-bg.svg">
            </span>
            <span class="text-uppercase font-weight-bold">upgrade to residential</span>
        </div>
      </div>
      <!-- change credit card -->
      <div class="border-bottom mb-3">
        <common-ui-change-stripe-card-alt [orderId]="orderId" [unMountCard]="unMountCard"
          (paymentMethodChange)="this.paymentMethodId = $event" [checkoutDetail]="this.shippingService.checkoutDetail">
        </common-ui-change-stripe-card-alt>
      </div>

      <!-- product dropdown -->
      <div class="d-flex flex-column flex-grow-1 mb-3" *ngIf="this.activePhones.length">
        <div class="btn-group flex-fill" dropdown>
          <button
            class="btn w-100 text-center p-2 mb-0 font-weight-bold dropdown-toggle d-flex justify-content-between align-items-center rounded-lg" style="background-color: #e9e9e9;"
            type="button" dropdownToggle type="button" aria-controls="dropdown-basic" id="dropdown-context-lable">
            {{this.selectedPhone?.heading}}
          </button>

          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <ng-container *ngFor="let phone of activePhones">
              <li role="menuitem"><button class="dropdown-item px-3"
                  *ngIf="phoneAvailableInCountry(phone)&&!phone.outOfStock" (click)="this.selectedPhone = phone; onCartItemdropdownOptionChange()">
                  {{phone.heading}}
                </button>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <!-- cart summary header -->
      <div class="bg-primary d-flex w-100 p-2 rounded-top">
        <span class="pr-2">
          <img src="assets/images/extraLine/icons/upgrade-to-residential-primary-bg.svg">
        </span>
        <div class="text-white">Residential Plan <b>(New)</b></div>
      </div>

      <!-- cart-summary -->
      <div class="d-flex flex-column flex-grow-1">
        <ul class="list-group list-group-flush  border-bottom">
          <li class="list-group-item p-3" *ngIf="groupedCartItems.length == 0" style="background-color: #F9F9F9;">
            <div class="totalrat rat-detail">
              <div class="total-left">
                <h6 class="m-0">YOUR CART ITEM IS EMPTY</h6>
              </div>
            </div>
          </li>

          <!-- TODO: though we are looping here but for current case we've only one cart item. So we need to refactor this accordingly -->
          <li class="list-group-item p-3" style="background-color: #F9F9F9;" *ngFor="let cartItem of groupedCartItems">
            <div class="d-flex flex-row align-items-center">
              <!-- product image -->
              <img [src]="getItemImage(cartItem.itemId, cartItem.type)" class="mr-2" style="height: 42px; width: 42px; object-fit: contain;">
              <div class="flex-fill d-flex justify-content-between">
                <!-- product title -->
                <div class="text-muted">
                  {{cartItem.heading}}
                </div>
                <!-- product price -->
                <div class="text-black font-weight-bold" *ngIf="!cartItem.phoneUnavailable">
                  {{cartItem.totalDiscountedCost | formatMoneyValue}}
                </div>
                <!-- if cart-item is unavailable -->
                <div class="font-weight-bold m-0 text-right" *ngIf="cartItem.phoneUnavailable">
                  NOT AVAILABLE
                </div>
              </div>
            </div>
            <!-- service charge -->
            <div *ngIf="cartItem.monthlyCharge > 0" class="d-flex text-muted mt-3">
              <i class="far fa-clock text-center align-self-center mr-2" style="width: 42px;"></i>
              <div class="flex-fill d-flex justify-content-between">
                <div class=""> Business Service</div>
                <div class="text-black font-weight-bold">{{cartItem.monthlyCharge | formatMoneyValue}}</div>
              </div>
            </div>
          </li>
        </ul>
        <div class="px-3 py-2 border-bottom" style="background-color: #F9F9F9;">
          <checkout-tax-and-fees-after-ckeckout-alt [taxDetail]="taxDetail"
            [payAnnually]="shippingService.checkoutDetail.payAnnually" [calculatingTotal]="calculatingTotal">
          </checkout-tax-and-fees-after-ckeckout-alt>
        </div>
        <div class="px-3 py-2 border-bottom" style="background-color: #F9F9F9;"
          *ngIf="hasCartItems && cartHasHardware">
          <!-- && shippingService.shippingOptions.length > 0 -->
          <checkout-shipping-options-after-checkout-alt [checkoutDetail]="shippingService.checkoutDetail"
            [calculatingTotal]="calculatingTotal" (shippingChange)="shippingChange($event)">
          </checkout-shipping-options-after-checkout-alt>
        </div>
        <div>
          <checkout-cart-total-after-checkout-alt [calculatingTotal]="calculatingTotal"
            [checkoutDetail]="shippingService.checkoutDetail"></checkout-cart-total-after-checkout-alt>
        </div>
      </div>

    </div>
    <!-- footer images -->
    <div class="footer bg-dark p-2 rounded-bottom">
      <img src="assets/images/secure-payment-alt.png" alt="" style="height: 23px;">
      <img src="assets/images/stripe.png" alt="" style="height: 23px;">
      <img src="assets/images/cards.png" alt="" style="height: 23px;">
    </div>

  </div>
  <!-- Billing Address -->
  <checkout-billing-new [showCompanyNameField]=false [billingAddress]="billingAddress"
                          (billingAddressChange)="billingAddressChange($event)"
                          [companyName]="shippingService.checkoutDetail.companyName"
                          (companyNameChange)="onCompanyNameChange($event)">
  </checkout-billing-new>
  <!-- bottom buttons -->
  <div class="mt-auto d-flex">
    <button class="btn text-black w-50 rounded mt-3 mr-2" style="background-color: #E9E9E9;" (click)="back();">Back</button>
    <button class="btn btn-primary text-primary w-50 rounded mt-3 ml-2" [disabled]="calculatingTotal  ||PayNowClicked ||shippingService.checkoutDetail.orderTotal<=0" (click)="onPayNow()">
      Pay Now
      <mat-spinner *ngIf="PayNowClicked" class="d-inline-block white-spinner ml-3" diameter="22" value="accent">
      </mat-spinner>
    </button>
  </div>
</div>



