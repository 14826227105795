import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'voiply-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  errMessage="Something went Wrong!"
  constructor(private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe(params => {
       switch(params.err){
         case 'orderId': this.errMessage="Order Id is missing"; break;
         case 'userId': this.errMessage="User Id is missing"; break;
         case 'orderData': this.errMessage="Order data not found"; break;
         default: this.errMessage="Something went wrong!"; break;
       }
    })
  }

  ngOnInit(): void {
  }

}
