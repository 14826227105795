import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/';

@Component({
  selector: 'primary-number-company-number-name-change-modal',
  templateUrl: './company-number-name-change-modal.component.html',
  styleUrls: ['./company-number-name-change-modal.component.scss']
})
export class CompanyNumberNameChangeModalComponent implements OnInit {
  @Input() companyNumber;
  @Output() nameChange = new EventEmitter<string>();
  name = ""
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.name = this.companyNumber.name;
  }

}
