import { Component, ChangeDetectorRef, Input } from '@angular/core';
import { BillingDetailService, UserService, StripeService, CrispService, ShippingService, OrderService } from '../../../services';
import { ToastrService } from 'ngx-toastr';
import { AnnualBillingModalBase } from '../annual-billing-modal.base.';
import { Router } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ChangeBillingPlanAppAction } from '../../../+state/app.actions';
import { CartItemType, IAddMorePhoneTemplate } from '@voiply/shared/model';
import * as _ from "lodash"


@Component({
  selector: 'common-ui-annual-billing-modal-alt',
  templateUrl: './annual-billing-modal-alt.component.html',
  styleUrls: ['./annual-billing-modal-alt.component.scss']
})
export class AnnualBillingModalAltComponent extends AnnualBillingModalBase {
  @Input() backButtonUrl: string;
  @Dispatch() onChangeBillingPlanAppAction = (isAnnualBilling) => new ChangeBillingPlanAppAction(isAnnualBilling);

  constructor(protected billingDetailService: BillingDetailService,public crispService: CrispService,public shippingService: ShippingService, public orderService: OrderService,
    protected cd: ChangeDetectorRef, protected tostr: ToastrService, protected userService: UserService, public stripeService: StripeService, private router: Router) {
    super(billingDetailService, cd, tostr, userService, stripeService);
  }

  onChangePaymentPlan() {
    this.confirmButtonClicked = true;
    this.billingDetailService.changePaymentPlan(this.billingDetails).then((response) => {
      if (response) {
        this.confirmButtonClicked = false;
        this.onChangeBillingPlanAppAction(true);
        this.tostr.success("Subscription upgraded to Annual successfully.");
        //#region Upgrade to annual  
        let data: IAddMorePhoneTemplate = {
          substitution_data: {
            id: this.orderId,
            name: this.shippingService.checkoutDetail.companyName,
            shipping_address: {
              name: this.shippingService.checkoutDetail.shippingAddress.name,
              address_line: this.shippingService.checkoutDetail.shippingAddress.address,
              city: this.shippingService.checkoutDetail.shippingAddress.city,
              state: this.shippingService.checkoutDetail.shippingAddress.state,
              country: this.shippingService.checkoutDetail.shippingAddress.country,
              zip: this.shippingService.checkoutDetail.shippingAddress.zip,
            },
            product_details: [],
            order_payment: {
              invoice_onetime: null,
              invoice_recurring: this.shippingService.checkoutDetail.monthlyTotal,
              text_shipping: this.shippingService.checkoutDetail.shippingOption,
              invoice_shipping: this.shippingService.checkoutDetail.shippingCharges,
              invoice_tax: this.shippingService.checkoutDetail.taxDetail.estimatedTotalTax,
              invoice_total: Math.round(this.shippingService.checkoutDetail.orderTotal),
              invoice_total_decimal: Math.round((this.shippingService.checkoutDetail.orderTotal - Math.round(this.shippingService.checkoutDetail.orderTotal)) * 100),
            },
          },
          metadata: {},
          options: {},
          email: 'support@voiply.com'
        };
        const unpaidCartItems = _.pickBy(this.cartItems, (value, key) => !value.paid);
        _.forIn(unpaidCartItems, (value, key) => {
          data.substitution_data.product_details.push(
            {
              name: value.heading,
              quantity: value.qty,
              rate_in_dollar: value.discountedPrice,
              image_path: `${location.protocol}//${location.host}/${this.getItemImage(value.itemId, value.type)}`,
            },
          )
        });
        
        this.orderService.postExtraLineUpgradeTemplate(data).subscribe();
      }
      //#endregion
      this.router.navigateByUrl(this.backButtonUrl);

    });
  }

  back() {
    this.router.navigateByUrl(this.backButtonUrl);
  }

  getItemImage(itemId: string, itemType: CartItemType) {
    if (this.phones.length > 0) return _.filter(this.phones, (phone) => phone.featureId === itemId)[0]?.image || '';
  }
  
}
