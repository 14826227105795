<!--containing in - Lib: product-configuration -->
<!--used by - Container: product-configuration-home, product-configuration-home-after-checkout -->
<!--Added for - App: Home -->
<form [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()">
    <div class="modal-header" *ngIf="showCancelBtn">
        <h4 class="modal-title pull-left">{{value.heading}}</h4>
        <button  type="button" class=" btn pull-right cursor-pointer" (click)="bsModalRef.hide()">
            <i class="fas fa-times-circle fa-lg text-primary"></i>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="">
            <div *ngIf="value.type==='phone'">
                <div formGroupName={{key}}>
                    <voiply-home-phone-form-control [submitted]="formSubmitted" formControlName="phone"
                        (isHideConfig)=onHideConfig($event)>
                    </voiply-home-phone-form-control>

                    <mat-tab-group #tabs animationDuration="0ms" class="cart-item-background-color"
                        [hidden]="isHideConfiguration===true">
                        <mat-tab label="VoiceMail Settings">
                            
                            <div class="d-flex flex-column flex-lg-row justify-content-between">
                                <div class="d-flex flex-row pb-3">
                                    <span class="mr-2 align-self-center">Enable Voicemail to Email</span>
                                    <div class=" btn btn-group btn-sm d-flex flex-row m-0 p-0" role="group"
                                        aria-label="Basic example" style="height: 20px;">
                                        <button id="1"  type="button" style="border-radius: 5px 0px 0px 5px;"
                                            class="btn border d-flex flex-row justify-content-between align-items-center px-3 py-3"
                                            [ngClass]="!toggle? 'open_color' :'nopen_color'" (click)="onClick('1')">
                                            <div class="flex-grow-1">Off</div>
                                        </button>
                                        <button id="2" type="button" style="border-radius: 0px 5px 5px 0px;"
                                            class="btn border d-flex flex-row justify-content-between align-items-center px-3 py-3"
                                            [ngClass]="!toggle? 'nopen_color' :'open_color'" (click)="onClick('2')">
                                            <div class="flex-grow-1">On</div>
                                        </button>
                                    </div>
                                </div>
                                

                                <voiply-missed-call-form-control  [isDisable]="!toggle" formControlName="ringCount">
                                </voiply-missed-call-form-control>
                                
                            </div>
                            <voiply-voicemail-attachment-form-control [isDisable]="!toggle" formControlName="voicemailAttachment">
                            </voiply-voicemail-attachment-form-control>

                            <voiply-voicemail-form-control   formControlName="voice" insertTemplateVariableFrom="{{key}}"
                                audioCategory="VoiceMail" [recordingData]="productConfig" [isDisable]="!toggle">
                            </voiply-voicemail-form-control>
                        </mat-tab>

                        <mat-tab label="Call Forwarding Settings">
                            <voiply-callforwarding-form-control formControlName="callForwarding">
                            </voiply-callforwarding-form-control>
                        </mat-tab>
                        <mat-tab label="Device Settings" *ngIf="value.itemId===11||userIsVoiplyMember">
                            <voiply-device-settings-form-control formControlName="deviceSettings" [models]="models"
                                [submitted]="formSubmitted" [disableDeviceSettings]="disableDeviceSettings"
                                [showDeviceSettingsValidation]="showDeviceSettingsValidation">
                            </voiply-device-settings-form-control>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>

            <div *ngIf="value.heading==='Online Fax'">
                <div formGroupName={{key}}>
                    <voiply-online-fax-form-control [submitted]="formSubmitted" [disableInput]="disableInput"
                        formControlName="onlineFax">
                    </voiply-online-fax-form-control>
                </div>
            </div>
            <div *ngIf="value.heading==='Spam Call Bot'">
                <div formGroupName={{key}}>
                    <voiply-spam-call-bot-form-control [submitted]="formSubmitted" formControlName="spamCallBot"
                        [blockedNumberList]="productConfig.blockedList || []"
                        [allowedNumberList]="productConfig.allowedList || []" [extDisabled]="extDisabled">
                    </voiply-spam-call-bot-form-control>
                </div>
            </div>

            <div *ngIf="value.heading==='Emergency 999'">
                <div formGroupName="{{key}}">
                    <voiply-alert911-form-control formControlName="emergency999"></voiply-alert911-form-control>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer cart-item-background-color">
        <button type="submit" class="btn bg-primary text-primary px-4 py-2">
            Save Changes
        </button>
    </div>
</form>
