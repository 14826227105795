import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NumberPorting } from '@voiply/shared/model';
@Component({
  selector: 'voiply-number-porting',
  templateUrl: './number-porting.component.html',
  styleUrls: ['./number-porting.component.scss']
})
export class NumberPortingComponent {
  @Input() numberPorting: NumberPorting;
  @Input() email: string;
  @Input() companyName: string;
  @Input() orderId: string;
  @Input() backButtonUrl;
  ngOnInit() { }

  constructor(private router: Router) {}

  back() {
    this.router.navigateByUrl(this.backButtonUrl);
  }
}
