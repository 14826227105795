<div class="px-3 py-4">
    <div class="rounded-border bg-white p-3">
        <h5 class="font-weight-bolder mb-3" *ngIf="device!=='undefined'"><span
                class="font-weight-normal text-black-50">Activate Data
                for</span>&nbsp;{{device}}</h5>
        <h5 class="font-weight-bolder mb-3">
            <span class="font-weight-normal text-black-50">
                Type:
            </span>
            &nbsp;
            {{(data.country=="US")?"4G Data Only eSIM":"LTEData Only eSIM"}}
        </h5>
        <h5 class="font-weight-bolder mb-0">
            <span class="font-weight-normal text-black-50">
                Coverage:
            </span>
            <select id="" name="" style="background-color: white;border: none;" >
                <option [selected]="data.country=='US'" (click)="countryChanged('US')"> United State(T-Mobile/AT&T) </option>
                <option [selected]="data.country=='CA'" (click)="countryChanged('CA')" >Canada (Rogers Network) </option>
            </select>
            <!-- &nbsp;{{(data.country=="CA")?"Canada (Rogers Network)":"United State(T-Mobile/AT&T)"}} -->
        </h5>
    </div>
    <div class="mt-2 bg-white py-4 px-3 rounded-border">
        <div class="my-2 bg-black text-white px-3 d-flex align-items-center" style="height:50px;border-radius:8px;">
            <img src="assets/images/power-button.svg" alt="power-button" style="height: 22px;width: 22px;">
            <span class="font-weight-bolder ml-3 activate-data-plan-font">ACTIVATE DATA PLAN</span>
        </div>
        <div>
            <voiply-data-plans-card [data_plans]="data" (payDataPlanClicked)="payDataPlanClicked($event)">
            </voiply-data-plans-card>
        </div>
    </div>
    <div class="mt-2">
        <button class="btn btn-lg btn-block border bg-light-grey rounded " (click)="back()">Back</button>
    </div>

</div>