<!-- <div class="wistia_responsive_wrapper">
    <img src="https://fast.wistia.com/embed/medias/{{videoId}}/swatch"
      style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" *ngIf="!loadVideoScript"
      (click)="loadVideo()">

    <ng-container *ngIf="loadVideoScript">
      <div class="wistia_embed wistia_async_{{videoId}} videoFoam=true"
        style="height:100%;position:relative;width:100%">
      </div>
    </ng-container>
  </div> -->


<div class="wistia_responsive_wrapper">
  <div class="wistia_embed wistia_async_{{videoId}} videoFoam=true" style="height:100%;position:relative;width:100%">
    <div class="wistia_swatch"
      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
      <img src="https://fast.wistia.com/embed/medias/{{videoId}}/swatch"
        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
        onload="this.parentNode.style.opacity=1;" /></div>
  </div>
</div>