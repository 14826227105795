<!-- TOLL FREE NUMBERS -->
<div class="row">
  <div class="col-12">
    <h6 style="color: #e3411d">Available Prefixes</h6>
    <div class="local-number row">
      <div class="col-6 col-sm-4 mb-1" style="padding-top: 0!important; font-size: 12px;"
        *ngFor="let num of tollFreePrefixes">
        <label class="radio-container">
          <input type="radio" name="tollFreePrefixRadio" [value]="num" [ngModel]="855"
            (click)="getTollFreeNumbers(num)">
          <span class="checkmark"></span>
          <span>({{num}}) Number</span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-12 mt-2">
    <h6 style="color: #e3411d">Choose Your Number</h6>
    <div class="numb-input2">
      <input class="form-control" placeholder="Filter Digits or Terms" name="" type="text"
        (keyup)="onTollFreeSearch($event.target.value)" [(ngModel)]="tollfreeSearchValue">
    </div>
    <div class="row mt-3">
      <div class="col-12" *ngIf="matchingTollFreeNumbers.length == 0 && tollfreeSearchValue.length > 0">
        <span class="text-muted">No matching number found.</span>
      </div>
      <div class="d-flex justify-content-center col-12" *ngIf="searching">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
      <div class="col-6 col-sm-4 mb-1" *ngFor="let tollFreeNumber of matchingTollFreeNumbers" style="font-size: 12px">
        <label class="radio-container">
          <input type="radio" name="tollfreenumber" [value]="tollFreeNumber"
            (click)="selectedNumberChanged(tollFreeNumber)">
          <span class="checkmark"></span>
          <span>{{tollFreeNumber | formatPhoneNumber}}</span>
        </label>
      </div>
    </div>
  </div>
</div>