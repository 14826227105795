<!--containing in - Lib: product-configuration -->
<!--used by - Component: home-configuration/home-configuration-modal, home-configuration/user-settings-home, business-configuration/business-configuration-modal, business-configuration/user-settings-business -->
<!--Added for - App: Home, Business user-settings -->
<div [formGroup]="form" class="cart-item-background-color p-3">
  <div class="d-flex flex-wrap flex-row">
    <div class="p-1 flex-fill">
      <div class="input-group" style="position: relative;">
        <input type="text" class="form-control" placeholder="Select Number" formControlName="mobile"
          [readOnly]="disableInput" mask='(000) 000-0000'
          [ngClass]="{ 'is-invalid': ((f.mobile.touched || submitted) && f.mobile.errors) }">
        <div *ngIf="((f.mobile.touched || submitted) && f.mobile.errors)">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid Phone Number"> </i>
        </div>
        <div class="input-group-append" *ngIf="!disableInput">
          <i class="fas fa-align-center bg-primary text-primary input-group-text clean"
            (click)="toggleNewNumber();"></i>
        </div>
      </div>
    </div>
    <div class="p-1 flex-fill" style="position: relative;">
      <input type="text" class="form-control" placeholder="Email" formControlName="email" [readOnly]="disableInput"
        [ngClass]="{ 'is-invalid': ((f.email.touched || submitted) && f.email.errors) }">
      <div *ngIf="((f.email.touched || submitted) && f.email.errors)">
        <span *ngIf="f.email.errors.required">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter email"></i></span>
        <span *ngIf="f.email.errors.email">
          <i class="far fa-exclamation-circle is-error" tooltip="Please enter valid email"> </i></span>
      </div>
    </div>
  </div>
  <div class="p-1">
    <p>Automatic Cover Page</p>
    <label class="radio-container ml-2 mr-3">
      <input type="radio" value="Yes" formControlName="coverpage">
      <span class="checkmark"></span>
      <span>Yes</span>
    </label>
    <label class="radio-container">
      <input type="radio" value="No" formControlName="coverpage">
      <span class="checkmark"></span>
      <span>No</span>
    </label>
  </div>
  <div class="p-1">
    Incoming faxes will be sent as PDF attachment to your email.
  </div>

</div>



<ng-container *ngIf="isHideNewNumber">
  <mat-tab-group animationDuration="0ms" #tabs class="cart-item-background-color">
    <mat-tab label="LOCAL NUMBERS">
      <div class="">
        <voiply-local-number-search (numberSelected)="selectedNumber = $event"></voiply-local-number-search>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-2" data-dismiss="modal"
          (click)="toggleNewNumber()">close</button>
        <button type="button" class="btn bg-primary text-primary" (click)="toggleNewNumber();onNumberSelected();">
          Select Number
        </button>
      </div>
    </mat-tab>

    <!-- <mat-tab label="TOLL FREE NUMBERS">
      <div class="">
        <voiply-tollfree-number-search (numberSelected)="selectedNumber = $event"></voiply-tollfree-number-search>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-2" data-dismiss="modal"
          (click)="toggleNewNumber()">Close</button>
        <button type="button" class="btn bg-primary text-primary" (click)="toggleNewNumber();onNumberSelected();">
          Select Number
        </button>
      </div>
    </mat-tab> -->
  </mat-tab-group>
</ng-container>
