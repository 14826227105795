import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CommonUiModule } from '@voiply/shared/common-ui';
import { Auth0Module } from '@voiply/auth0';

import { CONTAINERS, AddMorePhonesHomeComponent, AddMorePhonesBusinessComponent } from './containers';
import { COMPONENTS, ThankyouModalComponent } from './components';
import { NgxMaskModule } from 'ngx-mask';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),

    CommonUiModule,
    Auth0Module,
    NgxMaskModule,
    GooglePlaceModule,
  ],
  declarations: [
    ...CONTAINERS,
    ...COMPONENTS
  ],
  exports: [
    ...CONTAINERS,
    ...COMPONENTS
  ],
  entryComponents: [
    AddMorePhonesHomeComponent,
    AddMorePhonesBusinessComponent,
    ThankyouModalComponent
  ]
})
export class CheckoutModule { }
