import { Directive, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { Address } from '@voiply/shared/model';
import { FormBase, SetShippingAddressFormStatusAction, STATES, COUNTRIES } from '@voiply/shared/common-ui';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Directive()
export class ShippingAddressBase extends FormBase implements OnChanges{
  private _address: { name: string, phone: string, address: string, address2: string, city: string, state: string, country: string, zip: string };
  states = STATES;
  filteredStates: any;
  countries = COUNTRIES;
  @Output()
  shippingAddressChange = new EventEmitter<Address>();

  @Dispatch() formValidChanged = (isValid) => new SetShippingAddressFormStatusAction(isValid);


  @Input()
  set shippingAddress(value: Address) {
    this._address = {
      name: value.name || '',
      phone: value.phone || '',
      address: value.address || '',
      address2: value.address2 || '',
      city: value.city || '',
      state: value.state || '',
      country: value.country || '',
      zip: value.zip || ''
    };

    this.form.patchValue({ address: this._address });
    setTimeout(() => {
      this.form.updateValueAndValidity();      // Temp hack as initial value is not getting validated
    }, 1);
  }

  constructor() {
    super();

    this.form = new FormGroup({
      address: new FormControl(''),
    });

    // Subscribe to change events and other common initialization
    // this.initializeForm();

    this.subscriptions$.add(this.form.valueChanges.subscribe((changes) => {
      if (this._address !== changes.address) {
        this.shippingAddressChange.emit(changes.address);
      }
    }));
  }
  ngOnChanges(changes: SimpleChanges) {
    this.filteredStates = (_.filter(this.countries, { 'countryShortCode': changes.shippingAddress.currentValue.country })[0] || {}).regions;
  }
  public formStatusChanged(isValid: boolean) {
    this.formValidChanged(isValid);
  }
}
