<div [formGroup]="form" [ngClass]="{'disableBackground':isDisable}" class="pb-3">
  <div class=" mb-3">
    <mat-chip-list #chipList aria-label="Email Selection" class="email-chip-list">
      <mat-chip *ngFor="let email of this.form.controls['ccEmail'].value;let i=index" [selectable]="removable"
        [removable]="removable" (removed)="remove(i)">
        <span class="text-truncate">{{email}}</span>
        <i class="fas fa-times-circle text-primary d-flex align-items-center" matChipRemove *ngIf="removable"></i>
      </mat-chip>
      <input [attr.disabled]="isDisable ? 'disabled' : null" placeholder="Additional Voicemail Recipients" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
  </div>
  <mat-slide-toggle [disabled]="isDisable"  class="example-margin" [color]="color" formControlName="attachVoicemail">
    Attach Voicemail in Emails
  </mat-slide-toggle>
</div>
