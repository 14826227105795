<div class="d-flex flex-column">
    <div class="d-flex justify-content-start bg-dark text-white rounded p-2">
        <div class="d-flex align-items-center">
            <!-- heading -->
            <span class="pr-2 d-flex align-items-center">
                <img src="assets/images/extraline/icons/call-logs.svg" class="icon-white">
            </span>
            <span class="text-uppercase font-weight-bold">Call Logs</span>
        </div>
    </div>
    <div class="d-flex flex-column align-items-center pt-3">
        <div class="position-relative w-100">
            <a href="https://cdr.voiply.online/{{orderId}}" target="_blank">
                <i class="fal fa-external-link-square text-primary" style="position: absolute; top: 3px; right: 3px;"></i>
                <div class="btn btn-primary py-3 rounded w-100"><span class="font-weight-bold text-uppercase h5">Access your call logs</span></div>
            </a>
        </div>
    </div>
</div>
