import { Component, OnInit } from '@angular/core';
import { TaxAndFeesBase } from '../tax-and-fees-base';

@Component({
  selector: 'checkout-tax-and-fees-after-checkout',
  templateUrl: './tax-and-fees-after-checkout.component.html',
  styleUrls: ['./tax-and-fees-after-checkout.component.scss']
})
export class TaxAndFeesAfterCheckoutComponent extends TaxAndFeesBase {

}
