import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppRoutes } from './app.routing';
import { RootPageComponent } from './root-page/root-page.component';
import { ProductConfigurationModule } from '@voiply/product-configuration';
import { OrderSummaryModule } from '@voiply/order-summary';
import { HeaderOrderTrackingModule } from '@voiply/header-order-tracking';
import { CheckoutModule } from '@voiply/checkout';
import { CommonUiModule, ENVIRONMENT } from '@voiply/shared/common-ui';
import { PrimaryNumberAfterCheckoutModule } from '@voiply/primary-number-after-checkout';
import { NumberPortingComponent } from './number-porting/number-porting.component';
import { UpdateCreditCardComponent } from './update-credit-card/update-credit-card.component';
import { ViewInvoices } from './view-invoices/view-invoices.component';
import { E911Component } from './e911/e911.component';
import { ProductConfiguration } from './product-configuration/product-configuration.component';
import { UpgradeToAnnualComponent } from './upgrade-to-annual/upgrade-to-annual.component';
import { UpgradeToBusiness } from './upgrade-to-business/upgrade-to-business.component';
import { UpgradeToHome } from './upgrade-to-home/upgrade-to-home.component';
import { CallerIdComponent } from './caller-id/caller-id.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { DataPlansContainerComponent } from './data-plans-container/data-plans-container.component';
import { DataPlansCardComponent } from './data-plans-card/data-plans-card.component';

@NgModule({
  declarations: [
    AppComponent,
    RootPageComponent,
    NumberPortingComponent,
    UpdateCreditCardComponent,
    ViewInvoices,
    E911Component,
    ProductConfiguration,
    UpgradeToAnnualComponent,
    UpgradeToBusiness,
    UpgradeToHome,
    CallerIdComponent,
    ErrorPageComponent,
    OrderStatusComponent,
    DataPlansContainerComponent,
    DataPlansCardComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes, { initialNavigation: 'enabled' }),

    // State
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    ProductConfigurationModule,
    OrderSummaryModule,
    HeaderOrderTrackingModule,
    CheckoutModule,
    CommonUiModule,
    PrimaryNumberAfterCheckoutModule,


  ],
  providers: [{ provide: ENVIRONMENT, useValue: environment }],
  bootstrap: [AppComponent],
  exports: [BrowserModule]
})
export class AppModule { }
