import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { UserService, OrderService, CrispService } from '../services';
import { Invoice } from '@voiply/shared/model';
import { GetPhonesAndApps, UserIsVoiplyTeamMemberAction, FillOrderAction } from '../+state/app.actions';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AfterCheckoutAppInitializationGuard implements CanActivate {

  @Dispatch() getPhonesAndApps = () => new GetPhonesAndApps();
  @Dispatch() userIsVoiplyTeamMember = (isTrue) => new UserIsVoiplyTeamMemberAction(isTrue);
  @Dispatch() saveOrderState = (orderData: any) => new FillOrderAction(orderData);

  constructor(private userService: UserService, private orderService: OrderService, private crispService: CrispService) {
   
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      // Check order id is there or not
      const orderId = route.paramMap.get("id");
      if (!orderId)
        return reject();

      const orderData: any = await this.orderService.getOrderAsync(orderId);
      if (!orderData)
        return reject();

      // Dispatch action to fetch phones and apps data
      this.getPhonesAndApps();

      //TODO: invoices is commented as it was not used in old project. confirm and uncomment below code
      // const invoices: Invoice[] = await this.userService.getCustomerInvoicesAsync(orderId);
      // if(invoices) {
      //   _.forEach(invoices, (v, k) => {
      //     v.created = new Date(0); v.created.setUTCSeconds(v.created);
      //   });
      // }

      const auth0Id = localStorage.getItem('auth0Id');
      if (auth0Id) {
        this.userService.isVoiplyTeamMember(auth0Id).then(value => {
          this.userIsVoiplyTeamMember(value.voiplyTeamMember);
          console.log(`[USER] User ${auth0Id} ${value.voiplyTeamMember ? 'is' : 'is not'} Voiply Team Member.`);

          if (!value.voiplyTeamMember) {
            this.crispService.initCrisp(orderId);
          }
        });
      } else {
        this.crispService.initCrisp(orderId);
      }

      // Get object from Cosmos
      //Save order in state
      this.saveOrderState(orderData);

      return resolve(true);
    });
  }
}
