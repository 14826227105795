import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CheckoutModule } from '@voiply/checkout';
// Voiply Modules
import { CommonUiModule } from '@voiply/shared/common-ui';
import { COMPONENTS } from './components';
import { CONTAINERS } from './container';

import { NgxMaskModule } from 'ngx-mask';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommonUiModule,
    NgxMaskModule.forRoot(),
    CheckoutModule
  ],
  exports: [
    ...CONTAINERS
  ],
  declarations: [
    ...COMPONENTS,
    ...CONTAINERS
  ]
})
export class PrimaryNumberAfterCheckoutModule {

}
