export enum EventType {
    InitialLoad = "InitialLoad",
    PhoneNumber = "PhoneNumber",
    Survey = "Survey",
    CartItemPhone = "CartItemPhone",
    CartItemApp = "CartItemApp",
    BillingAddress = "BillingAddress",
    ShippingAddress = "ShippingAddress",
    BillingAddressExtraline="BillingAddressExtraline",
    EmergencyAddress = "EmergencyAddress",
    Shipping = "Shipping",
    ApplyPromoCode = "ApplyPromoCode",
    RemovePromoCode = "RemovePromoCode",
    PaymentDuration = "PaymentDuration",
    CallFlow = "CallFlow",
    InternetProvider = "InternetProvider",
    CompanyName = "CompanyName",
    Payment = "Payment",
    OrderLocked = "OrderLocked",
    ShareOrder = "ShareOrder",
    CartItemConfigurationChange = "CartItemConfigurationChange",
    NumberPorting = "NumberPorting",
    BYODupate = "BYODUpdate",
    Emergency999Update = "Emergency999Update",
    CompanyNumber = "CompanyNumber",
    AddMorePhones = "AddMorePhones",
    ProtectionPlanStatus = "ProtectionPlanStatus",
    CartItemProtectionPlan = "CartItemProtectionPlan",
    PaymentDoneAUTO = "PaymentDoneAUTO",
    CodeForLogin = "CodeForLogin",
    Metadata = "Metadata",
    UpgradeToBusiness = "UpgradeToBusiness",
    UpgradeToHome = "UpgradeToHome",
    ChangeBillingApp = "ChangeBillingApp"
}