import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'voiply-data-plans-card',
  templateUrl: './data-plans-card.component.html',
  styleUrls: ['./data-plans-card.component.scss']
})
export class DataPlansCardComponent implements OnInit {

  @Input() data_plans: any;
  @Output() payDataPlanClicked = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onPayClicked(event) {
    this.payDataPlanClicked.emit({ planID: event.id });
  }
}
