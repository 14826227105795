import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMoneyValue'
})
export class FormatMoneyValuePipe implements PipeTransform {
  transform(value, args?: any): any {
    if (value === '') { return ''; }
    if (value === 0 || value === '0') { return 'Free'; }
    const numberValue = parseFloat(value);
    if (numberValue.toString() === "NaN")
      return value;
    return '$' + (parseFloat((Math.round(value * 100) / 100).toString()).toFixed(2));
  }
}
