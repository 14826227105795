import { Gender, VoiceMail } from './enums';
import { required, email, pattern } from '@rxweb/reactive-form-validators';

export class PhoneConfiguration {
  userId: string;
  @required()
  @pattern({ expression: { 'userName': /^(?!User).*$/ } })
  firstName: string;
  lastName: string;
  @required()
  @pattern({ expression: { 'extension': /^[0-9]{2,5}$/ } })
  extension: number;
  phoneNumber: string;
  @required()
  @email()
  email: string;
  showRecording: boolean;
  selectedGender: Gender.Male;
  audioText: string;
  sendVoiceMailTo: VoiceMail.EmailAndPhone;
  blobFileName: string;
  fileName: string;
  voicemailRingTimeoutCount: number;
  callForwarding: boolean;
  isNewNumber: boolean;
  uploadRecording: boolean;
  callForwardingNumber: string;
  mac?: string;
  manufacturer?: string;
  serial?: string;
  model?: string;
  includeinCompanyDirectory?: boolean;
  ccEmail: string[];
  attachVoicemail: boolean;
  emergencyCallForwarding: boolean;
  emergencyCallForwardingNumber: string;
  isVoicemailToEmailEnable:boolean;
  isSMSArchivingEnable:boolean;
  archive_sms:string;
}
