<!-- <div class="row">
  <div class="col-md-6 col-12 pt-2">
    <p class="text-secondary font-weight-bold mb-1">1- Select State and Area Code</p>
    <div class="select-input">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom" (change)="onStateChange($event.target.value)" [ngModel]="selectedState" style="background-color: #EEEEEE !important;">
        
        <option *ngFor="let state of STATES" [value]="state.code">{{state.name}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-6 col-12 align-self-center pt-2">
    <div style="font-size: 13px;">
      Sort By
      <label class="radio-container mb-2 ml-2">
        <input type="radio" name="vehicle" [value]="false" [(ngModel)]="sortNumbersByCity"
          [checked]="!sortNumbersByCity">
        <span class="checkmark"></span>
        <span>Area Code</span>
      </label>
      <label class="radio-container mb-2 ml-2">
        <input type="radio" name="vehicle" [value]="true" [(ngModel)]="sortNumbersByCity" [checked]="sortNumbersByCity" style="background-color: #EEEEEE !important;">
        <span class="checkmark"></span>
        <span>City</span>
      </label>
    </div>
    <div class="select-input" *ngIf="!sortNumbersByCity">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom" (change)="onAreaCodeChange($event.target.value)" [ngModel]="selectedAreaCode" style="background-color: #EEEEEE !important;">
        <option value="" class="placeholder" selected="selected">All area Code</option>
        <option *ngFor="let areaCode of areaCodes">{{areaCode}}</option>
      </select>
    </div>
    <div class="select-input" *ngIf="sortNumbersByCity">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom" (change)="onCityChange($event.target.value)" [ngModel]="selectedCity"  style="background-color: #EEEEEE !important;">
        <option value="" class="placeholder" selected="selected">All Cities</option>
        <option *ngFor="let city of cities" [value]="city">{{city}}</option>
      </select>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-12">
    <p class="text-secondary font-weight-bold mb-1">2 - Choose Your Number</p>
  </div>
  <div class="col-12">
    <div class="numb-input form-group" >
      <input class="form-control" placeholder="Filter Digits or Terms" [(ngModel)]="searchValue" name="" type="text"
        (keyup)="onNumberSearch($event.target.value)" style="background-color: #EEEEEE !important;">
    </div>
  </div>

  <div class="col-12" *ngIf="matchingNumbers.length == 0 && searchValue.length > 0">
    <span class="text-muted">No matching number found.</span>
  </div>
  <div class="d-flex justify-content-center col-12" *ngIf="searching">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <div class="col-6 col-sm-4 mb-1" *ngFor="let localNumber of matchingNumbers" style="font-size: 15px">
    <label class="radio-container">
      <input type="radio" name="localNumber" [value]="localNumber" (click)="selectedNumberChanged(localNumber)">
      <span class="checkmark"></span>
      <span>{{localNumber | formatPhoneNumber}}</span>
    </label>
  </div>
</div> -->
<div class="row">
  <div class="col-12">
    <p class="font-weight-bold mb-1">1- Select State</p>
    <div class="select-input">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom" (change)="onStateChange($event.target.value)" [ngModel]="selectedState" style="background-color: #EEEEEE !important;">
        <!-- <option value="" class="placeholder" selected="selected">Select State</option> -->
        <option *ngFor="let state of STATES" [value]="state.code">{{state.name}}</option>
      </select>
    </div>
  </div>

  <div class="col-12 align-self-center pt-2">
    <div class="d-none" style="font-size: 13px;">
      Sort By
      <label class="radio-container mb-2 ml-2">
        <input type="radio" name="vehicle" [value]="false" [(ngModel)]="sortNumbersByCity"
          [checked]="!sortNumbersByCity">
        <span class="checkmark"></span>
        <span>Area Code</span>
      </label>
      <label class="radio-container mb-2 ml-2">
        <input type="radio" name="vehicle" [value]="true" [(ngModel)]="sortNumbersByCity" [checked]="sortNumbersByCity" style="background-color: #EEEEEE !important;">
        <span class="checkmark"></span>
        <span>City</span>
      </label>
    </div>
    <p class="font-weight-bold mb-1 mt-2">2- Select Area Code</p>
    
    <div class="select-input" *ngIf="!sortNumbersByCity">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom" (change)="onAreaCodeChange($event.target.value)" [ngModel]="selectedAreaCode" style="background-color: #EEEEEE !important;">
        <option value="" class="placeholder" selected="selected">All area Code</option>
        <option *ngFor="let areaCode of areaCodes">{{areaCode}}</option>
      </select>
    </div>
    
    <div class="select-input" *ngIf="sortNumbersByCity">
      <i class="fas fa-caret-down  dropdown-img d-flex align-items-center"></i>
      <select class="select-custom" (change)="onCityChange($event.target.value)" [ngModel]="selectedCity"  style="background-color: #EEEEEE !important;">
        <option value="" class="placeholder" selected="selected">All Cities</option>
        <option *ngFor="let city of cities" [value]="city">{{city}}</option>
      </select>
    </div>
  </div>
  
  
    <div class="col-12 mt-1">
      <p class="font-weight-bold mb-1 mt-2">3 - Choose Your Number</p>
      <div class="select-input">
        <i class="fas fa-caret-down  dropdown-img d-flex align-items-center ml-1"></i>
        <select class="select-custom"  (change)="selectedNumberChanged($event.target.value)"  style="background-color: #EEEEEE !important;">
          <option value="" class="placeholder"  selected="selected">Choose Number</option>
          <option *ngFor="let localNumber of matchingNumbers" [value]="localNumber" >{{localNumber}}</option>
        </select>
      </div>
    </div>
    <!-- <div class="col-12">
      <div class="numb-input form-group" >
        <input class="form-control" placeholder="Filter Digits or Terms" [(ngModel)]="searchValue" name="" type="text"
          (keyup)="onNumberSearch($event.target.value)" style="background-color: #EEEEEE !important;">
      </div>
    </div> -->

    <!-- <div class="col-12" *ngIf="matchingNumbers.length == 0 && searchValue.length > 0">
      <span class="text-muted">No matching number found.</span>
    </div> -->
    <!-- <div class="d-flex justify-content-center col-12" *ngIf="searching">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div> -->
    <!-- <div class="col-6 col-sm-4 mb-1" *ngFor="let localNumber of matchingNumbers" style="font-size: 15px">
      <label class="radio-container">
        <input type="radio" name="localNumber" [value]="localNumber" (click)="selectedNumberChanged(localNumber)">
        <span class="checkmark"></span>
        <span>{{localNumber | formatPhoneNumber}}</span>
      </label>
    </div> -->

    
    
</div>