import { Component, forwardRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ControlValueAccessorAndValidator, phoneNumberValidator } from '@voiply/shared/common-ui';

@Component({
  selector: 'voiply-phone-form-control',
  templateUrl: './phone-form-control.component.html',
  styleUrls: ['./phone-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneFormControlComponent),
      multi: true
    }
  ]
})
export class PhoneFormControlComponent extends ControlValueAccessorAndValidator implements OnInit {
  @Input() submitted = false;
  @Input() extDisable: boolean;
  @Input() disableInput = false;
  @Output() isHideConfig: EventEmitter<any> = new EventEmitter();
  isHideConfiguration = false;
  selectedNumber = '';
  isHideNewNumber = false;


  constructor() {
    super();

    this.form = new FormGroup({
      firstName: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.pattern({ expression: { 'userName': /^(?!User).*$/ } })], updateOn: 'blur' }),
      lastName: new FormControl('', { updateOn: 'blur' }),
      email: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.email()], updateOn: 'blur' }),
      userId: new FormControl(''),
      extension: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.pattern({ expression: { 'extension': /^[0-9]{2,5}$/ } })], updateOn: 'blur' }),
      phoneNumber: new FormControl('', { validators: [phoneNumberValidator('min')], updateOn: 'blur' }),
      isNewNumber: new FormControl(''),
      timezone: new FormControl('')
    });
  }
  ngOnInit() {
    this.isHideConfig.emit(this.isHideConfiguration);
    this.form.controls['phoneNumber'].valueChanges.subscribe(
      (value) => {
        if (value !== this.selectedNumber.toString()) {
          this.form.controls['isNewNumber'].patchValue(false);
        }
      });
  }
  toggleConfig() {
    if (this.isHideNewNumber) {
      this.toggleNewNumber();
    }
    this.isHideConfiguration = !this.isHideConfiguration
    this.isHideConfig.emit(this.isHideConfiguration);
  }

  toggleNewNumber() {
    if (!this.isHideConfiguration)
      this.toggleConfig();
    this.isHideNewNumber = !this.isHideNewNumber;
  }
  onNumberSelected() {
    const sanitizedPhoneNumber = this.selectedNumber.toString().replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
    this.form.controls['isNewNumber'].patchValue(true);
    this.form.controls['phoneNumber'].patchValue(sanitizedPhoneNumber);
  }

}
