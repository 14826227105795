import { ShareOrder } from './share-order.model';
import { required, email, digit, alpha, pattern } from '@rxweb/reactive-form-validators';

export class CheckoutDetail {
    @required()
    companyName: string;
    billingAddress: Address;
    shippingAddress: Address;
    isShippingSameAsBillingAddress: boolean;
    shippingCharges: number;
    discountedShippingCharges: number;            // Shipping charges after applying discount
    payAnnually: boolean;
    promoCode: string;
    description: string; //Promotion description
    discountedTotalAmount: number;            // Final charges or price charges after applying discount
    withoutDiscountTotal: number;
    taxDetail: TaxDetail;
    orderTotal: number;
    monthlyTotal: number;
    shippingOption: string;
    shipperMethod: string;
    internetProvider: {};
    sharing: ShareOrder;
    isValid: boolean;
    emergencyAddress: EmergencyAddress;
    hasProtectionPlan: boolean;
    protectionPlanTotal: number;
    savings: Savings
}

export class Address {
    @required()
    @pattern({ expression: { 'name': /^[a-zA-Z 0-9._-]*$/ } })
    name = "";
    @required()
    phone = "";
    @required()
    @email()
    email = "";
    @required()
    @pattern({ expression: { 'name': /^[a-zA-Z 0-9 $&+,:;=?@#|'<>.^*()%!-]*$/ } })
    address = "";
    @pattern({ expression: { 'name': /^[a-zA-Z 0-9 $&+,:;=?@#|'<>.^*()%!-]*$/ } })
    address2 = "";
    @required()
    @pattern({ expression: {'name': /^[a-zA-Z \-]*$/} })
    city = "";
    @required()
    state = "";
    @required()
    country = "";
    @required()
    zip = "";
}

export class ShippingAddress {
    @required()
    @pattern({ expression: { 'name': /^[a-zA-Z 0-9._-]*$/ } })
    name = "";
    @required()
    phone = "";
    @required()
    @pattern({ expression: { 'name': /^[a-zA-Z 0-9 $&+,:;=?@#|'<>.^*()%!-]*$/ } })
    address = "";
    @pattern({ expression: { 'name': /^[a-zA-Z 0-9 $&+,:;=?@#|'<>.^*()%!-]*$/ } })
    address2 = "";
    @required()
    @pattern({ expression: {'name': /^[a-zA-Z \-]*$/} })
    city = "";
    @required()
    state = "";
    @required()
    country = "";
    @required()
    zip = "";
}

export class EmergencyAddress {
    name = "";
    addressLine1 = "";
    addressLine2 = "";
    city = "";
    state = "";
    country = "";
    zip = "";
}
export interface TaxDetail {
    submissionId: string;
    taxBreakup: { tax_amount: number, description: string, hardwareTax: boolean }[];
    estimatedTotalTax: number;
}

export interface Savings {
    hardware: number,
    annual: number,
    shipping: number,
    freeporting: number,
    numberactivation: number,
    freemonth: number,
}
