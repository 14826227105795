import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ENVIRONMENT } from '../constants';
import { IEnvironment } from '../environment.interface';

const audioTemplateFileName = `${location.origin}/assets/audio_template.json`;

@Injectable({
  providedIn: 'root'
})
export class AudioService extends BaseService {
  textSpeechApi = `${this.environment.rootApi}/tts-new/{id}?t=t`;
  uploadRecordingAPI = `${this.environment.rootApi}/recording/{id}`;
  deleteRecordingAPI = `${this.environment.rootApi}/recording-delete`;

  constructor(@Inject(ENVIRONMENT) private readonly environment: IEnvironment, private http: HttpClient) {
    super();
  }

  getAudioTemplates(): Observable<any> {
    return this.http.get(audioTemplateFileName).pipe(catchError(this.handleError));
  }

  uploadRecordingFile(blobId: string, file: any) {
    const headers = new HttpHeaders().append('Content-Type', 'application/octet-stream');
    console.log('Uploading file to', `${this.uploadRecordingAPI.replace("{id}", blobId)}`);
    return this.http.post(`${this.uploadRecordingAPI.replace("{id}", blobId)}`,
      file, { headers }).pipe(catchError(this.handleError));
  }

  deleteRecordingFile(blobId: string) {
    return this.http.delete(`${this.deleteRecordingAPI}?id=${blobId}`)
      .pipe(catchError(this.handleError));
  }

  generateAudioFileFromText(text: string, gender: string, id: string): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(`${this.textSpeechApi.replace("{id}", id)}&languageCode=en&gender=${gender}`, JSON.stringify({ text: text }), { headers }).pipe(catchError(this.handleError));
  }
}
