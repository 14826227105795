<!--containing in - Lib: product-configuration -->
<!--used by - Container: product-configuration-extraline-settings -->
<!--Added for - App: Extraline-settings-->
<!--TODO figure out a way to display based on condition -->
<form [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()" class="h-100">
  <!-- call forwarding section -->
  <!-- <div *ngIf="displayFormControl === extralineScreen.CallForwarding">
      <div class="mb-3"> 
        <voiply-call-forwarding-form-control-alt formControlName="callForwarding"></voiply-call-forwarding-form-control-alt>
      </div>
        <div class="d-flex w-100 mt-auto">
          <button type="button" class="btn bg-grey text-black rounded w-50" (click)="back()">Back</button>
          <button type="submit" class="btn bg-primary text-primary rounded ml-4 w-50">Save</button>
        </div>
    </div> -->
  <!-- voice mail section -->
  <div class="h-100 d-flex flex-column">
    <div class="card shadow p-3 bg-white mb-3">
      <div class="d-flex flex-column">
        <div formGroupName={{key}}>
          <div *ngIf="displayFormControl === extralineScreen.CallForwarding">
            <voiply-call-forwarding-form-control-alt formControlName="callForwarding">
            </voiply-call-forwarding-form-control-alt>
          </div>

          <div *ngIf="displayFormControl === extralineScreen.VoiceMail">
            <div class="d-flex justify-content-start bg-dark text-white rounded p-2">
              <!-- heading -->
              <span class="pr-2 d-flex align-items-center">
                <img src="assets/images/extraline/icons/voicemail.svg" class="icon-white">
              </span>
              <span>
                Voicemail
              </span>
            </div>
            <div class="mt-3">
              <voiply-missed-call-form-control-alt formControlName="ringCount">
              </voiply-missed-call-form-control-alt>
              <voiply-voicemail-attachment-form-control-alt formControlName="voicemailAttachment"
                [submitted]="formSubmitted">
              </voiply-voicemail-attachment-form-control-alt>
              <voiply-voicemail-form-control-alt formControlName="voice" insertTemplateVariableFrom="{{key}}"
                audioCategory="VoiceMail" [recordingData]="productConfig">
              </voiply-voicemail-form-control-alt>
            </div>
          </div>


          <!-- spam call bot section  -->
          <div *ngIf="displayFormControl===extralineScreen.SpamBot">
            <voiply-spam-call-bot-form-control-alt [submitted]="formSubmitted" formControlName="spamCallBot"
              [blockedNumberList]="productConfig.blockedList || []"
              [allowedNumberList]="productConfig.allowedList || []" [extDisabled]="extDisabled">
            </voiply-spam-call-bot-form-control-alt>
          </div>

          <!-- online fax section -->
          <div *ngIf="displayFormControl===extralineScreen.Fax">
            <voiply-online-fax-form-control-alt [submitted]="formSubmitted" [disableInput]="disableInput"
              formControlName="onlineFax">
            </voiply-online-fax-form-control-alt>
          </div>

          <!-- call recording section -->
          <div *ngIf="displayFormControl===extralineScreen.CallRecording">
            <voiply-call-recording-form-control-alt [submitted]="formSubmitted" formControlName="callRecording"
              (isHideConfig)=onHideConfig($event)></voiply-call-recording-form-control-alt>
            <div class="mt-3">
              <voiply-voicemail-form-control-alt formControlName="voice" insertTemplateVariableFrom="{{key}}"
                audioCategory="CallRecording" [recordingData]="productConfig">
              </voiply-voicemail-form-control-alt>
            </div>
          </div>

          <!-- Emergency999 section -->
          <div *ngIf="displayFormControl===extralineScreen.Emergency999">
            <voiply-alert911-form-control-alt formControlName="emergency999"></voiply-alert911-form-control-alt>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex w-100 mt-auto">
      <button type="button" class="btn bg-grey text-black rounded w-50" (click)="back()">Back</button>
      <button type="submit" class="btn bg-primary text-primary rounded ml-4 w-50">Save</button>
    </div>
  </div>
</form>