<fieldset>
  <!-- <div class="bg-primary d-flex">
    <div class="text-primary font-weight-bold inner-header ml-3 mt-2">Shipping address</div>
    <div class="flex-grow-1 d-flex justify-content-end bg-secondary my-4">
        <a class=" d-flex align-items-center pr-3 pl-3  " (click)="isCollapsed = !isCollapsed">
            <label class="checkbox-container d-flex align-items-center pr-3 pl-3 ">
                <input type="checkbox" #shippingSameAsBillingCheckbox name="shippingSameAsBilling"
                  [checked]="isShippingSameAsBilling"
                  (change)="changeIsBillingSameAsShipping.emit()">
                <span style="color:#212529; margin-left: 11px;">Shipping address same as Billing address</span>
                <span class="checkmark"></span>
              </label>
        </a> 
      </div>
  </div> -->
  <form [formGroup]="form">
    <checkout-address formControlName="address" [filteredStates]="filteredStates" [submitted]="formSubmitted">
    </checkout-address>
  </form>
</fieldset>