import { OnInit, Input, Directive } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { ControlValueAccessorAndValidator } from '@voiply/shared/common-ui';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import * as _ from 'lodash';

@Directive()
export class SpamCallBotFormControlBase extends ControlValueAccessorAndValidator implements OnInit {
  @Input() submitted = false;
  @Input() extDisabled = false;
  @Input() blockedNumberList;
  @Input() allowedNumberList;
  isNumberRepeating: boolean = false; //when any number contains on both the list (allowed and blocked) then this flag is true else it is false
  constructor() {
    super();

  }

  ngOnInit() {
    this.form = new FormGroup({
      extension: new FormControl('', { validators: [RxwebValidators.required(), RxwebValidators.pattern({ expression: { 'extension': /^[0-9]{2,5}$/ } })], updateOn: 'blur' }),
      autoscreening: new FormControl(''),
      smsscreening: new FormControl(''),
      callrejection: new FormControl(''),
      isallowList: new FormControl(''),
      blockedList: new FormArray([]),
      allowedList: new FormArray([]),
    });
    if (this.blockedNumberList) {
      _.each(this.blockedNumberList, (number) => {
        this.blockedList.push(new FormControl(number))
      })
    }
    if (this.allowedNumberList) {
      _.each(this.allowedNumberList, (number) => {
        this.allowedList.push(new FormControl(number))
      })
    }

    this.blockedList.value.forEach(number => {
      if(this.allowedList.value.includes(number))
      this.isNumberRepeating = true;
    });
  }

  get blockedList() {
    return this.form.get('blockedList') as FormArray;
  }
  onAddBlockedNumber(number) {
    this.blockedList.push(new FormControl(number));

    if(this.allowedList.value.includes(number))
      this.isNumberRepeating = true;
  }

  onRemoveBlockedNumber(index) {
    this.blockedList.removeAt(index);
  }

  get allowedList() {
    return this.form.get('allowedList') as FormArray;
  }
  onAddAllowedNumber(number) {
    this.allowedList.push(new FormControl(number));

    if(this.blockedList.value.includes(number))
      this.isNumberRepeating = true;
  }

  onRemoveAllowedNumber(index) {
    this.allowedList.removeAt(index);
  }

}
