import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IEnvironment } from '../environment.interface';
import { ENVIRONMENT } from '../constants';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends BaseService {

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {
    super();
   }

  getCurrentLocationData() {
    return this.http.get(this.environment.ipstackAPI)
      .pipe(catchError(this.handleError));
  }
}
