<div class="modal-header p-4">
  <div class="d-flex flex-row align-items-center justify-content-between flex-grow-1">
    <div class="d-flex align-items-center"><i class="fas fa-chevron-circle-up mr-3" style="font-size: 22px;"></i>
      <h4 class="m-0 font-weight-bold text-primary">Switch to Annual Billing</h4>
    </div>
    <button type="button" class=" btn p-0" (click)="bsModalRef.hide(); onCardChangeCancel()">
      <i class="fas fa-times-circle fa-lg text-primary"></i>
    </button>
  </div>
</div>

<div>
  <div class="card border-top-0 p-4 d-flex flex-row justify-content-between">
    <div class="d-flex align-items-center flex-grow-1" *ngIf="!showCreditCard">
      <i class="fas fa-credit-card fa-2x text-secondary pr-3"></i>
      <div class="h4 m-0"> <span class="text-color"> Credit Card: </span><span class="font-weight-bold">****
          {{this.cardDetail.last4}}</span>
      </div>
    </div>
    <button class="btn bg-primary-gradient text-primary px-4" (click)="this.stripeService.mountNewCard(true)"
      *ngIf="!showCreditCard">CHANGE</button>
    <form (ngSubmit)="onSubmit()" class="d-flex align-items-center" [ngClass]="{'flex-grow-1':showCreditCard}">
      <div #cardElement class="input flex-grow-1"></div>
      <button class="btn bg-primary text-primary px-2 ml-2" *ngIf="showCreditCard" type="submit">
        <span *ngIf="!cardChangeRequesting">Submit</span>
        <mat-spinner *ngIf="cardChangeRequesting" diameter="22" value="accent" class="white-spinner"></mat-spinner>
      </button>
      <button class="btn bg-primary text-primary px-2 ml-2" *ngIf="showCreditCard" (click)="onCardChangeCancel()">
        <span>Cancel</span>
      </button>
    </form>
  </div>
  <div class="card py-3 px-4 d-flex flex-column flex-lg-row flex-wrap  border-top-0">
    <div class="flex-fill d-flex flex-column">
      <div class="h6 header" style="background-color: #5D5D5D;">
        <img src="assets/images/clock-circular.png" alt="" height="16px">
        <span class="pl-1">Monthly Billing <span class="font-weight-bold">(Current)</span></span></div>
      <div class="d-flex flex-column flex-grow-1" style="background-color: #F9F9F9;">
        <div class="d-flex flex-row justify-content-between border-top p-3 m-0 h6">
          <div class="d-flex text-color">
            <img src="assets/images/clock-circular-grey.png" alt="" height="18px">
            <span class="pl-1">Monthly Price</span></div>
          <ng-container>
            <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
            <div class="font-weight-bold" *ngIf="!loadingBillingDetails">
              {{this.billingDetails.currentSubtotal|formatMoneyValue}}</div>
          </ng-container>
        </div>

        <div class="d-flex flex-row justify-content-between border-top p-3 m-0 h6">
          <div class="d-flex text-color">
            <img src="assets/images/percent-circular.png" alt="" height="18px">
            <span class="pl-1">Monthly Tax</span></div>
          <ng-container>
            <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
            <div class="font-weight-bold" *ngIf="!loadingBillingDetails">
              {{this.billingDetails.currentTax|formatMoneyValue}}</div>
          </ng-container>
        </div>
      </div>
      <div class="header justify-content-center h6" style="background-color: #EFEDEC; color:#5D5D5D;">
        <i class="far fa-calendar-alt pr-2 fa-lg"></i> Next Billing:
        {{this.nextBillingDate|date:'MM/dd/yyyy'||'00/00/0000'}}
      </div>
    </div>
    <div class="m-2 align-self-center">
      <i class="far fa-chevron-double-right fa-lg text-secondary d-none d-lg-block"></i>
      <i class="far fa-chevron-double-down fa-lg text-secondary d-lg-none"></i>
    </div>
    <div class="flex-fill d-flex flex-column h-100">
      <div class="bg-primary-gradient header h6">
        <img src="assets/images/clock-circular-upgrade.png" alt="" height="22px">
        <span>Annual Billing <span class="font-weight-bold">(New)</span></span></div>
      <div class="h-100" style="background-color: #F9F9F9;">
        <div class="d-flex flex-row justify-content-between border-top p-3 m-0 h6">
          <div class="d-flex text-color">
            <img src="assets/images/clock-circular-grey.png" alt="" height="18px">
            <span class="pl-1">Annual Price</span></div>
          <div class="d-flex justify-content-between" style="width: 45%;">
            <div class="bg-primary-gradient text-primary px-2 py-1 align-self-center" style="font-size: 10px;">20% OFF
            </div>
            <ng-container>
              <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
              <div class="font-weight-bold text-secondary" *ngIf="!loadingBillingDetails">
                {{this.billingDetails.upgradeSubtotal|formatMoneyValue}}</div>
            </ng-container>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-between border-top p-3 m-0 h6">
          <div class="d-flex text-color">
            <img src="assets/images/percent-circular.png" alt="" height="18px">
            <span class="pl-1">Annual Tax</span>
          </div>
          <div class="d-flex justify-content-end" style="width: 45%;">
            <!-- <div class="bg-primary-gradient text-primary px-2 py-1 align-self-center" style="font-size: 10px;">20% OFF
            </div> -->
            <ng-container>
              <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
              <div class="font-weight-bold text-secondary" *ngIf="!loadingBillingDetails">
                {{this.billingDetails.upgradeTax|formatMoneyValue}}</div>
            </ng-container>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between border-top p-3 m-0 h6">
          <div class="d-flex text-color">
            <img src="assets/images/dollar-circular.png" alt="" height="18px">
            <span class="pl-1">Current Month Credit</span></div>
          <ng-container>
            <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
            <div class="font-weight-bold text-secondary" *ngIf="!loadingBillingDetails">
              {{this.billingDetails.upgradeCredit|formatMoneyValue}}</div>
          </ng-container>
        </div>
      </div>
      <div class="header justify-content-between h6 font-weight-bold" style="background-color: #EFEDEC; color:#5D5D5D;">
        <div>Total to Pay</div>
        <ng-container>
          <mat-spinner *ngIf="loadingBillingDetails" class="float-right" diameter="22" value="accent"></mat-spinner>
          <div *ngIf="!loadingBillingDetails">{{this.billingDetails.upgradeTotal|formatMoneyValue}}</div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card p-4 d-flex flex-row justify-content-between align-items-center border-top-0">
    <div style="color: #959595;" class="mr-3">
      Changes will take place immediately. Payment amount will be
      recalculated in order to take into account the time left in your
      current subscription.
    </div>
    <div class="w-100">
      <button
        class="btn bg-primary-gradient text-primary w-100 p-4 font-weight-bold d-flex align-items-center justify-content-center"
        [disabled]="loadingBillingDetails" (click)="onChangePaymentPlan()">CONFIRM <mat-spinner
          *ngIf="confirmButtonClicked" class="d-inline-block white-spinner" diameter="22" value="accent">
        </mat-spinner>
      </button>
    </div>
  </div>
</div>

<div class="footer">
  <img src="assets/images/secure-payment-alt.png" alt="" style="height: 35px;">
  <img src="assets/images/stripe.png" alt="" style="height: 35px;">
  <img src="assets/images/cards.png" alt="" style="height: 35px;">
</div>