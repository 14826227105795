import { Input, Output, EventEmitter, Directive } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { MaskPipe, MaskApplierService } from 'ngx-mask';


@Directive({})
export class SpamCallBlockedNumbersBase {
  number;
  @Input()
  blockedNumberList = []
  @Output()
  addBlockedNumber = new EventEmitter<any>();
  @Output()
  removeBlockedNumber = new EventEmitter<any>();
  mask = new MaskPipe(this.maskService);
  customPatterns = { '0': { pattern: new RegExp('[xX\\d]') } };

  constructor(protected toastr: ToastrService, protected maskService: MaskApplierService) {

  }

  onBlockNumber(phone) {
    if(_.isEmpty(phone)){
      return
    }
    let number = phone.internationalNumber.split(' ')[0] + ' ' + phone.number.replace('-', '');

    if (_.includes(this.blockedNumberList, number)) {
      this.toastr.error("You have already added " + this.formatInternationalNumber(number) + " in the Block list");
      return;
    }
    let validPhoneLength = phone.placeHolderExample?.match(/\d/g).join('').length;
    if(!validPhoneLength){
      this.toastr.error(`Please enter a valid number`);
      return;
    }
    if (phone.number.replace('-', '').length === validPhoneLength) {
      number=number.replace('+','');
      //number=number.replace(' ','');
      this.addBlockedNumber.emit(number)
    }
    else {
      this.toastr.error(`Please enter a ${validPhoneLength} digit valid number`)
      return;
    }

  }

  onDeleteNumber(index) {
    this.removeBlockedNumber.emit(index)
  }

  formatInternationalNumber(number) {

    const internationalNumber = number.split(' ');
    if (internationalNumber.length <= 1) {
      return '+1 ' + this.mask.transform(internationalNumber[0], ['000 000 0000', this.customPatterns])
    } else {
      return internationalNumber[0] + " " + this.mask.transform(internationalNumber[1], ['000 000 0000', this.customPatterns]);
    }

  }
}
