import { Component } from '@angular/core';
import { CrispService, CompanyNameChangeAction } from '@voiply/shared/common-ui';
import { SystemType } from '@voiply/shared/model';
import { CheckoutAfterCheckoutBase } from '../checkout.after-checkout.base';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import * as LogRocket from 'logrocket';

@Component({
  selector: 'voiply-checkout-business-after-checkout',
  templateUrl: './checkout-business-after-checkout.component.html',
  styleUrls: ['./../checkout.base.css']
})
export class CheckoutBusinessAfterCheckoutComponent extends CheckoutAfterCheckoutBase {

  @Dispatch() companyNameChange = (companyName) => new CompanyNameChangeAction(companyName);

  constructor(crispService: CrispService) {
    super(crispService, SystemType.Business);
  }

  onCompanyNameChange(companyName) {
    this.companyNameChange(companyName);
    this.crispService.CrispUserCompany = companyName;
    LogRocket.identify(this.orderId, { companyName: companyName });
  }
}
