import { Injectable, Inject } from '@angular/core';
import { BaseService } from './base.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IEnvironment } from '../environment.interface';
import { ENVIRONMENT } from '../constants';
import { catchError } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  makePaymentApi = `${this.environment.rootApi}/charge-payment-new/{id}`;
  makePaymentNewApi = `${this.environment.rootApi}/checkout-pay/{id}`;
  subscriptionApi = `${this.environment.rootApi}/checkout-subscribe/{id}`;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {
    super();
  }

  isVoiplyTeamMember(userId: string): Promise<any> {
    return this.http.get(`${this.environment.rootApi}/voiply-team-login/${userId}`).toPromise();
  }

  //TODO: need to move this out of this service to somewhere else.
  makePayment(orderId: string, stripeToken: any): Promise<any> {
    if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
      return this.http.post(`${this.makePaymentApi.replace("{id}", orderId)}?dev=true&stripeToken=${stripeToken}`, null).toPromise();
    else
      return this.http.post(`${this.makePaymentApi.replace("{id}", orderId)}?stripeToken=${stripeToken}`, null).toPromise();
  }

  makePaymentNew(orderId: string, paymentMethodId: any): Promise<any> {
    if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
      return this.http.post(`${this.makePaymentNewApi.replace("{id}", orderId)}?dev=true&paymentMethodId=${paymentMethodId}`, null).toPromise();
    else
      return this.http.post(`${this.makePaymentNewApi.replace("{id}", orderId)}?paymentMethodId=${paymentMethodId}`, null).toPromise();
  }

  createSubscription(orderId: string, paymentMethodId: any, subscriptionInfo: any): Promise<any> {
    if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
      return this.http.post(`${this.subscriptionApi.replace("{id}", orderId)}?dev=true&paymentMethodId=${paymentMethodId}`, { subscriptionInfo }).toPromise();
    else
      return this.http.post(`${this.subscriptionApi.replace("{id}", orderId)}?paymentMethodId=${paymentMethodId}`, { subscriptionInfo }).toPromise();
  }


  getCustomerInvoices(orderId: string): Observable<any> {
    if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
      return this.http.get(`${this.environment.rootApi}/invoices/${orderId}?dev=true&amount=-1`).pipe(catchError(this.handleError));
    else
      return this.http.get(`${this.environment.rootApi}/invoices/${orderId}?amount=-1`).pipe(catchError(this.handleError));
  }
  getUpcomingInvoice(orderId: string): Observable<any> {
    if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
      return this.http.get(`${this.environment.rootApi}/upcoming-invoice/${orderId}?dev=true`).pipe(catchError(this.handleError));
    else
      return this.http.get(`${this.environment.rootApi}/upcoming-invoice/${orderId}`).pipe(catchError(this.handleError));
  }

  customerUpdate(orderId, changes): Promise<any> {
    return this.http.post(`${this.environment.rootApi}/customer-update/${orderId}`, { changes }).toPromise();
  }

  chargePaymentPhones(orderId, total, phones, taxDetail, stripeToken, submissionId): Promise<any> {
    if (location.origin.indexOf("dev") > -1 || location.origin.indexOf("localhost") > -1)
      return this.http.post(`${this.environment.rootApi}/charge-payment-phones/${orderId}?dev=true&code=IVGAdORM6yGqlqbGQRURgDO38GqN8nEARnXfa8Rs4oQOWcoR7eNpeA==`, { total, phones, taxDetail, stripeToken, submissionId }).toPromise();
    else
      return this.http.post(`${this.environment.rootApi}/charge-payment-phones/${orderId}?code=IVGAdORM6yGqlqbGQRURgDO38GqN8nEARnXfa8Rs4oQOWcoR7eNpeA==`, { total, phones, taxDetail, stripeToken, submissionId }).toPromise();

  }
}
